import { useEffect, useState } from 'react';
import { get, put } from '../../../../../api/base';
import { apiUrls, icons } from '../../../../../constants';
import { toast } from 'react-toastify';
import { Toast, Select, SuccessPopUp } from '../../../../../components';
import { useDispatch } from 'react-redux';
import { setShowLoading } from '../../../../../store/slices/popup';

const ShareFormModal = ({ showModal, setShowModal, formId }) => {
  const [toOptions, setToOptions] = useState([]);
  const [selectedTo, setSelectedTo] = useState(null);
  const [remark, setRemark] = useState(null);
  const dispatch = useDispatch();
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const getDataUsersTo = () => {
    get(`${apiUrls.DYNAMIC_FORM}/users-notification`, { query: { type: 'CC' } }).then(res => {
      if (res.status === 200) {
        setToOptions(res.data.data);
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />);
      }
    });
  };

  const close = () => {
    setShowModal(false);
    setSelectedTo(null);
    setRemark(null);
  };

  const submit = () => {
    dispatch(setShowLoading(true));
    put(`${apiUrls.DYNAMIC_FORM}/contents/${formId}`, {
      shared_users: selectedTo,
      remarks: remark
    }).then(res => {
      dispatch(setShowLoading(false));
      if (res.status === 201) {
        close();
        setShowSuccessModal(true);
      } else {
        if (res.data.error.errors[0][0]) {
          toast.error(
            <Toast message={'Error'} detailedMessage={`${res.data.error.errors[0][0].message}`} />
          );
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />);
        }
      }
    });
  };

  useEffect(() => {
    if (showModal) getDataUsersTo();
  }, [showModal]);
  return (
    <div>
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px]  sm:h-fit">
          <div className="bg-white">
            <div className="flex items-center justify-between">
              <div className="font-semibold text-[18px] text-[#232323]">Share Form Response</div>
              <img
                src={icons.icCloseModal}
                className="w-6 h-6 cursor-pointer"
                alt={'close-modal'}
                onClick={close}
              />
            </div>
            <hr className="mt-2 mb-6" />
            <div>
              <div>
                <div className="font-semibold text-[14px] mt-[28px] mb-[16px]">To</div>
                <Select
                  getOptionLabel={option => option.full_name}
                  getOptionValue={option => option.id}
                  onChange={selectedOptions => setSelectedTo(selectedOptions)}
                  options={toOptions}
                  name="Type"
                  className={
                    'input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4'
                  }
                  placeholder="Select to user"
                  isMulti={true}
                  isSearchable
                  value={selectedTo}
                  styles={{
                    option: provided => ({
                      ...provided
                    }),
                    control: provided => ({
                      ...provided,
                      ':focus': { borderColor: '#6546C3' },
                      ':active': { borderColor: '#6546C3' },
                      ':hover': { borderColor: '#6546C3' },
                      paddingLeft: 4,
                      boxShadow: 'none'
                    }),
                    dropdownIndicator: provided => ({
                      ...provided,
                      color: '#6546C3'
                    }),
                    indicatorSeparator: () => ({}),
                    valueContainer: provided => ({
                      ...provided
                    }),
                    multiValueLabel: provided => ({
                      ...provided,
                      color: '#fff',
                      fontFamily: 'poppins'
                    }),
                    multiValueRemove: provided => ({
                      ...provided,
                      color: '#fff'
                    }),
                    multiValue: provided => ({
                      ...provided,
                      backgroundColor: '#6546C3',
                      borderRadius: 8
                    }),
                    menuList: base => ({
                      ...base,
                      maxHeight: '200px'
                    })
                  }}
                />
              </div>
              <div>
                <div className="font-semibold text-[14px] mt-[28px] mb-[16px]">Remark</div>
                <textarea
                  className="text-[12px] px-[16px] py-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
                  rows={5}
                  placeholder="Enter remark"
                  value={remark ? remark : ''}
                  onChange={event => setRemark(event.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center sm:justify-end w-full flex-row mt-9 gap-6">
            <button
              className="bg-transparent border-2 border-[#6546C3] rounded-lg py-3 px-8 font-semibold text-[#6546C3] text-[14px]"
              onClick={close}
            >
              Cancel
            </button>
            <button
              className="bg-[#6546C3] border-2 border-[#6546C3] rounded-lg py-3 px-8 font-semibold text-white text-[14px]r"
              onClick={submit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <SuccessPopUp
        show={showSuccessModal}
        title={'Successfully'}
        message={'Hooray! You have successfully share form'}
        labelSuccessButton={'Ok, Back'}
        handleSuccessButton={() => setShowSuccessModal(false)}
      />
    </div>
  );
};
export default ShareFormModal;
