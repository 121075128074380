import { useEffect, useState } from 'react';
import { apiUrls, icons, images } from '../../constants';
import { del, get } from '../../api/base';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Toast from '../Toast';
import Loading from '../Loading';
import EmptyData from '../EmptyData';
import AddOwnerModal from './AddOwnerModal';
import SuccessPopUp from '../Popup/SuccessPopup';
import { useDispatch } from 'react-redux';
import { setShowLoading } from '../../store/slices/popup';

const OwnerList = () => {
  const [search, setSearch] = useState('');
  const [owners, setOwners] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const searchData = event => {
    switch (true) {
      case event.key === 'Enter':
      case event.target.value.length === 0:
        setSearch(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleSuccessButton = () => {
    setSuccessModal(false);
    setShowModal(false);
    getOwners();
  };

  const getOwners = () => {
    setIsFetching(true);
    get(`${apiUrls.ISSUE}/${id}/owners`).then(res => {
      const { status } = res;
      if (status === 200) {
        setOwners(res.data);
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={res.data.error.message} />);
      }
      setIsFetching(false);
    });
  };

  const handleDeleteOwner = ownerId => {
    setSuccessMessage('Hooray! You have successfully remove owner');
    dispatch(setShowLoading(true));
    del(`${apiUrls.ISSUE}/${id}/owners/${ownerId}`).then(res => {
      dispatch(setShowLoading(false));
      if (res.status === 200) {
        setSuccessModal(true);
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={res.data.error.message} />);
      }
    });
  };

  const onClickAddButton = () => {
    setSuccessMessage('Hooray! You have successfully add owner');
    setShowModal(true);
  };

  useEffect(() => {
    getOwners();
  }, []);

  const CardOwner = ({ owner }) => {
    return (
      <div className="drop-shadow-md flex justify-between items-center lg:w-[32vw] rounded-md p-[16px] bg-white">
        <div className="flex">
          <img
            src={owner.picture ? owner.picture : icons.icActiveUserOnline}
            alt="photoProfile"
            className="rounded-full h-[36px] w-[36px]"
          />
          <div className="flex flex-col px-[12px] justify-center">
            <div className="font-semibold text-[12px]">{owner.full_name}</div>
            <div className="text-[#AFAFB9] text-[12px]">
              {owner.department ? owner.department : ''}
            </div>
          </div>
        </div>

        <div>
          {owner.is_creator ? (
            <div className="bg-[#EBEDF8] rounded-[8px] text-[#6546C3] text-[12px] font-semibold py-1 px-2">
              Owner
            </div>
          ) : (
            <img
              src={icons.icTrashSecondary}
              alt="delete"
              className="rounded-full h-[28px] w-[28px] cursor-pointer"
              onClick={() => handleDeleteOwner(owner.id)}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="bg-white rounded-2xl mx-0 sm:mx-4">
        <div className="max-h-[80vh] mt-[20px] flex flex-col  justify-between p-[16px] mb-[40px] gap-4">
          <div className="flex flex-row items-center justify-between">
            <div className="flex items-center gap-x-[16px]">
              <div className="font-semibold text-[16px]">Owner List</div>
              <img
                src={icons.icAddSquare}
                className="w-[32px] h-[32px] cursor-pointer"
                alt="Add owner"
                onClick={onClickAddButton}
              />
            </div>
            <div className="flex h-[40px] bg-white border-[1px] items-center p-[12px] rounded-lg sm:w-[25vw] w-[45vw]">
              <img src={icons.icSearch} className="w-[14px] h-[14px]" alt="Search" />
              <input
                className="ml-2 focus:outline-none text-[12px] w-full"
                defaultValue={search}
                placeholder="Search here..."
                onKeyDown={searchData}
                onBlur={searchData}
              />
            </div>
          </div>

          <div className="h-[80vh]">
            {isFetching ? (
              <div className="flex justify-center items-center h-[55vh] mx-auto">
                <Loading />
              </div>
            ) : owners.length ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2  max-h-[55vh] overflow-auto pb-4">
                {search
                  ? owners
                      .filter(o => o.full_name?.toLowerCase().includes(search.toLowerCase()))
                      .map((owner, index) => {
                        return <CardOwner owner={owner} key={index} />;
                      })
                  : owners.map((owner, index) => <CardOwner owner={owner} key={index} />)}
              </div>
            ) : (
              <EmptyData title={'Not Found'} />
            )}
          </div>

          <div className="mt-[20px] sm:mt-[20px] m-[12px] mb-[16px]">
            <button
              className=" bg-transparent border-2 border-[#6546C3] rounded-lg py-[10px] w-[150px] font-semibold text-[#6546C3] 
                    text-[14px] flex justify-center items-center"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
        </div>
      </div>
      <AddOwnerModal
        showModal={showModal}
        id={id}
        setShowModal={setShowModal}
        setSuccessModal={setSuccessModal}
      />

      <SuccessPopUp
        show={successModal}
        imageSuccess={images.imageSuccess}
        title="Successfully"
        message={successMessage}
        labelSuccessButton="Ok, Back to List"
        labelBackButton=""
        handleSuccessButton={handleSuccessButton}
      />
    </div>
  );
};

export default OwnerList;
