import { useEffect, useState } from 'react';
import { get } from '../../../api/base';
import { apiUrls, icons } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { EmptyData, Loading, TableAccent, Toast } from '../../../components';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { BsPlus } from 'react-icons/bs';
import FilterModal from './components/Filter';
import moment from 'moment';
import { setPage } from '../../../store/slices/table';
import _ from 'lodash';

const CardTask = ({ task, mappingStatus }) => {
  const navigate = useNavigate();
  let assignee = task?.users?.concat(task?.groups);
  return (
    <div
      className="flex flex-row gap-2 p-5 bg-white items-center mt-2 rounded-2xl w-full"
      onClick={() => {
        if (task?.status === 'DRAFT') {
          navigate(`/Task/TaskSubmission/${task?.id}`);
        } else {
          navigate(`/Task/DetailTask/${task?.id}`);
        }
      }}
    >
      <div className="w-1/4">
        <div className="flex flex-col w-fit items-center">
          {task?.status === 'DRAFT' ? (
            <img
              src={icons.icDraft}
              alt="draft"
              className="w-[40px] h-[40px] rounded-full border-4 border-white"
            />
          ) : (
            <div className="flex flex-col gap-1 items-center">
              <div className="avatar-group -space-x-6 w-fit">
                {assignee?.map((data, index) => {
                  if (index < 2) {
                    return (
                      <div key={index} className="avatar">
                        <div className="w-[36px] h-[36px] rounded-full">
                          <img
                            src={data?.picture ? data.picture : icons.icActiveUserOnline}
                            alt="avatar"
                            className="bg-white"
                          />
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
              {assignee?.length > 2 ? (
                <div className="text-[#6546C3]">+{assignee.length - 2}</div>
              ) : null}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="min-w-[6rem] max-w-[180px] font-bold text-[12px] text-[#232323] truncate mb-1">
          {assignee?.length ? assignee[0]?.full_name || assignee[0]?.name : 'No Assign Yet!'}
        </div>
        <div className="min-w-[6rem] max-w-[180px] text-[12px] text-[#AFAFB9] truncate mb-2">
          {task?.title}
        </div>
        <div className="rounded-full text-[#C800A5] bg-[#C800A5] bg-opacity-[0.1] p-[2px] items-center text-[12px] flex flex-row w-[110px]">
          <img src={icons.icLastUpdate} alt="lastUpdate" height={15} width={15} className="mr-2" />
          {moment(task?.due_date).format('DD-MMM-YYYY')}
        </div>
      </div>
      <div className="ml-auto">
        <div
          className={`text-[12px] font-bold italic ${
            task?.status === 'DRAFT'
              ? 'text-[#6546C3]'
              : task?.status === 'OPEN_TASK'
              ? 'text-[#01B59C]'
              : 'text-[#C800A5]'
          }`}
        >
          {mappingStatus(task?.status)}
        </div>
      </div>
    </div>
  );
};

const ListTaskPage = () => {
  const { limits, page, pageSize, search, order_by } = useSelector(state => state.table);
  const [tasks, setTasks] = useState(null);
  const [dataTasks, setDataTasks] = useState(null);
  const [pageRef, setPageRef] = useState(1);
  const [searchRef, setSearchRef] = useState('');
  const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });
  const [totalData, setTotalData] = useState(0);
  const [firstFetching, setFirstFetching] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const user = useSelector(state => state.user.userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let filterTasked = {
    myDocument: false,
    myGroup: false,
    myWorkingLocation: false,
    latest: false,
    earlier: false,
    draft: false,
    open: false,
    closed: false,
    assignee: ''
  };
  const [filterLength, setFilterLength] = useState(0);
  const [filterAttribute, setFilterAttribute] = useState(filterTasked);
  const [offlineFilterAttribute, setOfflineFilterAttribute] = useState(filterTasked);
  const headers = [
    {
      key: 'action',
      name: 'Action',
      isSortable: false,
      sortKey: null
    },
    {
      key: 'users',
      name: 'Name',
      isSortable: true
    },
    {
      key: 'title',
      name: 'Title',
      isSortable: true
    },
    {
      key: 'status',
      name: 'Status',
      isSortable: true
    },
    {
      key: 'due_date',
      name: 'Due Date',
      isSortable: true
    }
  ];

  const offlineFilter = () => {
    let rawTask = [];

    if (offlineFilterAttribute.myDocument) {
      rawTask = [
        ...rawTask,
        ..._.filter(dataTasks.data, function (task) {
          return (
            task.users.some(taskUser => taskUser.id === user.id) ||
            task.created_by === user?.username
          );
        })
      ];
    }
    if (offlineFilterAttribute.myGroup) {
      rawTask = [
        ...rawTask,
        ..._.filter(dataTasks.data, function (task) {
          return user.groups.some(group =>
            task.groups.some(
              groupss =>
                groupss.name === group.name && groupss.work_location_id === group.work_location_id
            )
          );
        })
      ];
    }
    if (offlineFilterAttribute.myWorkingLocation) {
      rawTask = [
        ...rawTask,
        ..._.filter(dataTasks.data, function (task) {
          return user.groups.some(group =>
            task.groups.some(groupss => groupss.work_location_id === group.work_location_id)
          );
        })
      ];
    }

    if (offlineFilterAttribute.open) {
      rawTask = [
        ...rawTask,
        ..._.filter(dataTasks.data, function (task) {
          return task.status === 'OPEN_TASK';
        })
      ];
    }
    if (offlineFilterAttribute.draft) {
      rawTask = [
        ...rawTask,
        ..._.filter(dataTasks.data, function (task) {
          return task.status === 'DRAFT';
        })
      ];
    }
    if (offlineFilterAttribute.closed) {
      rawTask = [
        ...rawTask,
        ..._.filter(dataTasks.data, function (task) {
          return task.status === 'COMPLETED_TASK';
        })
      ];
    }

    if (offlineFilterAttribute.assignee > 0) {
      rawTask = [
        ...rawTask,
        ..._.filter(dataTasks.data, function (task) {
          return task.users?.some(user => user.id === offlineFilterAttribute.assignee);
        })
      ];
    }

    if (offlineFilterAttribute.earlier) {
      if (rawTask.length) {
        rawTask?.sort((a, b) => new Date(a.planned_start) - new Date(b.planned_start));
      } else {
        rawTask = dataTasks?.data;
        rawTask?.sort((a, b) => new Date(a.planned_start) - new Date(b.planned_start));
      }
    }

    if (offlineFilterAttribute.latest) {
      if (rawTask.length) {
        rawTask?.sort((a, b) => new Date(b.planned_start) - new Date(a.planned_start));
      } else {
        rawTask = dataTasks?.data;
        rawTask?.sort((a, b) => new Date(b.planned_start) - new Date(a.planned_start));
      }
    }

    if (!rawTask.length) rawTask = dataTasks?.data;

    filterLengthCount(offlineFilterAttribute);
    setTasks({
      total_data: rawTask?.length,
      total_page: 1,
      data: rawTask
    });
    return;
  };

  const loadMoreData = reloadPage => {
    const filter = {
      order_by,
      sort_by: 'planned_start',
      limit: pageSize,
      page: reloadPage ? 1 : pageRef,
      search: searchRef
    };
    if (!searchRef) {
      delete filter.search;
    }
    get(apiUrls.TASK, {
      query: { ...filter, ...populateFilter() }
    })
      .then(res => {
        if (res.status === 200) {
          if (filter.page === 1) {
            setTasks(res.data);
            setDataTasks(res.data);
          } else {
            const result = tasks.data.concat(res.data.data);
            setTasks({ ...tasks, data: result });
            setDataTasks({ ...tasks, data: result });
          }

          setTotalData(res.data.total_data);
          setPageRef(filter.page + 1);
          setFirstFetching(false);
        } else {
          setFirstFetching(false);
          toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />);
        }
      })
      .catch(_ => {
        setFirstFetching(false);
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
      });
  };
  const searchDataRef = ev => {
    switch (true) {
      case ev.key === 'Enter':
      case ev.target.value.length === 0:
        if (navigator.onLine) {
          setSearchRef(ev.target.value);
        } else {
          offlineSearch(ev.target.value);
        }
        break;
      default:
        break;
    }
  };
  const initData = () => {
    const filter = {
      order_by,
      sort_by: 'planned_start',
      limit: pageSize,
      page,
      search
    };
    if (!search) {
      delete filter.search;
    }
    setIsFetching(true);
    get(apiUrls.TASK, {
      query: { ...filter, ...populateFilter() }
    })
      .then(res => {
        setIsFetching(false);
        if (res.status === 200) {
          if (page > res.data.total_page) {
            dispatch(setPage(1));
          }
          setTasks(res.data);
          setDataTasks(res.data);
          setFirstFetching(false);
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />);
        }
      })
      .catch(_ => {
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
        setIsFetching(false);
      });
  };

  const filterLengthCount = attribute => {
    let rawLength = Object.values(attribute).reduce(
      (before, after) => before + (after === true ? 1 : 0),
      0
    );
    if (attribute.assignee > 0) {
      rawLength = rawLength + 1;
    }
    setFilterLength(rawLength);
  };

  function clean(obj) {
    for (let propName in obj) {
      if (typeof obj[propName] === 'string' && obj[propName].trim() === '') {
        delete obj[propName];
      } else if (Array.isArray(obj[propName]) && obj[propName].length === 0) {
        delete obj[propName];
      }
    }
    return obj;
  }

  const populateFilter = () => {
    let tempQuery = {
      assignee: filterAttribute.assignee,
      status_task: [],
      group: []
    };

    const filterMap = {
      myDocument: 'DOCUMENT',
      myGroup: 'GROUP',
      myWorkingLocation: 'WORK_LOCATION',
      draft: 'DRAFT',
      open: 'OPEN_TASK',
      closed: 'COMPLETED_TASK'
    };

    Object.entries(filterAttribute).forEach(([key, value]) => {
      if (value && filterMap[key]) {
        if (key.startsWith('my')) {
          tempQuery.group.push(filterMap[key]);
        } else {
          tempQuery.status_task.push(filterMap[key]);
        }
      }
    });
    filterLengthCount(filterAttribute);
    return clean(tempQuery);
  };

  const offlineSearch = value => {
    if (value.length) {
      let rawTask = _.filter(tasks?.data, function (task) {
        return task.title.toLowerCase().includes(value);
      });
      setTasks({ total_data: rawTask.length, total_page: 1, data: rawTask });
    } else {
      offlineFilter();
    }
  };

  useEffect(() => {
    if (!isSmallScreen) initData();
  }, [limits, page, pageSize, order_by, search, filterAttribute]);

  useEffect(() => {
    if (!firstFetching) setFirstFetching(true);
    if (isSmallScreen) loadMoreData(true);
  }, [searchRef, order_by, filterAttribute]);

  useEffect(() => {
    if (!navigator.onLine) offlineFilter();
  }, [offlineFilterAttribute]);

  const handleOnClickTaskSubmission = () => {
    navigate('/Task/TaskSubmission');
  };

  const mappingStatus = status => {
    switch (status) {
      case 'DRAFT':
        return 'Draft';
      case 'OPEN_TASK':
        return 'Open';
      case 'COMPLETED_TASK':
        return 'Closed';
      default:
        return;
    }
  };

  return (
    <>
      {isSmallScreen ? (
        <div className="p-5 min-h-screen flex flex-col sm:hidden mb-[120px]">
          <div className="py-5 max-h-[calc(100%-17rem)] flex flex-col gap-y-2 overflow-y-scroll">
            <div className="flex flex-row-reverse md:flex-row gap-4">
              <div
                className={`flex gap-1 border-[1px] border-[#463191] border-opacity-25 items-center justify-center min-w-[45px] w-fit h-[40px] rounded-lg ${
                  filterLength > 0 ? 'px-3' : ''
                }`}
              >
                {filterLength > 0 && (
                  <div className="py-0.5 px-2 rounded-md bg-[#6546C3] text-white font-semibold">
                    {filterLength}
                  </div>
                )}
                <img
                  src={icons.icFilter}
                  className="w-[26px] h-[26px]"
                  alt="Filter"
                  onClick={() => setFilterModal(true)}
                />
              </div>
              <div className="flex w-full md:min-w-[150px] h-[40px] bg-white border-[1px] items-center p-[12px] rounded-lg">
                <img src={icons.icSearch} className="w-[14px] h-[14px]" alt="Search" />
                <input
                  className="ml-2 focus:outline-none text-[12px] w-full"
                  key={search}
                  defaultValue={search}
                  placeholder="Search here..."
                  onKeyDown={searchDataRef}
                  onBlur={searchDataRef}
                />
              </div>
            </div>
            {firstFetching ? (
              <div className="flex justify-center h-[50vh] my-auto">
                <Loading />
              </div>
            ) : tasks?.data?.length ? (
              <InfiniteScroll
                dataLength={tasks?.data?.length || 0}
                next={() => loadMoreData(false)}
                hasMore={tasks?.data?.length < totalData}
                className="!overflow-hidden"
                loader={
                  <div className="flex justify-center h-[100px] my-auto">
                    <Loading />
                  </div>
                }
              >
                {tasks?.data?.map((task, index) => (
                  <CardTask task={task} key={index} mappingStatus={mappingStatus} />
                ))}
              </InfiniteScroll>
            ) : (
              <EmptyData
                title={'There is no document to show'}
                message={'You have not submitted any form yet'}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="hidden sm:flex sm:flex-col mb-[120px] lg:mb-0">
          <TableAccent
            {...{
              isFetching,
              tableHeaders: headers,
              data: tasks,
              onFilterClick: () => setFilterModal(true),
              iconFilter: icons.icFilter,
              filterLength: filterLength,
              emptyDataTitle: 'There is no document to show',
              emptyDataDescription: 'You have not submitted any form yet',
              offlineSearch
            }}
          >
            {(item, tHead) => {
              switch (tHead.key) {
                case 'action':
                  return (
                    <div className="flex justify-center m-0 w-full">
                      <button
                        className="flex justify-center items-center bg-[#F1F1FD] w-[24px] h-[24px] rounded-md"
                        onClick={() => {
                          if (item?.status === 'DRAFT') {
                            navigate(`/Task/TaskSubmission/${item?.id}`);
                          } else {
                            navigate(`/Task/DetailTask/${item?.id}`);
                          }
                        }}
                      >
                        <img src={icons.icArrowRight} alt="detail task" />
                      </button>
                    </div>
                  );
                case 'users':
                  let assignee = item?.users?.concat(item?.groups);
                  return (
                    <div className="flex flex-row gap-2 items-center w-full">
                      {item.status === 'DRAFT' ? (
                        <img
                          src={icons.icDraft}
                          alt="draft"
                          className="w-[28px] h-[28px] rounded-full border-4 border-white"
                        />
                      ) : (
                        <div className="flex flex-row gap-1 items-center">
                          <div className="avatar-group -space-x-4 w-fit">
                            {assignee?.map((data, index) => {
                              if (index < 2) {
                                return (
                                  <div key={index} className="avatar">
                                    <div className="w-6 h-6 rounded-full">
                                      <img
                                        src={
                                          data?.picture ? data.picture : icons.icActiveUserOnline
                                        }
                                        alt="avatar"
                                        className="bg-white"
                                      />
                                    </div>
                                  </div>
                                );
                              }
                              return null;
                            })}
                          </div>
                          {assignee?.length > 2 ? (
                            <div className="text-[#6546C3]">+{assignee.length - 2}</div>
                          ) : null}
                        </div>
                      )}
                      <div className="w-full truncate text-[12px] text-[#232323]">
                        {assignee?.length
                          ? assignee[0]?.full_name || assignee[0]?.name
                          : 'No Assign Yet!'}
                      </div>
                    </div>
                  );
                case 'status':
                  return (
                    <div
                      className={`text-[12px] font-bold italic ${
                        item[tHead.key] === 'DRAFT'
                          ? 'text-[#6546C3]'
                          : item[tHead.key] === 'OPEN_TASK'
                          ? 'text-[#01B59C]'
                          : 'text-[#C800A5]'
                      }`}
                    >
                      {mappingStatus(item[tHead.key])}
                    </div>
                  );
                case 'due_date':
                  return (
                    <div className="rounded-full text-[#C800A5] bg-[#C800A5] bg-opacity-[0.1] p-[2px]  items-center text-[12px] flex flex-row w-[110px]">
                      <img
                        src={icons.icLastUpdate}
                        alt="lastUpdate"
                        height={15}
                        width={15}
                        className="mr-2"
                      />
                      {moment(item[tHead.key]).format('DD-MMM-YYYY')}
                    </div>
                  );
                default:
                  return (
                    <span className="w-full truncate text-[12px] text-[#232323]">
                      {item[tHead.key]}
                    </span>
                  );
              }
            }}
          </TableAccent>
        </div>
      )}
      <div className="fixed lg:bottom-5 right-5 bottom-[100px]">
        <button
          className="bg-[#6546C3] rounded-full text-white w-[44px] h-[44px] flex items-center justify-center"
          onClick={handleOnClickTaskSubmission}
        >
          <BsPlus size={35} />
        </button>
      </div>
      <FilterModal
        setOfflineFilterAttribute={setOfflineFilterAttribute}
        setPageRef={setPageRef}
        filterAttribute={filterAttribute}
        setFilterAttribute={setFilterAttribute}
        filterTasked={filterTasked}
        handleFilter={initData}
        showModal={filterModal}
        setShowModal={setFilterModal}
      />
    </>
  );
};
export default ListTaskPage;
