import moment from 'moment';
import { icons } from '../../../../../constants';
import FieldAttachment from '../../../Form/DetailForm/components/FieldAttachment';
import _ from 'lodash';
import Highlighter from 'react-highlight-words';

const CommentItem = ({ index, data, isLastComment, searchValue, scrollRef }) => {
  const createdAt = moment(data?.created_at).format('DD-MMM-YYYY h:mm:ss');
  const sender = data?.sender;
  const description = data?.description;
  const remarkMessage = data?.remark?.message;
  const assignTo = data.asign_to ? data.asign_to : [];
  const tags = data?.tags;
  const recording = data?.recording;
  const attachments = data?.attachments;
  return (
    <div>
      <div className="flex flex-row items-center mt-[16px]">
        <div className="h-1 w-1 rounded-full bg-[#6546C3]" />
        <div className="ml-[8px] text-[#AFAFB9] text-[12px]">{createdAt}</div>
      </div>
      <div className="flex flex-row">
        {isLastComment ? <div className="w-2" /> : <div className="bg-[#6546C3] w-1 mr-2" />}
        <div className="p-[12pt] flex flex-col bg-white rounded-[16px] drop-shadow-md flex-wrap w-full">
          <span className="text-[#C800A5] text-[12px] mb-1">
            {sender} <span className="text-[#232323]">{description}</span>
          </span>

          {/* Attachment */}
          {attachments?.length ? (
            <div className="my-2">
              <FieldAttachment attachments={attachments} submitted={true} />
            </div>
          ) : null}

          {/* Record Audio */}
          {!_.isEmpty(recording) ? (
            <div className="my-2">
              <FieldAttachment
                attachments={!_.has(recording[0], 'cacheIdentifier') ? [recording] : recording}
                submitted={true}
              />
            </div>
          ) : null}
          {/* Remark */}
          {remarkMessage?.length ? (
            <span className="p-[4pt] bg-[#EBEDF8] w-full flex flex-row rounded-md break-words gap-1.5 items-center">
              {remarkMessage.map((remark, temp) => (
                <div ref={el => (scrollRef.current[index] = el)} key={temp} className="text-[12px]">
                  {remark?.type === 'TEXT' ? (
                    <Highlighter
                      highlightClassName="highlighted-word"
                      searchWords={[searchValue]}
                      autoEscape={true}
                      textToHighlight={remark.value}
                    />
                  ) : (
                    <span className="text-[12px] text-[#6546C3] flex flex-row gap-1 items-center">
                      <img src={icons.icActiveUserOnline} height={20} width={20} alt="mention" />
                      {remark.value}
                    </span>
                  )}
                </div>
              ))}
            </span>
          ) : null}

          {/* Assignee */}
          {assignTo.length ? (
            <div className="flex flex-row flex-wrap">
              {assignTo.map((assign, index) => {
                return (
                  <div
                    key={index}
                    className="bg-[#EBEDF8] rounded-md flex flex-row items-center mr-2 mb-2 p-2"
                  >
                    <img src={icons.icActiveUserOnline} alt="user" height={20} width={20} />
                    <span className="text-[12px] ml-2 text-[#6546C3]">
                      {assign?.full_name || assign.name}
                    </span>
                  </div>
                );
              })}
            </div>
          ) : null}

          {/* Tag */}
          {tags?.length ? (
            <div className="flex flex-row">
              {tags.map((tag, index) => {
                return (
                  <div key={index} className="bg-[#EBEDF8] p-2 rounded-[8pt] my-2 mr-2">
                    <span className="text-[12px] text-[#6546C3]">{tag.name}</span>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CommentItem;
