import axios from 'axios';
import { apiUrls } from '../../constants';
import { logout, setToken } from '../../store/slices/auth';
import { store } from '../../store';
import { toast } from 'react-toastify';
import { Toast } from '../../components';

const baseURL = process.env.REACT_APP_BASE_URL;

const httpInstance = axios.create({ timeout: process.env.REACT_APP_TIMEOUT, baseURL });

const handleResponse = res => {
  if (!res) return { ok: false };

  const { data, status } = res;
  const ok = status && status >= 200 && status < 300;

  return { ok, status, data };
};

const isTokenExpired = res => {
  return res?.status === 401;
};
const isServerFault = res => res?.status >= 500 && res?.status < 600;

const handleLogout = async () => {
  store.dispatch(logout());
  await localStorage.clear();
};

const refreshTokenAndRetry = async (req, refreshToken) => {
  try {
    const res = await axios.get(`${baseURL}${apiUrls.REFRESH_TOKEN_URL}`, {
      params: {
        refresh_token: refreshToken
      }
    });
    const token = res.data.token;

    store.dispatch(setToken(token));
    req.headers.authorization = `Bearer ${token}`;
    return await httpInstance.request(req);
  } catch (error) {
    const { response } = error;
    if (isTokenExpired(response)) {
      return handleLogout();
    } else if (!isServerFault(response)) {
    }
    return handleResponse(error?.response);
  }
};

httpInstance.interceptors.response.use(
  response => handleResponse(response),
  async error => {
    const { response, config } = error;
    let responseTimeout;
    if (error?.code === 'ECONNABORTED') {
      toast.warning(
        <Toast
          message={'Error'}
          detailedMessage={
            'Looks like the server is taking too long to respond. Please try again in a while'
          }
        />
      );
    }

    if (error?.code === 'ERR_NETWORK') {
      responseTimeout = {
        status: 408,
        data: {
          error: {
            code: error.code,
            message: 'You are offline'
          }
        }
      };
    }

    const loginRequest = error.config.url.endsWith('/auth/login');
    if (isTokenExpired(error.response) && !config._retry && !loginRequest) {
      const { refreshToken } = store.getState().auth;
      config._retry = true;

      if (refreshToken) {
        return refreshTokenAndRetry(config, refreshToken);
      } else {
        return handleLogout();
      }
    }

    // TODO: HANDLE ERROR WITH UNDEFINED RESPONSE ( CLIENT ERROR MESSAGE )

    return handleResponse(response ? response : responseTimeout);
  }
);

export default httpInstance;
