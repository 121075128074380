import { useState } from 'react';
import { get, post } from '../../api/base';
import { toast } from 'react-toastify';
import { SuccessPopUp, Toast } from '../../components';
import { apiUrls, images } from '../../constants';
import { useEffect } from 'react';
import GenerateForm from '../Task/Form/DetailForm/GenerateForm';
import { useDispatch } from 'react-redux';
import { setShowLoading } from '../../store/slices/popup';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

const Feedback = () => {
  const [submission, setSubmission] = useState(null);
  const [successModal, setSuccessModal] = useState(false);
  let mandatoryField;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getFeedbackForm = () => {
    get(`${apiUrls.MASTER_DATA_FEEDBACK_FORM}`)
      .then(res => {
        if (res.status === 200) {
          setSubmission(res.data);
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />);
        }
      })
      .catch(_ =>
        toast.error(<Toast message={'Error'} detailedMessage={`Something went wrong`} />)
      );
  };

  const checkFieldValue = fields => {
    mandatoryField = [];
    for (const field of fields) {
      if (field?.additional_option?.length > 0) {
        for (const additional of field.additional_option) {
          if ((!additional?.value || !additional?.value?.length) && field?.mandatory) {
            field['highlight'] = true;
            mandatoryField.push(additional.title);
          } else if ((additional?.value || additional?.value?.length) && field?.mandatory) {
            if (field.hasOwnProperty('highlight')) {
              mandatoryField = mandatoryField.filter(function (e) {
                return e !== additional?.title;
              });
              delete field.highlight;
            }
          }
          if (field.field_type?.value === 'RADIO_BUTTON_ATTACHMENT') {
            for (const option of additional.options) {
              if (additional.value === option.label && option.mandatory) {
                if (_.isArray(additional.attachment_value)) {
                  if (!additional.attachment_value.length) {
                    return false;
                  }
                } else {
                  if (!additional.attachment_value?._parts.length) {
                    return false;
                  }
                }
              }
            }
          } else if (field.field_type?.value === 'CHECKBOX_ATTACHMENT') {
            for (const option of additional.options) {
              if (additional.value && additional.value !== '') {
                for (const value of additional?.value) {
                  if (value === option.label && option.mandatory) {
                    if (_.isArray(additional.attachment_value)) {
                      if (!additional.attachment_value.length) {
                        return false;
                      }
                    } else {
                      if (!additional.attachment_value?._parts.length) {
                        return false;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        if ((field?.value === '' || !field?.value.length) && field?.mandatory) {
          field['highlight'] = true;
          setSubmission({ ...submission });
          mandatoryField.push(field?.field_title);
        } else if ((field?.value !== '' || !field?.value.length) && field?.mandatory) {
          if (field.hasOwnProperty('highlight')) {
            mandatoryField = mandatoryField.filter(function (e) {
              return e !== field?.field_title;
            });
            delete field.highlight;
          }
          setSubmission({ ...submission });
        }
        if (field.field_type?.value === 'RADIO_BUTTON_ATTACHMENT') {
          for (const option of field.options) {
            if (field.value === option.label && option.mandatory) {
              if (_.isArray(field.attachment_value)) {
                if (!field.attachment_value.length) {
                  return false;
                }
              } else {
                if (!field.attachment_value?._parts.length) {
                  return false;
                }
              }
            }
          }
        }
        if (field.field_type?.value === 'CHECKBOX_ATTACHMENT') {
          for (const option of field.options) {
            if (field.value && field.value !== '') {
              for (const value of field.value) {
                if (value === option.label && option.mandatory) {
                  if (_.isArray(field.attachment_value)) {
                    if (!field.attachment_value.length) {
                      return false;
                    }
                  } else {
                    if (!field.attachment_value?._parts.length) {
                      return false;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return true;
  };

  const checkValue = (sections, attachment) => {
    for (const element of sections) {
      if (!checkFieldValue(element.fields)) {
        return false;
      }
      if (element.inner_section) {
        for (const inner of element.inner_section) {
          let resultInnFieldValue = checkFieldValue(inner);
          let resultFieldValue = checkFieldValue(element.fields);
          if (!resultInnFieldValue || !resultFieldValue) {
            return false;
          }
        }
      } else if (element.sub_sections?.length) {
        for (const sub of element.sub_sections) {
          let resultSubValue = checkFieldValue(sub.fields);
          let resultFieldValue = checkFieldValue(element.fields);
          if (!resultSubValue || !resultFieldValue) {
            return false;
          }
        }
      }
    }
    if (submission.attachment) {
      if (_.isArray(attachment)) {
        if (!attachment.length) {
          return false;
        }
      } else {
        if (!attachment?._parts?.length) {
          return false;
        }
      }
    }
    return true;
  };

  const checkMandatoryField = sections => {
    for (const section of sections) {
      const fields = section.fields;
      for (const field of fields) {
        if ('highlight' in field) {
          return false;
        }
      }
    }
    return true;
  };

  const storeForm = ({ sections, attachment, is_draft }) => {
    setSubmission({ ...submission, sections: sections });
    if (checkValue(sections, attachment) && checkMandatoryField(sections)) {
      let payload = {
        form_id: submission.id,
        sections: sections,
        submitted_time: new Date(),
        attachments: attachment && attachment.length ? attachment : undefined,
        location: null,
        is_draft: is_draft
      };
      submitForm(payload);
    } else {
      if (mandatoryField?.length) {
        toast.error(
          <Toast message={'Error'} detailedMessage={`${mandatoryField[0]} cannot be empty`} />
        );
      } else {
        toast.error(
          <Toast message={'Error'} detailedMessage={`Mandatory Field cannot be empty`} />
        );
      }
    }
  };

  const submitForm = finalPayload => {
    post(`${apiUrls.DYNAMIC_FORM}/contents`, finalPayload)
      .then(res => {
        dispatch(setShowLoading(false));
        if (res.status === 201) {
          setSuccessModal(true);
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={res.data?.error?.message} />);
        }
      })
      .catch(_ => {
        dispatch(setShowLoading(false));
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
      });
  };

  useEffect(() => {
    getFeedbackForm();
  }, []);

  return (
    <div className="flex flex-col gap-6 mx-4 px-4 rounded-lg">
      {submission && (
        <GenerateForm
          submission={{ ...submission }}
          submitted={false}
          storeForm={storeForm}
          isFeedback={true}
        />
      )}
      <SuccessPopUp
        show={successModal}
        imageSuccess={images.imageSuccess}
        title={'Successfully'}
        message={
          'Thank you for taking the time to provide feedback. Your input is valuable to us in developing and enhancing the quality of our application'
        }
        labelSuccessButton={'Ok'}
        labelBackButton={''}
        handleSuccessButton={() => navigate(0)}
      />
    </div>
  );
};

export default Feedback;
