import { useState } from 'react';
import { icons } from '../../../../../constants';
import { useNavigate } from 'react-router';

const CreateIssueModal = ({ showModal, setShowModal, formId }) => {
  const [description, setDescription] = useState('');
  const onChangeValue = event => {
    setDescription(event.target.value);
  };
  const navigate = useNavigate();
  return (
    <div>
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323/[0.5]">
        <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px]  sm:h-fit">
          <div className="bg-white">
            <div className="flex flex-row items-center justify-between">
              <div className="text-[18px] font-semibold">Create Issue</div>
              <img
                src={icons.icCloseModal}
                className="w-6 h-6 cursor-pointer"
                alt={'close-modal'}
                onClick={() => setShowModal(false)}
              />
            </div>
            <hr className="mt-2 mb-6" />
            <div className="flex flex-1 flex-col gap-2 ">
              <div className="font-semibold text-[12px]">Issue Description</div>
              <textarea
                className={
                  'text-[12px] px-[16px] py-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] h-[113px]'
                }
                defaultValue={description}
                onChange={event => onChangeValue(event)}
              />
            </div>
            <div className="flex justify-center sm:justify-end w-full flex-row mt-9 gap-4 sm:gap-6">
              <button
                onClick={() => {
                  setShowModal(false);
                }}
                className="border-2 border-[#6546C3] py-3 px-8 text-[#6546C3] text-[12px] sm:text-[14px] font-semibold rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  navigate('/Task/IssueSubmission', {
                    state: {
                      formId,
                      description
                    }
                  });
                }}
                className="py-3 px-8 bg-[#6546C3] text-white text-[12px] sm:text-[14px] font-semibold rounded-lg"
              >
                Create Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateIssueModal;
