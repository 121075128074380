import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { RequireNotAuth, RequireAuthentication } from './components';
import Splash from './pages/Splash';
import LoginPage from './pages/LoginPage';
import MainPage from './pages/MainPage';
import Home from './pages/Home';
import MyActivity from './pages/MyActivity';
import Task from './pages/Task';
import ExternalLoginPage from './pages/ExternalLoginPage';
import DetailForm from './pages/Task/Form/DetailForm';
import FormSubmission from './pages/Task/Form/FormSubmission';
import StaticField from './pages/Task/Form/DetailForm/StaticField';
import CreateTaskPage from './pages/Task/Task/CreateTaskPage';
import AssigneeList from './components/AssigneeList';
import DetailTask from './pages/Task/Task/DetailTask';
import CreateIssuePage from './pages/Task/Issue/CreateIssuePage';
import Profile from './pages/Profile';
import Notification from './pages/Notification';
import UserLogPage from './pages/UserLogPage';
import NewestSubmittedDocument from './pages/Task/Form/NewestSubmittedDocument';
import Feedback from './pages/Feedback';
import GuestCreatePasswordPage from './pages/LoginPage/components/GuestCreatePassword';
import { useDispatch, useSelector } from 'react-redux';
import GuestForm from './pages/GuestPage/GuestForm';
import PageNotFound from './pages/PageNotFound';
import _ from 'lodash';
import DetailIssue from './pages/Task/Issue/DetailIssue';
import OwnerList from './components/OwnerList';
import { getFirebaseToken } from './firebaseInit';
import { saveFcmToken } from './store/slices/auth';

function App() {
  const userGroups = useSelector(state => state.user.userData?.groups);
  const isGuestAccount = userGroups?.find(group => group?.name === 'GUEST USER');
  const dispatch = useDispatch();
  useEffect(() => {
    let data;
    async function tokenFunc() {
      data = await getFirebaseToken();
      if (data) {
        dispatch(saveFcmToken(data));
      }
    }
    tokenFunc();
  }, []);
  return (
    <Routes>
      <Route element={<RequireNotAuth />}>
        <Route path="Login" element={<LoginPage />} />
        <Route path="VerifyEmail/:token" element={<GuestCreatePasswordPage />} />
      </Route>
      <Route element={<RequireAuthentication />}>
        <Route path="/" element={<MainPage />}>
          {!_.isEmpty(isGuestAccount) ? (
            <>
              <Route index element={<Navigate to="/Form" replace />} />
              <Route path="Form" element={<GuestForm />} />
              <Route path="*" element={<Navigate to="/Form" />} />
            </>
          ) : (
            <>
              <Route index element={<Navigate to="/Home" replace />} />
              <Route path="Home" element={<Home />} />
              <Route path="MyActivity" element={<MyActivity />} />
              <Route path="Task" element={<Task />} />
              <Route path="Task/TaskSubmission" element={<CreateTaskPage />} />
              <Route path="Task/TaskSubmission/:id" element={<CreateTaskPage />} />
              <Route path="Task/IssueSubmission" element={<CreateIssuePage />} />
              <Route path="Task/IssueSubmission/:id" element={<CreateIssuePage />} />
              <Route path="/Task/TaskSubmission/SendTask" element={<AssigneeList />} />
              <Route path="Task/TaskSubmission/:id/SendTask" element={<AssigneeList />} />
              <Route path="Task/DetailTask/:id" element={<DetailTask />} />
              <Route path="Task/DetailTask/:id/SelectAssignee" element={<AssigneeList />} />
              <Route path="Task/DetailTask/:id/ShareTask" element={<AssigneeList />} />
              <Route path="Task/DetailIssue/:id" element={<DetailIssue />} />
              <Route path="Task/DetailIssue/:id/SelectAssignee" element={<AssigneeList />} />
              <Route path="Task/DetailIssue/:id/ShareIssue" element={<AssigneeList />} />
              <Route path="Task/DetailIssue/:id/Owner" element={<OwnerList />} />
              <Route path="/Task/IssueSubmission/SendIssue" element={<AssigneeList />} />
              <Route path="Task/IssueSubmission/:id/SendIssue" element={<AssigneeList />} />
              <Route path="Notification" element={<Notification />} />
              <Route path="UserLog" element={<UserLogPage />} />
              <Route path="Feedback" element={<Feedback />} />
              <Route path="PageNotFound" element={<PageNotFound />} />
              <Route path="*" element={<Navigate to="/PageNotFound" />} />
            </>
          )}
          <Route path="Profile" element={<Profile />} />
          <Route path="Task/DetailForm/:id" element={<DetailForm />} />
          <Route path="Task/DetailForm/:id/Guidance" element={<StaticField />} />
          <Route path="Task/FormSubmission" element={<FormSubmission />} />
          <Route path="Task/FormSubmission/DetailFormSubmission/:id" element={<DetailForm />} />
          <Route
            path="Task/FormSubmission/DetailFormSubmission/:id/:parentId"
            element={<DetailForm />}
          />
          <Route
            path="Task/FormSubmission/DetailFormSubmission/:id/Guidance"
            element={<StaticField />}
          />
          <Route
            path="Task/FormSubmission/DetailFormSubmission/:id/:parentId/Guidance"
            element={<StaticField />}
          />
          <Route path="Task/BrowseFormSubmission" element={<FormSubmission />} />
          <Route
            path="Task/BrowseFormSubmission/NewestSubmittedDocument/:id"
            element={<NewestSubmittedDocument />}
          />
          <Route
            path="Task/BrowseFormSubmission/NewestSubmittedDocument/:id/DetailFormSubmission/:responseId"
            element={<DetailForm />}
          />
          <Route
            path="Task/BrowseFormSubmission/NewestSubmittedDocument/:id/DetailFormSubmission/:responseId/Guidance"
            element={<StaticField />}
          />
        </Route>

        <Route path="ExternalLogin" element={<ExternalLoginPage />} />
      </Route>
      <Route path="Splash" element={<Splash />} />
    </Routes>
  );
}

export default App;
