import { useEffect, useRef, useState } from 'react';
import { get, post, put } from '../../../../api/base';
import { toast } from 'react-toastify';
import { Loading, SuccessPopUp, Toast } from '../../../../components';
import { useParams, useNavigate } from 'react-router-dom';
import { apiUrls, icons, images } from '../../../../constants';
import moment from 'moment';
import parse from 'html-react-parser';
import FieldAttachment from '../../Form/DetailForm/components/FieldAttachment';
import AssigneeListModal from './components/AssigneeListModal';
import CloseIssueConfirmationModal from './components/CloseIssueConfirmationModal';
import ActionModal from './components/ActionModal';
import CommentItem from '../components/CommentItem';
import AttachmentCommentModal from './components/AttachmentCommentModal';
import TagModal from './components/TagModal';
import ImageFromGalleryModal from './components/Attachments/ImageFromGallery';
import VideoFromGalleryModal from './components/Attachments/VideoFromGallery';
import DocumentModal from './components/Attachments/DocumentModal';
import ImageFromCameraModal from './components/Attachments/ImageFromCamera';
import VideoFromCameraModal from './components/Attachments/VideoFromCamera';
import VoiceRecorderModal from './components/Attachments/VoiceRecorderModal';
import { Mention, MentionsInput } from 'react-mentions';
import mentionInputStyle from './mentionInputStyle';
import { cacheHelper, offlineHelper } from '../../../../helpers';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { setShowLoading } from '../../../../store/slices/popup';
const DetailIssue = () => {
  const [issue, setIssue] = useState({});
  const [assignee, setAssignee] = useState([]);
  const [remark, setRemark] = useState('');
  const [activityHistory, setActivityHistory] = useState('');
  const [imageModal, setImageModal] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  const [documentModal, setDocumentModal] = useState(false);
  const [takePhotoModal, setTakePhotoModal] = useState(false);
  const [takeVideoModal, setTakeVideoModal] = useState(false);
  const [voiceRecorderModal, setVoiceRecorderModal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [comments, setComments] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const uuid = uuidv4();
  const user = useSelector(state => state.user.userData);
  const dispatch = useDispatch();
  const scrollRef = useRef([]);
  const [showAssigneeListModal, setShowAssigneeListModal] = useState(false);
  const [showCloseIssueConfirmation, setShowCloseIssueConfirmation] = useState(false);
  const [showCloseIssueSuccess, setShowCloseIssueSuccess] = useState(false);
  const [showActionModal, setShowActionModal] = useState(false);
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [showTagModal, setShowTagModal] = useState(false);
  const [mentions, setMentions] = useState([]);
  const rawAssigneeList = [...(issue?.users || []), ...(issue?.groups || [])];
  const assigneeList = rawAssigneeList
    .map(assignee => ({
      id: String(assignee?.id),
      display: assignee?.full_name,
      picture: assignee?.picture
    }))
    .filter(assignee => assignee.display !== undefined);

  useEffect(() => {
    fetchDetailIssue();
  }, []);

  const fetchDetailIssue = () => {
    setIsFetching(true);
    if (navigator.onLine) {
      get(`${apiUrls.ISSUE}/${id}`)
        .then(response => {
          const { status } = response;
          setIsFetching(false);
          if (status === 200) {
            setIssue(response?.data);
            setComments(response?.data?.comments.sort((a, b) => b.id - a.id));
            setAssignee([...response?.data?.users, ...response?.data?.groups]);
          } else {
            toast.error(
              <Toast message={'Error'} detailedMessage={`${response?.data.error.message}`} />
            );
          }
        })
        .catch(_ => {
          setIsFetching(false);
        });
    } else {
      cacheHelper
        .getCachedData(apiUrls.ISSUE)
        .then(response => {
          const cacheIssue = response.data?.filter(form => form.id.toString() === id.toString());
          setIssue(cacheIssue[0]);
          setComments(cacheIssue[0]?.comments.sort((a, b) => b.id - a.id));
          setAssignee([...cacheIssue[0]?.users, ...cacheIssue[0]?.groups]);
        })
        .finally(() => {
          setIsFetching(false);
        });
    }
  };

  const handleStatusButtonPress = viewOnly => {
    if (!viewOnly) {
      setShowCloseIssueConfirmation(true);
    }
  };

  const handleCloseIssue = remark => {
    put(`${apiUrls.ISSUE}/${id}`, {
      status: 'CLOSED',
      remark: remark
    }).then(res => {
      const { status } = res;
      if (status === 201) {
        setShowCloseIssueConfirmation(false);
        fetchDetailIssue();
        setShowCloseIssueSuccess(true);
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={`${res?.data.error.message}`} />);
      }
    });
  };

  const handleMoreButtonPress = (fullAccess, isComplete) => {
    if (fullAccess && !isComplete) {
      setShowActionModal(true);
    }
  };

  const handleAddOrReplaceAssignee = () => {
    setShowActionModal(false);
    navigate(`/Task/DetailIssue/${id}/SelectAssignee`, {
      state: {
        type: 'SelectAssigneeIssue'
      }
    });
  };

  const handleShareIssue = () => {
    setShowActionModal(false);
    navigate(`/Task/DetailIssue/${id}/ShareIssue`, {
      state: {
        type: 'ShareIssue'
      }
    });
  };

  const handleTag = () => {
    setShowActionModal(false);
    setShowTagModal(true);
  };

  const handleChecklistSource = () => {
    if (issue.form_section_id) {
      navigate(`/Task/DetailForm/${issue.form_section_id}`);
    } else {
      toast.error(
        <Toast message={'Failed'} detailedMessage={'This issue is not related to any form'} />
      );
    }
  };

  const handleEdit = () => {
    setShowActionModal(false);
    put(`${apiUrls.ISSUE}/${id}/concurrent-user`)
      .then(response => {
        if (response.status === 201) {
          navigate(`/Task/IssueSubmission/${id}`);
        } else {
          toast.error(
            <Toast message={'Failed'} detailedMessage={'Another user is updating the issue'} />
          );
        }
      })
      .catch(e => {
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
      });
  };

  const handleOwner = () => {
    setShowActionModal(false);
    navigate(`/Task/DetailIssue/${id}/Owner`);
  };

  const setSendComments = (commentType, mentions, remark) => {
    switch (commentType) {
      case 'remark':
        return {
          remark: { message: remark }
        };
      case 'mention':
        return {
          remark: {
            message: remark,
            tags: mentions
          }
        };

      default:
        return;
    }
  };

  const sendComment = finalSendMessage => {
    dispatch(setShowLoading(true));
    if (navigator.onLine) {
      post(`${apiUrls.ISSUE}/${issue?.id}/comments`, finalSendMessage)
        .then(response => {
          dispatch(setShowLoading(false));
          if (response.status === 201) {
            setRemark('');
            setMentions([]);
            fetchDetailIssue();
          } else {
            toast.error(
              <Toast message={'Error'} detailedMessage={`${response?.data.error.message}`} />
            );
          }
        })
        .catch(_ => {
          dispatch(setShowLoading(false));
        });
    } else {
      if (!mentions.length) {
        let tempIssue;
        let tempComment = {
          id: 'offline' + uuid,
          remark: {
            message: [{ value: finalSendMessage?.remark?.message, type: 'TEXT' }]
          },
          description: 'Added Remark',
          attachments: null,
          recording: null,
          created_at: new Date().toISOString(),
          sender: user?.full_name
        };
        cacheHelper
          .getCachedData(apiUrls.ISSUE)
          .then(response => {
            let index = response.data?.findIndex(issue => issue.id.toString() === id.toString());
            tempIssue = response.data;
            tempIssue[index].comments.unshift(tempComment);
            offlineHelper
              .updateRequestDataOffline({
                url: `${apiUrls.ISSUE}/${issue?.id}/comments`,
                body: finalSendMessage,
                cacheUrl: apiUrls.ISSUE,
                key: 'data',
                data: [...tempIssue]
              })
              .then(_ => {
                setRemark('');
                setIssue(tempIssue[index]);
                setComments(tempIssue[index]?.comments.sort((a, b) => b.id - a.id));
                setAssignee([...tempIssue[index]?.users, ...tempIssue[index]?.groups]);
              })
              .catch(_ => {
                dispatch(setShowLoading(false));
                toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
              });
          })
          .finally(() => {
            dispatch(setShowLoading(false));
          });
      } else {
        dispatch(setShowLoading(false));
        toast.error(
          <Toast
            message={'Error'}
            detailedMessage={'You need to be online to use mention feature'}
          />
        );
      }
    }
  };

  function findUserByName(name) {
    return mentions.find(user => user.name.toLowerCase() === name.toLowerCase());
  }
  const handleSendComment = () => {
    let sendMessage;
    const regex = /`@([^`]+)`/g;
    const resultArray = [];
    let match;
    const transformedString = remark.replace(/@\[(.*?)\]/g, '`@$1`');
    while ((match = regex.exec(transformedString)) !== null) {
      const userName = match[1];
      const user = findUserByName(userName);
      if (user) {
        resultArray.push({ user_id: user.user_id, name: user.name });
      }
    }
    if (!issue?.view_only) {
      if (mentions.length) {
        sendMessage = setSendComments('mention', resultArray, transformedString);
      } else {
        sendMessage = setSendComments('remark', '', remark);
      }
      sendComment(sendMessage);
    }
  };
  const autoScroll = () => {
    if (!scrollRef.current) return;
    let rawArray = scrollRef.current.map((ref, index) => {
      if (ref.querySelectorAll(`.highlighted-word`)[0]) {
        return index;
      }
      return ref[0];
    });
    const rawIndex = rawArray.filter(el => typeof el === 'number');
    const raw =
      scrollRef.current[rawIndex[rawIndex.length - 1]].querySelectorAll(`.highlighted-word`);
    if (raw.length > 0) {
      const highlightedElement = raw[0];

      // Scroll to the next highlighted occurrence
      highlightedElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  };

  return (
    <div className="sm:mx-[24px]">
      {isFetching ? (
        <div className="flex justify-center h-[50vh] my-auto">
          <Loading />
        </div>
      ) : (
        <>
          <div className="flex flex-row justify-between items-center mb-[24px] mx-[24px] sm:mx-0">
            <div className="flex flex-row items-center">
              <div className="avatar-group -space-x-6 w-fit mr-2">
                {assignee.map((data, index) => {
                  if (index < 2) {
                    return (
                      <div key={index} className="avatar">
                        <div className="w-[36px] h-[36px] rounded-full">
                          <img
                            src={data?.picture ? data.picture : icons.icActiveUserOnline}
                            alt="avatar"
                            className="bg-white"
                          />
                        </div>
                      </div>
                    );
                  } else if (index === 2) {
                    return (
                      <div key={index} className="avatar placeholder">
                        <div className="w-[36px] h-[36px] rounded-full text-white text-[12px] bg-[#6546C3]">
                          <span>+{assignee.length - 2}</span>
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
              <div className="text-[12px] mr-6">
                {assignee.map((item, index) => {
                  if (index < 2) {
                    return (
                      <span key={index}>
                        {item.full_name || item.name}
                        {index < 1 && assignee.length > 1 ? ', ' : ''}
                      </span>
                    );
                  }
                  return null;
                })}
              </div>
              <div
                className="font-semibold text-[12px] text-[#6546C3] cursor-pointer"
                onClick={() => setShowAssigneeListModal(true)}
              >
                View all <span className="ml-2">{'>'}</span>
              </div>
            </div>
            {!issue?.view_only && (
              <img
                src={icons.icMore}
                alt="more"
                className="w-6 h-6 cursor-pointer"
                onClick={() =>
                  handleMoreButtonPress(issue?.full_access, issue?.status === 'CLOSED')
                }
              />
            )}
          </div>
          <div className=" bg-[#6546C3] rounded-[32px]">
            <div className="p-4 sm:p-6">
              <div className="flex flex-col gap-2 sm:flex-row sm:justify-between">
                <div className="flex basis-2/5 justify-between">
                  <div>
                    <div className="font-semibold text-[14px] text-white">Title</div>
                    <div className="text-white font-normal text-[12px] mt-[4px]">
                      {issue?.title}
                    </div>
                  </div>
                  <div>
                    <div className="font-semibold text-[14px] text-white">Report On</div>
                    <div className="text-white font-normal text-[12px] mt-[4px]">
                      {moment(issue?.created_at).format('DD-MMM-YYYY HH:mm')}
                    </div>
                  </div>
                </div>

                <div className="flex basis-3/5 justify-end">
                  <div
                    className="flex flex-row justify-between items-center bg-cover h-[95px] sm:h-[67px] w-full sm:max-w-[384px] py-3 px-6 rounded-2xl"
                    style={{ backgroundImage: `url(${images.imageBgStatusTask})` }}
                  >
                    <div className="flex flex-col gap-1">
                      <div className="font-semibold text-[14px] text-[#232323]">Status</div>
                      <div className="flex flex-row items-center gap-2">
                        <div
                          className={`font-semibold italic text-[12px] ${
                            issue?.status === 'OPEN' ? 'text-[#01B59C]' : 'text-[#C800A5]'
                          }`}
                        >
                          {issue?.status === 'OPEN' ? 'Open' : 'Closed'}
                        </div>
                      </div>
                    </div>

                    {issue?.status === 'OPEN' && (
                      <button
                        className="bg-[#6546C3] border-2 border-[#6546C3] rounded-lg py-[10px] w-[126px] h-fit font-semibold text-white 
                    text-[14px] flex justify-center items-center disabled:bg-gray-300 disabled:border-gray-300"
                        onClick={() => handleStatusButtonPress(issue?.view_only)}
                        disabled={issue?.view_only ? true : false}
                      >
                        Close Issue
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col bg-[#EBEDF8] rounded-[32px] gap-2">
              <div className="flex flex-col gap-1 bg-white rounded-t-[32px] p-4 sm:p-6">
                <div className="font-semibold text-[14px] text-[#232323]">Description</div>
                <div className="text-[12px] text-[#232323]">{parse(issue.description || '')}</div>
              </div>
              <div className="grid grid-cols-3 sm:grid-cols-5 bg-white p-4 sm:p-6 gap-3">
                <div className="flex flex-col gap-1.5">
                  <div className="font-semibold text-[14px] text-[#232323]">Visibility</div>
                  <div className="flex flex-row gap-2 items-center">
                    <img src={icons.icVisibility} className="w-6 h-6" alt="visibility" />
                    <div className="font-semibold text-[12px] text-[#C800A5] capitalize">
                      {issue?.visibility?.charAt(0).toUpperCase() +
                        issue?.visibility?.slice(1).toLowerCase()}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-1.5">
                  <div className="font-semibold text-[14px] text-[#232323]">Issue Type</div>
                  <div className="text-[12px] text-[#232323] capitalize">
                    {issue?.issue_type?.charAt(0).toUpperCase() +
                      issue?.issue_type?.slice(1).toLowerCase()}
                  </div>
                </div>
                <div className="flex flex-col gap-1.5">
                  <div className="font-semibold text-[14px] text-[#232323]">Saverity</div>
                  <div
                    className={`font-semibold text-[12px] ${
                      issue?.saverity === 'HIGH'
                        ? 'text-[#C800A5]'
                        : issue.saverity === 'LOW'
                        ? 'text-[#01B59C]'
                        : 'text-[#FF7301]'
                    } capitalize`}
                  >
                    {issue?.saverity?.charAt(0).toUpperCase() +
                      issue?.saverity?.slice(1).toLowerCase()}
                  </div>
                </div>
                <div className="flex flex-col gap-1.5">
                  <div className="font-semibold text-[14px] text-[#232323]">Location</div>
                  <div className="text-[12px] text-[#232323] capitalize">
                    {issue?.location?.charAt(0).toUpperCase() +
                      issue?.location?.slice(1).toLowerCase()}
                  </div>
                </div>
                <div className="flex flex-col gap-1.5">
                  <div className="font-semibold text-[14px] text-[#232323]">Due Date</div>
                  <div className="text-[12px] text-[#232323] capitalize">
                    {moment(issue?.due_date).format('DD-MMM-YYYY')}
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-1 bg-white p-4 sm:p-6">
                <div className="font-semibold text-[14px] text-[#232323]">Attachment</div>
                {issue?.attachments?.length ? (
                  <div className="bg-white rounded-2xl p-4 shadow-lg">
                    <FieldAttachment attachments={issue.attachments} submitted={true} />
                  </div>
                ) : (
                  <div className="italic text-[#BDBDBD] text-[12px]">No Attachment</div>
                )}
              </div>
              <div className="flex flex-col gap-1 bg-white rounded-b-[32px] p-4 sm:p-6">
                <div className="font-semibold text-[14px] text-[#232323]">Tag</div>
                {issue?.tags?.length ? (
                  <div className="flex flex-row flex-wrap gap-4">
                    {issue?.tags?.map((tag, index) => (
                      <div
                        key={index}
                        className="w-fit border border-2 rounded-lg p-2 items-center justify-center border-[#6546C3] bg-[#6546C3] bg-opacity-[0.2]"
                      >
                        <div className="font-semibold text-[12px] text-[#6546C3]">{tag?.name}</div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="italic text-[#BDBDBD] text-[12px]">No Tag</div>
                )}
              </div>
              <div className="bg-[#ecedf8] p-[16px] mb-12">
                <div className="justify-between flex flex-row items-center">
                  <span className="font-semibold text-[14px] text-[#232323]">Activity History</span>
                  <div className="flex w-[150px] md:w-[300px] h-[40px] bg-white border-[1px] items-center p-[12px] rounded-lg">
                    <img src={icons.icSearch} className="w-[14px] h-[14px]" alt="Search" />
                    <input
                      className="ml-2 focus:outline-none text-[12px] w-full"
                      value={activityHistory}
                      onChange={e => setActivityHistory(e.target.value)}
                      placeholder="Search here..."
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          autoScroll();
                        }
                      }}
                    />
                  </div>
                </div>
                {comments?.map((comment, index) => {
                  return (
                    <CommentItem
                      index={index}
                      scrollRef={scrollRef}
                      searchValue={activityHistory}
                      data={comment}
                      key={index}
                      isLastComment={issue?.comments?.length - 1 === index}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          {issue?.status === 'CLOSED' ? (
            <div className="flex flex-row bg-white rounded-lg p-[16px] mt-[40px] mb-6 border">
              <img src={icons.icInfo} alt="info" className="w-[24px] h-[24px] mr-[16px]" />
              <div>
                <div className="font-semibold text-[14px] text-[#C800A5]">Issue Closed</div>
                <div className="flex flex-row text-[#AFAFB9] text-[12px]">{`Closed by ${
                  issue?.closed_by
                } on ${moment(issue?.closed_at).format('MMM DD, YYYY')}`}</div>
              </div>
            </div>
          ) : (
            <div className="fixed bottom-5 flex flex-row items-center justify-center gap-2 w-[90vw] mx-[18px] sm:mx-0 lg:w-[calc(100%-336px)]">
              <div
                className={`flex gap-2 bg-white w-full h-[40px] border-[1px] items-center px-[12px] py-[5px] rounded-lg`}
              >
                <img
                  src={icons.icAttachment}
                  className="w-[24px] h-[24px] cursor-pointer"
                  alt="Attachment"
                  onClick={() => {
                    if (!issue?.view_only) {
                      setShowAttachmentModal(true);
                    }
                  }}
                />
                <MentionsInput
                  className="flex w-full flex-wrap border-none  rounded-lg  rounded-lg"
                  singleLine
                  style={mentionInputStyle}
                  placeholder="Add remark"
                  value={remark}
                  onChange={e => setRemark(e.target.value)}
                  a11ySuggestionsListLabel={'Suggested mentions'}
                  forceSuggestionsAboveCursor
                  allowSuggestionsAboveCursor={true}
                  disabled={issue?.view_only}
                >
                  <Mention
                    markup="@[__display__]"
                    data={assigneeList}
                    onAdd={(id, display) => {
                      mentions.push({ user_id: id, name: display });
                    }}
                    className="mentions__mention"
                    displayTransform={(id, display) => {
                      return '@' + display;
                    }}
                    renderSuggestion={data => {
                      return (
                        <div key={data?.id} className="flex flex-row gap-2">
                          <img
                            className="rounded-full w-5 h-5"
                            src={data?.picture || icons.icActiveUserOnline}
                            alt="people"
                          />
                          {data?.display}
                        </div>
                      );
                    }}
                  />
                </MentionsInput>
              </div>
              <div
                className={`${
                  !issue.view_only ? 'bg-[#6546C3]' : 'bg-gray-300'
                } bg-[#6546C3] min-w-[38px] p-[6px] rounded-lg`}
                onClick={!issue.view_only ? () => handleSendComment() : () => {}}
              >
                <img src={icons.icSend} className="w-[24px]  h-[24px] " alt="send" />
              </div>
            </div>
          )}
        </>
      )}
      <AssigneeListModal
        showModal={showAssigneeListModal}
        setShowModal={setShowAssigneeListModal}
        isClosed={issue?.status === 'CLOSED'}
        fullAccess={issue?.full_access}
        issueId={id}
      />
      <CloseIssueConfirmationModal
        showModal={showCloseIssueConfirmation}
        setShowModal={setShowCloseIssueConfirmation}
        handleCloseIssue={handleCloseIssue}
      />
      <SuccessPopUp
        show={showCloseIssueSuccess}
        imageSuccess={images.imageSuccess}
        title={'Successfully'}
        message={'Hooray! You have successfully closed the issue'}
        labelSuccessButton={'Ok'}
        labelBackButton={''}
        handleSuccessButton={() => {
          setShowCloseIssueSuccess(false);
        }}
      />
      <ActionModal
        showModal={showActionModal}
        setShowModal={setShowActionModal}
        handleAssign={handleAddOrReplaceAssignee}
        handleShare={handleShareIssue}
        handleTag={handleTag}
        handleChecklistSource={handleChecklistSource}
        handleEdit={handleEdit}
        handleOwner={handleOwner}
      />
      <TagModal showModal={showTagModal} setShowModal={setShowTagModal} issueId={id} />
      <AttachmentCommentModal
        showModal={showAttachmentModal}
        setShowModal={setShowAttachmentModal}
        setImageModal={() => setImageModal(true)}
        setVideoModal={() => setVideoModal(true)}
        setDocumentModal={() => setDocumentModal(true)}
        setTakePhotoModal={() => setTakePhotoModal(true)}
        setTakeVideoModal={() => setTakeVideoModal(true)}
        setVoiceRecorderModal={() => setVoiceRecorderModal(true)}
      />
      <ImageFromGalleryModal
        setShowModal={setImageModal}
        showModal={imageModal}
        id={issue?.id}
        setComments={setComments}
        setIssue={setIssue}
      />
      <VideoFromGalleryModal
        setShowModal={setVideoModal}
        showModal={videoModal}
        id={issue?.id}
        setComments={setComments}
        setIssue={setIssue}
      />
      <DocumentModal
        setShowModal={setDocumentModal}
        showModal={documentModal}
        id={issue?.id}
        setComments={setComments}
        setIssue={setIssue}
      />
      <ImageFromCameraModal
        setShowModal={setTakePhotoModal}
        showModal={takePhotoModal}
        id={issue?.id}
        setComments={setComments}
        setIssue={setIssue}
      />
      <VideoFromCameraModal
        setShowModal={setTakeVideoModal}
        showModal={takeVideoModal}
        id={issue?.id}
        setComments={setComments}
        setIssue={setIssue}
      />
      <VoiceRecorderModal
        setShowModal={setVoiceRecorderModal}
        showModal={voiceRecorderModal}
        id={issue?.id}
        setComments={setComments}
        setIssue={setIssue}
      />
    </div>
  );
};

export default DetailIssue;
