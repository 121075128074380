import { useEffect, useState } from 'react';
import { EmptyData, Loading, Toast } from '../../components';
import { apiUrls, icons, images } from '../../constants';
import { get } from '../../api/base';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
const validationDate = date => {
  const currentDate = new Date();
  const timeDifferenceMs = currentDate - new Date(date);
  const timeDifferenceHours = timeDifferenceMs / (1000 * 60 * 60);
  if (timeDifferenceHours > 24 && timeDifferenceHours < 48) {
    return '#FF7301';
  } else if (timeDifferenceHours < 24) {
    return '#01B59C';
  } else {
    return '#AAAAAA';
  }
};
const CardUserLog = ({ userLog }) => {
  return (
    <div className="flex flex-row p-2 bg-white rounded-box items-center mt-2 gap-1">
      <div className="flex justify-center items-center relative h-[37px] w-[37px]">
        <img
          src={userLog?.picture || images.imageDummyAvatar}
          alt="user"
          className="h-[24px] w-[24px] rounded-full"
        />
        <div
          style={{ backgroundColor: validationDate(userLog?.last_login) }}
          className={`h-[13px] w-[13px] absolute rounded-full bottom-1 right-1`}
        />
      </div>
      <div className="flex flex-col w-[200px]">
        <div className={'min-w-[6rem] font-bold text-[12px] font-semibold truncate'}>
          {userLog?.username}
        </div>
        <div className="text-[#CDC6E1] items-center text-[11px] flex flex-row gap-1">
          Last Online:
          <div className="text-[#C800A5] text-[12px]">
            {moment(userLog?.last_login).format('DD-MMM-YYYY HH:mm')}
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center">
        {userLog?.whatsapp_number && (
          <img
            src={icons.icWA}
            alt="status"
            className="h-[24px] w-[24px] cursor-pointer"
            onClick={() => window.open(userLog?.whatsapp_number)}
          />
        )}
        {userLog?.microsoft_teams && (
          <img
            src={icons.icTeams}
            alt="status"
            className="h-[24px] w-[24px] cursor-pointer"
            onClick={() => window.open(userLog?.microsoft_teams)}
          />
        )}
      </div>
    </div>
  );
};
const CardUserLogWebVersion = ({ userLog }) => {
  return (
    <div className="flex flex-row py-5 pl-5 bg-white rounded-box items-center mt-2 justify-between shadow-lg">
      <div className="flex flex-row justify-self-center items-center w-[20vw]">
        <div className="flex justify-center items-center relative h-[37px] w-[37px] ">
          <img
            src={userLog?.picture || images.imageDummyAvatar}
            alt="user"
            className="h-[24px] w-[24px] rounded-full"
          />
          <div
            style={{ backgroundColor: validationDate(userLog?.last_login) }}
            className={`h-[13px] w-[13px] absolute rounded-full bottom-1 right-1`}
          />
        </div>
        <div className={'min-w-[6rem] font-bold text-[12px] font-semibold truncate'}>
          {userLog?.username}
        </div>
      </div>
      <div className="rounded-full p-[2px] w-[20vw]  items-center text-[12px] text-center">
        {moment(userLog?.last_login).format('DD-MMM-YYYY HH:mm')}
      </div>
      <div className="flex flex-row items-center w-[20vw] justify-center">
        {userLog?.whatsapp_number && (
          <img
            onClick={() => window.open(userLog?.whatsapp_number)}
            src={icons.icWA}
            className="cursor-pointer"
            alt="status"
            width={26}
            height={10}
          />
        )}
        {userLog?.microsoft_teams && (
          <img
            onClick={() => window.open(userLog?.microsoft_teams)}
            src={icons.icTeams}
            className="cursor-pointer"
            alt="status"
            width={26}
            height={10}
          />
        )}
      </div>
    </div>
  );
};
const UserLogPage = () => {
  const [userLogs, setUserLogs] = useState([]);
  const [pageRef, setPageRef] = useState(1);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });
  const [totalData, setTotalData] = useState(0);
  const [firstFetching, setFirstFetching] = useState(true);

  const getActivitiesMobile = () => {
    const filter = {
      limit: 25,
      page: pageRef
    };
    get(`${apiUrls.USER_LOG}`, {
      query: filter
    })
      .then(res => {
        if (res.status === 200) {
          let temp = res.data;
          if (pageRef === 1) {
            setUserLogs(temp);
          } else {
            const result = userLogs.data.concat(temp.data);
            setUserLogs({ ...userLogs, data: result });
          }
          setTotalData(res.data.total_data);
          setFirstFetching(false);
          setPageRef(pageRef + 1);
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />);
        }
      })
      .catch(_ => {
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
        setFirstFetching(false);
      });
  };

  useEffect(() => {
    getActivitiesMobile();
  }, []);

  return (
    <>
      {isSmallScreen ? (
        <div className="p-5 min-h-screen flex flex-col sm:hidden  mb-[100px]">
          <div className=" max-h-[calc(100%-17rem)] flex flex-col gap-y-2 overflow-y-scroll">
            {firstFetching && !userLogs?.data?.length ? (
              <div className="flex justify-center h-[50vh] my-auto">
                <Loading />
              </div>
            ) : userLogs?.data?.length ? (
              <InfiniteScroll
                dataLength={userLogs?.data?.length || 0}
                next={getActivitiesMobile}
                hasMore={userLogs?.data?.length < totalData}
                className="!overflow-hidden"
                loader={
                  <div className="flex justify-center h-[100px] my-auto">
                    <Loading />
                  </div>
                }
              >
                {userLogs?.data?.map((userLog, index) => (
                  <CardUserLog userLog={userLog} key={index} />
                ))}
              </InfiniteScroll>
            ) : (
              <EmptyData
                title={'There is no document to show'}
                message={'You do not have any activity'}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="p-5 min-h-screen flex flex-col mb-[100px]">
          <div className="py-5 max-h-[calc(100%-17rem)] flex flex-col gap-y-2">
            {firstFetching && !userLogs?.data?.length ? (
              <div className="flex justify-center h-[50vh] my-auto">
                <Loading />
              </div>
            ) : userLogs?.data?.length ? (
              <InfiniteScroll
                dataLength={userLogs?.data?.length || 0}
                next={getActivitiesMobile}
                hasMore={userLogs?.data?.length < totalData}
                className="!overflow-hidden"
                loader={
                  <div className="flex justify-center h-[100px] my-auto">
                    <Loading />
                  </div>
                }
              >
                <div className=" p-5 bg-white rounded-box  mt-2 ">
                  <div className="flex flex-row justify-between">
                    <div className="items-center w-[20vw] text-[12px] font-bold ml-8">Name</div>
                    <div className="items-center w-[20vw] font-bold text-[12px] text-center">
                      Last Online
                    </div>
                    <div className="items-center w-[20vw] font-bold text-[12px] text-center">
                      Connected Chat
                    </div>
                  </div>
                  {userLogs?.data?.map((activity, index) => (
                    <CardUserLogWebVersion userLog={activity} key={index} />
                  ))}
                </div>
              </InfiniteScroll>
            ) : (
              <EmptyData
                title={'There is no document to show'}
                message={'You do not have any activity'}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default UserLogPage;
