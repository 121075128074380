import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { selectToken } from '../../store/slices/auth';

const RequireAuthentication = () => {
  const location = useLocation();
  const token = useSelector(selectToken);
  const isFromLogout = useSelector(state => state.setting?.isFromLogout);
  return token ? (
    <Outlet />
  ) : (
    <Navigate to="/Login" state={{ from: isFromLogout ? null : location }} />
  );
};

export default RequireAuthentication;
