import { icons } from '../../../../../../constants';

const ActionModal = ({
  showModal,
  setShowModal,
  handleAssign,
  handleShare,
  handleTag,
  handleChecklistSource,
  handleOwner,
  handleEdit
}) => {
  const ActionItem = ({ icon, title, onClick }) => {
    return (
      <div
        className="flex flex-row sm:flex-col gap-2 items-center sm:justify-center sm:w-[115px] sm:h-[115px] cursor-pointer"
        onClick={onClick}
      >
        <img src={icon} alt="edit" className="w-6 h-6 sm:w-[40px] sm:h-[40px]" />
        <div className="text-[#232323] text-[12px]">{title}</div>
      </div>
    );
  };

  return (
    <div>
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px] h-fit p-0">
          <div className="bg-white p-6">
            <div className="flex flex-row items-center justify-between">
              <div className="text-[18px] font-semibold">Attachment</div>
              <img
                src={icons.icCloseModal}
                className="w-6 h-6 cursor-pointer"
                alt={'close-modal'}
                onClick={() => setShowModal(false)}
              />
            </div>
            <hr className="mt-2 mb-6" />
            <div className="flex flex-col sm:flex-row flex-wrap gap-2 sm:gap-0">
              <ActionItem icon={icons.icEditBg} title={'Edit'} onClick={handleEdit} />
              <ActionItem icon={icons.icShare} title={'Share'} onClick={handleShare} />
              <ActionItem icon={icons.icAssignBg} title={'Assign'} onClick={handleAssign} />
              <ActionItem icon={icons.icOwnerBg} title={'Owner'} onClick={handleOwner} />
              <ActionItem icon={icons.icTagBg} title={'Tag'} onClick={handleTag} />
              <ActionItem
                icon={icons.icChecklistSource}
                title={'Checklist Source'}
                onClick={handleChecklistSource}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionModal;
