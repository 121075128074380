import { useEffect, useState } from 'react';
import { icons, apiUrls } from '../../../../constants';
import { put } from '../../../../api/base';
import { SuccessPopUp, Toast } from '../../../../components';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setShowLoading } from '../../../../store/slices/popup';
import { setUserData } from '../../../../store/slices/user';

const TimeZoneModal = ({
  showModal,
  setShowModal,
  timezoneOptions,
  currentTimezone,
  search,
  setSearch,
  userId
}) => {
  const [selectedTimezone, setSelectedTimezone] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.userData);

  const close = () => {
    setSearch('');
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal) setSelectedTimezone(currentTimezone);
  }, [showModal]);

  const handleChangeTimezone = () => {
    close();
    dispatch(setShowLoading(true));
    put(`${apiUrls.UPDATE_USER}/${userId}`, {
      timezone: { name: selectedTimezone.name, offset: selectedTimezone.timezone }
    }).then(res => {
      dispatch(setShowLoading(false));
      const { status } = res;
      if (status === 200) {
        setShowSuccessModal(true);
        dispatch(
          setUserData({
            ...user,
            timezone: { name: selectedTimezone.name, offset: selectedTimezone.timezone }
          })
        );
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={`${res?.data.error.message}`} />);
      }
    });
  };

  return (
    <div>
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px] h-[75vh] sm:h-fit">
          <div className="bg-white">
            <div className="flex flex-row items-center justify-between">
              <div className="text-[18px] font-semibold">Filter</div>
              <img
                src={icons.icCloseModal}
                className="w-6 h-6 cursor-pointer"
                alt={'close-modal'}
                onClick={() => close()}
              />
            </div>
            <hr className="mt-2 mb-6" />
            <div className="flex h-[40px] w-full bg-white border-[1px] items-center p-[12px] rounded-lg">
              <img src={icons.icSearch} className="w-[14px] h-[14px]" alt="Search" />
              <input
                className="ml-2 focus:outline-none text-[12px] w-full"
                placeholder="Search time zone here..."
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            </div>
            <div className="max-h-[250px] flex flex-col gap-[14px] my-6 overflow-y-auto">
              {timezoneOptions?.map(timezone => (
                <div
                  key={timezone.id}
                  className={`flex flex-row cursor-pointer border border-2 rounded-md p-2 items-center  ${
                    timezone.timezone === selectedTimezone.timezone &&
                    timezone.name === selectedTimezone.name
                      ? 'border-[#6546C3] bg-[#6546C3] bg-opacity-[0.2]'
                      : ''
                  }`}
                  onClick={() => setSelectedTimezone(timezone)}
                >
                  <div
                    className={`text-[12px] ${
                      timezone.timezone === selectedTimezone.timezone &&
                      timezone.name === selectedTimezone.name
                        ? 'text-[#6546C3] font-semibold'
                        : ''
                    }`}
                  >
                    {timezone.name}
                  </div>
                  <div
                    className={`ml-auto border border-2 rounded-full w-[16px] h-[16px] border-[#6546C3] ${
                      timezone.timezone === selectedTimezone.timezone &&
                      timezone.name === selectedTimezone.name
                        ? 'bg-[#6546C3]'
                        : 'bg-transparent'
                    }`}
                  />
                </div>
              ))}
            </div>

            <div className="flex flex-row justify-center sm:justify-end w-full">
              <button
                className="rounded-lg border-2 border-[#6546C3] px-7 py-2 m-2 flex text-[#6546C3] font-semibold text-[14px]"
                onClick={() => close()}
              >
                Back
              </button>
              <button
                className="rounded-lg border-2 border-[#6546C3] bg-[#6546C3] px-8 py-2 m-2 flex text-white font-semibold text-[14px]"
                onClick={() => handleChangeTimezone()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <SuccessPopUp
        show={showSuccessModal}
        title={'Successfully'}
        message={'Hooray! You have successfully edit time zone'}
        labelSuccessButton={'Ok'}
        handleSuccessButton={() => setShowSuccessModal(false)}
      />
    </div>
  );
};

export default TimeZoneModal;
