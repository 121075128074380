import { useEffect, useState } from 'react';
import FormInput from '../../components/FormInput';
import { icons, images } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { savePassword, saveUsername } from '../../store/slices/auth';
import { Toast } from '../../components';
import { toast } from 'react-toastify';
import CryptoJS from 'crypto-js';
import { setShowLoading } from '../../store/slices/popup';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { selectUserData } from '../../store/slices/user';

const ExternalLoginPage = () => {
  const auth = useSelector(state => state.auth);
  const [passIcon, setPassIcon] = useState(icons.icEyeClose);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passType, setPassType] = useState('password');
  const [isFormError, setIsFormError] = useState(false);
  const handleUsernameChange = event => setUsername(event.target.value);
  const handlePasswordChange = event => setPassword(event.target.value);
  const dispatch = useDispatch();
  const location = useLocation();
  const userData = useSelector(selectUserData);

  const appName = location.state.appName;
  const appLink = location.state.appLink;
  const message = location.state.message;
  const appWebsite = location.state.appWebsite;
  const authUrl = location.state.authUrl;

  useEffect(() => {
    setUsername(auth?.username);
    setPassword(decrypt(auth?.password));

    if (message) {
      toast.error(<Toast message={'Error'} detailedMessage={message} />);
    }
  }, []);

  const handlePassVisibility = () => {
    if (passType === 'password') {
      setPassType('text');
      setPassIcon(icons.icEyeOpen);
    } else {
      setPassType('password');
      setPassIcon(icons.icEyeClose);
    }
  };

  const decrypt = text => {
    const decryptedText = CryptoJS.AES.decrypt(text, process.env.REACT_APP_SECRET_PASS).toString(
      CryptoJS.enc.Utf8
    );
    return decryptedText.replace(/^"(.*)"$/, '$1');
  };

  const encrypt = text => {
    return CryptoJS.AES.encrypt(JSON.stringify(text), process.env.REACT_APP_SECRET_PASS).toString();
  };

  const loginValidation = () => {
    if (username === '' || password === '') {
      return false;
    }
    return true;
  };

  const handleLoginExt = async (appName, appLink, appWebsite) => {
    if (loginValidation()) {
      dispatch(setShowLoading(true));
      let bodyParam = {
        username: username,
        password: password
      };
      const axiosInstance = axios.create();
      axiosInstance.interceptors.response.use(
        response => {
          dispatch(setShowLoading(false));
          let token = response.data.token;
          let refreshToken = response.data.refreshToken;
          dispatch(saveUsername(username));
          dispatch(savePassword(encrypt(password)));

          let deepLinkUrl = `${appLink}/${token}/${refreshToken}/${username}/${password}`;
          window.location.href = deepLinkUrl;

          setTimeout(() => {
            window.open(appWebsite);
          }, 5000);
        },
        error => {
          dispatch(setShowLoading(false));
          if (error.response?.status === 401) {
            const message = 'Wrong credential';
            toast.error(<Toast message={'Error'} detailedMessage={message} />);
          } else {
            if (error.code === 'ERR_CANCELED') {
              toast.error(
                <Toast
                  message={'Error'}
                  detailedMessage={
                    'Server taking too long to response, please try again later or contact your IT support'
                  }
                />
              );
            } else {
              toast.error(<Toast message={'Error'} detailedMessage={error.message} />);
            }
          }
        }
      );

      const sleep = time => new Promise(resolve => setTimeout(() => resolve(), time));
      const controller = new AbortController();
      axiosInstance.post(authUrl, bodyParam, { signal: controller.signal });
      await sleep(5000).then(() => {
        controller.abort();
      });
    } else {
      setIsFormError(true);
      toast.error(
        <Toast message={'Error'} detailedMessage={'Username or Passwrod cannot be empty'} />
      );
    }
  };

  return (
    <>
      <div className="hidden lg:w-screen h-screen bg-gradient-to-b from-[#6546C3] to-[#9E7CE3] p-[16px] md:flex justify-center items-center">
        <div className="w-full h-full  bg-white rounded-[36px] p-[40px] flex flex-col md:flex-row gap-[40px]">
          <div className="w-auto self-center h-auto overflow-hidden md:m-0">
            <div className="flex justify-between items-center">
              <div className="font-poppins text-[16px] text-[#232323]">
                Hai, <span className="font-bold">{userData?.full_name}</span>
              </div>
              <img src={icons.icInfo} alt="Info" className="w-[18px] h-[18px]" />
            </div>

            <hr className="border-dashed my-4" />
            <img
              src={images.imageLoginSide}
              alt="ilustration"
              className="max-h-[80vh] self-center"
            />
          </div>
          <div className="flex-auto p-[8px] md:p-[40px] overflow-y-auto bg-[#EBEDF8] rounded-[36px] md:min-w-[400px]">
            <img src={images.imageM2PLogo} alt="ITM LOGO" className="w-[140px] mb-[16px]" />
            <div className="mb-1">
              <div className="font-poppins font-normal text-[16px] text-[#232323]">
                Enter your Account login to{' '}
                <span className="font-bold text-[#FF7301]">{appName}</span>
              </div>
            </div>
            <div className="flex flex-col mt-[40px]">
              <div className="flex-1">
                <div>
                  <div className="mb-1">
                    <div className="font-poppins font-semibold text-[14px] text-[#232323]">
                      Username
                    </div>
                  </div>
                  <FormInput
                    type="text"
                    placeholder="Enter username"
                    value={username}
                    onChange={handleUsernameChange}
                    isError={isFormError}
                  />
                </div>
              </div>
              <div className="flex-1 mt-[24px]">
                <div className="mb-[45px]">
                  <div className="mb-1">
                    <div className="font-poppins font-semibold text-[14px] text-[#232323]">
                      Password
                    </div>
                  </div>
                  <FormInput
                    type={passType}
                    placeholder="Enter password"
                    value={password}
                    onChange={handlePasswordChange}
                    rightIcon={passIcon}
                    onRightIconClick={handlePassVisibility}
                    isError={isFormError}
                  />
                </div>
              </div>
            </div>
            <button
              className="bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] font-poppins font-semibold text-[14px] text-white p-3 w-full rounded-md"
              onClick={() => handleLoginExt(appName, appLink, appWebsite)}
            >
              Login
            </button>
          </div>
        </div>
      </div>

      {/* Mobile View */}
      <div className="md:hidden w-screen h-screen bg-gradient-to-b from-[#6546C3] to-[#9E7CE3] pt-[16px] flex justify-center items-center">
        <div className="w-full h-full  bg-white rounded-[36px] flex flex-col md:flex-row">
          <img src={images.imageM2PLogo} alt="ITM LOGO" className="w-[140px] m-[16px]" />
          <div className="m-[16px] mt-0">
            <div className="font-poppins font-normal text-[12px] text-[#232323]">
              Enter your Account login to{' '}
              <span className="font-bold text-[#FF7301]">{appName}</span>
            </div>
          </div>
          <div className="w-auto m-auto mx-[16px] h-auto md:m-0">
            <div className="flex justify-between items-center">
              <div className="font-poppins text-[16px] text-[#232323]">
                Hai, <span className="font-bold">{userData?.full_name}</span>
              </div>
              <img src={icons.icInfo} alt="Info" className="w-[18px] h-[18px]" />
            </div>

            <hr className="border-dashed my-4" />
            <img src={images.imageLoginSide} alt="ilustration" className="h-max self-center" />
          </div>
          <div className="flex-auto p-[8px] md:p-[10px] lg:p-[48px]  bg-[#EBEDF8]">
            <div className="flex flex-col mt-[40px]">
              <div className="flex-1">
                <div>
                  <div className="mb-1">
                    <div className="font-poppins font-semibold text-[14px] text-[#232323]">
                      Username
                    </div>
                  </div>
                  <FormInput
                    type="text"
                    placeholder="Enter username"
                    value={username}
                    onChange={handleUsernameChange}
                    isError={isFormError}
                    setIsFormError={setIsFormError}
                  />
                </div>
              </div>
              <div className="flex-1 mt-[24px]">
                <div className="mb-[48px]">
                  <div className="mb-1">
                    <div className="font-poppins font-semibold text-[14px] text-[#232323]">
                      Password
                    </div>
                  </div>
                  <FormInput
                    type={passType}
                    placeholder="Enter password"
                    value={password}
                    onChange={handlePasswordChange}
                    rightIcon={passIcon}
                    onRightIconClick={handlePassVisibility}
                    isError={isFormError}
                    setIsFormError={setIsFormError}
                  />
                </div>
              </div>
            </div>
            <button
              className="bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] font-poppins font-semibold text-[14px] text-white p-3 w-full rounded-md"
              onClick={() => handleLoginExt(appName, appLink, appWebsite)}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExternalLoginPage;
