import { getMessaging, getToken } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './store/Firebase';

const publicKey = process.env.REACT_APP_VAPID_KEY;

const initFirebase = initializeApp(firebaseConfig);

const messaging = getMessaging(initFirebase);

export const getFirebaseToken = async setTokenFound => {
  let firebaseToken = '';
  try {
    firebaseToken = await getToken(messaging, {
      vapidKey: publicKey
    });

    if (firebaseToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (_) {}

  return firebaseToken;
};
