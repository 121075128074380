import { useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { apiUrls, icons, images } from '../../../../constants';
import Select from 'react-select';
import FieldAttachment from '../../Form/DetailForm/components/FieldAttachment';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { SuccessPopUp, Toast } from '../../../../components';
import { setShowLoading } from '../../../../store/slices/popup';
import { del, get, post, put } from '../../../../api/base';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useLocation, useNavigate, useParams } from 'react-router';
import { cacheHelper, offlineHelper } from '../../../../helpers';
import { v4 as uuidv4 } from 'uuid';

const CreateIssuePage = () => {
  const uuid = uuidv4();
  const [title, setTitle] = useState('');
  const [dueDate, setDuedate] = useState(new Date());
  const [visibility, setVisibility] = useState('');
  const [visibilityDescription, setVisibilityDescription] = useState('');
  const [visibilityOptions, setVisibilityOptions] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [typelist, setTypeList] = useState([]);
  const [type, setType] = useState([]);
  const [location, setLocation] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const locationState = useLocation();
  const tempDescription = locationState.state?.description;
  const [description, setDescription] = useState(tempDescription || '');
  const [attachments, setAttachments] = useState([]);
  const [saverity, setSaverity] = useState({ LOW: false, MEDIUM: false, HIGH: false });
  const [successModal, setSuccessModal] = useState(false);
  const [registeredUsers, setUsersAssignee] = useState(null);
  const [registeredGroups, setGroupsAssignee] = useState(null);
  const [registeredStatus, setRegisteredStatus] = useState(null);
  const user = useSelector(state => state.user?.userData);
  const formId = locationState.state?.formId;
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id && visibilityOptions.length) fetchDetailIssue();
  }, [id, visibilityOptions]);

  const fetchDetailIssue = () => {
    dispatch(setShowLoading(true));
    if (navigator.onLine) {
      get(`${apiUrls.ISSUE}/${id}`)
        .then(response => {
          const { status, data } = response;
          dispatch(setShowLoading(false));
          if (status === 200) {
            setGroupsAssignee(data.groups);
            setUsersAssignee(data.users);
            setRegisteredStatus(data?.status);
            setTitle(data.title);
            setDuedate(new Date(data.due_date));
            setType({ name: data.issue_type });
            setSelectedType(data.issue_type);
            setSaverity({
              LOW: data.saverity === 'LOW',
              MEDIUM: data.saverity === 'MEDIUM',
              HIGH: data.saverity === 'HIGH'
            });
            setLocation({ name: data.location });
            setSelectedLocation(data.location);
            setVisibility({ name: data.visibility });
            setVisibilityDescription(
              visibilityOptions?.find(opt => opt?.name === data.visibility)?.description
            );
            setDescription(data.description || '');
            setAttachments(data.attachments);
          } else {
            toast.error(
              <Toast message={'Error'} detailedMessage={`${response?.data.error.message}`} />
            );
          }
        })
        .catch(_ => {
          dispatch(setShowLoading(false));
        });
    }
  };

  async function handleAddAttachment({ target: { files } }) {
    if (!files.length) return;

    const fileSizeUser = user?.file_size * 1048576;

    const formData = new FormData();
    for (let index = 0; index < files.length; index++) {
      formData.append('files[]', files[index]);
    }
    let statusFile;
    if (attachments?.length <= 5) {
      if (files.length <= 5) {
        for (let i = 0; i < files.length; i++) {
          if (files[i].size > fileSizeUser) {
            statusFile = false;
            toast.error(
              <Toast
                message={'Error'}
                detailedMessage={`Cannot upload ${files[i].name} because the size of file is more than ${fileSizeUser} mb`}
              />
            );
          } else {
            statusFile = true;
            formData.append('files[]', {
              uri: files[i].fileCopyUri,
              type: files[i].type,
              name: files[i].name
            });
          }
        }
        if (statusFile || formData._parts.length) {
          dispatch(setShowLoading(true));
          if (navigator.onLine) {
            post('/api/dynamic-forms/attachments', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(response => {
              if (response.status === 200) {
                let rawAttachment = attachments.concat(response.data);
                setAttachments(rawAttachment);
              } else if (response.status === 0) {
                toast.error(
                  <Toast
                    message={'Error'}
                    detailedMessage={'Cannot Reach server, please try again later'}
                  />
                );
              } else {
                toast.error(
                  <Toast message={'Error'} detailedMessage={`${response.data.error.message}`} />
                );
              }
              dispatch(setShowLoading(false));
            });
          } else {
            const rawAttachment = await Promise.all(
              formData
                .getAll('files[]')
                .filter(data => data instanceof File)
                .map(async file => {
                  return await offlineHelper.saveAttachmentOffline(file);
                })
            );
            setAttachments(attachments.concat(rawAttachment));
            dispatch(setShowLoading(false));
          }
        }
      } else {
        toast.error(
          <Toast message={'Error'} detailedMessage={'Maximum of uploaded file is 5 files'} />
        );
      }
    } else {
      toast.error(
        <Toast
          message={'Error'}
          detailedMessage={'You have reached the maximum number of file uploads, maximum 5'}
        />
      );
    }
    inputRef.current.value = null;
  }

  const deleteAttachment = id => {
    del(`/api/dynamic-forms/attachments/${id}`).then(response => {
      dispatch(setShowLoading(false));
      if (response.status === 200) {
        setAttachments(prevAttachments => prevAttachments.filter(attach => attach.id !== id));
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={`${response.data.error.message}`} />);
      }
    });
  };

  const handleDeleteAttachment = index => {
    dispatch(setShowLoading(true));
    if (navigator.onLine) {
      deleteAttachment(attachments[index].id);
    } else {
      cacheHelper.deleteCachedAttachment(attachments[index].cacheIdentifier);
      attachments.splice(index, 1);
      setAttachments([...attachments]);
      dispatch(setShowLoading(false));
    }
  };

  const getVisibility = () => {
    get(apiUrls.ISSUE_VISIBILITY)
      .then(res => {
        if (res.status === 200) {
          setVisibilityOptions(res.data);
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />);
        }
      })
      .catch(_ =>
        toast.error(<Toast message={'Error'} detailedMessage={'Something Went Wrong'} />)
      );
  };

  const validationIssue = isMessage => {
    if (!title) {
      return isMessage ? 'Title cannot be empty' : false;
    }
    if (!selectedType) {
      return isMessage ? 'Issue Type cannot be empty' : false;
    }
    if (!Object.keys(saverity).find(level => saverity[level] === true)) {
      return isMessage ? 'Saverity cannot be empty' : false;
    }
    if (!selectedLocation) {
      return isMessage ? 'Location cannot be empty' : false;
    }
    if (!visibility) {
      return isMessage ? 'Visibility cannot be empty' : false;
    }
    if (!description) {
      return isMessage ? 'Description cannot be empty' : false;
    }

    return true;
  };

  const handleSubmit = isDraft => {
    if (validationIssue() || isDraft) {
      let payload = {
        title: title,
        description: description,
        issue_type: selectedType,
        ...(formId && { form_section_id: formId }),
        location: location?.name,
        due_date: dueDate,
        visibility: visibility?.name,
        attachments: attachments,
        status: isDraft ? 'DRAFT' : 'OPEN',
        saverity: Object.keys(saverity).find(level => saverity[level] === true),
        ...(registeredGroups?.length && {
          assign_to_groups: registeredGroups?.map(group => group?.id)
        }),
        ...(registeredUsers?.length && { assign_to_users: registeredUsers?.map(user => user?.id) })
      };
      if (isDraft) {
        dispatch(setShowLoading(true));
        if (id) {
          put(`${apiUrls.ISSUE}/${id}`, payload).then(res => {
            dispatch(setShowLoading(false));
            if (res.status === 201) {
              setSuccessModal(true);
            } else {
              if (res.data?.error?.errors[0][0].message) {
                toast.error(
                  <Toast
                    message={'Error'}
                    detailedMessage={res.data?.error?.errors[0][0].message}
                  />
                );
              } else {
                toast.error(<Toast message={'Error'} detailedMessage={res.data.error.message} />);
              }
            }
          });
        } else {
          if (navigator.onLine) {
            post(apiUrls.ISSUE, payload).then(res => {
              dispatch(setShowLoading(false));
              if (res.status === 201) {
                setSuccessModal(true);
              } else {
                if (res.data?.error?.errors[0][0].message) {
                  toast.error(
                    <Toast
                      message={'Error'}
                      detailedMessage={res.data?.error?.errors[0][0].message}
                    />
                  );
                } else {
                  toast.error(<Toast message={'Error'} detailedMessage={res.data.error.message} />);
                }
              }
            });
          } else {
            offlineHelper
              .saveRequestDataOffline({
                url: apiUrls.ISSUE,
                body: payload,
                cacheUrl: apiUrls.ISSUE,
                key: 'data',
                data: {
                  id: 'offline' + uuid,
                  ...payload,
                  created_by: user?.full_name,
                  created_at: new Date().toISOString(),
                  comments: [
                    {
                      id: 1,
                      remark: {
                        message: []
                      },
                      description: 'Assign this issue to',
                      attachments: null,
                      recording: null,
                      created_at: new Date().toISOString(),
                      sender: user?.full_name,
                      tags: []
                    }
                  ]
                }
              })
              .then(_ => {
                dispatch(setShowLoading(false));
                setSuccessModal(true);
              })
              .catch(_ => {
                dispatch(setShowLoading(false));
                toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
              });
          }
        }
      } else {
        if (registeredStatus === 'OPEN' && id) {
          put(`${apiUrls.ISSUE}/${id}`, payload).then(res => {
            dispatch(setShowLoading(false));
            if (res.status === 201) {
              setSuccessModal(true);
            } else {
              if (res.data?.error?.errors[0][0].message) {
                toast.error(
                  <Toast
                    message={'Error'}
                    detailedMessage={res.data?.error?.errors[0][0].message}
                  />
                );
              } else {
                toast.error(<Toast message={'Error'} detailedMessage={res.data.error.message} />);
              }
            }
          });
        } else if (id) {
          navigate(`/Task/IssueSubmission/${id}/SendIssue`, {
            state: {
              type: 'SubmitIssue',
              payload
            }
          });
        } else {
          navigate('/Task/IssueSubmission/SendIssue', {
            state: {
              type: 'SubmitIssue',
              payload
            }
          });
        }
      }
    } else {
      toast.error(<Toast message={'Error'} detailedMessage={validationIssue(true)} />);
    }
  };

  const fetchType = () => {
    get(apiUrls.ISSUE_TYPES).then(res => {
      if (res.status === 200) {
        setTypeList(res.data);
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={res.data.error.message} />);
      }
    });
  };

  const fetchLocation = () => {
    get(apiUrls.ISSUE_WORK_LOCATION).then(res => {
      const { status } = res;
      if (status === 200) {
        setLocationList(res.data);
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={res.data.error.message} />);
      }
    });
  };

  useEffect(() => {
    getVisibility();
    fetchType();
    fetchLocation();
  }, []);

  return (
    <div className="flex flex-col bg-white rounded-2xl justify-between p-[16px] mb-[40px] mx-0 sm:mx-[16px] gap-4">
      <div className=" flex flex-col sm:flex-row gap-4 ">
        <div className="flex flex-1 flex-col gap-2">
          <div className="font-semibold text-[12px]">Title</div>
          <input
            className={
              'input-text w-full  text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]'
            }
            onChange={event => setTitle(event.target.value)}
            value={title}
            placeholder="Enter title"
          />
        </div>
        <div className="flex flex-1 flex-col gap-2">
          <div className="font-semibold text-[12px]">Due Date</div>
          <div className="flex relative">
            <ReactDatePicker
              selected={dueDate}
              minDate={new Date()}
              onChange={date => setDuedate(date)}
              dateFormat={'dd-MMM-yyyy'}
              className={
                'input-text text-[12px] pl-[40px] pr-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]'
              }
              wrapperClassName="date-picker"
              onKeyDown={e => {
                e.preventDefault();
              }}
              popperModifiers={[
                {
                  name: 'arrow',
                  options: {
                    padding: ({ popper, reference }) => ({
                      right: Math.min(popper.width, reference.width) - 50
                    })
                  }
                }
              ]}
            />

            <img
              src={icons.icCalendar}
              className="w-6 absolute left-[12px] top-[8px] pointer-events-none"
              alt="calendar"
            />
          </div>
        </div>
      </div>
      <div className=" flex flex-col sm:flex-row gap-4 ">
        <div className="flex flex-1 flex-col gap-2">
          <div className="font-semibold text-[12px]">Issue Type</div>
          <Select
            onChange={selectedOptions => {
              setType(selectedOptions);
              setSelectedType(selectedOptions?.name);
            }}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.name}
            options={typelist}
            name="Type"
            className={
              'input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4'
            }
            placeholder="Select type"
            isClearable
            value={type}
            styles={{
              option: provided => ({
                ...provided
              }),
              control: provided => ({
                ...provided,
                ':focus': { borderColor: '#6546C3' },
                ':active': { borderColor: '#6546C3' },
                ':hover': { borderColor: '#6546C3' },
                paddingLeft: 4,
                boxShadow: 'none'
              }),
              dropdownIndicator: provided => ({
                ...provided,
                color: '#6546C3'
              }),
              indicatorSeparator: () => ({}),
              valueContainer: provided => ({
                ...provided
              })
            }}
          />
        </div>
        <div className="flex flex-1 flex-col gap-2">
          <div className="font-semibold text-[12px]">Saverity</div>
          <div className="flex flex-row justify-between gap-6">
            <div
              className={`flex flex-row flex-grow cursor-pointer border border-2 rounded-md p-1 items-center   ${
                saverity.LOW ? 'border-[#6546C3] bg-[#6546C3] bg-opacity-[0.2]' : ''
              }`}
              onClick={() => setSaverity({ LOW: !saverity.LOW, MEDIUM: false, HIGH: false })}
            >
              <div className="w-[6px] mr-2  h-[24px] rounded-md  border-2 border-[#01B59C] bg-[#01B59C]" />
              <div
                className={`text-[12px] mx-auto ${
                  saverity.LOW ? 'text-[#6546C3] font-semibold' : 'text-[#AAAAAA]'
                }`}
              >
                Low
              </div>
            </div>
            <div
              className={`flex flex-row flex-grow cursor-pointer border border-2 rounded-md p-2 items-center   ${
                saverity.MEDIUM ? 'border-[#6546C3] bg-[#6546C3] bg-opacity-[0.2]' : ''
              }`}
              onClick={() =>
                setSaverity({
                  LOW: false,
                  MEDIUM: !saverity.MEDIUM,
                  HIGH: false
                })
              }
            >
              <div className="w-[6px] mr-2  h-[24px] rounded-md  border-2 border-[#FF7301] bg-[#FF7301]" />
              <div
                className={`text-[12px] mx-auto ${
                  saverity.MEDIUM ? 'text-[#6546C3] font-semibold' : 'text-[#AAAAAA]'
                }`}
              >
                Medium
              </div>
            </div>
            <div
              className={`flex flex-row flex-grow cursor-pointer border border-2 rounded-md p-2 items-center  ${
                saverity.HIGH ? 'border-[#6546C3] bg-[#6546C3] bg-opacity-[0.2]' : ''
              }`}
              onClick={() =>
                setSaverity({
                  LOW: false,
                  MEDIUM: false,
                  HIGH: !saverity.HIGH
                })
              }
            >
              <div className="w-[6px] mr-2  h-[24px] rounded-md  border-2 border-[#C800A5] bg-[#C800A5]" />
              <div
                className={`text-[12px] mx-auto ${
                  saverity.HIGH ? 'text-[#6546C3] font-semibold' : 'text-[#AAAAAA]'
                }`}
              >
                High
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" flex flex-col sm:flex-row gap-4 ">
        <div className="flex flex-1 flex-col gap-2">
          <div className="font-semibold text-[12px]">Location</div>
          <Select
            onChange={selectedOptions => {
              setLocation(selectedOptions);
              setSelectedLocation(selectedOptions?.name);
            }}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.name}
            options={locationList}
            name="Location"
            className={
              'input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4'
            }
            placeholder="Select location"
            isClearable
            value={location}
            styles={{
              option: provided => ({
                ...provided
              }),
              control: provided => ({
                ...provided,
                ':focus': { borderColor: '#6546C3' },
                ':active': { borderColor: '#6546C3' },
                ':hover': { borderColor: '#6546C3' },
                paddingLeft: 4,
                boxShadow: 'none'
              }),
              dropdownIndicator: provided => ({
                ...provided,
                color: '#6546C3'
              }),
              indicatorSeparator: () => ({}),
              valueContainer: provided => ({
                ...provided
              })
            }}
          />
        </div>
        <div className="flex flex-1 flex-col gap-2">
          <div className="font-semibold text-[12px]">Visibility</div>
          <Select
            getOptionLabel={option => option.name}
            getOptionValue={option => option.name}
            isSearchable={false}
            options={visibilityOptions}
            value={visibility}
            isClearable
            onChange={selectedOption => {
              setVisibility(selectedOption);
              setVisibilityDescription(selectedOption?.description);
            }}
            className={'flex-1 text-[12px] focus:outline-none focus:border-[#6546C3]'}
            placeholder={'Select Visibility'}
            styles={{
              control: provided => ({
                ...provided,
                borderColor: 'none',
                ':focus': { borderColor: '#6546C3' },
                ':active': { borderColor: '#6546C3' },
                ':hover': { borderColor: '#6546C3' },
                paddingLeft: 4,
                boxShadow: 'none'
              }),
              dropdownIndicator: base => ({
                ...base,
                color: '#6546C3',
                marginRight: '4px',
                ':hover': { color: '#6546C3' }
              }),
              indicatorSeparator: () => ({}),
              valueContainer: provided => ({
                ...provided
              })
            }}
          />
          <div>
            {visibilityDescription && (
              <span className="p-1 rounded-lg w-auto font-poppins font-semibold bg-opacity-[0.16] text-[10px] mb-2 bg-[#C800A5] text-[#C800A5]">
                {visibilityDescription}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-1 flex-col gap-2">
        <div className="font-semibold text-[12px]">Description</div>
        <textarea
          placeholder={'Enter Description'}
          className={
            'text-[12px] px-[16px] py-[12px] border rounded-lg w-full min-h-[100px] focus:outline-none focus:border-[#6546C3]'
          }
          value={description}
          onChange={event => setDescription(event.target.value)}
        />
      </div>
      <div className="flex flex-1 flex-col gap-2">
        <div className="font-semibold text-[12px]">Attachment</div>
        <FieldAttachment
          attachments={attachments}
          fileInputRef={inputRef}
          submitted={false}
          handleAddAttachment={handleAddAttachment}
          handleDeleteAttachment={handleDeleteAttachment}
        />
      </div>
      <div className="flex flex-row gap-6 mt-[60px] sm:mt-[160px]  justify-center sm:justify-between mx-[12px] sm:mx-0">
        {registeredStatus !== 'OPEN' ? (
          <button
            className="bg-transparent border-2 border-[#6546C3] rounded-lg py-[10px] w-[150px] font-semibold text-[#6546C3] 
                  text-[14px] flex justify-center items-center"
            onClick={() => {
              handleSubmit(true);
            }}
          >
            Save Draft
          </button>
        ) : (
          <div className="w-[150px]" />
        )}
        <div className="flex flex-row gap-6">
          <button
            className="hidden sm:block bg-transparent border-2 border-[#6546C3] rounded-lg py-[10px] w-[150px] font-semibold text-[#6546C3] 
                    text-[14px] flex justify-center items-center"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <button
            className="bg-[#6546C3] border-2 border-[#6546C3] rounded-lg py-[10px] w-[150px] font-semibold text-white 
                    text-[14px] flex justify-center items-center"
            onClick={() => handleSubmit(false)}
          >
            {registeredStatus === 'OPEN' ? 'Save' : 'Send Issue'}
          </button>
        </div>
      </div>
      <SuccessPopUp
        show={successModal}
        imageSuccess={images.imageSuccess}
        title={'Successfully Save Draft'}
        message={'Hooray! You have successfully save an issue to draft'}
        labelSuccessButton={'Ok, Back to List'}
        handleSuccessButton={() => {
          setSuccessModal(false);
          navigate('/Task', {
            state: {
              tabName: 'Issue'
            }
          });
        }}
        labelBackButton={''}
      />
    </div>
  );
};
export default CreateIssuePage;
