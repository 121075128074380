import { useDispatch } from 'react-redux';
import { post } from '../../../../../api/base';
import { apiUrls, images } from '../../../../../constants';
import { setShowLoading } from '../../../../../store/slices/popup';
import { useState } from 'react';
import { Toast, SuccessPopUp } from '../../../../../components';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const DuplicateFormModal = ({
  showModal,
  setShowModal,
  formId,
  formTitle,
  reloadAfterDuplicate
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [duplicatedFormId, setDuplicatedFormId] = useState(null);

  const handleDuplicateForm = ({ reset }) => {
    dispatch(setShowLoading(true));
    post(`${apiUrls.DYNAMIC_FORM}/contents/${formId}/duplicate`, {}, { query: { reset } })
      .then(res => {
        dispatch(setShowLoading(false));
        if (res.status === 201) {
          setDuplicatedFormId(res.data.id);
          setShowModal(false);
          setShowSuccessModal(true);
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={res.data?.error?.message} />);
        }
      })
      .catch(_ => {
        dispatch(setShowLoading(false));
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
      });
  };

  const handleSuccessDuplicate = () => {
    if (duplicatedFormId) {
      setShowSuccessModal(false);
      navigate(`/Task/DetailForm/${duplicatedFormId}`);
    }
  };

  return (
    <div>
      <input
        type="checkbox"
        className="modal-toggle"
        checked={showModal}
        onChange={e => e.preventDefault()}
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div
          className="modal-box bg-white relative sm:rounded-2xl rounded-t-[32px] w-screen sm:w-[350px] h-fit sm:h-fit py-[40px] px-[35px]"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex flex-col items-center items-center mb-[42px]">
            <img
              src={images.imageConfirmation}
              className="w-[120px] h-[120px] mb-9"
              alt="confirmation"
            />
            <span className="font-semibold text-[14px] mb-1">Duplicate Form</span>
            <span className="w-[232px] text-[12px] text-center text-gray-500">
              Are you sure you want to duplicate form?
            </span>
          </div>
          <div className="grid grid-cols-2 w-fit sm:mt-9 gap-[14px] mx-auto">
            <button
              className="col-span-2 bg-transparent border-2 border-[#6546C3] rounded-lg py-[10px] w-full font-semibold text-[#6546C3]  
                    text-[14px] flex justify-center items-center shadow-lg"
              onClick={() => handleDuplicateForm({ reset: true })}
            >
              Duplicate & Reset all value
            </button>
            <button
              className="bg-transparent border-2 border-[#6546C3] rounded-lg py-[10px] w-[130px] font-semibold text-[#6546C3] 
                    text-[14px] flex justify-center items-center"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
            <button
              className="bg-[#6546C3] border-2 border-[#6546C3] rounded-lg py-[10px] w-[130px] font-semibold text-white 
                    text-[14px] flex justify-center items-center shadow-lg"
              onClick={() => handleDuplicateForm({ reset: false })}
            >
              Duplicate
            </button>
          </div>
        </div>
      </div>
      <SuccessPopUp
        show={showSuccessModal}
        title={'Successfully'}
        message={`Form ${formTitle} duplicated and the status set to Draft. All attachment need to complete with new one`}
        labelSuccessButton={'Continue to Edit'}
        labelBackButton={'Back to List'}
        handleSuccessButton={handleSuccessDuplicate}
        handleBackButton={() => {
          setShowSuccessModal(false);
          navigate('/Task', {
            state: { tabName: 'Form', ...(reloadAfterDuplicate && { key: new Date() }) }
          });
        }}
      />
    </div>
  );
};

export default DuplicateFormModal;
