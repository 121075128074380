import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setOrderBy } from '../../../../../store/slices/table';
import { icons } from '../../../../../constants';

const FilterModal = ({
  showModal,
  setShowModal,
  setFormName,
  setSync,
  setNotSync,
  setPageRef,
  setOfflineFilterAttribute
}) => {
  const [tempFilterAttribute, setTempFilterAttribute] = useState({ order_by: null });
  const dispatch = useDispatch();
  const [tempSync, setTempSync] = useState(false);
  const [tempNotSync, setTempNotSync] = useState(false);
  const [formTitle, setFormTitle] = useState(null);

  const sortBy = value => {
    setTempFilterAttribute({ ...tempFilterAttribute, order_by: value });
  };
  const reset = () => {
    setTempNotSync(false);
    setTempSync(false);
    setFormTitle('');
    setFormName('');
    setTempFilterAttribute({ order_by: null });
  };

  const handleOnChangeSync = ({ target }) => {
    setTempSync(target.checked);
  };
  const handleOnChangeNotSync = ({ target }) => {
    setTempNotSync(target.checked);
  };

  return (
    <div>
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px] h-[75vh] sm:h-fit">
          <div className="bg-white">
            <div className="flex flex-row items-center justify-between">
              <div className="text-[18px] font-semibold">Filter</div>
              <img
                src={icons.icCloseModal}
                className="w-6 h-6 cursor-pointer"
                alt={'close-modal'}
                onClick={() => setShowModal(false)}
              />
            </div>
            <hr className="mt-2 mb-6" />
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="accent-[#6546C3]"
                checked={tempSync}
                onChange={handleOnChangeSync}
              />
              <div className="text-[12px]">Sync</div>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="accent-[#6546C3]"
                checked={tempNotSync}
                onChange={handleOnChangeNotSync}
              />
              <div className="text-[12px]">Not Sync</div>
            </div>
            <div className="font-semibold text-[12px] mt-[28px] mb-[16px]">Sort</div>
            <div
              style={{
                borderColor: tempFilterAttribute?.order_by === 'desc' ? '#6546C3' : '',
                backgroundColor:
                  tempFilterAttribute?.order_by === 'desc' ? 'rgba(101, 70, 195, 0.16)' : ''
              }}
              className="flex flex-row border border-2 rounded-md p-2  items-center bg-opacity[0.06]"
              onClick={() =>
                tempFilterAttribute?.order_by === 'desc' ? sortBy(null) : sortBy('desc')
              }
            >
              <div
                className="text-[12px]"
                style={{
                  color: tempFilterAttribute?.order_by === 'desc' ? '#6546C3' : ''
                }}
              >
                Newest on top
              </div>
              <div
                className="ml-auto border border-2 rounded-full w-[16px] h-[16px]"
                style={{
                  backgroundColor:
                    tempFilterAttribute?.order_by === 'desc' ? '#6546C3' : 'transparent',
                  borderColor: '#6546C3'
                }}
              />
            </div>

            <div
              className="flex border-2 flex-row border rounded-md p-2 w-full items-center mt-[8px]"
              style={{
                borderColor: tempFilterAttribute?.order_by === 'asc' ? '#6546C3' : '',
                backgroundColor:
                  tempFilterAttribute?.order_by === 'asc' ? 'rgba(101, 70, 195, 0.16)' : ''
              }}
              onClick={() =>
                tempFilterAttribute?.order_by === 'asc' ? sortBy(null) : sortBy('asc')
              }
            >
              <div
                className="text-[12px]"
                style={{
                  color: tempFilterAttribute?.order_by === 'asc' ? '#6546C3' : ''
                }}
              >
                Oldest on top
              </div>
              <div
                className="ml-auto border border-2 rounded-full w-[16px] h-[16px]"
                style={{
                  backgroundColor:
                    tempFilterAttribute?.order_by === 'asc' ? '#6546C3' : 'transparent',
                  borderColor: '#6546C3'
                }}
              />
            </div>
            <div className="font-semibold text-[12px] mt-[28px] mb-[16px]">Form Name</div>
            <input
              type="text"
              className="focus:outline-none focus:border-[#6546C3] border text-[12px] w-full p-2 rounded-md"
              value={formTitle || ''}
              onChange={e => setFormTitle(e.target.value)}
              placeholder="Search here..."
            />
          </div>
        </div>
        <div className="flex flex-row justify-center sm:justify-end bg-[#EBEDF8] w-full absolute sm:w-[512px] md:w-[512px] sm:mt-[420px] rounded-b-xl">
          <button
            className="rounded-lg border-2 border-[#6546C3] px-7 py-2 m-2 flex text-[#6546C3] font-semibold text-[14px]"
            onClick={reset}
          >
            Reset
          </button>
          <button
            className="rounded-lg border-2 bg-[#6546C3] px-6 py-2 m-2 flex text-white font-semibold text-[14px]"
            onClick={() => {
              if (navigator.onLine) {
                dispatch(setOrderBy(tempFilterAttribute.order_by || 'desc'));
                setFormName(formTitle);
                setSync(tempSync);
                setNotSync(tempNotSync);
                setShowModal(false);
                setPageRef(1);
              } else {
                setShowModal(false);
                let attribute = {
                  sync: tempSync,
                  notSync: tempNotSync,
                  earlier: tempFilterAttribute.order_by === 'asc',
                  latest: tempFilterAttribute.order_by === 'desc',
                  formName: formTitle
                };
                setOfflineFilterAttribute(attribute);
              }
            }}
          >
            Filter Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
