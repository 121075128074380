import { useLocation, useNavigate } from 'react-router-dom';
import { icons } from '../../constants';

const BottomNavigation = () => {
  const location = useLocation();
  let mockData = ['/Task', '/MyActivity', '/Home', '/UserLog'];
  const navigate = useNavigate();
  const isHome = () => location.pathname === '/Home';
  const isMyActivity = () => location.pathname === '/MyActivity';
  const isTask = () => location.pathname === '/Task';
  const isUserLog = () => location.pathname === '/UserLog';
  return (
    <div
      className={`btm-nav ${
        mockData.includes(location.pathname) ? '' : 'hidden'
      } lg:left-[288px] lg:w-[calc(100%-288px)] p-6 h-[120px] lg:hidden bg-transparent`}
    >
      <button
        onClick={() => navigate('/Home')}
        className={`bg-white ${
          isHome() ? 'text-[#C800A5] font-semibold' : 'text-[#CDC6E1]'
        }  rounded-l-full shadow-md`}
      >
        <img
          src={isHome() ? icons.icHomeActive : icons.icHomeInactive}
          alt="home"
          width={24}
          height={24}
        />
        <span className="btm-nav-label text-[12px]">Home</span>
      </button>
      <button
        onClick={() => navigate('/MyActivity')}
        className={`bg-white ${
          isMyActivity() ? 'text-[#C800A5] font-semibold' : 'text-[#CDC6E1]'
        } shadow-md`}
      >
        <img
          src={isMyActivity() ? icons.icMyActivityActive : icons.icMyActivityInactive}
          alt="home"
          width={24}
          height={24}
        />
        <span className="btm-nav-label text-[12px]">My Activity</span>
      </button>
      <button
        onClick={() => navigate('/Task')}
        className={`bg-white ${
          isTask() ? 'text-[#C800A5] font-semibold' : 'text-[#CDC6E1]'
        }  shadow-md`}
      >
        <img
          src={location.pathname === '/Task' ? icons.icTaskActive : icons.icTaskInactive}
          alt="home"
          width={24}
          height={24}
        />
        <span className="btm-nav-label text-[12px]">Task</span>
      </button>
      <button
        onClick={() => navigate('/UserLog')}
        className={`bg-white ${
          isUserLog() ? 'text-[#C800A5] font-semibold' : 'text-[#CDC6E1]'
        }  rounded-r-full shadow-md`}
      >
        <img
          src={isUserLog() ? icons.icActiveUserLog : icons.icInactiveUserLog}
          alt="home"
          width={24}
          height={24}
        />
        <span className="btm-nav-label text-[12px]">User Log</span>
      </button>
    </div>
  );
};

export default BottomNavigation;
