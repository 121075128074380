import { useEffect, useState } from 'react';
import { icons, apiUrls } from '../../constants';
import { get, post } from '../../api/base';
import { EmptyData, Loading, Toast } from '../../components';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setShowLoading } from '../../store/slices/popup';
import AppsInfoModal from './components/AppsInfoModal';
import ModuleInfoModal from './components/ModuleInfoModal';
import axios from 'axios';
import { savePassword, saveUsername } from '../../store/slices/auth';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import FilterModal from './components/FilterModal';

const ViewApp = ({ viewType, activeType, setActiveType, getData }) => {
  return (
    <div className="btn-group h-[40px] bg-[#F5F6F8] rounded-lg w-fit">
      {viewType?.map((filter, index) => (
        <div
          key={index}
          className={`cursor-pointer w-[40px] h-full p-0 rounded-lg flex items-center justify-center ${
            activeType === filter.name
              ? 'bg-[#6546C3] hover:bg-[#6546C3]'
              : 'bg-[#F5F6F8] hover:bg-gray-300'
          }`}
          onClick={() => {
            setActiveType(filter.name);
            getData(filter.name);
          }}
        >
          <img
            src={activeType === filter.name ? filter.icon_active : filter.icon}
            className="w-[20px] h-[20px]"
            alt={`Filter By ${filter.name}`}
          />
        </div>
      ))}
    </div>
  );
};

const Filter = ({ openFilter, filterLength, searchData, search }) => {
  return (
    <div className="flex flex-row-reverse sm:flex-row gap-4">
      <div
        className={`flex gap-1 border-[1px] border-[#463191] border-opacity-25 items-center justify-center min-w-[45px] w-fit h-[40px] rounded-lg cursor-pointer ${
          filterLength > 0 ? 'px-4' : 'px-1'
        }`}
        onClick={() => openFilter(true)}
      >
        {filterLength > 0 && (
          <div className="py-0.5 px-2 rounded-md bg-[#6546C3] text-white font-semibold">
            {filterLength}
          </div>
        )}
        <img src={icons.icFilter} className="w-[26px] h-[26px]" alt="Filter" />
      </div>
      <div className="flex w-full md:max-w-[300px] h-[40px] border-[1px] items-center p-[12px] rounded-lg">
        <img src={icons.icSearch} className="w-[14px] h-[14px]" alt="Search" />
        <input
          defaultValue={search}
          className="ml-2 focus:outline-none text-[12px] w-full"
          placeholder="Search here..."
          onKeyDown={searchData}
          onBlur={searchData}
        />
      </div>
    </div>
  );
};

const ModuleItem = ({ module, handleModuleInfo }) => {
  return (
    <div className="flex flex-col items-center gap-2">
      <div
        className="bg-[#EBEDF8] rounded-md w-[70px] h-[70px] flex items-center justify-center cursor-pointer"
        onClick={() => handleModuleInfo(module.id)}
      >
        <img
          src={module.icon || icons.icDefaultModule}
          className="w-[46px] h-[46px]"
          alt={module.name}
        />
      </div>
      <div className="text-[12px] font-normal text-center">{module.name}</div>
    </div>
  );
};
const Home = () => {
  const viewType = [
    {
      name: 'App Name',
      icon: icons.icByAppName,
      icon_active: icons.icByAppNameActive
    },
    {
      name: 'Area Name',
      icon: icons.icByAreaName,
      icon_active: icons.icByAreaNameActive
    },
    {
      name: 'App Favorite',
      icon: icons.icStar,
      icon_active: icons.icStarActive
    }
  ];
  const [activeType, setActiveType] = useState('App Name');
  const [dataApplication, setDataApplication] = useState([]);
  const [dataApplicationByArea, setDataApplicationByArea] = useState([]);
  const [username, setUsername] = useState('');
  const [credentialData, setCredentialData] = useState({});
  const [password, setPassword] = useState('');
  const [showModalApps, setShowModalApps] = useState(false);
  const [showModalModule, setShowModalModule] = useState(false);
  const [detailApps, setDetailApps] = useState({});
  const [detailModule, setDetailModule] = useState({});
  const [isFirstFetching, setIsFirstFetching] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingAppInfo, setIsFetchingAppInfo] = useState(false);
  const [isFetchingModuleInfo, setIsFetchingModuleInfo] = useState(false);
  const [areaName, setAreaName] = useState('');
  const [typeName, setTypeName] = useState('');
  const [filterModal, setFilterModal] = useState('');
  const [filterLength, setFilterLength] = useState(0);
  const [search, setSearch] = useState('');
  const auth = useSelector(state => state.auth);
  const user = useSelector(state => state.user.userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchData = ev => {
    switch (true) {
      case ev.key === 'Enter':
      case ev.target.value.length === 0:
        setSearch(ev.target.value);
        break;
      default:
        break;
    }
  };

  const getDataApplication = name => {
    setIsFetching(true);
    let query = {
      favorite: name === 'App Favorite' ? true : false,
      area: areaName,
      type: typeName,
      search
    };
    if (!query.favorite) delete query.favorite;
    if (!search) delete query.search;
    get(apiUrls.APPLICATION_PROFILE, { query: { ...query } })
      .then(response => {
        const { status } = response;
        setIsFetching(false);
        dispatch(setShowLoading(false));
        if (status === 200) {
          setDataApplication(response.data?.applications || []);
        } else if (status === 408) {
          toast.error(
            <Toast message={'Error'} detailedMessage={`${response.data.error.message}`} />
          );
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={'Failed to fetch applications'} />);
        }
      })
      .catch(e => {
        dispatch(setShowLoading(false));
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
      });
  };

  const getDataApplicationByArea = () => {
    let query = { area: areaName, type: typeName, search };
    if (!search) delete query.search;
    setIsFetching(true);
    get(`${apiUrls.APPLICATION_PROFILE}/by-area`, {
      query: query
    })
      .then(response => {
        const { status } = response;
        setIsFetching(false);
        if (status === 200) {
          setDataApplicationByArea(response.data?.data || []);
        } else if (status === 408) {
          toast.error(
            <Toast message={'Error'} detailedMessage={`${response.data.error.message}`} />
          );
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={'Failed to fetch applications'} />);
        }
      })
      .catch(() => {
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
      });
  };

  const handleAppInfo = appId => {
    setIsFetchingAppInfo(true);
    get(`${apiUrls.APPLICATION_PROFILE}/${appId}`)
      .then(response => {
        const { status, data } = response;
        if (status === 200) {
          setDetailApps(data);
          setShowModalApps(true);
        } else if (status === 408) {
          toast.error(
            <Toast message={'Error'} detailedMessage={`${response.data.error.message}`} />
          );
        } else {
          toast.error(
            <Toast message={'Error'} detailedMessage={'Failed to fetch application info'} />
          );
        }
        setIsFetchingAppInfo(false);
      })
      .catch(() => {
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
      });
  };

  const handleModuleInfo = moduleId => {
    setIsFetchingModuleInfo(true);
    get(`${apiUrls.MODULE}/${moduleId}`)
      .then(response => {
        const { status, data } = response;
        if (status === 200) {
          setDetailModule(data);
          setShowModalModule(true);
        } else if (status === 408) {
          toast.error(
            <Toast message={'Error'} detailedMessage={`${response.data.error.message}`} />
          );
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={'Failed to fetch module info'} />);
        }
        setIsFetchingModuleInfo(false);
      })
      .catch(() => {
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
      });
  };

  const getStoredCredentials = () => {
    const pass = decrypt(auth?.password);
    setCredentialData({
      ...credentialData,
      username: auth.username,
      password: pass
    });
    setUsername(auth?.username);
    setPassword(pass);
  };

  const decrypt = text => {
    const decryptedText = CryptoJS.AES.decrypt(text, process.env.REACT_APP_SECRET_PASS).toString(
      CryptoJS.enc.Utf8
    );
    return decryptedText.replace(/^"(.*)"$/, '$1');
  };

  const handleFavoriteApps = idApps => {
    post(`${apiUrls.APPLICATION_PROFILE}/${idApps}/favorites`)
      .then(res => {
        if (res.status === 200) {
          getDataApplication(activeType);
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />);
        }
      })
      .catch(_ => {
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
      });
  };

  const handleOpenApp = async (appName, appLink, authParameter, appWebsite, authUrl) => {
    if (authUrl) {
      dispatch(setShowLoading(true));
      let param = authParameter ? authParameter.split('; ') : [];
      let bodyParam = {};
      for (var i = 0; i < param.length; i++) {
        bodyParam[param[i]] = credentialData[param[i]];
      }

      const axiosInstance = axios.create();
      axiosInstance.interceptors.response.use(
        response => {
          dispatch(setShowLoading(false));
          let token = response.data.token;
          let refreshToken = response.data.refreshToken;
          dispatch(saveUsername(username));
          dispatch(savePassword(password));
          let deepLinkUrl = `${appLink}/${token}/${refreshToken}/${username}/${password}`;
          window.location.href = deepLinkUrl;

          setTimeout(() => {
            window.open(appWebsite);
          }, 5000);
        },
        error => {
          dispatch(setShowLoading(false));
          if (error.response?.status === 401) {
            const message = 'Wrong credential';
            navigate('/ExternalLogin', {
              state: { appName, appLink, message, appWebsite, authUrl }
            });
          } else {
            let message;
            if (error.code === 'ERR_CANCELED') {
              message =
                'Server taking too long to response, please try again later or contact your IT support';
            } else {
              message = error.message;
            }
            navigate('/ExternalLogin', {
              state: { appName, appLink, message, appWebsite, authUrl }
            });
          }
        }
      );

      const sleep = time => new Promise(resolve => setTimeout(() => resolve(), time));
      const controller = new AbortController();
      axiosInstance.post(authUrl, bodyParam, { signal: controller.signal });
      await sleep(5000).then(() => {
        controller.abort();
      });
    } else {
      let temp = appLink?.replace(/.*?:\/\//g, '/').split('/')[2];
      let idFormTemp = appLink?.replace(/.*?:\/\//g, '/').split('/')[3];
      if (temp?.toLowerCase().includes('form') && /^\d+$/.test(idFormTemp)) {
        return navigate(`/Task/FormSubmission/DetailFormSubmission/${idFormTemp}`);
      } else if (appLink) {
        return window.open(appLink, '_blank');
      } else {
        return window.open(appWebsite, '_blank');
      }
    }
  };

  const populateGetData = name => {
    switch (name) {
      case 'App Name':
        getDataApplication(name);
        break;
      case 'App Favorite':
        getDataApplication(name);
        break;
      default:
        getDataApplicationByArea();
        break;
    }
  };

  useEffect(() => {
    if (user?.landing_page === 'Home') {
      setActiveType('App Favorite');
      getDataApplication('App Favorite');
    } else {
      getDataApplication();
    }
    setIsFirstFetching(true);
    getStoredCredentials();
  }, [user, areaName, typeName, search]);

  useEffect(() => {
    if (
      user?.landing_page === 'Home' &&
      activeType === 'App Favorite' &&
      !dataApplication.length &&
      isFirstFetching
    ) {
      setActiveType('App Name');
      getDataApplication('App Name');
      setIsFirstFetching(false);
    }
  }, [dataApplication, areaName, typeName, search]);

  return (
    <div className="mt-6 mb-[120px] sm:mx-6 lg:mb-0 bg-white rounded-2xl min-h-[calc(100vh-150px)] flex flex-col">
      {/* === Content Header === */}
      <div className="flex flex-col-reverse md:flex-row gap-4 p-6">
        <div className="flex flex-row flex-grow gap-4 justify-between">
          <div className="font-semibold text-[16px] text-[#232323]">
            View By <span className="text-[#C800A5]">{activeType}</span>
          </div>
          <ViewApp
            viewType={viewType}
            activeType={activeType}
            setActiveType={setActiveType}
            getData={populateGetData}
          />
        </div>
        <Filter
          openFilter={setFilterModal}
          filterLength={filterLength}
          search={search}
          searchData={searchData}
        />
      </div>

      {/* === Content === */}
      {/* View By App Name */}
      {isFetching && activeType !== 'Area Name' ? (
        <div className="h-[50vh] flex items-center justify-center">
          <Loading />
        </div>
      ) : (
        (activeType === 'App Name' || activeType === 'App Favorite') &&
        (dataApplication.length > 0 ? (
          dataApplication.map(app => (
            <div key={app.id}>
              <div className="flex items-center justify-between w-full bg-[#EBEDF8] h-[52px] px-6">
                <div className="flex items-center gap-2 text-[16px] font-semibold">
                  {app.name}{' '}
                  <img
                    src={icons.icInfo}
                    alt="Info"
                    className="w-[18px] h-[18px] cursor-pointer"
                    onClick={() => handleAppInfo(app.id)}
                  />
                </div>
                <div className="flex items-center gap-4">
                  <button
                    className="w-[87px] h-[28px] bg-[#6546C3] shadow-lg shadow-[#46319180] text-white text-[10px] 
                      font-semibold rounded-lg flex flex-row justify-center items-center gap-1"
                    onClick={() =>
                      handleOpenApp(
                        app.name,
                        app.deeplink,
                        app.authenticationParameter,
                        app.applicationLink,
                        app.authenticationApiLink
                      )
                    }
                  >
                    Open
                    <img src={icons.icExternalLink} alt="Open App" className="w-[16px] h-[16px]" />
                  </button>
                  <img
                    src={app?.isFavorite ? icons.icStarFilled : icons.icStarNotFilled}
                    alt="Favorite"
                    className="w-[36px] h-[36px] cursor-pointer"
                    onClick={() => handleFavoriteApps(app.id)}
                  />
                </div>
              </div>
              <div className="px-4 py-6 lg:p-6 grid grid-cols-4 sm:grid-cols-5 lg:grid-cols-8 gap-4">
                {app?.modules.map(module => (
                  <ModuleItem key={module.id} module={module} handleModuleInfo={handleModuleInfo} />
                ))}
              </div>
            </div>
          ))
        ) : (
          <EmptyData
            title="No application yet"
            message={
              activeType === 'App Name'
                ? 'Should have any inquiry please send email to'
                : 'You have not set favorite aplication yet'
            }
            contact={activeType === 'App Name' ? 'ithelpdesk@banpuindo.co.id' : ''}
            label={activeType === 'App Name' ? 'Send email' : ''}
          />
        ))
      )}

      {/* View By Area Name */}
      {activeType === 'Area Name' &&
        (dataApplicationByArea.length > 0 ? (
          dataApplicationByArea.map((item, index) => (
            <div key={index}>
              <div className="flex items-center w-full bg-[#EBEDF8] h-[52px] px-6">
                <div className="text-[16px] font-semibold">{item.area_name}</div>
              </div>

              {item?.applications.map((app, index) => (
                <div key={index}>
                  <div className="px-4 py-6 lg:p-6">
                    <div className="flex justify-between">
                      <div className="flex items-center gap-2 text-[16px] font-semibold">
                        {app?.name}{' '}
                        <img
                          src={icons.icInfo}
                          alt="Info"
                          className="w-[18px] h-[18px] cursor-pointer"
                          onClick={() => handleAppInfo(app.id)}
                        />
                      </div>
                      <button
                        className="w-[87px] h-[28px] bg-[#6546C3] shadow-lg shadow-[#46319180] text-white text-[10px] 
                      font-semibold rounded-lg flex flex-row justify-center items-center gap-1"
                        onClick={() =>
                          handleOpenApp(
                            app.name,
                            app.deeplink,
                            app.authenticationParameter,
                            app.applicationLink,
                            app.authenticationApiLink
                          )
                        }
                      >
                        Open
                        <img
                          src={icons.icExternalLink}
                          alt="Open App"
                          className="w-[16px] h-[16px]"
                        />
                      </button>
                    </div>

                    <div className="py-6 grid grid-cols-4 sm:grid-cols-5 lg:grid-cols-8 gap-4">
                      {app?.modules.map((module, index) => (
                        <ModuleItem
                          key={index}
                          module={module}
                          handleModuleInfo={handleModuleInfo}
                        />
                      ))}
                    </div>
                  </div>
                  {index + 1 !== item.applications.length && (
                    <div className="w-full bg-[#F5F6F8] h-[8px] px-6"></div>
                  )}
                </div>
              ))}
            </div>
          ))
        ) : isFetching ? (
          <div className="h-[50vh] flex items-center justify-center">
            <Loading />
          </div>
        ) : (
          <EmptyData
            title="No application yet "
            message="Should have any inquiry please send email to"
            contact="ithelpdesk@banpuindo.co.id"
            label="Send email"
          />
        ))}

      <AppsInfoModal
        showModal={showModalApps}
        setShowModal={setShowModalApps}
        detailApps={detailApps}
        isFetching={isFetchingAppInfo}
      />
      <ModuleInfoModal
        showModal={showModalModule}
        setShowModal={setShowModalModule}
        detailModule={detailModule}
        handleOpenApp={handleOpenApp}
        isFetching={isFetchingModuleInfo}
      />
      <FilterModal
        showModal={filterModal}
        setShowModal={setFilterModal}
        setAreaName={setAreaName}
        setTypes={setTypeName}
        countFilterLength={setFilterLength}
      />
    </div>
  );
};

export default Home;
