import { useSelector } from 'react-redux';
import { mockData, icons } from '../../../../../../constants';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import cacheHelper from '../../../../../../helpers/cache.helper';

const FieldAttachment = ({
  attachments,
  fileInputRef,
  submitted,
  handleAddAttachment,
  handleDeleteAttachment
}) => {
  const user = useSelector(state => state.user.userData);

  const [attachmentsData, setAttachmentsData] = useState(attachments);

  useEffect(() => {
    async function getAttachmentData() {
      const newAttachments = [];
      if (!_.isEmpty(attachments) && _.has(attachments[0], 'cacheIdentifier')) {
        for (const value of attachments) {
          await cacheHelper.getCachedAttachment(value.cacheIdentifier).then(res => {
            newAttachments.push(res);
          });
        }
        setAttachmentsData(newAttachments);
      } else {
        setAttachmentsData(attachments);
      }
    }
    getAttachmentData();
  }, [attachments]);

  const getAttachmentType = fileName => {
    if (fileName) {
      const fileNameSplit = fileName.split('.');
      return fileNameSplit[fileNameSplit.length - 1];
    } else {
      return 'jpg';
    }
  };

  const checkSoundType = file => {
    const found = mockData.extensionSounds.findIndex(
      el =>
        `audio/${el?.toLowerCase()}` === file?.type?.toLowerCase() ||
        el?.toLowerCase() === file?.type?.toLowerCase()
    );
    return found !== -1;
  };

  const getAttachmentImage = fileType => {
    switch (fileType) {
      case 'pptx':
        return icons.icPpt;
      case 'ppt':
        return icons.icPpt;
      case 'docx':
        return icons.icDoc;
      case 'doc':
        return icons.icDoc;
      case 'xls':
        return icons.icXls;
      case 'xlsx':
        return icons.icXls;
      default:
        return icons.icDoc;
    }
  };

  return (
    <>
      {!submitted && (
        <div className="relative flex items-center gap-2 p-2">
          <input
            ref={fileInputRef}
            type="file"
            className="absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer"
            multiple={true}
            accept={mockData.fileTypes.join(',')}
            onChange={handleAddAttachment}
          />
          <button
            type="button"
            className="flex items-center justify-center rounded-lg bg-[#EBEDF8] w-[40px] h-[40px]"
          >
            <img src={icons.icAddAttachment} alt="add attachment" className="w-8 h-8" />
          </button>
          <div className="text-[12px] text-[#6546C3]">
            Upload document, image, or audio up to{' '}
            <span className="text-[#C800A5]">{user?.file_size} mb</span>
          </div>
        </div>
      )}

      <div className="flex flex-row flex-wrap gap-9">
        {attachmentsData?.map((attachment, index) => {
          if (!_.has(attachment, 'cacheIdentifier')) {
            const isImage = mockData.extensionImages.includes(
              getAttachmentType(attachment?.fileName || attachment?.name)
            );
            const isVideo = mockData.extensionVideos.includes(
              getAttachmentType(attachment?.fileName || attachment?.name)
            );
            if (!checkSoundType(attachment)) {
              if (isImage) {
                return (
                  <div
                    key={index}
                    className="relative"
                    onClick={() => window.open(attachment.url || URL.createObjectURL(attachment))}
                  >
                    <img
                      src={attachment?.thumbnail || URL.createObjectURL(attachment)}
                      className="sm:w-[120px] sm:h-[120px] w-20 h-20 rounded-lg"
                      alt="Attachments"
                    />
                    {!submitted && (
                      <button
                        className="bg-white w-7 h-7 rounded-full flex items-center justify-center absolute bottom-[100px] left-[100px]"
                        onClick={e => {
                          e.stopPropagation();
                          handleDeleteAttachment(index);
                        }}
                      >
                        <img src={icons.icTrash} className="w-6 h-6" alt="clear" />
                      </button>
                    )}
                  </div>
                );
              } else if (isVideo) {
                return (
                  <div
                    key={index}
                    className="relative"
                    onClick={() => window.open(attachment.url || URL.createObjectURL(attachment))}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${attachment.thumbnail})`,
                        backgroundRepeat: 'no-repeat'
                      }}
                      className="flex sm:w-[120px] sm:h-[120px] w-20 h-20 rounded-lg items-center justify-center bg-black"
                    >
                      <img
                        className="items-center justify-center"
                        src={icons.icPlayVideo}
                        alt={'Attachments'}
                        height={40}
                        width={40}
                      />
                    </div>

                    {!submitted && (
                      <button
                        className="bg-white w-7 h-7 rounded-full flex items-center justify-center absolute bottom-[100px] left-[100px]"
                        onClick={e => {
                          e.stopPropagation();
                          handleDeleteAttachment(index);
                        }}
                      >
                        <img src={icons.icTrash} className="w-6 h-6" alt="clear" />
                      </button>
                    )}
                  </div>
                );
              } else if (!checkSoundType(attachment)) {
                return (
                  <div
                    className="relative w-[120px] h-[120px] bg-[#EBEDF8] rounded-lg flex flex-col gap-1 items-center justify-center"
                    key={index}
                    onClick={() => window.open(attachment.url)}
                  >
                    <img
                      src={getAttachmentImage(
                        getAttachmentType(attachment.fileName || attachment.name)
                      )}
                      className="sm:w-20 sm:h-20 w-10 h-10"
                      alt="Attachments"
                    />
                    <div className="w-20 truncate text-[12px] text-[#232323] text-center">
                      {attachment.fileName
                        ? attachment.fileName.substring(attachment.fileName.indexOf('_') + 1)
                        : attachment.name}
                    </div>
                    {!submitted && (
                      <button
                        className="bg-white w-7 h-7 rounded-full flex items-center justify-center absolute bottom-[100px] left-[100px]"
                        onClick={e => {
                          e.stopPropagation();
                          handleDeleteAttachment(index);
                        }}
                      >
                        <img src={icons.icTrash} className="w-6 h-6" alt="clear" />
                      </button>
                    )}
                  </div>
                );
              }
            }
          }
          return null;
        })}
        {attachmentsData?.map((attachment, index) => {
          if (!_.has(attachment, 'cacheIdentifier')) {
            if (checkSoundType(attachment)) {
              return (
                <div className="flex flex-row  items-center justify-center w-full">
                  <AudioPlayer
                    src={attachment.url || URL.createObjectURL(attachment)}
                    showSkipControls={false}
                    showJumpControls={false}
                    customProgressBarSection={[
                      RHAP_UI.MAIN_CONTROLS,
                      RHAP_UI.PROGRESS_BAR,
                      submitted ? null : (
                        <button
                          className="bg-[#EBEDF8] w-10 h-10 rounded-full flex items-center justify-center"
                          onClick={e => {
                            e.stopPropagation();
                            handleDeleteAttachment(index);
                          }}
                        >
                          <img src={icons.icTrash} className="w-6 h-6" alt="clear" />
                        </button>
                      )
                    ]}
                    customControlsSection={[
                      <div className="truncate w-[90px] mr-2 text-[12px] text-[#232323]">
                        {attachment?.fileName
                          ? attachment.fileName.substring(attachment.fileName.indexOf('_') + 1)
                          : attachment.name.substring(attachment.name.indexOf('_') + 1)}
                      </div>,
                      RHAP_UI.DURATION
                    ]}
                  />
                </div>
              );
            }
          }
          return null;
        })}
      </div>
    </>
  );
};

export default FieldAttachment;
