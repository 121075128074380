import { useEffect, useState } from 'react';
import { get, post } from '../../../../api/base';
import { apiUrls, icons, images } from '../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { Loading, SuccessPopUp, TableAccent, Toast } from '../../../../components';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMediaQuery } from 'react-responsive';
import { setShowLoading } from '../../../../store/slices/popup';
import { setPage, setPageSize } from '../../../../store/slices/table';
import { useLocation, useNavigate } from 'react-router-dom';

const CardSubmittedForm = ({ form, handleFavoriteForm, handleGoToDetail, handleAddOffline }) => {
  const location = useLocation();
  return (
    <div
      className="flex flex-row p-5 bg-white items-center mt-2 rounded-md"
      style={{ boxShadow: '0 5px 15px 0 rgba(0, 0, 0, 0.06)' }}
      onClick={() => handleGoToDetail({ id: form.id, title: form?.title })}
    >
      {!location.pathname.split('/').includes('BrowseFormSubmission') && (
        <div
          className="mr-2"
          onClick={e => {
            e.stopPropagation();
            handleAddOffline(form?.id);
          }}
        >
          <img
            src={form?.is_offline ? icons.icCanOffline : icons.icCantOffline}
            alt="document"
            height={32}
            width={32}
          />
        </div>
      )}
      <div className="flex flex-col">
        <div className={'min-w-[6rem] max-w-[180px] font-bold text-[12px] truncate'}>
          {form?.title}
        </div>
        <div className="text-[#C800A5] max-w-[180px] text-[12px]">
          <div className="w-full truncate">{form?.company?.join(', ')}</div>
        </div>
        {form?.last_submission ? (
          <div className={'min-w-[6rem] flex flex-row text-[10px] text-[#AFAFB9]'}>
            Last Submission:<div className="text-[#01B59C] ml-1">{form.last_submission}</div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div
        className="ml-auto"
        onClick={e => {
          e.stopPropagation();
          handleFavoriteForm(form?.id);
        }}
      >
        <img
          src={form?.is_favorite ? icons.icStarFullColor : icons.icStar}
          alt="status"
          className={'text-[#01B59C]'}
          width={30}
          height={30}
        />
      </div>
    </div>
  );
};

const FormSubmission = () => {
  const { limits, page, pageSize, search } = useSelector(state => state.table);
  const [forms, setForms] = useState([]);
  const [pageRef, setPageRef] = useState(1);
  const [searchRef, setSearchRef] = useState('');
  const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });
  const [totalData, setTotalData] = useState(0);
  const [firstFetching, setFirstFetching] = useState(false);
  const user = useSelector(state => state.user.userData);
  const [isFetching, setIsFetching] = useState(false);
  const [displayFavorite, setDisplayFavorite] = useState(false);
  const [limitOfflineModal, setLimitOfflineModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const headers = [
    {
      key: 'action',
      name: 'Action',
      isSortable: false
    },
    {
      key: 'title',
      name: 'Form Name',
      isSortable: true
    },
    {
      key: 'company',
      name: 'Company',
      isSortable: true
    },
    {
      key: 'last_submission',
      name: 'Last Submission',
      isSortable: true
    },
    {
      key: 'is_favorite',
      name: '',
      isSortable: false
    }
  ];

  const loadMoreData = reloadPage => {
    const filter = {
      limit: 50,
      page: reloadPage ? 1 : pageRef,
      search: searchRef
    };
    if (!searchRef) {
      delete filter.search;
    }
    if (displayFavorite) {
      filter.favorite = true;
    }
    get(`${apiUrls.BASE_URL_USERS}/${user?.id}/dynamic-forms`, {
      query: filter
    })
      .then(res => {
        if (res.status === 200) {
          let temp = res.data;
          temp.data = temp.forms;
          delete temp.forms;
          if (filter.page === 1) {
            setForms(temp);
          } else {
            const result = forms.data.concat(temp.data);
            setForms({ ...forms, data: result });
          }

          setTotalData(res.data.total_data);
          setPageRef(filter.page + 1);
          setFirstFetching(false);
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />);
        }
      })
      .catch(_ => {
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
      });
  };
  const searchDataRef = ev => {
    switch (true) {
      case ev.key === 'Enter':
      case ev.target.value.length === 0:
        setSearchRef(ev.target.value);
        break;
      default:
        break;
    }
  };
  const initiateFormData = () => {
    const filter = {
      limit: pageSize,
      page,
      search
    };
    if (!search) {
      delete filter.search;
    }
    if (displayFavorite) {
      filter.favorite = true;
    }
    setIsFetching(true);
    get(`${apiUrls.BASE_URL_USERS}/${user?.id}/dynamic-forms`, {
      query: filter
    })
      .then(res => {
        setIsFetching(false);
        if (res.status === 200) {
          if (page > res.data.total_page) {
            dispatch(setPage(1));
          }
          let temp = res.data;
          temp.data = temp.forms;
          delete temp.forms;
          setForms(temp);
          setFirstFetching(false);
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />);
        }
      })
      .catch(_ => {
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
        setIsFetching(false);
      });
  };

  const handleFavoriteForm = id => {
    dispatch(setShowLoading(true));
    post(`${apiUrls.DYNAMIC_FORM}/${id}/favorites`)
      .then(response => {
        if (response.status === 200) {
          initiateFormData();
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={response.data?.error?.message} />);
        }
        dispatch(setShowLoading(false));
      })
      .catch(e => {
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
        dispatch(setShowLoading(false));
      });
  };

  const saveOfflineForm = () => {
    get(`${apiUrls.BASE_URL_USERS}/${user?.id}/dynamic-forms`, {
      query: { offline: true }
    }).then(res => {
      if (!navigator.onLine) {
        if (res.status === 200) {
          let temp = res.data;
          temp.data = temp.forms;
          delete temp.forms;
          setForms(temp);
        }
      }
    });
  };

  const handleAddOffline = id => {
    dispatch(setShowLoading(true));
    post(`${apiUrls.DYNAMIC_FORM}/${id}/offline-mode`)
      .then(response => {
        if (response.status === 200) {
          saveOfflineForm();
          initiateFormData();
        } else if (response.status === 403) {
          setLimitOfflineModal(true);
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={response.data?.error?.message} />);
        }
        dispatch(setShowLoading(false));
      })
      .catch(e => {
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
        dispatch(setShowLoading(false));
      });
  };

  const handleGoToDetail = ({ id, title }) => {
    if (location.pathname.split('/').includes('BrowseFormSubmission')) {
      navigate(`/Task/BrowseFormSubmission/NewestSubmittedDocument/${id}`, {
        state: { formTitle: title }
      });
    } else {
      navigate(`/Task/FormSubmission/DetailFormSubmission/${id}`);
    }
  };

  useEffect(() => {
    dispatch(setPageSize(50));
    saveOfflineForm();
  }, []);

  useEffect(() => {
    if (!isSmallScreen) initiateFormData();
  }, [limits, page, pageSize, search, displayFavorite]);

  useEffect(() => {
    if (!firstFetching) setFirstFetching(true);
    if (isSmallScreen) loadMoreData(true);
  }, [searchRef, displayFavorite]);

  return (
    <>
      {isSmallScreen ? (
        <div className="p-5 pt-0 min-h-screen flex flex-col sm:hidden">
          <div className="max-h-[calc(100%-17rem)] flex flex-col gap-y-2 overflow-y-scroll">
            <div className="flex flex-row-reverse md:flex-row gap-4">
              <div
                className="flex border-[1px] border-[#463191] border-opacity-25 items-center justify-center w-[45px] h-[40px] rounded-lg"
                onClick={() => setDisplayFavorite(!displayFavorite)}
              >
                <img
                  src={displayFavorite ? icons.icStarFullColor : icons.icStar}
                  className="w-[26px] h-[26px]"
                  alt="Filter"
                />
              </div>
              <div className="flex w-full md:min-w-[150px] h-[40px] bg-white border-[1px] items-center p-[12px] rounded-lg">
                <img src={icons.icSearch} className="w-[14px] h-[14px]" alt="Search" />
                <input
                  className="ml-2 focus:outline-none text-[12px] w-full"
                  key={search}
                  defaultValue={search}
                  placeholder="Search here..."
                  onKeyDown={searchDataRef}
                  onBlur={searchDataRef}
                />
              </div>
            </div>
            {firstFetching ? (
              <div className="flex justify-center h-[50vh] my-auto">
                <Loading />
              </div>
            ) : (
              <InfiniteScroll
                dataLength={forms?.data?.length || 0}
                next={() => loadMoreData(false)}
                hasMore={forms?.data?.length < totalData}
                className="!overflow-hidden"
                loader={
                  <div className="flex justify-center h-[100px] my-auto">
                    <Loading />
                  </div>
                }
              >
                {forms?.data?.map((form, index) => (
                  <CardSubmittedForm
                    form={form}
                    key={index}
                    handleFavoriteForm={handleFavoriteForm}
                    handleGoToDetail={handleGoToDetail}
                    handleAddOffline={handleAddOffline}
                  />
                ))}
              </InfiniteScroll>
            )}
          </div>
        </div>
      ) : (
        <div className="hidden sm:flex sm:flex-col">
          <TableAccent
            {...{
              isFetching,
              tableTitle: 'Form List',
              tableTitleOnTop: true,
              tableHeaders: headers,
              data: forms,
              iconFilter: displayFavorite ? icons.icStarFullColor : icons.icStar,
              onFilterClick: () => setDisplayFavorite(!displayFavorite),
              emptyDataTitle: 'There is no document to show',
              emptyDataDescription: 'You are not assigned to any form',
              isForm: true
            }}
          >
            {(item, tHead) => {
              switch (tHead.key) {
                case 'action':
                  return (
                    <div className="flex justify-center m-0 w-full">
                      <button
                        className="flex justify-center items-center bg-[#F1F1FD] w-[24px] h-[24px] rounded-md"
                        onClick={() => handleGoToDetail({ id: item.id, title: item.title })}
                      >
                        <img src={icons.icArrowRight} alt="form submission" />
                      </button>
                    </div>
                  );
                case 'company':
                  return (
                    <div className="text-[#C800A5] w-[300px]">
                      <div className="w-full truncate">{item[tHead.key]?.join(', ')}</div>
                    </div>
                  );
                case 'title':
                  return (
                    <div className="flex flex-row items-center w-full overflow">
                      {!location.pathname.split('/').includes('BrowseFormSubmission') && (
                        <img
                          onClick={() => handleAddOffline(item.id)}
                          src={item?.is_offline ? icons.icCanOffline : icons.icCantOffline}
                          alt="document"
                          height={36}
                          width={36}
                          className=" cursor-pointer"
                        />
                      )}

                      <div className="ml-2 w-full truncate">{item[tHead.key]}</div>
                    </div>
                  );
                case 'last_submission':
                  return <span className="text-[#01B59C]">{item[tHead.key]}</span>;
                case 'is_favorite':
                  return (
                    <button
                      className="flex justify-center items-center w-max"
                      onClick={() => handleFavoriteForm(item.id)}
                    >
                      <img
                        src={item[tHead.key] ? icons.icStarFullColor : icons.icStar}
                        alt="favorite"
                        className="w-[24px] h-[24px]"
                      />
                    </button>
                  );
                default:
                  return <span>{item[tHead.key]}</span>;
              }
            }}
          </TableAccent>
        </div>
      )}
      <SuccessPopUp
        show={limitOfflineModal}
        imageSuccess={images.imageEmpty}
        title={'Offline Form Usage Limit'}
        message={'Offline mode cannot be enabled for this form due to the limit'}
        labelSuccessButton={'Ok, back'}
        labelBackButton={''}
        handleSuccessButton={() => setLimitOfflineModal(false)}
      />
    </>
  );
};
export default FormSubmission;
