import { useEffect, useState } from 'react';
import { get } from '../../../../../api/base';
import { apiUrls, icons } from '../../../../../constants';
import { toast } from 'react-toastify';
import { EmptyData, Toast } from '../../../../../components';
import { useDispatch } from 'react-redux';
import { setShowLoading } from '../../../../../store/slices/popup';
import moment from 'moment';
import fileDownload from 'js-file-download';
const ApprovalHistoryModal = ({ showModal, setShowModal, id, title }) => {
  const [approvals, setApprovals] = useState([]);
  const dispatch = useDispatch();
  const getApprovalList = () => {
    dispatch(setShowLoading(true));
    get(`${apiUrls.DYNAMIC_FORM}/contents/${id}/history-approval`)
      .then(res => {
        dispatch(setShowLoading(false));
        if (res.status === 200) {
          setApprovals(res.data);
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={`${res?.data.error.message}`} />);
        }
      })
      .catch(_ => {
        dispatch(setShowLoading(false));
        toast.error(<Toast message={'Error'} detailedMessage={`Something went wrong`} />);
      });
  };

  const handleConvertToPdf = htmlString => {
    const file = new Blob([htmlString]);

    const dateTime = new Date();
    const formatedDate =
      dateTime.getDate() + '-' + (dateTime.getMonth() + 1) + '-' + dateTime.getFullYear();
    const formatedTime =
      dateTime.getHours() + '-' + dateTime.getMinutes() + '-' + dateTime.getSeconds();

    let validName = title.replace(/[/\\?%*:|"<>]/g, '-');

    let fileName = validName + '_' + formatedDate + '_' + formatedTime + '_Approval.pdf';
    fileDownload(file, fileName);
  };

  const printApproval = () => {
    dispatch(setShowLoading(true));
    get(`${apiUrls.DYNAMIC_FORM}/contents/${id}/print-approval`, { responseType: 'blob' })
      .then(res => {
        dispatch(setShowLoading(false));
        if (res.status === 200) {
          handleConvertToPdf(res.data);
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={`${res?.data.error.message}`} />);
        }
      })
      .catch(_ => {
        dispatch(setShowLoading(false));
        toast.error(<Toast message={'Error'} detailedMessage={`Something went wrong`} />);
      });
  };

  useEffect(() => {
    if (showModal) getApprovalList();
  }, [showModal]);

  return (
    <div>
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px] h-fit overflow-y-hidden p-0">
          <div className="p-6">
            <div className="flex flex-row items-center justify-between">
              <div className="text-[18px] font-semibold">History Approval</div>
              <img
                src={icons.icCloseModal}
                className="w-6 h-6 cursor-pointer"
                alt={'close-modal'}
                onClick={() => setShowModal(false)}
              />
            </div>
            <hr className="mt-2 mb-4" />
            <div className="bg-white max-h-[50vh] overflow-y-auto sm:mb-0">
              <div className="overflow-y-auto">
                {approvals.length ? (
                  approvals.map((approval, index) => {
                    return (
                      <div
                        className="flex flex-col gap-2 mb-4 shadow-md p-2 rounded-sm"
                        key={index}
                      >
                        <div className="flex flex-rows items-center gap-2">
                          <div className="text-[12px]">
                            {moment(new Date(approval.created_at)).format('DD-MMM-YYYY HH:mm:ss')}
                          </div>
                          <div className="text-[12px]"> - </div>
                          <div className="text-[12px]">{approval?.full_name || '-'}</div>
                        </div>
                        <div className="flex flex-rows items-center">
                          <div className="text-[12px] font-bold mr-10">Action</div>
                          <div className="text-[12px]">:</div>
                          <div className="text-[12px] ml-2">{approval?.action || '-'}</div>
                        </div>
                        <div className="flex flex-rows items-center gap-2">
                          <div className="text-[12px] font-bold">Description</div>
                          <div className="text-[12px]">:</div>
                          <div className="text-[12px]">{approval?.description || '-'}</div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="mt-10 flex items-center justify-center">
                    <EmptyData title={'There is no approval history to show'} />
                  </div>
                )}
              </div>
            </div>
          </div>
          {approvals.length ? (
            <div className="flex flex-row justify-center sm:justify-end bg-[#EBEDF8] w-full sm:w-[512px] md:w-[512px] rounded-b-xl">
              <button
                className="rounded-lg border-2 border-[#6546C3] px-7 py-2 m-2 flex text-[#6546C3] font-semibold text-[14px]"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Back
              </button>
              <button
                className="rounded-lg border-2 bg-[#6546C3] px-6 py-2 m-2 flex text-white font-semibold text-[14px]"
                onClick={() => printApproval()}
              >
                Print
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApprovalHistoryModal;
