import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { apiUrls, icons } from '../../../../constants';
import { get } from '../../../../api/base';
import { setShowLoading } from '../../../../store/slices/popup';

const FilterModal = ({ showModal, setShowModal, setTypes, setAreaName, countFilterLength }) => {
  const [selectedArea, setSelectedArea] = useState('');
  const [selectedAreaOptions, setSelectedAreaOptions] = useState(null);
  const [areaOptions, setAreaOptions] = useState({});
  const [selectedType, setSelectedType] = useState('');
  const [selectedTypeOptions, setSelectedTypeOptions] = useState(null);
  const dispatch = useDispatch();
  const getListArea = () => {
    dispatch(setShowLoading(true));
    get(apiUrls.AREA)
      .then(res => {
        dispatch(setShowLoading(false));
        if (res.status === 200) {
          setAreaOptions(res.data?.areas);
        }
      })
      .catch(_ => dispatch(setShowLoading(false)));
  };
  const handleOnAreaChange = selectedOptions => {
    setSelectedAreaOptions(selectedOptions);
    setSelectedArea(selectedOptions?.name);
  };
  const handleOnTypeChange = selectedOptions => {
    setSelectedTypeOptions(selectedOptions);
    setSelectedType(selectedOptions?.value);
  };

  const reset = () => {
    setSelectedType('');
    setSelectedTypeOptions(null);
    setSelectedArea('');
    setSelectedAreaOptions(null);
  };

  useEffect(() => {
    if (showModal) getListArea();
  }, [showModal]);
  return (
    <div>
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px] h-[75vh] sm:h-fit">
          <div className="bg-white  mb-[100px]">
            <div className="flex flex-row items-center justify-between">
              <div className="text-[18px] font-semibold">Filter</div>
              <img
                src={icons.icCloseModal}
                className="w-6 h-6 cursor-pointer"
                alt={'close-modal'}
                onClick={() => setShowModal(false)}
              />
            </div>
            <hr className="mt-2 mb-6" />
            <div>
              <div className="font-semibold text-[14px] mt-[28px] mb-[16px]">Area</div>
              <Select
                onChange={selectedOptions => handleOnAreaChange(selectedOptions)}
                getOptionValue={option => option.name}
                getOptionLabel={option => option.name}
                isClearable={true}
                options={areaOptions}
                name="Type"
                className={
                  'input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4'
                }
                placeholder="Select type"
                isMulti={false}
                value={selectedAreaOptions}
                styles={{
                  option: provided => ({
                    ...provided
                  }),
                  control: provided => ({
                    ...provided,
                    ':focus': { borderColor: '#6546C3' },
                    ':active': { borderColor: '#6546C3' },
                    ':hover': { borderColor: '#6546C3' },
                    paddingLeft: 4,
                    boxShadow: 'none'
                  }),
                  dropdownIndicator: provided => ({
                    ...provided,
                    color: '#6546C3'
                  }),
                  indicatorSeparator: () => ({})
                }}
              />
            </div>
            <div>
              <div className="font-semibold text-[14px] mt-[28px] mb-[16px]">Application Type</div>
              <Select
                onChange={selectedOptions => handleOnTypeChange(selectedOptions)}
                options={[
                  {
                    value: 'Web',
                    label: 'Web'
                  },
                  {
                    value: 'Mobile',
                    label: 'Mobile'
                  }
                ]}
                name="Type"
                className={
                  'input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-2'
                }
                placeholder="Select type"
                isMulti={false}
                isClearable={true}
                value={selectedTypeOptions}
                styles={{
                  option: provided => ({
                    ...provided
                  }),
                  control: provided => ({
                    ...provided,
                    ':focus': { borderColor: '#6546C3' },
                    ':active': { borderColor: '#6546C3' },
                    ':hover': { borderColor: '#6546C3' },
                    paddingLeft: 4,
                    boxShadow: 'none'
                  }),
                  dropdownIndicator: provided => ({
                    ...provided,
                    color: '#6546C3'
                  }),
                  indicatorSeparator: () => ({})
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center sm:justify-end bg-[#EBEDF8] w-full absolute sm:w-[512px] md:w-[512px] sm:mt-[340px] rounded-b-xl">
          <button
            className="rounded-lg border-2 border-[#6546C3] px-7 py-2 m-2 flex text-[#6546C3] font-semibold text-[14px]"
            onClick={reset}
          >
            Reset
          </button>
          <button
            className="rounded-lg border-2 bg-[#6546C3] px-6 py-2 m-2 flex text-white font-semibold text-[14px]"
            onClick={() => {
              setTypes(selectedType);
              setAreaName(selectedArea);
              setShowModal(false);
              let length = 0;
              if (selectedArea) {
                length = 1;
              }
              if (selectedType) {
                length = length + 1;
              }
              countFilterLength(length);
            }}
          >
            Filter Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
