import { useEffect, useState } from 'react';
import { apiUrls, images, icons } from '../../constants';
import { get, put } from '../../api/base';
import { Loading, Toast } from '../../components';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

const Notification = () => {
  const [notifList, setNotifList] = useState([]);
  const [totalUnread, setTotalUnread] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    setIsFetching(true);
    fetchNotifications();
  }, []);

  const fetchNotifications = reloadPage => {
    let query = { limit: 10, page: reloadPage ? 1 : page };
    get(apiUrls.LIST_NOTIFICATION, { query: query }).then(response => {
      if (response.status === 200) {
        if (query.page === 1) {
          setNotifList(response.data.data);
        } else {
          setNotifList(prevNotifList => [...prevNotifList, ...response.data.data]);
        }

        setTotalData(response.data.total_data);
        setPage(query.page + 1);
        setTotalUnread(response.data.total_unread_notification);
      } else {
        toast.error(
          <Toast message={'Error'} detailedMessage={`${response.data.error?.message}`} />
        );
      }
      setIsFetching(false);
    });
  };

  const getFormatDate = date => {
    if (!date) {
      return '-';
    }

    if (moment(date).format('DD MMM YYYY') === moment().format('DD MMM YYYY')) {
      return moment(date).format('[Today, ] h:mm a');
    }

    return moment(date).format('DD MMM YYYY');
  };

  const handleOnNotifItemClick = ({ notifId, id, className, url }) => {
    setIsFetching(true);
    put(apiUrls.LIST_NOTIFICATION, {}, { query: { id: notifId } })
      .then(response => {
        setIsFetching(false);
        if (response.status === 200) {
          fetchNotifications(true);
          switch (className) {
            case 'Task':
              navigate(`/Task/DetailTask/${id}`);
              break;
            case 'Issue':
              navigate(`/Task/DetailIssue/${id}`);
              break;
            case 'FORM':
              navigate(`/Task/FormSubmission/DetailFormSubmission/${id}`);
              break;
            case 'Form Submission':
              navigate(`/Task/DetailForm/${id}`);
              break;
            case 'Version':
              window.open(url);
              break;
            default:
              return;
          }
        } else {
          toast.error(
            <Toast message={'Error'} detailedMessage={`${response.data?.error?.message}`} />
          );
        }
      })
      .catch(e => {
        setIsFetching(false);
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
      });
  };

  const handleMarkAllAsRead = () => {
    if (!totalUnread) {
      toast.info(
        <Toast
          message={'Notification Clear'}
          detailedMessage={'All notifications have been read'}
        />
      );
    } else {
      setIsFetching(true);
      put(apiUrls.LIST_NOTIFICATION)
        .then(response => {
          setIsFetching(false);
          if (response.status === 200) {
            fetchNotifications(true);
          } else {
            toast.error(
              <Toast message={'Error'} detailedMessage={`${response.data?.error?.message}`} />
            );
          }
        })
        .catch(() => {
          setIsFetching(false);
          toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
        });
    }
  };

  return (
    <div className="mt-6 sm:mb-6 sm:mx-6">
      <img
        src={icons.icBack}
        alt="back"
        className="w-8 h-8 absolute top-3 left-3 lg:hidden"
        onClick={() => navigate(-1)}
      />
      <div className="flex justify-center lg:justify-between pb-6">
        <div className="text-[#232323] text-xl sm:text-2xl font-semibold mb-1">Notification</div>
        <button
          className="rounded-lg bg-[#6546C3] px-6 py-2 flex text-white font-semibold text-[14px] shadow-lg hidden lg:block"
          onClick={handleMarkAllAsRead}
        >
          Mark as Read
        </button>
      </div>
      <div className="bg-white rounded-3xl h-fit">
        <div className="flex lg:hidden flex-row items-center justify-between pt-4 px-6">
          <div className="flex gap-2 items-center">
            <div className="text-[16px] text-[#232323] font-semibold font-poppins">
              Notification List
            </div>
            <div className="bg-[#EBEDF8] w-8 h-8 rounded-xl text-[#C800A5] font-semibold font-poppins flex items-center justify-center">
              {totalUnread}
            </div>
          </div>
          <div className="flex items-center cursor-pointer" onClick={handleMarkAllAsRead}>
            <img src={icons.icMarkAllRead} alt="mark as read" className="w-7" />
            <div className="text-[12px] text-[#6546C3] font-poppins">Mark as read</div>
          </div>
        </div>
        {isFetching ? (
          <div className="flex justify-center items-center py-[350px]">
            <Loading />
          </div>
        ) : notifList.length ? (
          <div>
            <InfiniteScroll
              dataLength={notifList?.length || 0}
              next={() => fetchNotifications()}
              hasMore={notifList.length < totalData}
              className="!overflow-hidden"
              loader={
                <div className="flex justify-center h-[100px] my-auto">
                  <Loading />
                </div>
              }
            >
              <div className="flex flex-col gap-3 py-6 pr-6 pl-4">
                {notifList.map(item => (
                  <div key={item.id} className={`flex gap-2 ${item?.read ? 'ml-3' : ''}`}>
                    {!item?.read && <div className="w-1 bg-[#6546C3] rounded"></div>}
                    <div
                      className={`p-4 flex flex-1 gap-4 rounded-xl ${
                        item?.read ? 'bg-white shadow-md' : 'bg-[#EBEDF8]'
                      } cursor-pointer`}
                      onClick={() =>
                        handleOnNotifItemClick({
                          notifId: item.id,
                          id: item.message.data.id,
                          className: item.class_name,
                          message: item.message,
                          url: item.message.data.url
                        })
                      }
                    >
                      {!item?.sender ? (
                        <div className="w-8 h-8 rounded-full border border-[#DEDEDE] flex items-center justify-center">
                          <img src={icons.icInfo} alt="info" className="w-4 h-4" />
                        </div>
                      ) : item?.sender?.picture ? (
                        <img
                          src={item?.sender?.picture}
                          alt="sender"
                          className="w-8 h-8 rounded-full"
                        />
                      ) : (
                        <img
                          src={images.imageDummyAvatar}
                          alt="sender"
                          className="w-8 h-8 rounded-full"
                        />
                      )}

                      <div className="flex-col flex-1 gap-1">
                        <div className="text-[12px] text-[#232323] font-semibold font-poppins">
                          {item?.sender?.full_name || 'M2P'}
                        </div>
                        <div className="text-[12px] text-[#AFAFB9] font-poppins">
                          {item?.message?.notification?.body}
                        </div>
                      </div>
                      <div className="flex items-center text-[10px] text-[#232323] font-poppins">
                        {getFormatDate(item?.created_at)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          </div>
        ) : (
          <div className="flex flex-col items-center p-[150px]">
            <img src={images.imageEmptyNotif} alt="no notif" className="w-[180px] mb-[20px]" />
            <div className="text-[16px] text-[#232323] font-semibold mb-2">
              No Notification Right Now!
            </div>
            <div className="text-[12px] text-[#AAAAAA]">You’re up-to-date! would work well</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notification;
