import { icons, images } from '../../../../../../constants';

const LocationModal = ({ showModal, setShowModal, handleAllow }) => {
  return (
    <div>
      <input
        type="checkbox"
        className="modal-toggle"
        checked={showModal}
        onChange={e => e.preventDefault()}
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div
          className="modal-box bg-white relative sm:rounded-2xl rounded-t-[32px] w-screen sm:w-[350px] h-fit sm:h-fit"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex flex-row items-center justify-between">
            <div className="text-[18px] font-semibold">GPS Disabled</div>
            <img
              src={icons.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              alt={'close-modal'}
              onClick={() => setShowModal(false)}
            />
          </div>
          <hr className="mt-2 mb-6" />
          <div className="flex flex-col items-center items-center mb-[42px]">
            <img src={images.imageLocation} className="w-[140px] mb-9" alt="gps location" />
            <span className="font-semibold text-[14px] mb-1 text-center">
              Allow this device to access your location
            </span>
            <span className="w-[232px] text-[12px] text-center text-gray-500">
              M2P will access your device's location
            </span>
          </div>
          <div className="flex justify-center w-full sm:mt-6">
            <button
              className="bg-transparent border-2 border-[#6546C3] rounded-lg py-[10px] w-[130px] font-semibold text-[#6546C3] 
                    text-[14px] flex justify-center items-center"
              onClick={() => {
                setShowModal(false);
                handleAllow();
              }}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationModal;
