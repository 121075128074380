import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { FormInput, SuccessPopUp, Toast } from '../../../../components';
import { icons, images } from '../../../../constants';
import { setShowLoading } from '../../../../store/slices/popup';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
const GuestCreatePasswordPage = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const config = {
    headers: {
      'Cache-Control': 'no-store',
      Authorization: `Bearer ${token}`
    }
  };
  const axiosInstance = axios.create({ config, baseURL, timeout: 18000 });
  const [passIcon, setPassIcon] = useState(icons.icEyeClose);
  const [confirPassIcon, setConfirPassIcon] = useState(icons.icEyeClose);
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passType, setPassType] = useState('password');
  const [confirPassType, setConfirPassType] = useState('password');
  const [successPopup, setSuccessPopup] = useState(false);
  const [isFormError, setIsFormError] = useState(false);
  const handleConfirmationPasswordChange = event => setConfirmationPassword(event.target.value);
  const handlePasswordChange = event => setPassword(event.target.value);
  let toastMessage;
  const dispatch = useDispatch();

  const handlePassVisibility = () => {
    if (passType === 'password') {
      setPassType('text');
      setPassIcon(icons.icEyeOpen);
    } else {
      setPassType('password');
      setPassIcon(icons.icEyeClose);
    }
  };
  const handleConfirmationPassVisibility = () => {
    if (confirPassType === 'password') {
      setConfirPassType('text');
      setConfirPassIcon(icons.icEyeOpen);
    } else {
      setConfirPassType('password');
      setConfirPassIcon(icons.icEyeClose);
    }
  };
  const passwordValidation = () => {
    if (!password) {
      toastMessage = 'Password cannot be empty';
      return false;
    } else if (!confirmationPassword) {
      toastMessage = 'Confirmation Password cannot be empty';
      return false;
    } else if (password !== confirmationPassword) {
      toastMessage = 'Password and confirm password does not match';
      return false;
    }
    return true;
  };

  const savePassword = () => {
    dispatch(setShowLoading(true));
    if (passwordValidation()) {
      axiosInstance
        .put(
          `${baseURL}/api/users/password`,
          { password: password, confirm_password: confirmationPassword },
          config
        )
        .then(res => {
          if (res.status === 200) {
            setSuccessPopup(true);
            setIsFormError(false);
          }
        })
        .catch(e => {
          setIsFormError(true);
          if (e?.response?.data?.error?.errors[0][0].message) {
            toast.error(
              <Toast
                message={'Error'}
                detailedMessage={e?.response?.data?.error?.errors[0][0].message}
              />
            );
          } else {
            toast.error(<Toast message={'Error'} detailedMessage={'Cannot reach server'} />);
          }
        });
    } else {
      dispatch(setShowLoading(false));
      setIsFormError(true);
      toast.error(<Toast message={'Error'} detailedMessage={toastMessage} />);
    }
  };

  return (
    <>
      <div className="hidden lg:w-screen h-screen bg-gradient-to-b from-[#6546C3] to-[#9E7CE3] p-[16px] md:flex justify-center items-center">
        <div className="w-full h-full  bg-white rounded-[36px] p-[40px] flex flex-col md:flex-row">
          <div className="w-auto self-center h-auto overflow-hidden md:m-0">
            <img
              src={images.imageCreatePasswordSide}
              alt="ilustration"
              className=" max-h-[85vh] self-center"
            />
          </div>
          <div className="flex-auto p-[8px] md:p-[40px] overflow-y-auto bg-[#EBEDF8] rounded-[36px] md:min-w-[400px]">
            <img src={images.imageM2PLogo} alt="ITM LOGO" className="w-[140px] mb-[16px]" />
            <div className="mb-1">
              <div className="font-poppins font-normal text-[12px] text-[#232323]">
                ONE click for any application
              </div>
            </div>
            <div className="flex flex-col mt-[40px]">
              <div className="flex-1">
                <div>
                  <div className="mb-1">
                    <div className="font-poppins font-semibold text-[14px] text-[#232323]">
                      Password
                    </div>
                  </div>
                  <FormInput
                    type={passType}
                    placeholder="Enter password"
                    value={password}
                    onChange={handlePasswordChange}
                    rightIcon={passIcon}
                    onRightIconClick={handlePassVisibility}
                    isError={isFormError}
                  />
                </div>
              </div>
              <div className="flex-1 mt-[24px]">
                <div className="mb-[45px]">
                  <div className="mb-1">
                    <div className="font-poppins font-semibold text-[14px] text-[#232323]">
                      Confirm Password
                    </div>
                  </div>
                  <FormInput
                    type={confirPassType}
                    placeholder="Confirm password"
                    value={confirmationPassword}
                    onChange={handleConfirmationPasswordChange}
                    rightIcon={confirPassIcon}
                    onRightIconClick={handleConfirmationPassVisibility}
                    isError={isFormError}
                  />
                </div>
              </div>
            </div>
            <button
              className="bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] font-poppins font-semibold text-[14px] text-white p-3 w-full rounded-md"
              onClick={savePassword}
            >
              Save
            </button>
          </div>
        </div>
      </div>

      {/* Mobile View */}
      <div className="md:hidden w-screen h-screen bg-gradient-to-b from-[#6546C3] to-[#9E7CE3] pt-[16px] flex justify-center items-center">
        <div className="w-full h-full  bg-white rounded-[36px] flex flex-col md:flex-row">
          <img src={images.imageM2PLogo} alt="ITM LOGO" className="w-[140px] m-[16px]" />
          <div className="m-[16px] mt-0">
            <div className="font-poppins font-normal text-[12px] text-[#232323]">
              ONE click for any application
            </div>
          </div>
          <div className="w-auto m-auto h-auto md:m-0">
            <img src={images.imageLoginSide} alt="ilustration" className="w-full self-center" />
          </div>
          <div className="flex-auto p-[8px] md:p-[10px] lg:p-[48px]  bg-[#EBEDF8]">
            <div className="flex flex-col mt-[40px]">
              <div className="flex-1">
                <div>
                  <div className="mb-1">
                    <div className="font-poppins font-semibold text-[14px] text-[#232323]">
                      Password
                    </div>
                  </div>
                  <FormInput
                    type={passType}
                    placeholder="Enter password"
                    value={password}
                    onChange={handlePasswordChange}
                    rightIcon={passIcon}
                    onRightIconClick={handlePassVisibility}
                    isError={isFormError}
                  />
                </div>
              </div>
              <div className="flex-1 mt-[24px]">
                <div className="mb-[48px]">
                  <div className="mb-1">
                    <div className="font-poppins font-semibold text-[14px] text-[#232323]">
                      Confirm Password
                    </div>
                  </div>
                  <FormInput
                    type={confirPassType}
                    placeholder="Confirm password"
                    value={confirmationPassword}
                    onChange={handleConfirmationPasswordChange}
                    rightIcon={confirPassIcon}
                    onRightIconClick={handleConfirmationPassVisibility}
                    isError={isFormError}
                  />
                </div>
              </div>
            </div>
            <button
              className="bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] font-poppins font-semibold text-[14px] text-white p-3 w-full rounded-md"
              onClick={savePassword}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <SuccessPopUp
        show={successPopup}
        imageSuccess={images.imageSuccess}
        title={'Successfully'}
        message={'You have successfully create your password'}
        labelSuccessButton={'Ok'}
        handleSuccessButton={() => {
          setSuccessPopup(false);
          navigate('/Login');
        }}
      />
    </>
  );
};

export default GuestCreatePasswordPage;
