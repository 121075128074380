import { icons } from '../../../../../../../constants';
import { toast } from 'react-toastify';
import { Toast } from '../../../../../../../components';
import FieldAttachment from '../../../components/FieldAttachment';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const AttachmentModal = ({
  showModal,
  setShowModal,
  sections,
  submitFormTabletLayout,
  field,
  attachments,
  fileInputRef,
  handleAddAttachment,
  handleDeleteAttachment,
  additionalTitle,
  fieldValue,
  setNewAttachments,
  setFieldModal
}) => {
  const checkValue = () => {
    return attachments && attachments.length > 0;
  };

  const statusForm = useSelector(state => state.form.finishStoreForm);

  const cancel = () => {
    setShowModal(false);
    if (!_.isEmpty(attachments)) {
      attachments?.forEach((_, index) => {
        handleDeleteAttachment(index);
      });
    }
    setFieldModal(false);
    setNewAttachments([]);
  };

  useEffect(() => {
    if (statusForm === 'Success') cancel();
  }, [statusForm]);

  const submit = () => {
    if (checkValue()) {
      submitFormTabletLayout({
        sections,
        attachments,
        title: additionalTitle ? additionalTitle : field?.field_title,
        value: fieldValue
      });
    } else {
      toast.error(<Toast message={'Error'} detailedMessage={'Attachment is mandatory'} />);
    }
  };

  return (
    <div>
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px]  sm:h-fit">
          <div className="bg-white">
            <div className="flex items-center justify-between flex-wrap">
              <div className="font-semibold text-[18px] text-[#C800A5]">Attachment</div>
              <img
                src={icons.icCloseModal}
                className="w-6 h-6 cursor-pointer"
                alt={'close-modal'}
                onClick={cancel}
              />
            </div>
            <hr className="mt-2 mb-6" />
            <FieldAttachment
              attachments={attachments}
              fileInputRef={fileInputRef}
              handleAddAttachment={handleAddAttachment}
              handleDeleteAttachment={handleDeleteAttachment}
            />
          </div>
          <div className="flex justify-center sm:justify-end w-full flex-row mt-9 gap-6">
            <button
              className="bg-transparent border-2 border-[#6546C3] rounded-lg py-3 px-8 font-semibold text-[#6546C3] text-[14px]"
              onClick={cancel}
            >
              Cancel
            </button>
            <button
              className="bg-[#6546C3] border-2 border-[#6546C3] rounded-lg py-3 px-8 font-semibold text-white text-[14px]r"
              onClick={submit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttachmentModal;
