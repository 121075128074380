const PageNotFound = () => {
  return (
    <div className="flex flex-col h-[100vh] items-center w-full mt-20">
      <div className="text-[64px] text-[#6546C3]">404</div>
      <div className="text-center text-[22px] mt-4 w-[60vw]">
        The page you are looking for may have been moved, deleted, or possibly never existed
      </div>
    </div>
  );
};

export default PageNotFound;
