/**
 * Open IndexedDB and get object store
 *
 * @param {{dbName: string, objectName: string, objectStoreOptions: object, method: string}} options Options
 *
 * @returns {Promise<IDBObjectStore>}
 */
const openObjectStore = ({
  dbName,
  objectName,
  objectStoreOptions = null,
  method = 'readwrite'
}) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName);

    request.onupgradeneeded = () => {
      if (!request.result.objectStoreNames.contains(objectName) && objectStoreOptions) {
        request.result.createObjectStore(objectName, objectStoreOptions);
      }
    };

    request.onsuccess = () => {
      try {
        const db = request.result;
        const transaction = db.transaction(objectName, method);
        const objectStore = transaction.objectStore(objectName);
        resolve(objectStore);
      } catch (err) {
        reject({
          message: err.message,
          error: err
        });
      }
    };

    request.onerror = () => {
      reject({
        message: `Cannot open db: ${dbName}`,
        error: request.error?.message || 'Unknown error'
      });
    };
  });
};

const storeData = async (data, { dbName, objectName, objectStoreOptions }) => {
  try {
    const objectStore = await openObjectStore({
      dbName,
      objectName,
      objectStoreOptions,
      method: 'readwrite'
    });
    objectStore.add(data);
    return data;
  } catch (err) {
    return err;
  }
};

const storeRefreshToken = refreshToken => {
  return storeData(
    { key: 'refresh_token', value: refreshToken },
    { dbName: 'offline-data', objectName: 'auth-data', objectStoreOptions: { keyPath: 'key' } }
  );
};

const getRefreshToken = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const objectStore = await openObjectStore({
        dbName: 'offline-data',
        objectName: 'auth-data',
        method: 'readonly',
        objectStoreOptions: { keyPath: 'key' }
      });
      const request = objectStore.get('refresh_token');
      request.onsuccess = () => resolve(request.result.value);
    } catch (err) {
      return null;
    }
  });
};

const storeRequestData = request => {
  const requestData = { ...request, url: process.env.REACT_APP_BASE_URL + request.url };
  return storeData(requestData, {
    dbName: 'sync-data',
    objectName: 'request',
    objectStoreOptions: { keyPath: 'id', autoIncrement: true }
  });
};

const deleteRequestData = id => {
  return new Promise(async (resolve, reject) => {
    try {
      const objectStore = await openObjectStore({
        dbName: 'sync-data',
        objectName: 'request',
        method: 'readwrite'
      });

      const request = objectStore.delete(id);
      request.onsuccess = () => {
        resolve();
      };

      request.onerror = () => {
        reject({
          message: 'Failed to delete from IndexedDB',
          error: request.error.message
        });
      };
    } catch (err) {
      reject(err);
    }
  });
};

const isNotSyncDataAvailable = () => {
  return new Promise(async resolve => {
    try {
      const objectStore = await openObjectStore({
        dbName: 'sync-data',
        objectName: 'request',
        method: 'readonly',
        objectStoreOptions: { keyPath: 'id', autoIncrement: true }
      });

      const request = objectStore.count();
      request.onsuccess = () => resolve(request.result > 0);
    } catch (err) {
      resolve(false);
    }
  });
};

const getAllNotSyncData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const objectStore = await openObjectStore({
        dbName: 'sync-data',
        objectName: 'request',
        method: 'readonly',
        objectStoreOptions: { keyPath: 'id', autoIncrement: true }
      });

      const request = await objectStore.getAll();
      request.onsuccess = () => {
        resolve(request.result);
      };
    } catch (err) {
      reject(err);
    }
  });
};

export default {
  storeRefreshToken,
  storeRequestData,
  isNotSyncDataAvailable,
  getAllNotSyncData,
  getRefreshToken,
  deleteRequestData
};
