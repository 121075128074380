import { useEffect, useState } from 'react';
import { get } from '../../../api/base';
import { apiUrls, icons } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { EmptyData, Loading, TableAccent, Toast, Portal } from '../../../components';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { BsPlus } from 'react-icons/bs';
import FilterModal from './components/Filter';
import { downloadForm } from './components/DownloadForm';
import CreateIssueModal from './components/CreateIssueModal';
import { setPage } from '../../../store/slices/table';
import _ from 'lodash';
import { usePopper } from 'react-popper';
import DuplicateFormModal from './components/DuplicateFormModal';
import ShareFormModal from './components/ShareFormModal';

const CardSubmittedForm = ({ form }) => {
  const navigate = useNavigate();
  return (
    <div
      className="flex flex-row p-5 bg-white items-center mt-2 rounded-2xl"
      onClick={() => {
        navigate(`/Task/DetailForm/${form.id}`, {
          state: {
            name: 'Detail Form',
            crumbs: ['Task, Issue & Form', 'Detail Form']
          }
        });
      }}
    >
      <div className="mr-2">
        <img src={icons.icDocument} alt="document" height={32} width={32} />
      </div>
      <div className="flex flex-col">
        <div className={'min-w-[6rem] max-w-[180px] font-bold text-[12px] truncate'}>
          {form?.form.title}
        </div>
        <div className={'min-w-[6rem]  flex flex-row text-[12px] text-[#AFAFB9]'}>
          Submitted:<div className="text-[#C800A5] ml-1">{form?.submitted_time}</div>
        </div>
      </div>
      <div className="ml-auto">
        <img
          src={!form.id.toString().includes('offline') ? icons.icChecklist : icons.icNotSync}
          alt="status"
          className={'text-[#01B59C]'}
          width={20}
          height={10}
        />
      </div>
    </div>
  );
};

const ActionItem = ({
  item,
  index,
  actionOpened,
  setActionOpened,
  setFormId,
  setCreateIssueModal,
  setShowShareFormModal,
  setShowDuplicateFormModal,
  setFormTitle,
  setReloadAfterDuplicate,
  moduleId
}) => {
  const navigate = useNavigate();
  const [actionPopperElement, setActionPopperElement] = useState(null);
  const [actionReferenceElement, setActionReferenceElement] = useState(null);
  const { styles, attributes } = usePopper(actionReferenceElement, actionPopperElement, {
    placement: 'right-start'
  });

  return (
    <div className="dropdown flex justify-center m-0 w-full">
      <button
        tabIndex={0}
        className="flex justify-center items-center bg-[#F1F1FD] w-[24px] h-[24px] rounded-md"
        onClick={() => {
          setActionOpened(actionOpened === index ? -1 : index);
          document.activeElement.blur();
        }}
        ref={setActionReferenceElement}
      >
        <img src={icons.icMore} alt="more" width={20} height={20} />
      </button>
      {actionOpened === index && (
        <Portal>
          <ul
            tabIndex={0}
            className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 ml-2 m-0"
            ref={setActionPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <li>
              <div
                className="flex flex-row items-center gap-4"
                onClick={() => {
                  navigate(`/Task/DetailForm/${item.id}`, {
                    state: {
                      name: 'Detail Form',
                      crumbs: ['Task, Issue & Form', 'Detail Form']
                    }
                  });
                }}
              >
                <img src={icons.icArrowRight} alt="arrow" height={24} width={24} />
                Detail
              </div>
            </li>
            <li>
              <div
                className="flex flex-row items-center gap-4"
                onClick={() => {
                  setFormId(item.id);
                  setCreateIssueModal(true);
                }}
              >
                <img src={icons.icChecklistSource} alt="arrow" height={24} width={24} />
                Create Issue
              </div>
            </li>
            <li>
              <div
                className="flex flex-row items-center gap-4"
                onClick={() => {
                  setFormId(item.id);
                  setShowShareFormModal(true);
                }}
              >
                <img src={icons.icShare} alt="arrow" height={24} width={24} />
                Share
              </div>
            </li>
            <li>
              <div
                className="flex flex-row items-center gap-4"
                onClick={() => downloadForm(item.id, item?.form?.title)}
              >
                <img src={icons.icDownload} alt="arrow" height={24} width={24} />
                Download Form
              </div>
            </li>
            <li>
              <div
                className={`flex flex-row items-center gap-4 ${
                  moduleId ? 'opacity-50' : 'opacity-100'
                }`}
                onClick={
                  moduleId
                    ? null
                    : () => {
                        setFormId(item.id);
                        setFormTitle(item?.form?.title);
                        setShowDuplicateFormModal(true);
                        setActionOpened(-1);
                        setReloadAfterDuplicate(true);
                      }
                }
              >
                <img src={icons.icDuplicateForm} alt="arrow" height={24} width={24} />
                Duplicate Form
              </div>
            </li>
          </ul>
        </Portal>
      )}
    </div>
  );
};

const ListFormSubmittedPage = () => {
  const { limits, page, pageSize, search, order_by } = useSelector(state => state.table);
  const [forms, setForms] = useState(null);
  const [formId, setFormId] = useState(null);
  const [formTitle, setFormTitle] = useState('');
  const [cloneForms, setCloneForms] = useState(null);
  const [pageRef, setPageRef] = useState(1);
  const [searchRef, setSearchRef] = useState('');
  const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });
  const [totalData, setTotalData] = useState(0);
  const [firstFetching, setFirstFetching] = useState(false);
  const user = useSelector(state => state.user.userData);
  const [isFetching, setIsFetching] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [createIssueModal, setCreateIssueModal] = useState(false);
  const [formName, setFormName] = useState('');
  const [sync, setSync] = useState(false);
  const [notSync, setNotSync] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const filterAttribute = {
    sync: false,
    notSync: false,
    formName: ''
  };
  const [offlineFilterAttribute, setOfflineFilterAttribute] = useState({});
  const headers = [
    {
      key: 'sync',
      name: '',
      isSortable: false,
      sortKey: null
    },
    {
      key: 'action',
      name: 'Action',
      isSortable: false,
      sortKey: null
    },
    {
      key: 'title',
      name: 'Form Name',
      isSortable: true,
      sortKey: 'companyCode'
    },
    {
      key: 'status',
      name: 'Status',
      isSortable: true,
      sortKey: 'contractorName'
    },
    {
      key: 'submitted_time',
      name: 'Last Update',
      isSortable: true
    }
  ];

  const [actionOpened, setActionOpened] = useState(-1);
  const [showDuplicateFormModal, setShowDuplicateFormModal] = useState(false);
  const [reloadAfterDuplicate, setReloadAfterDuplicate] = useState(false);
  const [showShareFormModal, setShowShareFormModal] = useState(false);

  const loadMoreData = reloadPage => {
    const filter = {
      order_by,
      form_name: formName,
      limit: 50,
      page: reloadPage ? 1 : pageRef,
      search: searchRef
    };
    if (!searchRef) {
      delete filter.search;
    }
    if (!formName) {
      delete filter.form_name;
    }
    get(`${apiUrls.BASE_URL_USERS}/${user?.id}/dynamic-forms/contents`, {
      query: filter
    })
      .then(res => {
        if (res.status === 200) {
          let temp = res.data;
          temp.data = temp.form_contents;
          delete temp.form_contents;
          if (filter.page === 1) {
            setForms(temp);
            setCloneForms(temp);
          } else {
            const result = forms.data.concat(temp.data);
            setForms({ ...forms, data: result });
            setCloneForms({ ...forms, data: result });
          }

          setTotalData(res.data.total_data);
          setPageRef(filter.page + 1);
          setFirstFetching(false);
        } else {
          setFirstFetching(false);
          toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />);
        }
      })
      .catch(_ => {
        setFirstFetching(false);
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
      });
  };
  const searchDataRef = ev => {
    switch (true) {
      case ev.key === 'Enter':
      case ev.target.value.length === 0:
        if (navigator.onLine) {
          setSearchRef(ev.target.value);
        } else {
          offlineSearch(ev.target.value);
        }
        break;
      default:
        break;
    }
  };
  const initData = () => {
    const filter = {
      order_by,
      form_name: formName,
      limit: pageSize,
      page,
      search
    };
    if (!search) {
      delete filter.search;
    }
    if (!formName) {
      delete filter.form_name;
    }
    setIsFetching(true);
    get(`${apiUrls.BASE_URL_USERS}/${user?.id}/dynamic-forms/contents`, {
      query: filter
    })
      .then(res => {
        setIsFetching(false);
        if (res.status === 200) {
          if (page > res.data.total_page) {
            dispatch(setPage(1));
          }
          let temp = res.data;
          temp.data = temp.form_contents;
          delete temp.form_contents;
          setForms(temp);
          setCloneForms(temp);
          setFirstFetching(false);
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />);
        }
      })
      .catch(_ => {
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
        setIsFetching(false);
      });
  };

  const handleFilterSync = () => {
    let rawFilter;
    if (notSync) {
      rawFilter = [...cloneForms?.data.filter(elForm => elForm.id.toString().includes('offline'))];
    }
    if (sync) {
      rawFilter = [...cloneForms?.data.filter(elForm => !elForm.id.toString().includes('offline'))];
    }
    if ((sync && notSync) || (!sync && !notSync)) {
      rawFilter = cloneForms?.data;
    }
    setForms({
      total_data: rawFilter?.length,
      total_page: rawFilter?.length / pageSize,
      data: rawFilter
    });
    setPageRef(1);
    setTotalData(rawFilter?.length);

    setFirstFetching(false);
  };

  useEffect(() => {
    handleFilterSync();
  }, [sync, notSync]);

  useEffect(() => {
    if (!isSmallScreen && (!notSync || (notSync && sync)) && pageSize !== 25) initData();
  }, [limits, page, pageSize, search, order_by, formName, sync, notSync, location.state?.key]);

  useEffect(() => {
    if (!firstFetching) setFirstFetching(true);
    if (isSmallScreen && (!notSync || (notSync && sync))) loadMoreData(true);
  }, [searchRef, order_by, formName, notSync, sync, location.state?.key]);

  useEffect(() => {
    if (!navigator.onLine) offlineFilter();
  }, [offlineFilterAttribute]);

  const handleOnClickFormSubmission = () => {
    navigate('/Task/FormSubmission');
  };

  const offlineFilter = () => {
    let rawForm = _.cloneDeep(cloneForms?.data);
    let rawFilter;

    if (offlineFilterAttribute.notSync) {
      rawFilter = [...rawForm?.filter(elForm => elForm.id.toString().includes('offline'))];
    }
    if (offlineFilterAttribute.sync) {
      rawFilter = [...rawForm?.filter(elForm => !elForm.id.toString().includes('offline'))];
    }
    if (
      (offlineFilterAttribute.sync && offlineFilterAttribute.notSync) ||
      (!offlineFilterAttribute.sync && !offlineFilterAttribute.notSync)
    ) {
      rawFilter = rawForm;
    }

    if (offlineFilterAttribute.earlier) {
      rawFilter.reverse();
    }

    if (offlineFilterAttribute.latest) {
      rawFilter.sort();
    }

    if (offlineFilterAttribute.formName) {
      rawFilter = [
        ...rawForm?.filter(elForm =>
          elForm.form.title.toLowerCase().includes(offlineFilterAttribute.formName)
        )
      ];
    }

    setForms({
      total_data: rawFilter?.length,
      total_page: 1,
      data: rawFilter
    });
    return;
  };

  const offlineSearch = value => {
    if (value.length) {
      let rawForm = _.filter(forms?.data, function (form) {
        return form.form.title.toLowerCase().includes(value);
      });
      setForms({ total_data: rawForm.length, total_page: 1, data: rawForm });
    } else {
      offlineFilter();
    }
  };

  return (
    <>
      {isSmallScreen ? (
        <div className="p-5 min-h-screen flex flex-col sm:hidden mb-[120px]">
          <div className="py-5 max-h-[calc(100%-17rem)] flex flex-col gap-y-2 overflow-y-scroll">
            <div className="flex flex-row-reverse md:flex-row gap-4">
              <div className="flex border-[1px] border-[#463191] border-opacity-25 items-center justify-center w-[45px] h-[40px] rounded-lg">
                <img
                  src={icons.icFilter}
                  className="w-[26px] h-[26px]"
                  alt="Filter"
                  onClick={() => setFilterModal(true)}
                />
              </div>
              <div className="flex w-full md:min-w-[150px] h-[40px] bg-white border-[1px] items-center p-[12px] rounded-lg">
                <img src={icons.icSearch} className="w-[14px] h-[14px]" alt="Search" />
                <input
                  className="ml-2 focus:outline-none text-[12px] w-full"
                  key={search}
                  defaultValue={search}
                  placeholder="Search here..."
                  onKeyDown={searchDataRef}
                  onBlur={searchDataRef}
                />
              </div>
            </div>
            {firstFetching ? (
              <div className="flex justify-center h-[50vh] my-auto">
                <Loading />
              </div>
            ) : forms?.data?.length ? (
              <InfiniteScroll
                dataLength={forms?.data?.length || 0}
                next={() => loadMoreData(false)}
                hasMore={forms?.data?.length < totalData}
                className="!overflow-hidden"
                loader={
                  <div className="flex justify-center h-[100px] my-auto">
                    <Loading />
                  </div>
                }
              >
                {forms?.data?.map((form, index) => (
                  <CardSubmittedForm form={form} key={index} />
                ))}
              </InfiniteScroll>
            ) : (
              <EmptyData
                title={'There is no document to show'}
                message={'You have not submitted any form yet'}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="hidden sm:flex sm:flex-col mb-[120px] lg:mb-0">
          <TableAccent
            {...{
              isFetching,
              tableTitle: 'Document List',
              tableHeaders: headers,
              data: forms,
              onFilterClick: () => setFilterModal(true),
              iconFilter: icons.icFilter,
              emptyDataTitle: 'There is no document to show',
              emptyDataDescription: 'You have not submitted any form yet',
              offlineSearch,
              buttonText: 'Browse Form Response',
              onButtonClick: () => {
                navigate(`/Task/BrowseFormSubmission`, {
                  state: {
                    name: 'Form Submission',
                    crumbs: ['Task, Issue & Form', 'Browse Form Submission']
                  }
                });
              }
            }}
          >
            {(item, tHead, index) => {
              switch (tHead.key) {
                case 'sync':
                  return (
                    <div className="items-center w-[20px]">
                      <img
                        src={
                          !item.id.toString().includes('offline')
                            ? icons.icChecklist
                            : icons.icNotSync
                        }
                        alt="status"
                        className={'text-[#01B59C] w-[20px] h-[20px]'}
                        width={20}
                        height={10}
                      />
                    </div>
                  );
                case 'action':
                  return (
                    <ActionItem
                      item={item}
                      index={index}
                      actionOpened={actionOpened}
                      setActionOpened={setActionOpened}
                      setFormId={setFormId}
                      setCreateIssueModal={setCreateIssueModal}
                      setShowShareFormModal={setShowShareFormModal}
                      setShowDuplicateFormModal={setShowDuplicateFormModal}
                      setFormTitle={setFormTitle}
                      setReloadAfterDuplicate={setReloadAfterDuplicate}
                      moduleId={item?.form?.module_id}
                    />
                  );
                case 'title':
                  return (
                    <div className="flex flex-row items-center w-full overflow">
                      <img src={icons.icDocument} alt="document" height={36} width={36} />
                      <div className="ml-2 w-full truncate">{item.form[tHead.key]}</div>
                    </div>
                  );
                case 'submitted_time':
                  return <span className="text-[#C800A5]">{item[tHead.key]}</span>;
                default:
                  return <span>{item[tHead.key]}</span>;
              }
            }}
          </TableAccent>
        </div>
      )}
      <div className="fixed lg:bottom-5 right-5 bottom-[100px]">
        <button
          className="bg-[#6546C3] rounded-full text-white w-[44px] h-[44px] flex items-center justify-center"
          onClick={handleOnClickFormSubmission}
        >
          <BsPlus size={35} />
        </button>
      </div>
      <FilterModal
        setOfflineFilterAttribute={setOfflineFilterAttribute}
        setPageRef={setPageRef}
        setNotSync={setNotSync}
        setSync={setSync}
        setFormName={setFormName}
        filterAttribute={filterAttribute}
        handleFilter={initData}
        showModal={filterModal}
        setShowModal={setFilterModal}
      />
      <CreateIssueModal
        showModal={createIssueModal}
        setShowModal={setCreateIssueModal}
        formId={formId}
      />
      <DuplicateFormModal
        showModal={showDuplicateFormModal}
        setShowModal={setShowDuplicateFormModal}
        formId={formId}
        formTitle={formTitle}
        reloadAfterDuplicate={reloadAfterDuplicate}
      />
      <ShareFormModal
        showModal={showShareFormModal}
        setShowModal={setShowShareFormModal}
        formId={formId}
      />
    </>
  );
};
export default ListFormSubmittedPage;
