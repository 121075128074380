import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const RequireNotAuth = () => {
  const location = useLocation();
  const token = useSelector(state => state.auth?.token);

  return token ? <Navigate to={'/'} state={{ from: location }} /> : <Outlet />;
};

export default RequireNotAuth;
