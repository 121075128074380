import { useState, useEffect } from 'react';
import { icons, apiUrls, images } from '../../../../../../constants';
import { get, del } from '../../../../../../api/base';
import ConfirmationModal from '../../../../../../components/Popup/ConfirmationModal';
import { toast } from 'react-toastify';
import { SuccessPopUp, Toast } from '../../../../../../components';

const AssigneeListModal = ({ showModal, setShowModal, isComplete, fullAccess, taskId }) => {
  const [assignees, setAssignees] = useState([]);
  const [showDeleteConfModal, setShowDeleteConfModal] = useState(false);
  const [showSuccessDeleteModal, setShowSuccessDeleteModal] = useState(false);
  const [deleteSoonUser, setDeleteSoonUser] = useState(null);
  const deleteSoonUserId = deleteSoonUser?.id;
  const deleteSoonUserType = deleteSoonUser?.type;

  useEffect(() => {
    if (showModal) fetchAssignees();
  }, [showModal]);

  const fetchAssignees = () => {
    get(`${apiUrls.TASK}/${taskId}/assignee`)
      .then(response => {
        const { status } = response;
        if (status === 200) {
          setAssignees(response.data.data);
        } else {
          toast.error(
            <Toast message={'Error'} detailedMessage={`${response?.data?.error?.message}`} />
          );
        }
      })
      .catch(e => {
        toast.error(<Toast message={'Error'} detailedMessage={'Failed to fetch assignee'} />);
      });
  };

  const handleOnDeleteButtonPress = user => {
    setShowDeleteConfModal(true);
    setDeleteSoonUser(user);
  };

  const handleDelete = () => {
    del(
      `${apiUrls.TASK}/${taskId}/assignee/${deleteSoonUserId}`,
      {},
      {
        query: { type: deleteSoonUserType }
      }
    )
      .then(response => {
        const { status } = response;
        if (status === 200) {
          handleOnSuccessDeleteAssignee();
        } else {
          toast.error(
            <Toast message={'Error'} detailedMessage={`${response?.data?.error?.message}`} />
          );
        }
      })
      .catch(e => {
        toast.error(<Toast message={'Error'} detailedMessage={'Failed to delete assignee'} />);
      });
  };

  const handleOnSuccessDeleteAssignee = () => {
    setShowDeleteConfModal(false);
    setShowSuccessDeleteModal(true);
    fetchAssignees();
  };

  return (
    <div>
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px] h-fit p-0">
          <div className="bg-white p-6">
            <div className="flex flex-row items-center justify-between">
              <div className="text-[18px] font-semibold">Assign List</div>
              <img
                src={icons.icCloseModal}
                className="w-6 h-6 cursor-pointer"
                alt={'close-modal'}
                onClick={() => setShowModal(false)}
              />
            </div>
            <hr className="mt-2 mb-6" />
            <div className="flex flex-col gap-4 max-h-[450px] overflow-auto pb-2">
              {assignees.map((assignee, index) => (
                <div key={index} className="bg-white shadow-md rounded-lg p-4 flex items-center">
                  <img
                    src={assignee?.picture ? assignee.picture : icons.icActiveUserOnline}
                    alt="assignee"
                    className="rounded-full w-9 h-9 mr-4"
                  />
                  <div className="flex flex-col gap-1 flex-1">
                    <div className="text-[12px] text-[#232323] font-semibold">
                      {assignee?.full_name || assignee?.name}
                    </div>
                    <div className="text-[12px] text-[#AFAFB9]">{assignee?.department || ''}</div>
                  </div>
                  {!isComplete && fullAccess && (
                    <img
                      src={icons.icTrashSecondary}
                      alt="remove"
                      className="w-6 h-6 cursor-pointer"
                      onClick={() => handleOnDeleteButtonPress(assignee)}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        showModal={showDeleteConfModal}
        setShowModal={setShowDeleteConfModal}
        handleSubmit={handleDelete}
        title={'Delete Assignee'}
        message={'Are you sure you want to delete assignee?'}
        labelSubmitButton={'Yes, Delete'}
      />
      <SuccessPopUp
        show={showSuccessDeleteModal}
        imageSuccess={images.imageSuccess}
        title={'Successfully'}
        message={'Hooray! You have successfully delete assignee'}
        labelSuccessButton={'Ok, Back'}
        handleSuccessButton={() => setShowSuccessDeleteModal(false)}
      />
    </div>
  );
};

export default AssigneeListModal;
