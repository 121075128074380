import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import Section from '../Section';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Toast } from '../../../../../components';
import { del, post } from '../../../../../api/base';
import { setShowLoading } from '../../../../../store/slices/popup';
import FieldAttachment from '../components/FieldAttachment';
import { icons } from '../../../../../constants';
import { useNavigate } from 'react-router-dom';
import { cacheHelper, offlineHelper } from '../../../../../helpers';
import CustomTabletLayout from '../CustomTabletLayout';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import moment from 'moment';
import TableLayout from '../TableLayout';
import ApprovalModal from '../components/ApprovalModal';
const GenerateForm = ({
  submission,
  submitted,
  allowApproval,
  handleSendApproval,
  storeForm,
  isEditingMode,
  setIsEditingMode,
  isTabletLayout,
  submitFormTabletLayout,
  isWizardLayout,
  indexSection,
  setIndexSection,
  isFeedback,
  prevIndexSection,
  setPrevIndexSection,
  checkFieldValue
}) => {
  const [sections, setSections] = useState(_.cloneDeep(submission?.sections));
  const [newAttachments, setNewAttachments] = useState(submission.attachments || []);
  const [isHaveAttachment, setIsHaveAttachment] = useState(
    submitted ? submission?.form?.attachment : submission?.attachment
  );
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [approvalType, setApprovalType] = useState('');
  useEffect(() => {
    if (submission?.form?.attachment) setIsHaveAttachment(true);
  }, [submission]);
  const user = useSelector(state => state.user.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileInputRef = useRef();
  const onChangeCheckBox = (
    event,
    label,
    additionalIndex,
    fieldIndex,
    innerSectionIndex,
    sectionIndex
  ) => {
    let sectionsCopy = [...sections];
    const values = [];

    const section =
      innerSectionIndex || innerSectionIndex === 0
        ? sectionsCopy[sectionIndex].inner_section[innerSectionIndex][fieldIndex]
        : sectionsCopy[sectionIndex].fields[fieldIndex];

    const options =
      additionalIndex || additionalIndex === 0
        ? section.additional_option[additionalIndex].options
        : section.options;

    options.forEach(v => {
      if (v.label === label) {
        v.value = event.target.checked;
      }
      if (v.value) {
        values.push(v.label);
      }
    });

    if (additionalIndex || additionalIndex === 0) {
      section.additional_option[additionalIndex].value = values;
    } else {
      section.value = values;
    }

    setSections(sectionsCopy);
  };

  const onChangeRadioButton = (
    event,
    label,
    additionalIndex,
    fieldIndex,
    innerSectionIndex,
    sectionIndex
  ) => {
    const sectionsCopy = [...sections];
    const section =
      innerSectionIndex || innerSectionIndex === 0
        ? sectionsCopy[sectionIndex].inner_section[innerSectionIndex][fieldIndex]
        : sectionsCopy[sectionIndex].fields[fieldIndex];

    if (additionalIndex || additionalIndex === 0) {
      section.additional_option[additionalIndex].value = label;
    } else {
      section.value = label;
    }

    setSections(sectionsCopy);
  };

  const onChangeValueField = (event, additionalIndex, fieldIndex, sectionIndex) => {
    const sectionsCopy = [...sections];
    const field = sectionsCopy[sectionIndex].fields[fieldIndex];
    if (additionalIndex || additionalIndex === 0) {
      const additionalOption = field.additional_option[additionalIndex];
      const fieldType = field.field_type.value;

      switch (fieldType) {
        case 'TEXT_FIELD':
        case 'TEXT_AREA':
          additionalOption.value = event.target.value;
          break;
        case 'CHECKBOX':
        case 'RADIO_BUTTON':
          additionalOption.value = event.target.checked;
          break;
        case 'DROPDOWN_MASTER_DATA':
        case 'DROPDOWN':
        case 'DATE_PICKER':
        case 'SIGNATURE':
        case 'RATE_BY_ICON':
          additionalOption.value = event;
          break;
        default:
          break;
      }
    } else {
      const fieldType = field.field_type.value;

      switch (fieldType) {
        case 'MASTER_STOCK_AMOUNT':
        case 'STOCK_IN_TRANSACTION':
        case 'TEXT_FIELD':
        case 'TEXT_AREA':
        case 'CALCULATED_FIELD':
        case 'CALCULATED_SUBTOTAL':
          field.value = event.target.value;
          break;
        case 'CHECKBOX':
        case 'RADIO_BUTTON':
          field.value = event.target.checked;
          break;
        case 'DROPDOWN_MASTER_DATA':
        case 'STOCK_UNIT':
        case 'MASTER_STOCK':
        case 'DROPDOWN':
        case 'DATE_PICKER':
        case 'SIGNATURE':
        case 'RATE_BY_ICON':
          field.value = event;
          break;
        default:
          break;
      }
    }
    setSections(sectionsCopy);
  };

  const onChangeValueInnerSectionField = (
    event,
    additionalIndex,
    fieldIndex,
    innerSectionIndex,
    sectionIndex
  ) => {
    let sectionsCopy = [...sections];
    const field = sectionsCopy[sectionIndex].inner_section[innerSectionIndex][fieldIndex];

    if (additionalIndex || additionalIndex === 0) {
      const additionalOption = field.additional_option[additionalIndex];
      const fieldType = field.field_type.value;

      switch (fieldType) {
        case 'TEXT_FIELD':
        case 'TEXT_AREA':
          additionalOption.value = event.target.value;
          break;
        case 'DROPDOWN_MASTER_DATA':
        case 'DROPDOWN':
        case 'DATE_PICKER':
        case 'SIGNATURE':
        case 'RATE_BY_ICON':
          additionalOption.value = event;
          break;
        default:
          break;
      }
    } else {
      const fieldType = field.field_type.value;

      switch (fieldType) {
        case 'STOCK_IN_TRANSACTION':
        case 'TEXT_FIELD':
        case 'TEXT_AREA':
        case 'CALCULATED_FIELD':
          field.value = event.target.value;
          break;
        case 'DROPDOWN_MASTER_DATA':
        case 'STOCK_UNIT':
        case 'MASTER_STOCK':
        case 'DROPDOWN':
        case 'DATE_PICKER':
        case 'SIGNATURE':
        case 'RATE_BY_ICON':
          field.value = event;
          break;
        default:
          break;
      }
    }

    setSections(sectionsCopy);
  };

  const handleAddMoreField = (section, sectionIndex) => {
    const sectionsCopy = [...sections];
    const currentSection = sectionsCopy[sectionIndex];
    let tempSectionInner = _.cloneDeep(submission?.sections);
    const sectionData = tempSectionInner.find(item => item.section_id === section.section_id);
    let isIncludeCalculatedSubtotal = sectionData?.fields?.find(
      field => field?.field_type?.value === 'CALCULATED_SUBTOTAL'
    );
    if (!_.isEmpty(isIncludeCalculatedSubtotal)) {
      let calculatedSubtotalIndex = sectionData?.fields?.indexOf(isIncludeCalculatedSubtotal);
      delete sectionData?.fields?.splice(calculatedSubtotalIndex, 1);
    }
    if (!currentSection.inner_section?.length) {
      currentSection.inner_section = [sectionData.fields];
    } else {
      currentSection.inner_section.push([...sectionData.fields]);
    }
    toast.success(<Toast message={'Success'} detailedMessage={'Success Add Field'} />);
    setSections(sectionsCopy);
  };

  const handleDeleteMoreField = (sectionIndex, innerSectionIndex) => {
    sections[sectionIndex] = innerSectionIndex;
    setSections([...sections]);
  };

  const onAddAttachment = async (
    e,
    additionalIndex,
    fieldIndex,
    innerSectionIndex,
    sectionIndex
  ) => {
    const files = e.target.files;
    if (!files.length) return;

    const fileSizeUser = user?.file_size * 1048576;

    const formData = new FormData();

    let statusFile;

    let sectionsCopy = [...sections];
    let currentField;

    if (innerSectionIndex || innerSectionIndex === 0) {
      if (additionalIndex || additionalIndex === 0) {
        currentField =
          sectionsCopy[sectionIndex]?.inner_section[innerSectionIndex][fieldIndex]
            .additional_option[additionalIndex];
      } else {
        currentField = sectionsCopy[sectionIndex]?.inner_section[innerSectionIndex][fieldIndex];
      }
    } else {
      if (additionalIndex || additionalIndex === 0) {
        currentField =
          sectionsCopy[sectionIndex].fields[fieldIndex].additional_option[additionalIndex];
      } else {
        currentField = sectionsCopy[sectionIndex].fields[fieldIndex];
      }
    }

    const processUpload = async () => {
      if (files.length <= 5) {
        for (let i = 0; i < files.length; i++) {
          const isImage = files[i].type.match('image') ? true : false;
          if (isImage && submission?.image_resolution) {
            let resizedImage = await resizeImage(
              files[i].type,
              URL.createObjectURL(files[i]),
              submission?.image_resolution?.width
            );
            const blob = await dataUrlToBlob(resizedImage);
            const file = blobToFile(blob, files[i].name);

            if (file.size > fileSizeUser) {
              statusFile = false;
              toast.error(
                <Toast
                  message={'Error'}
                  detailedMessage={`Cannot upload ${files[i].name} because the size of file is more than ${fileSizeUser} mb`}
                />
              );
            } else {
              statusFile = true;
              formData.append('files[]', file, file.name);
              formData.append('files[]', {
                uri: URL.createObjectURL(file),
                type: files[i].type,
                name: files[i].name
              });
            }
          } else {
            if (files[i].size > fileSizeUser) {
              statusFile = false;
              toast.error(
                <Toast
                  message={'Error'}
                  detailedMessage={`Cannot upload ${files[i].name} because the size of file is more than ${fileSizeUser} mb`}
                />
              );
            } else {
              statusFile = true;
              formData.append('files[]', files[i]);
              formData.append('files[]', {
                uri: files[i].fileCopyUri,
                type: files[i].type,
                name: files[i].name
              });
            }
          }
        }
        if (statusFile) {
          if (navigator.onLine) {
            dispatch(setShowLoading(true));
            post('/api/dynamic-forms/attachments', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(response => {
              if (response.status === 200) {
                if (currentField.hasOwnProperty('attachment_value')) {
                  currentField.attachment_value = currentField.attachment_value.concat(
                    response.data
                  );
                } else {
                  currentField.attachment_value = response.data;
                }
                setSections(sectionsCopy);
              } else if (response.status === 0) {
                toast.error(
                  <Toast
                    message={'Error'}
                    detailedMessage={'Cannot Reach server, please try again later'}
                  />
                );
              } else {
                toast.error(
                  <Toast message={'Error'} detailedMessage={`${response.data.error.message}`} />
                );
              }
              dispatch(setShowLoading(false));
            });
          } else {
            const rawAttachment = await Promise.all(
              formData
                .getAll('files[]')
                .filter(data => data instanceof File)
                .map(async file => {
                  return await offlineHelper.saveAttachmentOffline(file);
                })
            );
            if (currentField.hasOwnProperty('attachment_value')) {
              currentField.attachment_value.push(...rawAttachment);
            } else {
              currentField.attachment_value = rawAttachment;
            }
            setSections([...sectionsCopy]);
          }
        }
      } else {
        toast.error(
          <Toast message={'Error'} detailedMessage={'Maximum of uploaded file is 5 files'} />
        );
      }
    };

    if (currentField.hasOwnProperty('attachment_value')) {
      if (currentField.attachment_value.length <= 5) {
        processUpload();
      } else {
        toast.error(
          <Toast
            message={'Error'}
            detailedMessage={'You have reached the maximum number of file uploads, maximum 5'}
          />
        );
      }
    } else {
      processUpload();
    }
  };

  const onRemoveAttachment = (
    fileIndex,
    additionalIndex,
    fieldIndex,
    innerSectionIndex,
    sectionIndex
  ) => {
    let sectionsCopy = [...sections];
    let currentAttachmentValue = [];
    if (innerSectionIndex || innerSectionIndex === 0) {
      if (additionalIndex || additionalIndex === 0) {
        currentAttachmentValue =
          sectionsCopy[sectionIndex].inner_section[innerSectionIndex][fieldIndex].additional_option[
            additionalIndex
          ].attachment_value;
      } else {
        currentAttachmentValue =
          sectionsCopy[sectionIndex].inner_section[innerSectionIndex][fieldIndex].attachment_value;
      }
    } else {
      if (additionalIndex || additionalIndex === 0) {
        currentAttachmentValue =
          sectionsCopy[sectionIndex].fields[fieldIndex].additional_option[additionalIndex]
            .attachment_value;
      } else {
        currentAttachmentValue = sectionsCopy[sectionIndex].fields[fieldIndex].attachment_value;
      }
    }

    const fileId = currentAttachmentValue[fileIndex].id;
    if (navigator.onLine) {
      dispatch(setShowLoading(true));
      del(`/api/dynamic-forms/attachments/${fileId}`).then(response => {
        if (response.status === 200) {
          currentAttachmentValue.splice(fileIndex, 1);
          setSections(sectionsCopy);
        } else {
          toast.error(
            <Toast message={'Error'} detailedMessage={`${response.data.error.message}`} />
          );
        }
        dispatch(setShowLoading(false));
      });
    } else {
      cacheHelper.deleteCachedAttachment(currentAttachmentValue[fileIndex].cacheIdentifier);
      currentAttachmentValue.splice(fileIndex, 1);
      setSections(sectionsCopy);
    }
  };

  const onChangeValueSubSectionField = (
    event,
    additionalIndex,
    fieldIndex,
    subSectionIndex,
    sectionIndex
  ) => {
    const sectionsCopy = [...sections];
    const section = sectionsCopy[sectionIndex].sub_sections[subSectionIndex];
    const field =
      additionalIndex || additionalIndex === 0
        ? section.fields[fieldIndex].additional_option[additionalIndex]
        : section.fields[fieldIndex];
    const fieldType = section.fields[fieldIndex].field_type.value;

    switch (fieldType) {
      case 'TEXT_FIELD':
      case 'TEXT_AREA':
        field.value = event.target.value;
        break;
      case 'CHECKBOX':
      case 'RADIO_BUTTON':
        field.value = event.target.checked;
        break;
      case 'DROPDOWN_MASTER_DATA':
      case 'DROPDOWN':
      case 'DATE_PICKER':
      case 'SIGNATURE':
        field.value = event;
        break;
      default:
        break;
    }

    setSections(sectionsCopy);
  };

  const onChangeSubSectionCheckBox = (
    event,
    label,
    additionalIndex,
    fieldIndex,
    subSectionIndex,
    sectionIndex
  ) => {
    const sectionsCopy = [...sections];
    const section = sectionsCopy[sectionIndex].sub_sections[subSectionIndex];
    const values = [];

    const field =
      additionalIndex || additionalIndex === 0
        ? section.fields[fieldIndex].additional_option[additionalIndex]
        : section.fields[fieldIndex];

    const options = field.options;

    options.forEach(v => {
      if (v.label === label) {
        v.value = event.target.checked;
      }
      if (v.value) {
        values.push(v.label);
      }
    });

    if (additionalIndex || additionalIndex === 0) {
      field.value = values;
    } else {
      field.value = values;
    }

    setSections(sectionsCopy);
  };

  const onChangeSubSectionRadio = (
    e,
    label,
    additionalIndex,
    fieldIndex,
    subSectionIndex,
    sectionIndex
  ) => {
    const sectionsCopy = [...sections];
    const section = sectionsCopy[sectionIndex].sub_sections[subSectionIndex];
    const field =
      additionalIndex || additionalIndex === 0
        ? section.fields[fieldIndex].additional_option[additionalIndex]
        : section.fields[fieldIndex];

    if (additionalIndex || additionalIndex === 0) {
      field.value = label;
    } else {
      field.value = label;
    }

    setSections(sectionsCopy);
  };

  const onAddSubSectionAttachment = async (
    e,
    additionalIndex,
    fieldIndex,
    subSectionIndex,
    sectionIndex
  ) => {
    const files = e.target.files;
    if (!files.length) return;

    const fileSizeUser = user?.file_size * 1048576;

    const formData = new FormData();

    let statusFile;

    let sectionsCopy = [...sections];
    let currentField;

    if (additionalIndex || additionalIndex === 0) {
      currentField =
        sectionsCopy[sectionIndex].sub_sections[subSectionIndex].fields[fieldIndex]
          .additional_option[additionalIndex];
    } else {
      currentField = sectionsCopy[sectionIndex].sub_sections[subSectionIndex].fields[fieldIndex];
    }

    const processUpload = async () => {
      if (files.length <= 5) {
        for (let i = 0; i < files.length; i++) {
          const isImage = files[i].type.match('image') ? true : false;
          if (isImage && submission?.image_resolution) {
            let resizedImage = await resizeImage(
              files[i].type,
              URL.createObjectURL(files[i]),
              submission?.image_resolution?.width
            );
            const blob = await dataUrlToBlob(resizedImage);
            const file = blobToFile(blob, files[i].name);

            if (file.size > fileSizeUser) {
              statusFile = false;
              toast.error(
                <Toast
                  message={'Error'}
                  detailedMessage={`Cannot upload ${files[i].name} because the size of file is more than ${fileSizeUser} mb`}
                />
              );
            } else {
              statusFile = true;
              formData.append('files[]', file, file.name);
              formData.append('files[]', {
                uri: URL.createObjectURL(file),
                type: files[i].type,
                name: files[i].name
              });
            }
          } else {
            if (files[i].size > fileSizeUser) {
              statusFile = false;
              toast.error(
                <Toast
                  message={'Error'}
                  detailedMessage={`Cannot upload ${files[i].name} because the size of file is more than ${fileSizeUser} mb`}
                />
              );
            } else {
              statusFile = true;
              formData.append('files[]', files[i]);
              formData.append('files[]', {
                uri: files[i].fileCopyUri,
                type: files[i].type,
                name: files[i].name
              });
            }
          }
        }
        if (statusFile) {
          if (navigator.onLine) {
            dispatch(setShowLoading(true));
            post('/api/dynamic-forms/attachments', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(response => {
              if (response.status === 200) {
                if (currentField.hasOwnProperty('attachment_value')) {
                  currentField.attachment_value = currentField.attachment_value.concat(
                    response.data
                  );
                } else {
                  currentField.attachment_value = response.data;
                }
                setSections(sectionsCopy);
              } else if (response.status === 0) {
                toast.error(
                  <Toast
                    message={'Error'}
                    detailedMessage={'Cannot Reach server, please try again later'}
                  />
                );
              } else {
                toast.error(
                  <Toast message={'Error'} detailedMessage={`${response.data.error.message}`} />
                );
              }
              dispatch(setShowLoading(false));
            });
          } else {
            const rawAttachment = await Promise.all(
              formData
                .getAll('files[]')
                .filter(data => data instanceof File)
                .map(async file => {
                  return await offlineHelper.saveAttachmentOffline(file);
                })
            );
            if (currentField.hasOwnProperty('attachment_value')) {
              currentField.attachment_value.push(...rawAttachment);
            } else {
              currentField.attachment_value = rawAttachment;
            }
            setSections(sectionsCopy);
          }
        }
      } else {
        toast.error(
          <Toast message={'Error'} detailedMessage={'Maximum of uploaded file is 5 files'} />
        );
      }
    };

    if (currentField.hasOwnProperty('attachment_value')) {
      if (currentField.attachment_value.length <= 5) {
        processUpload();
      } else {
        toast.error(
          <Toast
            message={'Error'}
            detailedMessage={'You have reached the maximum number of file uploads, maximum 5'}
          />
        );
      }
    } else {
      processUpload();
    }
  };

  const onRemoveSubSectionAttachment = (
    fileIndex,
    additionalIndex,
    fieldIndex,
    subSectionIndex,
    sectionIndex
  ) => {
    let sectionsCopy = [...sections];
    let currentAttachmentValue = [];
    if (additionalIndex || additionalIndex === 0) {
      currentAttachmentValue =
        sectionsCopy[sectionIndex].sub_sections[subSectionIndex].fields[fieldIndex]
          .additional_option[additionalIndex].attachment_value;
    } else {
      currentAttachmentValue =
        sectionsCopy[sectionIndex].sub_sections[subSectionIndex].fields[fieldIndex]
          .attachment_value;
    }

    if (navigator.onLine) {
      const fileId = currentAttachmentValue[fileIndex].id;
      dispatch(setShowLoading(true));
      del(`/api/dynamic-forms/attachments/${fileId}`).then(response => {
        if (response.status === 200) {
          currentAttachmentValue.splice(fileIndex, 1);
          setSections(sectionsCopy);
        } else {
          toast.error(
            <Toast message={'Error'} detailedMessage={`${response.data.error.message}`} />
          );
        }
        dispatch(setShowLoading(false));
      });
    } else {
      cacheHelper.deleteCachedAttachment(currentAttachmentValue[fileIndex].cacheIdentifier);
      currentAttachmentValue.splice(fileIndex, 1);
      setSections([...sectionsCopy]);
    }
  };

  const dataUrlToBlob = async dataUrl => {
    const response = await fetch(dataUrl);
    const blob = await response.blob();
    return blob;
  };

  const blobToFile = (blob, filename) => {
    const file = new File([blob], filename, { type: blob.type });
    return file;
  };

  const resizeImage = (type, imageDataUrl, width) => {
    return new Promise(resolve => {
      const img = new Image();
      img.src = imageDataUrl;
      img.onload = () => {
        if (img.width <= width) {
          resolve(imageDataUrl);
        } else {
          const aspectRatio = img.width / img.height;
          const height = width / aspectRatio;

          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          const resizedImage = canvas.toDataURL(type);
          resolve(resizedImage);
        }
      };
    });
  };

  async function handleAddAttachment({ target: { files } }) {
    if (!files.length) return;

    const fileSizeUser = user?.file_size * 1048576;

    const formData = new FormData();

    let statusFile;
    if (newAttachments?.length <= 5) {
      if (files.length <= 5) {
        for (let i = 0; i < files.length; i++) {
          const isImage = files[i].type.match('image') ? true : false;
          if (isImage && submission?.image_resolution) {
            let resizedImage = await resizeImage(
              files[i].type,
              URL.createObjectURL(files[i]),
              submission?.image_resolution?.width
            );
            const blob = await dataUrlToBlob(resizedImage);
            const file = blobToFile(blob, files[i].name);

            if (file.size > fileSizeUser) {
              statusFile = false;
              toast.error(
                <Toast
                  message={'Error'}
                  detailedMessage={`Cannot upload ${files[i].name} because the size of file is more than ${fileSizeUser} mb`}
                />
              );
            } else {
              statusFile = true;
              formData.append('files[]', file, file.name);
              formData.append('files[]', {
                uri: URL.createObjectURL(file),
                type: files[i].type,
                name: files[i].name
              });
            }
          } else {
            if (files[i].size > fileSizeUser) {
              statusFile = false;
              toast.error(
                <Toast
                  message={'Error'}
                  detailedMessage={`Cannot upload ${files[i].name} because the size of file is more than ${fileSizeUser} mb`}
                />
              );
            } else {
              statusFile = true;
              formData.append('files[]', files[i]);
              formData.append('files[]', {
                uri: files[i].fileCopyUri,
                type: files[i].type,
                name: files[i].name
              });
            }
          }
        }
        if (statusFile) {
          if (navigator.onLine) {
            dispatch(setShowLoading(true));
            post('/api/dynamic-forms/attachments', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(response => {
              if (response.status === 200) {
                let rawAttachment = newAttachments.concat(response.data);
                setNewAttachments(rawAttachment);
              } else if (response.status === 0) {
                toast.error(
                  <Toast
                    message={'Error'}
                    detailedMessage={'Cannot Reach server, please try again later'}
                  />
                );
              } else {
                toast.error(
                  <Toast message={'Error'} detailedMessage={`${response.data.error.message}`} />
                );
              }
              dispatch(setShowLoading(false));
            });
          } else {
            const rawAttachment = await Promise.all(
              formData
                .getAll('files[]')
                .filter(data => data instanceof File)
                .map(async file => {
                  return await offlineHelper.saveAttachmentOffline(file);
                })
            );
            if (_.isEmpty(newAttachments)) {
              setNewAttachments(rawAttachment);
            } else {
              newAttachments.push(...rawAttachment);
              setNewAttachments([...newAttachments]);
            }
          }
        }
      } else {
        toast.error(
          <Toast message={'Error'} detailedMessage={'Maximum of uploaded file is 5 files'} />
        );
      }
    } else {
      toast.error(
        <Toast
          message={'Error'}
          detailedMessage={'You have reached the maximum number of file uploads, maximum 5'}
        />
      );
    }
    fileInputRef.current.value = null;
  }

  const deleteAttachment = id => {
    del(`/api/dynamic-forms/attachments/${id}`).then(response => {
      dispatch(setShowLoading(false));
      if (response.status === 200) {
        setNewAttachments(prevAttachments => prevAttachments.filter(attach => attach.id !== id));
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={`${response.data.error.message}`} />);
      }
    });
  };

  const resetSections = () => {
    setSections(_.cloneDeep(submission?.sections));
  };

  useEffect(() => {
    if (!isEditingMode) resetSections();
  }, [isEditingMode]);

  const handleDeleteAttachment = index => {
    dispatch(setShowLoading(true));
    if (navigator.onLine) {
      deleteAttachment(newAttachments[index].id);
    } else {
      cacheHelper.deleteCachedAttachment(newAttachments[index].cacheIdentifier);
      newAttachments.splice(index, 1);
      setNewAttachments([...newAttachments]);
      dispatch(setShowLoading(false));
    }
  };

  const checkMandatoryField = section => {
    const fields = section.fields;
    for (const field of fields) {
      if ('highlight' in field) {
        return false;
      }
    }
    return true;
  };
  const checkValue = (section, attachment) => {
    if (!checkFieldValue(section.fields)) {
      return false;
    }
    if (section.inner_section) {
      for (const inner of section.inner_section) {
        let resultInnFieldValue = checkFieldValue(inner);
        let resultFieldValue = checkFieldValue(section.fields);
        if (!resultInnFieldValue || !resultFieldValue) {
          return false;
        }
      }
    } else if (section.sub_sections?.length) {
      for (const sub of section.sub_sections) {
        let resultSubValue = checkFieldValue(sub.fields);
        let resultFieldValue = checkFieldValue(section.fields);
        if (!resultSubValue || !resultFieldValue) {
          return false;
        }
      }
    }
    if (submission.attachment && indexSection + 1 === sections?.length && !isTabletLayout) {
      if (_.isArray(attachment)) {
        if (!attachment.length) {
          return false;
        }
      } else {
        if (!attachment?._parts?.length) {
          return false;
        }
      }
    }
    return true;
  };

  const jumpToSections = () => {
    const mappingCondition = (
      jumpSettingsCondition,
      jumpValue,
      value,
      fieldType,
      format,
      keyboardType
    ) => {
      const parseValue =
        fieldType === 'DATE_PICKER'
          ? moment(value, format)
          : keyboardType === 'NUMERIC'
          ? parseInt(value)
          : value;
      const parseJumpValue =
        fieldType === 'DATE_PICKER'
          ? moment(jumpValue, format)
          : keyboardType === 'NUMERIC'
          ? parseInt(jumpValue)
          : jumpValue;

      switch (jumpSettingsCondition) {
        case 'EQUAL':
          // eslint-disable-next-line eqeqeq
          return parseValue == parseJumpValue;
        case 'NOT_EQUAL':
          // eslint-disable-next-line eqeqeq
          return parseValue != parseJumpValue;
        case '<=':
          return parseValue <= parseJumpValue;
        case '>=':
          return parseValue >= parseJumpValue;
        case '<':
          return parseValue < parseJumpValue;
        case '>':
          return parseValue > parseJumpValue;
        default:
          return false;
      }
    };

    let resultJump;
    if (sections.length) {
      if (
        checkValue(sections[indexSection], newAttachments) &&
        checkMandatoryField(sections[indexSection])
      ) {
        let jumpSettings = sections[indexSection]?.jump_settings;
        let jumpTo = sections[indexSection]?.jump_to_section;
        if (jumpSettings?.length) {
          let fields = sections[indexSection]?.fields;
          jumpSettings?.forEach(setting => {
            let field = fields?.find(function (rField) {
              return rField.field_id === setting?.field_id;
            });
            if (setting?.logic_operator) {
              switch (setting?.logic_operator) {
                case 'AND':
                  return (resultJump =
                    resultJump &&
                    mappingCondition(
                      setting?.condition,
                      setting?.value,
                      field.value,
                      field?.field_type?.value,
                      field?.format,
                      field?.keyboard_type?.value
                    ));
                case 'OR':
                  return (resultJump =
                    resultJump ||
                    mappingCondition(
                      setting?.condition,
                      setting?.value,
                      field.value,
                      field?.field_type?.value,
                      field?.format,
                      field?.keyboard_type?.value
                    ));
                default:
                  return;
              }
            } else {
              resultJump = mappingCondition(
                setting?.condition,
                setting?.value,
                field.value,
                field?.field_type?.value,
                field?.format,
                field?.keyboard_type?.value
              );
            }
          });

          if (resultJump) {
            let tempSections = sections.map(section => section.section_id);
            let tempIndexSection = tempSections?.indexOf(jumpTo.section_id);
            setIndexSection(tempIndexSection);
          } else {
            setIndexSection(indexSection + 1);
          }
        } else {
          setIndexSection(indexSection + 1);
        }
        setPrevIndexSection(indexSection);
      } else {
        toast.error(
          <Toast message={'Error'} detailedMessage={'Mandatory Field Cannot be empty'} />
        );
      }
    }
  };

  const submitWizardForm = () => {
    if (
      checkValue(sections[indexSection], newAttachments) &&
      checkMandatoryField(sections[indexSection])
    ) {
      storeForm({ sections, attachment: newAttachments, is_draft: false });
    } else {
      toast.error(<Toast message={'Error'} detailedMessage={'Mandatory Field Cannot be empty'} />);
    }
  };

  return (!isTabletLayout && !isWizardLayout) || submitted ? (
    <>
      {sections?.map((section, sectionIndex) =>
        !section?.tabular || submitted ? (
          <Section
            formId={submission.id}
            sectionIndex={sectionIndex}
            formType={submission?.form_type}
            key={sectionIndex}
            path={`sections.${sectionIndex}`}
            section={section}
            submitted={submitted}
            handleAddMoreField={() => handleAddMoreField(section, sectionIndex)}
            handleDeleteMoreField={innerSectionIndex =>
              handleDeleteMoreField(sectionIndex, innerSectionIndex)
            }
            onChangeValueField={(event, additionalIndex, fieldIndex) =>
              onChangeValueField(event, additionalIndex, fieldIndex, sectionIndex)
            }
            onChangeValueInnerSectionField={(
              event,
              additionalIndex,
              fieldIndex,
              innerSectionIndex
            ) =>
              onChangeValueInnerSectionField(
                event,
                additionalIndex,
                fieldIndex,
                innerSectionIndex,
                sectionIndex
              )
            }
            onChangeCheckBox={(event, label, index, fieldIndex, innerSectionIndex) =>
              onChangeCheckBox(event, label, index, fieldIndex, innerSectionIndex, sectionIndex)
            }
            onChangeRadio={(event, label, index, fieldIndex, innerSectionIndex) =>
              onChangeRadioButton(event, label, index, fieldIndex, innerSectionIndex, sectionIndex)
            }
            onAddAttachment={(file, additionalIndex, fieldIndex, innerSectionIndex) =>
              onAddAttachment(file, additionalIndex, fieldIndex, innerSectionIndex, sectionIndex)
            }
            removeFile={(fileIndex, additionalIndex, fieldIndex, innerSectionIndex) =>
              onRemoveAttachment(
                fileIndex,
                additionalIndex,
                fieldIndex,
                innerSectionIndex,
                sectionIndex
              )
            }
            onChangeValueSubSection={(event, additionalIndex, fieldIndex, subSectionIndex) =>
              onChangeValueSubSectionField(
                event,
                additionalIndex,
                fieldIndex,
                subSectionIndex,
                sectionIndex
              )
            }
            onChangeSubCheckBox={(e, label, additionalIndex, fieldIndex, subSectionIndex) =>
              onChangeSubSectionCheckBox(
                e,
                label,
                additionalIndex,
                fieldIndex,
                subSectionIndex,
                sectionIndex
              )
            }
            onChangeSubRadio={(e, label, additionalIndex, fieldIndex, subSectionIndex) =>
              onChangeSubSectionRadio(
                e,
                label,
                additionalIndex,
                fieldIndex,
                subSectionIndex,
                sectionIndex
              )
            }
            onAddSubAttachment={(file, additionalIndex, fieldIndex, subSectionIndex) =>
              onAddSubSectionAttachment(
                file,
                additionalIndex,
                fieldIndex,
                subSectionIndex,
                sectionIndex
              )
            }
            removeSubSectionFile={(fileIndex, additionalIndex, fieldIndex, subSectionIndex) =>
              onRemoveSubSectionAttachment(
                fileIndex,
                additionalIndex,
                fieldIndex,
                subSectionIndex,
                sectionIndex
              )
            }
          />
        ) : (
          <TableLayout
            formType={submission?.form_type}
            sectionIndex={sectionIndex}
            key={sectionIndex}
            path={`sections.${sectionIndex}`}
            section={section}
            submitted={submitted}
            handleAddMoreField={() => handleAddMoreField(section, sectionIndex)}
            handleDeleteMoreField={innerSectionIndex =>
              handleDeleteMoreField(sectionIndex, innerSectionIndex)
            }
            onChangeValueField={(event, additionalIndex, fieldIndex) =>
              onChangeValueField(event, additionalIndex, fieldIndex, sectionIndex)
            }
            onChangeValueInnerSectionField={(
              event,
              additionalIndex,
              fieldIndex,
              innerSectionIndex
            ) =>
              onChangeValueInnerSectionField(
                event,
                additionalIndex,
                fieldIndex,
                innerSectionIndex,
                sectionIndex
              )
            }
            onChangeCheckBox={(event, label, index, fieldIndex, innerSectionIndex) =>
              onChangeCheckBox(event, label, index, fieldIndex, innerSectionIndex, sectionIndex)
            }
            onChangeRadio={(event, label, index, fieldIndex, innerSectionIndex) =>
              onChangeRadioButton(event, label, index, fieldIndex, innerSectionIndex, sectionIndex)
            }
            onAddAttachment={(file, additionalIndex, fieldIndex, innerSectionIndex) =>
              onAddAttachment(file, additionalIndex, fieldIndex, innerSectionIndex, sectionIndex)
            }
            removeFile={(fileIndex, additionalIndex, fieldIndex, innerSectionIndex) =>
              onRemoveAttachment(
                fileIndex,
                additionalIndex,
                fieldIndex,
                innerSectionIndex,
                sectionIndex
              )
            }
            onChangeValueSubSection={(event, additionalIndex, fieldIndex, subSectionIndex) =>
              onChangeValueSubSectionField(
                event,
                additionalIndex,
                fieldIndex,
                subSectionIndex,
                sectionIndex
              )
            }
            onChangeSubCheckBox={(e, label, additionalIndex, fieldIndex, subSectionIndex) =>
              onChangeSubSectionCheckBox(
                e,
                label,
                additionalIndex,
                fieldIndex,
                subSectionIndex,
                sectionIndex
              )
            }
            onChangeSubRadio={(e, label, additionalIndex, fieldIndex, subSectionIndex) =>
              onChangeSubSectionRadio(
                e,
                label,
                additionalIndex,
                fieldIndex,
                subSectionIndex,
                sectionIndex
              )
            }
            onAddSubAttachment={(file, additionalIndex, fieldIndex, subSectionIndex) =>
              onAddSubSectionAttachment(
                file,
                additionalIndex,
                fieldIndex,
                subSectionIndex,
                sectionIndex
              )
            }
            removeSubSectionFile={(fileIndex, additionalIndex, fieldIndex, subSectionIndex) =>
              onRemoveSubSectionAttachment(
                fileIndex,
                additionalIndex,
                fieldIndex,
                subSectionIndex,
                sectionIndex
              )
            }
          />
        )
      )}
      {(submitted && newAttachments.length) ||
      (submitted && newAttachments?._parts?.length) ||
      isHaveAttachment ? (
        <div className="flex flex-col bg-white rounded-2xl p-6">
          <div className="font-semibold text-[14px] text-[#C800A5]">Attachment</div>
          <hr className="mt-2 mb-6" />
          <FieldAttachment
            attachments={newAttachments}
            fileInputRef={fileInputRef}
            submitted={submitted}
            handleAddAttachment={handleAddAttachment}
            handleDeleteAttachment={handleDeleteAttachment}
          />
        </div>
      ) : (
        <></>
      )}
      {submitted ? (
        <>
          <div
            className={`flex flex-row bg-white rounded-lg p-[16px] ${
              allowApproval ? 'mt-4' : 'my-[40px]'
            }`}
          >
            <img src={icons.icInfo} alt="info" className="w-[24px] h-[24px] mr-[16px]" />
            <div>
              <div className="font-semibold text-[14px] text-[#C800A5]">Form Submitted</div>
              <div className="flex flex-row text-[#AFAFB9] text-[12px]">{`Submitted by ${submission?.user?.full_name} on ${submission.submitted_time}`}</div>
            </div>
          </div>
          {allowApproval && (
            <div className="flex flex-row gap-6 mb-[40px] justify-center sm:justify-end">
              <button
                className="py-[10px] w-[150px] bg-gradient-to-r from-[#C800A5] to-[#FD79E6] text-white text-[14px] font-semibold rounded-lg"
                onClick={() => {
                  setShowApprovalModal(true);
                  setApprovalType('REVISE');
                }}
              >
                Send Back
              </button>
              <button
                className="py-[10px] w-[150px] bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[14px] font-semibold rounded-lg"
                onClick={() => {
                  setShowApprovalModal(true);
                  setApprovalType('APPROVE');
                }}
              >
                Approve
              </button>
            </div>
          )}
        </>
      ) : !isFeedback ? (
        <div
          className={`flex flex-row gap-6 mt-[60px] mb-[24px] justify-center ${
            !isEditingMode ? 'sm:justify-between' : 'sm:justify-end'
          } mx-[12px] sm:mx-0`}
        >
          {!isEditingMode && (
            <button
              className="bg-transparent border-2 border-[#6546C3] rounded-lg py-[10px] w-[150px] font-semibold text-[#6546C3] 
                  text-[14px] flex justify-center items-center"
              onClick={() => storeForm({ sections, attachment: newAttachments, is_draft: true })}
            >
              Save Draft
            </button>
          )}

          <div className="flex flex-row gap-6">
            <button
              className={`${
                isEditingMode ? '' : 'hidden sm:block'
              } bg-transparent border-2 border-[#6546C3] rounded-lg py-[10px] w-[150px] font-semibold text-[#6546C3] 
                    text-[14px] flex justify-center items-center`}
              onClick={
                isEditingMode
                  ? () => {
                      setIsEditingMode(false);
                    }
                  : () => navigate(-1)
              }
            >
              {isEditingMode ? 'Cancel' : 'Back'}
            </button>
            <button
              className="bg-[#6546C3] border-2 border-[#6546C3] rounded-lg py-[10px] w-[150px] font-semibold text-white 
                    text-[14px] flex justify-center items-center"
              onClick={() => storeForm({ sections, attachment: newAttachments, is_draft: false })}
            >
              Submit
            </button>
          </div>
        </div>
      ) : (
        <div className="flex flex-row gap-6 mt-[60px] mb-[24px] justify-center sm:justify-end mx-[12px] sm:mx-0">
          <button
            className="bg-[#6546C3] border-2 border-[#6546C3] rounded-lg py-[10px] w-[150px] font-semibold text-white 
                    text-[14px] flex justify-center items-center"
            onClick={() => storeForm({ sections, attachment: newAttachments, is_draft: false })}
          >
            Submit
          </button>
        </div>
      )}
      <ApprovalModal
        showModal={showApprovalModal}
        setShowModal={setShowApprovalModal}
        approvalType={approvalType}
        handleSendApproval={handleSendApproval}
      />
    </>
  ) : isTabletLayout ? (
    <>
      <CustomTabletLayout
        customTabletLayout={submission.custom_tablet_layout}
        sections={sections}
        submitFormTabletLayout={submitFormTabletLayout}
        isHaveAttachment={isHaveAttachment}
        attachments={newAttachments}
        setNewAttachments={setNewAttachments}
        fileInputRef={fileInputRef}
        handleAddAttachment={handleAddAttachment}
        handleDeleteAttachment={handleDeleteAttachment}
        onChangeValueField={(event, additionalIndex, fieldIndex, sectionIndex) =>
          onChangeValueField(event, additionalIndex, fieldIndex, sectionIndex)
        }
        onChangeCheckBox={(event, label, index, fieldIndex, innerSectionIndex, sectionIndex) =>
          onChangeCheckBox(event, label, index, fieldIndex, innerSectionIndex, sectionIndex)
        }
        onChangeRadio={(event, label, index, fieldIndex, innerSectionIndex, sectionIndex) =>
          onChangeRadioButton(event, label, index, fieldIndex, innerSectionIndex, sectionIndex)
        }
        onAddAttachment={(file, additionalIndex, fieldIndex, innerSectionIndex, sectionIndex) =>
          onAddAttachment(file, additionalIndex, fieldIndex, innerSectionIndex, sectionIndex)
        }
        removeFile={(fileIndex, additionalIndex, fieldIndex, innerSectionIndex, sectionIndex) =>
          onRemoveAttachment(
            fileIndex,
            additionalIndex,
            fieldIndex,
            innerSectionIndex,
            sectionIndex
          )
        }
        onChangeValueSubSection={(
          event,
          additionalIndex,
          fieldIndex,
          subSectionIndex,
          sectionIndex
        ) =>
          onChangeValueSubSectionField(
            event,
            additionalIndex,
            fieldIndex,
            subSectionIndex,
            sectionIndex
          )
        }
        onChangeSubCheckBox={(
          e,
          label,
          additionalIndex,
          fieldIndex,
          subSectionIndex,
          sectionIndex
        ) =>
          onChangeSubSectionCheckBox(
            e,
            label,
            additionalIndex,
            fieldIndex,
            subSectionIndex,
            sectionIndex
          )
        }
        onChangeSubRadio={(e, label, additionalIndex, fieldIndex, subSectionIndex, sectionIndex) =>
          onChangeSubSectionRadio(
            e,
            label,
            additionalIndex,
            fieldIndex,
            subSectionIndex,
            sectionIndex
          )
        }
        onAddSubAttachment={(file, additionalIndex, fieldIndex, subSectionIndex, sectionIndex) =>
          onAddSubSectionAttachment(
            file,
            additionalIndex,
            fieldIndex,
            subSectionIndex,
            sectionIndex
          )
        }
        removeSubSectionFile={(
          fileIndex,
          additionalIndex,
          fieldIndex,
          subSectionIndex,
          sectionIndex
        ) =>
          onRemoveSubSectionAttachment(
            fileIndex,
            additionalIndex,
            fieldIndex,
            subSectionIndex,
            sectionIndex
          )
        }
      />
      <div className="flex flex-row gap-6 mb-[24px] justify-end mx-[12px] sm:mx-0">
        <button
          className="bg-transparent border-2 border-[#6546C3] rounded-lg py-[10px] w-[150px] font-semibold text-[#6546C3] 
                    text-[14px] flex justify-center items-center"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
    </>
  ) : sections?.length ? (
    <>
      <Section
        formId={submission.id}
        path={`sections.${indexSection}`}
        section={sections[indexSection]}
        submitted={submitted}
        handleAddMoreField={() => handleAddMoreField(sections[indexSection], indexSection)}
        handleDeleteMoreField={innerSectionIndex =>
          handleDeleteMoreField(indexSection, innerSectionIndex)
        }
        onChangeValueField={(event, additionalIndex, fieldIndex) =>
          onChangeValueField(event, additionalIndex, fieldIndex, indexSection)
        }
        onChangeValueInnerSectionField={(event, additionalIndex, fieldIndex, innerSectionIndex) =>
          onChangeValueInnerSectionField(
            event,
            additionalIndex,
            fieldIndex,
            innerSectionIndex,
            indexSection
          )
        }
        onChangeCheckBox={(event, label, index, fieldIndex, innerSectionIndex) =>
          onChangeCheckBox(event, label, index, fieldIndex, innerSectionIndex, indexSection)
        }
        onChangeRadio={(event, label, index, fieldIndex, innerSectionIndex) =>
          onChangeRadioButton(event, label, index, fieldIndex, innerSectionIndex, indexSection)
        }
        onAddAttachment={(file, additionalIndex, fieldIndex, innerSectionIndex) =>
          onAddAttachment(file, additionalIndex, fieldIndex, innerSectionIndex, indexSection)
        }
        removeFile={(fileIndex, additionalIndex, fieldIndex, innerSectionIndex) =>
          onRemoveAttachment(
            fileIndex,
            additionalIndex,
            fieldIndex,
            innerSectionIndex,
            indexSection
          )
        }
        onChangeValueSubSection={(event, additionalIndex, fieldIndex, subSectionIndex) =>
          onChangeValueSubSectionField(
            event,
            additionalIndex,
            fieldIndex,
            subSectionIndex,
            indexSection
          )
        }
        onChangeSubCheckBox={(e, label, additionalIndex, fieldIndex, subSectionIndex) =>
          onChangeSubSectionCheckBox(
            e,
            label,
            additionalIndex,
            fieldIndex,
            subSectionIndex,
            indexSection
          )
        }
        onChangeSubRadio={(e, label, additionalIndex, fieldIndex, subSectionIndex) =>
          onChangeSubSectionRadio(
            e,
            label,
            additionalIndex,
            fieldIndex,
            subSectionIndex,
            indexSection
          )
        }
        onAddSubAttachment={(file, additionalIndex, fieldIndex, subSectionIndex) =>
          onAddSubSectionAttachment(
            file,
            additionalIndex,
            fieldIndex,
            subSectionIndex,
            indexSection
          )
        }
        removeSubSectionFile={(fileIndex, additionalIndex, fieldIndex, subSectionIndex) =>
          onRemoveSubSectionAttachment(
            fileIndex,
            additionalIndex,
            fieldIndex,
            subSectionIndex,
            indexSection
          )
        }
      />
      {isHaveAttachment && indexSection + 1 === sections.length ? (
        <div className="flex flex-col bg-white rounded-2xl p-6">
          <div className="font-semibold text-[14px] text-[#C800A5]">Attachment</div>
          <hr className="mt-2 mb-6" />
          <FieldAttachment
            attachments={newAttachments}
            fileInputRef={fileInputRef}
            submitted={submitted}
            handleAddAttachment={handleAddAttachment}
            handleDeleteAttachment={handleDeleteAttachment}
          />
        </div>
      ) : (
        <></>
      )}
      <div className="hidden sm:flex flex-row mt-[60px] mb-[24px] justify-between mx-[12px] sm:mx-0 gap-2">
        <div className="flex flex-row gap-2">
          <button
            className="bg-transparent border-2 border-[#6546C3] rounded-lg py-[10px] w-[150px] font-semibold text-[#6546C3] 
                  text-[14px] flex justify-center items-center"
            onClick={() => storeForm({ sections, attachment: newAttachments, is_draft: true })}
          >
            Save Draft
          </button>
          <button
            disabled={indexSection === 0}
            className="bg-transparent border-2 disabled:border-[#AFAFB9] border-[#6546C3] rounded-lg py-[10px] w-[150px] font-semibold disabled:text-[#AFAFB9] text-[#6546C3] 
                  text-[14px] flex justify-center items-center"
            onClick={() => {
              setPrevIndexSection(prevIndexSection - 1);
              setIndexSection(prevIndexSection);
              window.scrollTo(0, 0);
            }}
          >
            <AiOutlineArrowLeft size={16} className="mr-2" /> Previous
          </button>
        </div>
        <div className="flex flex-row gap-2">
          <button
            disabled={indexSection === sections.length - 1}
            className="disabled:bg-[#F1F1FD] disabled:text-[#AFAFB9] disabled:border-[#AFAFB9] bg-[#6546C3] border-2 border-[#6546C3] rounded-lg py-[10px] w-[150px] font-semibold text-white 
                    text-[14px] flex justify-center items-center"
            onClick={() => {
              jumpToSections();
              window.scrollTo(0, 0);
            }}
          >
            Next <AiOutlineArrowRight size={16} className="ml-2" />
          </button>
          <button
            disabled={indexSection + 1 < sections.length}
            className=" disabled:bg-[#F1F1FD] disabled:text-[#AFAFB9] disabled:border-[#AFAFB9] bg-[#6546C3] border-2 border-[#6546C3] rounded-lg py-[10px] w-[150px] font-semibold text-white 
                    text-[14px] flex justify-center items-center"
            onClick={submitWizardForm}
          >
            Submit
          </button>
        </div>
      </div>

      {/*Mobile Mode*/}

      <div className="block sm:hidden flex flex-row mt-[60px] mb-[24px] justify-between gap-2">
        <div className="flex flex-col gap-2">
          <button
            className="bg-transparent border-2 border-[#6546C3] rounded-lg py-[10px] w-[120px] font-semibold text-[#6546C3] 
                  text-[14px] "
            onClick={() => storeForm({ sections, attachment: newAttachments, is_draft: true })}
          >
            Save Draft
          </button>
          <button
            disabled={indexSection === 0}
            className="bg-transparent border-2 disabled:border-[#AFAFB9] border-[#6546C3] rounded-lg py-[10px] w-[120px] font-semibold disabled:text-[#AFAFB9] text-[#6546C3] 
                  text-[14px] flex justify-center items-center"
            onClick={() => {
              setPrevIndexSection(prevIndexSection - 1);
              setIndexSection(prevIndexSection);
              window.scrollTo(0, 0);
            }}
          >
            <AiOutlineArrowLeft size={16} className="mr-2" /> Previous
          </button>
        </div>
        <div className="flex flex-col gap-2">
          <button
            disabled={indexSection + 1 < sections.length}
            className=" disabled:bg-[#F1F1FD] disabled:text-[#AFAFB9] disabled:border-[#AFAFB9] bg-[#6546C3] border-2 border-[#6546C3] rounded-lg py-[10px] w-[120px] font-semibold text-white 
                    text-[14px] flex justify-center items-center"
            onClick={submitWizardForm}
          >
            Submit
          </button>
          <button
            disabled={indexSection === sections.length - 1}
            className="disabled:bg-[#F1F1FD] disabled:text-[#AFAFB9] disabled:border-[#AFAFB9] bg-[#6546C3] border-2 border-[#6546C3] rounded-lg py-[10px] w-[120px] font-semibold text-white 
                    text-[14px] flex justify-center items-center"
            onClick={() => {
              jumpToSections();
              window.scrollTo(0, 0);
            }}
          >
            Next <AiOutlineArrowRight size={16} className="ml-2" />
          </button>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default GenerateForm;
