import { useState } from 'react';
import { apiUrls, icons } from '../../../../../../constants';
import { useNavigate, useParams } from 'react-router';
import { get } from '../../../../../../api/base';
import { toast } from 'react-toastify';
import { Toast } from '../../../../../../components';

const SubResponseModal = ({ showModal, setShowModal, subResponseForm }) => {
  const [selectedForm, setSelectedForm] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const submit = () => {
    if (!subResponseForm?.can_multiple && subResponseForm?.count > 0) {
      toast.error(
        <Toast
          message={'Error'}
          detailedMessage={
            "You can't create another response because this document only allows one response"
          }
        />
      );
    } else {
      get(`${apiUrls.DYNAMIC_FORM}/${selectedForm?.id}/parent/${id}`).then(response => {
        const { status, data } = response;
        if (status === 200) {
          navigate(`/Task/FormSubmission/DetailFormSubmission/${data?.id}/${id}`);
          close();
        } else {
          toast.error(
            <Toast message={'Error'} detailedMessage={`${response.data?.error?.message}`} />
          );
        }
      });
    }
  };

  function close() {
    setShowModal(false);
    setSelectedForm(null);
  }

  return (
    <div>
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323/[0.5]">
        <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px]  sm:h-fit">
          <div className="bg-white">
            <div className="flex flex-row items-center justify-between">
              <div className="text-[18px] font-semibold">Select Sub Response Form</div>
              <img
                src={icons.icCloseModal}
                className="w-6 h-6 cursor-pointer"
                alt={'close-modal'}
                onClick={close}
              />
            </div>
            <hr className="mt-2 mb-6" />
            <div className="flex flex-1 flex-col gap-4 max-h-[350px] pb-4 overflow-auto">
              {subResponseForm?.map(form => (
                <div
                  key={form.id}
                  className={`${
                    form.id === selectedForm?.id ? 'bg-[#EBEDF8]' : 'bg-white'
                  } rounded-xl px-6 py-4 shadow-[0_5px_15px_0_rgba(0,0,0,0.06)] cursor-pointer`}
                  onClick={() => setSelectedForm(form)}
                >
                  <div className="flex justify-between items-center">
                    <div className="text-[14px]">{form.title}</div>
                    {form.id === selectedForm?.id && (
                      <img src={icons.icPrimaryChecklist} alt="selected form" className="h-4" />
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-center sm:justify-end w-full flex-row mt-9 gap-4 sm:gap-6">
              <button
                onClick={close}
                className="border-2 border-[#6546C3] py-3 px-8 text-[#6546C3] text-[12px] sm:text-[14px] font-semibold rounded-lg"
              >
                Back
              </button>
              <button
                onClick={submit}
                disabled={!selectedForm}
                className="py-3 px-8 enabled:bg-[#6546C3] disabled:bg-gray-400 text-white text-[12px] sm:text-[14px] font-semibold rounded-lg shadow-lg"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SubResponseModal;
