import { useEffect, useState } from 'react';
import { get } from '../../../api/base';
import { apiUrls, icons } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { EmptyData, Loading, TableAccent, Toast } from '../../../components';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { BsPlus } from 'react-icons/bs';
import FilterModal from './components/Filter';
import moment from 'moment';
import { setPage } from '../../../store/slices/table';
import _ from 'lodash';

const CardIssue = ({ issue, toSentenceCase }) => {
  const navigate = useNavigate();
  return (
    <div
      className="flex flex-row gap-2 p-5 bg-white items-center mt-2 rounded-2xl w-full"
      onClick={() => {
        if (issue?.status === 'DRAFT') {
          navigate(`/Task/IssueSubmission/${issue?.id}`);
        } else {
          navigate(`/Task/DetailIssue/${issue?.id}`);
        }
      }}
    >
      <div
        className={`w-[8px] my-2 h-[78px] rounded-md  border-2 ${
          issue.saverity === 'HIGH'
            ? 'border-[#C800A5] bg-[#C800A5]'
            : issue.saverity === 'LOW'
            ? 'border-[#01B59C] bg-[#01B59C]'
            : 'border-[#FF7301] bg-[#FF7301]'
        }`}
      />
      <div className="flex flex-col">
        <div className="min-w-[6rem] max-w-[180px] font-bold text-[12px] text-[#232323] truncate mb-1">
          {issue?.title}
        </div>
        <div className="min-w-[6rem] max-w-[130px] text-[12px] text-[#AFAFB9] truncate mb-2">
          {toSentenceCase(issue?.saverity)}
        </div>
        <div className="rounded-full text-[#C800A5] bg-[#C800A5] bg-opacity-[0.1] p-[2px] items-center text-[12px] flex flex-row w-[110px]">
          <img src={icons.icLastUpdate} alt="lastUpdate" height={15} width={15} className="mr-2" />
          {moment(issue?.due_date).format('DD-MMM-YYYY')}
        </div>
      </div>
      <div className="ml-auto">
        <div
          className={`text-[12px] font-bold italic  ${
            issue?.status === 'DRAFT'
              ? 'text-[#AAAAAA]'
              : issue?.status === 'OPEN'
              ? 'text-[#01B59C]'
              : 'text-[#FF7301]'
          }`}
        >
          {toSentenceCase(issue?.status)}
        </div>
      </div>
    </div>
  );
};

const ListIssuePage = () => {
  const { limits, page, pageSize, search, order_by } = useSelector(state => state.table);
  const [issues, setIssues] = useState(null);
  const [dataIssues, setDataIssues] = useState(null);
  const [pageRef, setPageRef] = useState(1);
  const [searchRef, setSearchRef] = useState('');
  const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });
  const [totalData, setTotalData] = useState(0);
  const [firstFetching, setFirstFetching] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let filterIssue = {
    status: { DRAFT: false, OPEN: false, CLOSED: false },
    saverity: { MEDIUM: false, HIGH: false, LOW: false },
    location: '',
    issue_type: ''
  };
  const [filterLength, setFilterLength] = useState(0);
  const [filterAttribute, setFilterAttribute] = useState(filterIssue);
  const [offlineFilterAttribute, setOfflineFilterAttribute] = useState(filterIssue);
  const headers = [
    {
      key: 'action',
      name: 'Action',
      isSortable: false,
      sortKey: null
    },
    {
      key: 'title',
      name: 'Title',
      isSortable: true
    },
    {
      key: 'issue_type',
      name: 'Issue Type',
      isSortable: true
    },
    {
      key: 'saverity',
      name: 'Saverity',
      isSortable: true
    },
    {
      key: 'status',
      name: 'Status',
      isSortable: true
    },
    {
      key: 'due_date',
      name: 'Due Date',
      isSortable: true
    }
  ];

  const offlineFilter = () => {
    let rawResultFilter = dataIssues?.data;
    let rawLength;
    rawLength = 0;
    let filter;
    filter = [];

    Object.entries(offlineFilterAttribute).forEach(([key, value]) => {
      if (value) {
        if (Object.keys(value).find(level => value[level] === true) !== undefined) {
          filter.push(Object.keys(value).find(level => value[level] === true));
          rawLength += 1;
        } else {
          filter.push('');
        }
      }
    });
    if (offlineFilterAttribute.issue_type) {
      rawLength = rawLength + 1;
    }
    if (offlineFilterAttribute.location) {
      rawLength = rawLength + 1;
    }
    if (offlineFilterAttribute?.order_by) {
      rawLength = rawLength + 1;
    }
    setFilterLength(rawLength);
    const query = {
      issue_type: offlineFilterAttribute.issue_type,
      saverity: Object.keys(offlineFilterAttribute.saverity).find(
        level => offlineFilterAttribute.saverity[level] === true
      ),
      status: Object.keys(offlineFilterAttribute.status).find(
        level => offlineFilterAttribute.status[level] === true
      ),
      location: offlineFilterAttribute.location,
      order_by: offlineFilterAttribute?.order_by
    };

    if (query.issue_type) {
      rawResultFilter = [
        ..._.filter(rawResultFilter, function (issue) {
          return issue.issue_type === query.issue_type;
        })
      ];
    }
    if (query.location) {
      rawResultFilter = [
        ..._.filter(rawResultFilter, function (issue) {
          return issue.location === query.location;
        })
      ];
    }
    if (query.saverity) {
      rawResultFilter = [
        ..._.filter(rawResultFilter, function (issue) {
          return issue.saverity === query.saverity;
        })
      ];
    }
    if (query.status) {
      rawResultFilter = [
        ..._.filter(rawResultFilter, function (issue) {
          return issue.status === query.status;
        })
      ];
    }
    if (query.order_by === 'asc') {
      rawResultFilter.sort((a, b) => {
        return new Date(a.created_at) - new Date(b.created_at);
      });
    }
    if (query.order_by === 'desc') {
      rawResultFilter.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
    }
    setIssues({ total_data: rawResultFilter?.length, total_page: 1, data: rawResultFilter });
  };

  const populateFilter = () => {
    let rawLength;
    rawLength = 0;
    let filter;
    filter = [];
    Object.entries(filterAttribute).forEach(([key, value]) => {
      if (Object.keys(value).find(level => value[level] === true) !== undefined) {
        filter.push(Object.keys(value).find(level => value[level] === true));
        rawLength += 1;
      } else {
        filter.push('');
      }
    });
    if (filterAttribute.issue_type) {
      rawLength = rawLength + 1;
    }
    if (filterAttribute.location) {
      rawLength = rawLength + 1;
    }
    if (filterAttribute?.order_by) {
      rawLength = rawLength + 1;
    }
    setFilterLength(rawLength);
    const query = {
      issue_type: filterAttribute.issue_type,
      saverity: filter[1],
      status: filter[0],
      location: filterAttribute.location
    };
    function clean(obj) {
      for (var propName in obj) {
        if (obj[propName] === '') {
          delete obj[propName];
        }
      }
      return obj;
    }
    let rawQuery = clean(query);
    if (rawQuery.hasOwnProperty('order_by')) {
      rawQuery = { ...query, sort_by: 'created_at' };
    }
    return rawQuery;
  };

  const loadMoreData = reloadPage => {
    const filter = {
      order_by,
      sort_by: 'created_at',
      limit: pageSize,
      page: reloadPage ? 1 : pageRef,
      search: searchRef
    };
    if (!searchRef) {
      delete filter.search;
    }
    get(apiUrls.ISSUE, {
      query: { ...filter, ...populateFilter() }
    })
      .then(res => {
        if (res.status === 200) {
          if (filter.page === 1) {
            setIssues(res.data);
            setDataIssues(res.data);
          } else {
            const result = issues.data.concat(res.data.data);
            setIssues({ ...issues, data: result });
            setDataIssues({ ...issues, data: result });
          }

          setTotalData(res.data.total_data);
          setPageRef(filter.page + 1);
          setFirstFetching(false);
        } else {
          setFirstFetching(false);
          toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />);
        }
      })
      .catch(_ => {
        setFirstFetching(false);
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
      });
  };
  const searchDataRef = ev => {
    switch (true) {
      case ev.key === 'Enter':
      case ev.target.value.length === 0:
        if (navigator.onLine) {
          setSearchRef(ev.target.value);
        } else {
          offlineSearch(ev.target.value);
        }
        break;
      default:
        break;
    }
  };
  const initData = () => {
    const filter = {
      order_by,
      sort_by: 'created_at',
      limit: pageSize,
      page,
      search
    };
    if (!search) {
      delete filter.search;
    }
    setIsFetching(true);
    get(apiUrls.ISSUE, {
      query: { ...filter, ...populateFilter() }
    })
      .then(res => {
        setIsFetching(false);
        if (res.status === 200) {
          if (page > res.data.total_page) {
            dispatch(setPage(1));
          }
          setIssues(res.data);
          setDataIssues(res.data);
          setFirstFetching(false);
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />);
        }
      })
      .catch(_ => {
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
        setIsFetching(false);
      });
  };

  const offlineSearch = value => {
    if (value.length) {
      let rawIssues = _.filter(issues?.data, function (task) {
        return task.title.toLowerCase().includes(value);
      });
      setIssues({ total_data: rawIssues.length, total_page: 1, data: rawIssues });
    } else {
      offlineFilter();
    }
  };

  useEffect(() => {
    if (!isSmallScreen) initData();
  }, [limits, page, pageSize, order_by, search, filterAttribute]);

  useEffect(() => {
    if (!firstFetching) setFirstFetching(true);
    if (isSmallScreen) loadMoreData(true);
  }, [searchRef, order_by, filterAttribute]);

  useEffect(() => {
    if (!navigator.onLine) offlineFilter();
  }, [offlineFilterAttribute]);

  const handleOnClickTaskSubmission = () => {
    navigate('/Task/IssueSubmission');
  };

  const toSentenceCase = str => {
    return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  return (
    <>
      {isSmallScreen ? (
        <div className="p-5 min-h-screen flex flex-col sm:hidden  mb-[120px]">
          <div className="py-5 max-h-[calc(100%-17rem)] flex flex-col gap-y-2 overflow-y-scroll">
            <div className="flex flex-row-reverse md:flex-row gap-4">
              <div
                className={`flex gap-1 border-[1px] border-[#463191] border-opacity-25 items-center justify-center min-w-[45px] w-fit h-[40px] rounded-lg ${
                  filterLength > 0 ? 'px-3' : ''
                }`}
              >
                {filterLength > 0 && (
                  <div className="py-0.5 px-2 rounded-md bg-[#6546C3] text-white font-semibold">
                    {filterLength}
                  </div>
                )}
                <img
                  src={icons.icFilter}
                  className="w-[26px] h-[26px]"
                  alt="Filter"
                  onClick={() => setFilterModal(true)}
                />
              </div>
              <div className="flex w-full md:min-w-[150px] h-[40px] bg-white border-[1px] items-center p-[12px] rounded-lg">
                <img src={icons.icSearch} className="w-[14px] h-[14px]" alt="Search" />
                <input
                  className="ml-2 focus:outline-none text-[12px] w-full"
                  key={search}
                  defaultValue={search}
                  placeholder="Search here..."
                  onKeyDown={searchDataRef}
                  onBlur={searchDataRef}
                />
              </div>
            </div>
            {firstFetching ? (
              <div className="flex justify-center h-[50vh] my-auto">
                <Loading />
              </div>
            ) : issues?.data?.length ? (
              <InfiniteScroll
                dataLength={issues?.data?.length || 0}
                next={() => loadMoreData(false)}
                hasMore={issues?.data?.length < totalData}
                className="!overflow-hidden"
                loader={
                  <div className="flex justify-center h-[100px] my-auto">
                    <Loading />
                  </div>
                }
              >
                {issues?.data?.map((issue, index) => (
                  <CardIssue issue={issue} key={index} toSentenceCase={toSentenceCase} />
                ))}
              </InfiniteScroll>
            ) : (
              <EmptyData
                title={'There is no document to show'}
                message={'You have not submitted any form yet'}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="hidden sm:flex sm:flex-col mb-[120px] lg:mb-0">
          <TableAccent
            {...{
              isFetching,
              tableHeaders: headers,
              data: issues,
              onFilterClick: () => setFilterModal(true),
              iconFilter: icons.icFilter,
              filterLength: filterLength,
              emptyDataTitle: 'There is no document to show',
              emptyDataDescription: 'You have not submitted any form yet',
              offlineSearch
            }}
          >
            {(item, tHead) => {
              switch (tHead.key) {
                case 'action':
                  return (
                    <div className="flex justify-center m-0 w-full">
                      <button
                        className="flex justify-center items-center bg-[#F1F1FD] w-[24px] h-[24px] rounded-md"
                        onClick={() => {
                          if (item?.status === 'DRAFT') {
                            navigate(`/Task/IssueSubmission/${item?.id}`);
                          } else {
                            navigate(`/Task/DetailIssue/${item?.id}`);
                          }
                        }}
                      >
                        <img src={icons.icArrowRight} alt="detail issue" />
                      </button>
                    </div>
                  );
                case 'status':
                  return (
                    <div
                      className={`text-[12px] font-bold italic ${
                        item[tHead.key] === 'DRAFT'
                          ? 'text-[#AAAAAA]'
                          : item[tHead.key] === 'OPEN'
                          ? 'text-[#01B59C]'
                          : 'text-[#FF7301]'
                      }`}
                    >
                      {toSentenceCase(item[tHead.key])}
                    </div>
                  );
                case 'saverity':
                  return (
                    <div
                      className={`flex flex-row items-center gap-2 text-[12px] font-bold ${
                        item[tHead.key] === 'HIGH'
                          ? 'text-[#C800A5]'
                          : item[tHead.key] === 'LOW'
                          ? 'text-[#01B59C]'
                          : 'text-[#FF7301]'
                      }`}
                    >
                      <div
                        className={`w-[2px] h-[24px] border-2 ${
                          item[tHead.key] === 'HIGH'
                            ? 'border-[#C800A5]'
                            : item[tHead.key] === 'LOW'
                            ? 'border-[#01B59C]'
                            : 'border-[#FF7301]'
                        }`}
                      />
                      {toSentenceCase(item[tHead.key])}
                    </div>
                  );
                case 'due_date':
                  return (
                    <div className="rounded-full text-[#C800A5] bg-[#C800A5] bg-opacity-[0.1] p-[2px]  items-center text-[12px] flex flex-row w-[110px]">
                      <img
                        src={icons.icLastUpdate}
                        alt="lastUpdate"
                        height={15}
                        width={15}
                        className="mr-2"
                      />
                      {moment(item[tHead.key]).format('DD-MMM-YYYY')}
                    </div>
                  );
                case 'issue_type':
                  return (
                    <span className="w-full truncate text-[12px] text-[#232323]">
                      {toSentenceCase(item[tHead.key])}
                    </span>
                  );
                default:
                  return (
                    <span className="w-full truncate text-[12px] text-[#232323]">
                      {item[tHead.key]}
                    </span>
                  );
              }
            }}
          </TableAccent>
        </div>
      )}
      <div className="fixed lg:bottom-5 right-5 bottom-[100px]">
        <button
          className="bg-[#6546C3] rounded-full text-white w-[44px] h-[44px] flex items-center justify-center"
          onClick={handleOnClickTaskSubmission}
        >
          <BsPlus size={35} />
        </button>
      </div>
      <FilterModal
        setOfflineFilterAttribute={setOfflineFilterAttribute}
        setPageRef={setPageRef}
        filterAttribute={filterAttribute}
        setFilterAttribute={setFilterAttribute}
        filterTasked={filterIssue}
        handleFilter={initData}
        showModal={filterModal}
        setShowModal={setFilterModal}
      />
    </>
  );
};
export default ListIssuePage;
