import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setOrderBy } from '../../../../../store/slices/table';
import { icons, apiUrls } from '../../../../../constants';
import Select from 'react-select';
import { get } from '../../../../../api/base';
import { toast } from 'react-toastify';
import { Toast } from '../../../../../components';

const FilterModal = ({
  showModal,
  setShowModal,
  filterAttribute,
  setFilterAttribute,
  filterTasked,
  setPageRef,
  setOfflineFilterAttribute
}) => {
  const [assigneeList, setAssigneeList] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState('');
  const [tempFilterAttribute, setTempFilterAttribute] = useState({
    order_by: null,
    ...filterAttribute
  });
  const dispatch = useDispatch();

  const sortBy = value => {
    setTempFilterAttribute({ ...tempFilterAttribute, order_by: value });
  };
  const reset = () => {
    setSelectedAssignee('');
    setTempFilterAttribute({ order_by: null, ...filterTasked });
  };

  const fetchUsers = () => {
    get(apiUrls.TASK_USERS).then(response => {
      const { status } = response;
      if (status === 200) {
        let rawUsers = response.data.users;
        let rawFullName = rawUsers.map(user => {
          return { id: user.id, full_name: user.full_name };
        });
        setAssigneeList(rawFullName);
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={'Fetch user failed'} />);
      }
    });
  };

  useEffect(() => {
    if (showModal) fetchUsers();
  }, [showModal]);

  return (
    <div>
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px] h-fit p-0">
          <div className="bg-white p-6">
            <div className="flex flex-row items-center justify-between">
              <div className="text-[18px] font-semibold">Filter</div>
              <img
                src={icons.icCloseModal}
                className="w-6 h-6 cursor-pointer"
                alt={'close-modal'}
                onClick={() => setShowModal(false)}
              />
            </div>
            <hr className="mt-2 mb-6" />
            <div className="flex items-center gap-2 mb-2">
              <input
                type="checkbox"
                className="accent-[#6546C3]"
                checked={tempFilterAttribute?.myDocument}
                onChange={() =>
                  setTempFilterAttribute({
                    ...tempFilterAttribute,
                    myDocument: !tempFilterAttribute?.myDocument
                  })
                }
              />
              <div className="text-[12px]">My Document</div>
            </div>
            <div className="flex items-center gap-2 mb-2">
              <input
                type="checkbox"
                className="accent-[#6546C3]"
                checked={tempFilterAttribute?.myGroup}
                onChange={() =>
                  setTempFilterAttribute({
                    ...tempFilterAttribute,
                    myGroup: !tempFilterAttribute?.myGroup
                  })
                }
              />
              <div className="text-[12px]">My Group</div>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="accent-[#6546C3]"
                checked={tempFilterAttribute?.myWorkingLocation}
                onChange={() =>
                  setTempFilterAttribute({
                    ...tempFilterAttribute,
                    myWorkingLocation: !tempFilterAttribute?.myWorkingLocation
                  })
                }
              />
              <div className="text-[12px]">My Working Location</div>
            </div>

            <div className="font-semibold text-[12px] mt-[28px] mb-[16px]">Assignee</div>
            <Select
              onChange={selectedOptions => setSelectedAssignee(selectedOptions)}
              getOptionLabel={option => option.full_name}
              getOptionValue={option => option.id}
              options={assigneeList}
              name="Assignee"
              className={
                'input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4'
              }
              placeholder="Select assignee"
              isClearable
              value={selectedAssignee}
              styles={{
                option: provided => ({
                  ...provided
                }),
                control: provided => ({
                  ...provided,
                  ':focus': { borderColor: '#6546C3' },
                  ':active': { borderColor: '#6546C3' },
                  ':hover': { borderColor: '#6546C3' },
                  paddingLeft: 4,
                  boxShadow: 'none'
                }),
                dropdownIndicator: provided => ({
                  ...provided,
                  color: '#6546C3'
                }),
                indicatorSeparator: () => ({}),
                valueContainer: provided => ({
                  ...provided
                })
              }}
            />

            <div className="font-semibold text-[12px] mt-[28px] mb-[16px]">Sort</div>
            <div
              className={`flex flex-row cursor-pointer border border-2 rounded-md p-2 items-center  ${
                tempFilterAttribute?.order_by === 'desc'
                  ? 'border-[#6546C3] bg-[#6546C3] bg-opacity-[0.2]'
                  : ''
              }`}
              onClick={() =>
                tempFilterAttribute?.order_by === 'desc' ? sortBy(null) : sortBy('desc')
              }
            >
              <div
                className={`text-[12px] ${
                  tempFilterAttribute?.order_by === 'desc' ? 'text-[#6546C3] font-semibold' : ''
                }`}
              >
                Newest on top
              </div>
              <div
                className={`ml-auto border border-2 rounded-full w-[16px] h-[16px] border-[#6546C3] ${
                  tempFilterAttribute?.order_by === 'desc' ? 'bg-[#6546C3]' : 'bg-transparent'
                }`}
              />
            </div>

            <div
              className={`flex flex-row cursor-pointer border border-2 rounded-md p-2 items-center mt-[8px] ${
                tempFilterAttribute?.order_by === 'asc'
                  ? 'border-[#6546C3] bg-[#6546C3] bg-opacity-[0.2]'
                  : ''
              }`}
              onClick={() =>
                tempFilterAttribute?.order_by === 'asc' ? sortBy(null) : sortBy('asc')
              }
            >
              <div
                className={`text-[12px] ${
                  tempFilterAttribute?.order_by === 'asc' ? 'text-[#6546C3] font-semibold' : ''
                }`}
              >
                Oldest on top
              </div>
              <div
                className={`ml-auto border border-2 rounded-full w-[16px] h-[16px] border-[#6546C3] ${
                  tempFilterAttribute?.order_by === 'asc' ? 'bg-[#6546C3]' : 'bg-transparent'
                }`}
              />
            </div>

            <div className="font-semibold text-[12px] mt-[28px] mb-[16px]">Status</div>
            <div className="flex flex-row justify-between gap-6">
              <div
                className={`flex flex-row flex-grow cursor-pointer border border-2 rounded-md p-2 items-center justify-center  ${
                  tempFilterAttribute?.draft ? 'border-[#6546C3] bg-[#6546C3] bg-opacity-[0.2]' : ''
                }`}
                onClick={() =>
                  setTempFilterAttribute({
                    ...tempFilterAttribute,
                    draft: !tempFilterAttribute?.draft
                  })
                }
              >
                <div
                  className={`text-[12px] ${
                    tempFilterAttribute?.draft ? 'text-[#6546C3] font-semibold' : 'text-[#AAAAAA]'
                  }`}
                >
                  Draft
                </div>
              </div>
              <div
                className={`flex flex-row flex-grow cursor-pointer border border-2 rounded-md p-2 items-center justify-center  ${
                  tempFilterAttribute?.open ? 'border-[#6546C3] bg-[#6546C3] bg-opacity-[0.2]' : ''
                }`}
                onClick={() =>
                  setTempFilterAttribute({
                    ...tempFilterAttribute,
                    open: !tempFilterAttribute?.open
                  })
                }
              >
                <div
                  className={`text-[12px] ${
                    tempFilterAttribute?.open ? 'text-[#6546C3] font-semibold' : 'text-[#AAAAAA]'
                  }`}
                >
                  Open
                </div>
              </div>
              <div
                className={`flex flex-row flex-grow cursor-pointer border border-2 rounded-md p-2 items-center justify-center  ${
                  tempFilterAttribute?.closed
                    ? 'border-[#6546C3] bg-[#6546C3] bg-opacity-[0.2]'
                    : ''
                }`}
                onClick={() =>
                  setTempFilterAttribute({
                    ...tempFilterAttribute,
                    closed: !tempFilterAttribute?.closed
                  })
                }
              >
                <div
                  className={`text-[12px] ${
                    tempFilterAttribute?.closed ? 'text-[#6546C3] font-semibold' : 'text-[#AAAAAA]'
                  }`}
                >
                  Closed
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-center sm:justify-end bg-[#EBEDF8] w-full">
            <button
              className="rounded-lg border-2 border-[#6546C3] px-7 py-2 m-2 flex text-[#6546C3] font-semibold text-[14px]"
              onClick={reset}
            >
              Reset
            </button>
            <button
              className="rounded-lg border-2 bg-[#6546C3] px-6 py-2 m-2 flex text-white font-semibold text-[14px]"
              onClick={() => {
                if (navigator.onLine) {
                  dispatch(setOrderBy(tempFilterAttribute.order_by || 'desc'));
                  setFilterAttribute({
                    ...tempFilterAttribute,
                    assignee: selectedAssignee?.id || '',
                    earlier: tempFilterAttribute.order_by === 'asc',
                    latest: tempFilterAttribute.order_by === 'desc'
                  });
                  setShowModal(false);
                  setPageRef(1);
                } else {
                  setShowModal(false);
                  let attribute = {
                    ...tempFilterAttribute,
                    assignee: selectedAssignee?.id || '',
                    earlier: tempFilterAttribute.order_by === 'asc',
                    latest: tempFilterAttribute.order_by === 'desc'
                  };
                  setOfflineFilterAttribute(attribute);
                }
              }}
            >
              Filter Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
