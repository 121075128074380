import { EmptyData, Loading } from '../../../../components';
import { icons } from '../../../../constants';

const ModuleInfoModal = ({ showModal, setShowModal, detailModule, handleOpenApp, isFetching }) => {
  return (
    <div>
      <input
        type="checkbox"
        id="menu-modal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />

      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div
          className="modal-box flex flex-col bg-white p-4 sm:p-6 w-screen sm:w-[512px] md:w-[780px] h-[75vh] sm:h-fit"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex flex-row gap-4">
            <div className="w-6 h-6 bg-[#EBEDF8] rounded-md flex justify-center items-center">
              <img src={detailModule?.icon} className="w-4 h-4" alt={detailModule?.name} />
            </div>

            <div className="flex-grow flex-col font-semibold text-[18px] text-[#232323]">
              {detailModule?.name}
              <div className="bg-[#F1F1FD] text-[12px] text-[#6546C3] font-normal px-2 py-1 rounded-md w-fit flex justify-center items-center">
                {detailModule?.application?.name}
              </div>
            </div>
            <img
              src={icons.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              alt={'close-modal'}
              onClick={() => setShowModal(false)}
            />
          </div>

          <hr className="my-4" />

          {detailModule?.description ? (
            <div className="flex flex-1 flex-col justify-between">
              <div className="text-[12px] text-[#232323] flex-grow max-h-[230px] md:max-h-[350px] overflow-y-auto">
                <div dangerouslySetInnerHTML={{ __html: detailModule?.description }} />
              </div>
              <button
                className="bg-[#6546C3] rounded-lg py-[10px] mt-4 w-full font-semibold text-white 
                text-[14px] flex justify-center items-center"
                onClick={() =>
                  handleOpenApp(
                    detailModule.name,
                    detailModule.deeplink,
                    detailModule.application?.authenticationParameter,
                    detailModule.application?.applicationLink,
                    detailModule.application?.authenticationApiLink
                  )
                }
              >
                Go to apps
              </button>
            </div>
          ) : isFetching ? (
            <div className="h-[200px] flex items-center justify-center">
              <Loading />
            </div>
          ) : (
            <EmptyData
              title="There is no description added about this module"
              message="Should have any inquiry please send email to"
              contact="ithelpdesk@banpuindo.co.id"
              label="Send email"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ModuleInfoModal;
