import Webcam from 'react-webcam';

import { useCallback, useRef, useState } from 'react';
import { apiUrls, icons } from '../../../../../constants';
import { IoCameraReverseOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { setShowLoading } from '../../../../../store/slices/popup';
import { put } from '../../../../../api/base';
import { toast } from 'react-toastify';
import { Toast } from '../../../../../components';
import { useNavigate } from 'react-router-dom';
const ImageFromCamera = ({ showModal, setShowModal }) => {
  const user = useSelector(state => state.user.userData);
  const [facingMode, setFacingMode] = useState('user');
  const webcamRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const videoConstraints = {
    width: 420,
    height: 420,
    facingMode: facingMode
  };

  const updateUser = picture => {
    dispatch(setShowLoading(true));
    put(`${apiUrls.UPDATE_USER}/${user.id}`, {
      picture: picture
    })
      .then(response => {
        dispatch(setShowLoading(false));
        if (response.status === 200) {
          toast.success(<Toast message={`Success`} detailedMessage={`Update photo successful`} />);
          navigate(0);
        } else {
          toast.error(
            <Toast message={`Error`} detailedMessage={`${response?.data?.error?.message}`} />
          );
        }
      })
      .catch(e => {
        dispatch(setShowLoading(false));
        toast.error(<Toast message={`Error`} detailedMessage={e} />);
      });
  };

  function base64ToBlob(base64) {
    const parts = base64.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uint8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uint8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uint8Array], { type: contentType });
  }

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();

    const blob = base64ToBlob(imageSrc);

    const fileName = 'picture.jpg';
    const fileType = 'image/jpeg';
    const file = new File([blob], fileName, { type: fileType });

    const maxSize = 5 * 1048576;

    if (file.size > maxSize) {
      toast.error(
        <Toast
          message={'Error'}
          detailedMessage={`Cannot update profile picture because file size is more than 5 mb`}
        />
      );
      return;
    }

    updateUser(imageSrc);
  }, [webcamRef]);

  const switchCamera = useCallback(() => {
    setFacingMode(prevState => (prevState === 'user' ? 'environment' : 'user'));
  }, []);
  return (
    <div>
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px] h-[75vh] sm:h-fit">
          <div className="flex flex-row items-center justify-between">
            <div className="text-[18px] font-semibold">From Camera</div>
            <img
              src={icons.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              alt={'close-modal'}
              onClick={() => setShowModal(false)}
            />
          </div>
          <hr className="mt-2 mb-2" />
          {showModal && (
            <div className="flex flex-col bg-white w-full h-[500px] p-0 items-center justify-center relative">
              <Webcam
                className="rounded-2xl"
                audio={false}
                mirrored={facingMode === 'user'}
                height={500}
                width={400}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
              <IoCameraReverseOutline
                className="ml-auto absolute  w-fit h-[30px] sm:h-[40px] mr-4 right-5 bottom-0"
                color="#6546C3"
                onClick={switchCamera}
              />
            </div>
          )}

          <div className="w-full h-[68px] rounded-full bg-[#232323]/[0.5] flex items-center justify-center cursor-pointer">
            <img
              src={icons.icCapture}
              className="w-[66px] h-[66px]"
              onClick={capture}
              alt="capture"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageFromCamera;
