import parse from 'html-react-parser';
import { useLocation, useNavigate } from 'react-router-dom';
const StaticField = () => {
  const location = useLocation();
  const { content } = location.state;
  const navigate = useNavigate();
  return (
    <>
      <div className="bg-white rounded-2xl justify-between p-[16px] mb-[40px] mx-[24px]">
        {parse(content)}
      </div>
      <button
        className="rounded-2xl border-2 border-[#6546C3] px-14 py-2 m-2 ml-auto flex text-[#6546C3]"
        onClick={() => navigate(-1)}
      >
        Back
      </button>
    </>
  );
};

export default StaticField;
