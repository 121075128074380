import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isFromLogout: false
};

const settingsSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    setIsFromLogout: (state, action) => {
      state.isFromLogout = action.payload;
    }
  }
});

export const { setIsFromLogout } = settingsSlice.actions;
export default settingsSlice.reducer;
