import { icons } from '../../../../../../../constants';
import Field from '../../../Field';
import { toast } from 'react-toastify';
import { Toast } from '../../../../../../../components';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const ModalField = ({
  showModal,
  setShowModal,
  sections,
  submitFormTabletLayout,
  sectionIndex,
  fieldIndex,
  sectionId,
  subSectionIndex,
  field,
  isHaveAttachment,
  onChangeValueField,
  onChangeCheckBox,
  onChangeRadio,
  onAddAttachment,
  removeFile,
  onChangeValueSubSection,
  onChangeSubCheckBox,
  onChangeSubRadio,
  onAddSubAttachment,
  removeSubSectionFile,
  additionalTitle,
  setShowAttachmentModal
}) => {
  const isStaticField = field?.field_type.value === 'STATIC_FIELD';
  const statusForm = useSelector(state => state.form.finishStoreForm);
  const isCheckboxAttachment = () => {
    return field?.field_type.value === 'CHECKBOX_ATTACHMENT';
  };

  const isRadioButtonAttachment = () => {
    return field?.field_type.value === 'RADIO_BUTTON_ATTACHMENT';
  };

  let additional =
    field?.additional_option.length > 0
      ? field?.additional_option.find(e => e.title === additionalTitle)
      : null;

  const checkValue = () => {
    if (additional && field.mandatory && (!additional.value || !additional.value.length)) {
      return false;
    }
    if (
      !additional &&
      field.mandatory &&
      (!field.value || (Array.isArray(field.value) && !field.value.length))
    ) {
      return false;
    }

    if (isRadioButtonAttachment()) {
      const selectedOption = field.options.find(
        option => option.label === (additionalTitle ? additional.value : field.value)
      );
      if (selectedOption?.mandatory && !additionalTitle) {
        return Array.isArray(field.attachment_value)
          ? field.attachment_value.length > 0
          : field.attachment_value?._parts.length > 0;
      } else if (selectedOption?.mandatory && additionalTitle) {
        return Array.isArray(additional.attachment_value)
          ? additional.attachment_value.length > 0
          : additional.attachment_value?._parts.length > 0;
      }
    } else if (isCheckboxAttachment()) {
      if (field.value.length > 0) {
        return field.options.every(option =>
          field.value.includes(option.label)
            ? !option.mandatory ||
              (Array.isArray(field.attachment_value)
                ? field.attachment_value.length > 0
                : field.attachment_value?._parts.length > 0)
            : true
        );
      }
      if (additional.value.length > 0) {
        return field.options.every(option =>
          additional.value.includes(option.label)
            ? !option.mandatory ||
              (Array.isArray(field.attachment_value)
                ? additional.attachment_value.length > 0
                : additional.attachment_value?._parts.length > 0)
            : true
        );
      }
    }
    return true;
  };

  const cancel = () => {
    setShowModal(false);
    if (!field?.mandatory) {
      if (_.isEmpty(additional)) {
        field.value = '';
      } else {
        additional.value = '';
      }
    }
  };

  useEffect(() => {
    if (statusForm === 'Success') {
      cancel();
    }
  }, [statusForm]);

  const submit = () => {
    if (checkValue()) {
      if (isHaveAttachment) {
        setShowAttachmentModal(true);
      } else {
        submitFormTabletLayout({
          sections,
          title: additionalTitle ? additionalTitle : field?.field_title
        });
      }
    } else {
      toast.error(
        <Toast
          message={'Error'}
          detailedMessage={`${additionalTitle ? additionalTitle : field?.field_title} is mandatory`}
        />
      );
    }
  };

  const isSubsection = () => subSectionIndex !== -1;

  return (
    <div>
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px]  sm:h-fit">
          <div className="bg-white">
            <div className="flex items-center justify-between flex-wrap">
              <div className="font-semibold text-[18px] text-[#C800A5]">
                {additionalTitle ? additionalTitle : field?.field_title}
              </div>
              <img
                src={icons.icCloseModal}
                className="w-6 h-6 cursor-pointer"
                alt={'close-modal'}
                onClick={cancel}
              />
            </div>
            <hr className="mt-2 mb-6" />
            <Field
              tabletLayout={true}
              key={field?.field_id}
              additionalTitle={additionalTitle}
              field={field}
              submitted={false}
              sectionId={sectionId}
              onChange={(event, additionalIndex) => {
                isSubsection()
                  ? onChangeValueSubSection(
                      event,
                      additionalIndex,
                      fieldIndex,
                      subSectionIndex,
                      sectionIndex
                    )
                  : onChangeValueField(event, additionalIndex, fieldIndex, sectionIndex);
              }}
              onChangeCheck={(e, label, index) => {
                isSubsection()
                  ? onChangeSubCheckBox(e, label, index, fieldIndex, subSectionIndex, sectionIndex)
                  : onChangeCheckBox(e, label, index, fieldIndex, null, sectionIndex);
              }}
              onChangeRadioButton={(e, label, index) => {
                isSubsection()
                  ? onChangeSubRadio(e, label, index, fieldIndex, subSectionIndex, sectionIndex)
                  : onChangeRadio(e, label, index, fieldIndex, null, sectionIndex);
              }}
              addAttachment={(file, additionalIndex) => {
                isSubsection()
                  ? onAddSubAttachment(
                      file,
                      additionalIndex,
                      fieldIndex,
                      subSectionIndex,
                      sectionIndex
                    )
                  : onAddAttachment(file, additionalIndex, fieldIndex, null, sectionIndex);
              }}
              onRemoveFile={(indexFile, additionalIndex) => {
                isSubsection()
                  ? removeSubSectionFile(
                      indexFile,
                      additionalIndex,
                      fieldIndex,
                      subSectionIndex,
                      sectionIndex
                    )
                  : removeFile(indexFile, additionalIndex, fieldIndex, null, sectionIndex);
              }}
            />
          </div>
          {!isStaticField && (
            <div className="flex justify-center sm:justify-end w-full flex-row mt-9 gap-6">
              <button
                className="bg-transparent border-2 border-[#6546C3] rounded-lg py-3 px-8 font-semibold text-[#6546C3] text-[14px]"
                onClick={cancel}
              >
                Cancel
              </button>
              <button
                className="bg-[#6546C3] border-2 border-[#6546C3] rounded-lg py-3 px-8 font-semibold text-white text-[14px]r"
                onClick={submit}
              >
                Submit
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalField;
