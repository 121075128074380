import { useState, useEffect } from 'react';
import { apiUrls, icons } from '../../../../../../constants';
import { get, post } from '../../../../../../api/base';
import { EmptyData, SuccessPopUp, Toast } from '../../../../../../components';
import { toast } from 'react-toastify';
import { IoCloseOutline } from 'react-icons/io5';
import Autosuggest from 'react-autosuggest';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setShowLoading } from '../../../../../../store/slices/popup';

const TagModal = ({ showModal, setShowModal, taskId }) => {
  const [tagSuggestions, setTagSuggestions] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagName, setTagName] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchCurrentTags = () => {
    get(`${apiUrls.TASK}/${taskId}/tags`).then(response => {
      if (response.status === 200) {
        setTags(response.data);
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={'Failed to fetch tags'} />);
      }
    });
  };

  const fetchTagSuggestions = () => {
    get(apiUrls.TASK_TAGS, { query: { search: tagName } }).then(response => {
      const { status } = response;
      if (status === 200) {
        setTagSuggestions(response.data);
      } else {
        toast.error(
          <Toast message={'Error'} detailedMessage={'Failed to fetch tag suggestions'} />
        );
      }
    });
  };

  useEffect(() => {
    if (showModal) {
      fetchCurrentTags();
      fetchTagSuggestions();
    }
  }, [showModal]);

  const handleOnAddTag = () => {
    setTagName('');
    setTags(prevTags => [...prevTags, tagName]);
  };

  const handleDeleteTag = index => {
    const newTags = tags.filter((tag, i) => i !== index);
    setTags(newTags);
  };

  const saveTags = () => {
    dispatch(setShowLoading(true));
    post(`${apiUrls.TASK}/${taskId}/tags`, { tags: tags }).then(response => {
      const { status } = response;
      if (status === 200) {
        dispatch(setShowLoading(false));
        setShowModal(false);
        setShowSuccessModal(true);
      } else {
        toast.error(
          <Toast message={'Error'} detailedMessage={'Save tags failed, please try again'} />
        );
      }
    });
  };

  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength >= 3
      ? tagSuggestions.filter(
          suggestion => suggestion.name.toLowerCase().slice(0, inputLength) === inputValue
        )
      : [];
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onChange = (_, { newValue }) => {
    setTagName(newValue);
  };

  const getSuggestionValue = suggestion => suggestion.name;
  const renderSuggestion = suggestion => {
    return <div>{suggestion.name}</div>;
  };

  const inputProps = {
    placeholder: 'Enter tag name',
    value: tagName,
    onChange,
    className: 'w-full focus:outline-none'
  };

  const customAutosuggestStyle = {
    input: {
      border: '1px solid #DEDEDE',
      borderRadius: '8px',
      fontSize: '12px',
      padding: '12px'
    },
    inputFocused: {
      border: '1px solid #6546C3'
    },
    suggestion: {
      cursor: 'pointer',
      padding: '8px 0px 8px 15px'
    },
    suggestionHighlighted: {
      backgroundColor: 'rgba(59, 130, 246, 0.2)'
    },
    suggestionsContainerOpen: {
      boxShadow: '0 5px 15px 0 rgba(0, 0, 0, 0.15)',
      borderRadius: '8px',
      fontSize: '12px',
      marginTop: '8px',
      maxHeight: '150px',
      overflow: 'auto'
    },
    suggestionsList: {
      listStyle: 'none',
      margin: 0,
      padding: 0
    }
  };

  return (
    <div>
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px] min-h-[500px] max-h-[75vh] p-0">
          <div className="bg-white p-6 flex flex-col flex-1">
            <div className="flex flex-row items-center justify-between">
              <div className="text-[18px] font-semibold">Tag Manage</div>
              <img
                src={icons.icCloseModal}
                className="w-6 h-6 cursor-pointer"
                alt={'close-modal'}
                onClick={() => setShowModal(false)}
              />
            </div>
            <hr className="flex mt-2 mb-6" />
            <div className="flex flex-col flex-1">
              <div className="text-[12px] font-semibold mb-2">Tag Name</div>
              <div className="flex gap-6">
                <div className="flex-1 w-full">
                  <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                    theme={customAutosuggestStyle}
                  />
                </div>

                <div
                  className="w-[40px] h-[40px] bg-[#6546C3] rounded-[12px] flex items-center justify-center cursor-pointer"
                  onClick={handleOnAddTag}
                >
                  <img src={icons.icPlus} alt="add tag" className="w-3 h-3" />
                </div>
              </div>
              {tags.length ? (
                <div className="flex flex-col justify-between gap-2 flex-1">
                  <div className="flex flex-row flex-wrap gap-4 mt-6">
                    {tags?.map((tag, index) => (
                      <div
                        key={index}
                        className="w-fit border border-1 rounded-lg p-2 items-center justify-center border-[#6546C3] bg-[#6546C3] bg-opacity-[0.2]
                      flex gap-3"
                      >
                        <div className="font-semibold text-[12px] text-[#6546C3]">{tag}</div>
                        <IoCloseOutline
                          size={20}
                          color="#6546C3"
                          className="cursor-pointer"
                          onClick={() => handleDeleteTag(index)}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="flex flex-row justify-center sm:justify-end w-full">
                    <button
                      className="rounded-lg border-2 border-[#6546C3] px-7 py-2 m-2 flex text-[#6546C3] font-semibold text-[14px]"
                      onClick={() => setShowModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="rounded-lg border-2 bg-[#6546C3] px-10 py-2 m-2 flex text-white font-semibold text-[14px]"
                      onClick={saveTags}
                    >
                      Save
                    </button>
                  </div>
                </div>
              ) : (
                <div className="mt-8">
                  <EmptyData
                    title={'Not Tag Display Here'}
                    message={'Please enter tag name now!'}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <SuccessPopUp
        show={showSuccessModal}
        title={'Successfully'}
        message={'Hooray! You have successfully save tag'}
        labelSuccessButton={'Ok, Back to detail'}
        handleSuccessButton={() => {
          setShowSuccessModal(false);
          navigate(0);
        }}
      />
    </div>
  );
};

export default TagModal;
