import { icons } from '../../../../../../constants';

const RequestorInfoModal = ({ showModal, setShowModal, requestorInfo }) => {
  return (
    <div>
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px]  sm:h-fit">
          <div className="bg-white">
            <div className="flex items-center justify-between">
              <div className="font-semibold text-[18px] text-[#C800A5]">Requestor Info</div>
              <img
                src={icons.icCloseModal}
                className="w-6 h-6 cursor-pointer"
                alt={'close-modal'}
                onClick={() => setShowModal(false)}
              />
            </div>
            <hr className="mt-2 mb-6" />

            <div className="flex flex-col gap-4">
              <div className="flex flex-1 flex-col gap-2">
                <div className="font-semibold text-[12px]">Name</div>
                <input
                  className={
                    'text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]'
                  }
                  placeholder={'Name'}
                  value={requestorInfo?.full_name || requestorInfo?.name || '-'}
                  disabled
                />
              </div>
              <div className="flex flex-1 flex-col gap-2">
                <div className="font-semibold text-[12px]">Email</div>
                <input
                  className={
                    'text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]'
                  }
                  placeholder={'Email'}
                  value={requestorInfo?.email || '-'}
                  disabled
                />
              </div>
              <div className="flex flex-1 flex-col gap-2">
                <div className="font-semibold text-[12px]">Company</div>
                <input
                  className={
                    'text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]'
                  }
                  value={requestorInfo?.company || '-'}
                  disabled
                />
              </div>
              <div className="flex flex-1 flex-col gap-2">
                <div className="font-semibold text-[12px]">Work Location</div>
                <input
                  className={
                    'text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]'
                  }
                  value={requestorInfo?.work_location || '-'}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RequestorInfoModal;
