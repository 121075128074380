import { useSelector } from 'react-redux';

const LoadingPopup = () => {
  const show = useSelector(state => state.popup.showPopupLoading);

  return (
    <div>
      <input
        type="checkbox"
        className="modal-toggle"
        checked={show}
        onChange={e => e.preventDefault()}
      />
      <div className="modal bg-[#232323]/[0.5] overflow-y-auto py-6">
        <div
          className="modal-box bg-white relative p-2 w-[50px] h-[50px] lg:w-[80px] lg:h-[80px] flex justify-center items-center"
          onClick={e => e.stopPropagation()}
        >
          <span className="loading loading-dots loading-sm lg:loading-lg text-[#6546C3]"></span>
        </div>
      </div>
    </div>
  );
};

export default LoadingPopup;
