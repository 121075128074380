import { useEffect, useState } from 'react';
import { icons } from '../../../constants';
import Select from 'react-select';
import Loading from '../../Loading';
import EmptyData from '../../EmptyData';

const GroupAssigneeList = ({
  groups,
  getGroups,
  setSelectedGroups,
  selectedGroups,
  workingLocationOptions,
  workingLocation,
  setWorkingLocation,
  isFetching,
  fullSelectedGroups,
  setFullSelectedGroups
}) => {
  const [search, setSearch] = useState('');

  const handleOnGroupPress = item => {
    const userId = item.id;
    let newSelectedGroups = [];
    let newFullGroups = [];
    if (selectedGroups.includes(userId)) {
      newSelectedGroups = selectedGroups.filter(oldSelectedId => oldSelectedId !== userId);
      newFullGroups = fullSelectedGroups.filter(group => group.id !== item.id);
    } else {
      newSelectedGroups = [...selectedGroups, userId];
      newFullGroups = fullSelectedGroups.concat(groups.filter(group => group.id === item.id));
    }
    setFullSelectedGroups(newFullGroups);
    setSelectedGroups(newSelectedGroups);
  };

  const searchData = event => {
    switch (true) {
      case event.key === 'Enter':
      case event.target.value.length === 0:
        setSearch(event.target.value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getGroups(search);
  }, [search]);

  const CardGroups = ({ group }) => {
    return (
      <div
        className={`drop-shadow-md flex items-center lg:w-[32vw] rounded-md p-[16px] on-pointer ${
          selectedGroups.includes(group.id) ? 'bg-[#EBEDF8]' : 'bg-white'
        }`}
        onClick={() => handleOnGroupPress(group)}
      >
        <img
          src={icons.icGroup}
          alt="photoProfile"
          className=" bg-white rounded-full h-[36px] w-[36px] mr-[12px]"
        />
        <div className="flex flex-col px-[12px]">
          <div className="font-semibold text-[12px]">{group.name}</div>
          <div className="text-[#AFAFB9] text-[12px]">{`Task to ${group.name}`}</div>
        </div>
        {selectedGroups.includes(group.id) ? (
          <img src={icons.icPrimaryChecklist} className="h-[16px] ml-auto" alt="choose" />
        ) : (
          <div className="h-[16px]" />
        )}
      </div>
    );
  };

  return (
    <div className=" max-h-[80vh] mt-[20px] flex flex-col justify-between p-[16px] mb-[40px] gap-4">
      <div className="flex flex-row items-center justify-between">
        <div className="font-semibold text-[16px]">Groups List</div>
        <div className="flex h-[40px] bg-white border-[1px] items-center p-[12px] rounded-lg sm:w-[25vw] w-[45vw]">
          <img src={icons.icSearch} className="w-[14px] h-[14px]" alt="Search" />
          <input
            className="ml-2 focus:outline-none text-[12px] w-full"
            key={search}
            defaultValue={search}
            placeholder="Search here..."
            onKeyDown={searchData}
            onBlur={searchData}
          />
        </div>
      </div>
      <div className="flex flex-1 flex-col gap-2">
        <div className="font-semibold text-[12px]">Working Location</div>
        <Select
          getOptionLabel={option => option.name}
          getOptionValue={option => option.name}
          isSearchable={false}
          options={workingLocationOptions}
          isClearable
          value={workingLocation}
          onChange={selectedOption => setWorkingLocation(selectedOption)}
          className={
            'flex-1 text-[12px] border rounded-lg focus:outline-none focus:border-[#6546C3] sm:w-[35vw]'
          }
          placeholder={'Select working location'}
          styles={{
            control: provided => ({
              ...provided,
              borderColor: 'none',
              ':focus': { borderColor: '#6546C3' },
              ':active': { borderColor: '#6546C3' },
              ':hover': { borderColor: '#6546C3' },
              paddingLeft: 4,
              boxShadow: 'none'
            }),
            dropdownIndicator: base => ({
              ...base,
              color: '#6546C3',
              marginRight: '4px',
              ':hover': { color: '#6546C3' }
            }),
            indicatorSeparator: () => ({}),
            valueContainer: provided => ({
              ...provided
            }),
            multiValueLabel: provided => ({
              ...provided,
              color: 'black',
              fontFamily: 'poppins'
            }),
            multiValueRemove: provided => ({
              ...provided,
              color: '#fff'
            }),
            multiValue: provided => ({
              ...provided,
              backgroundColor: '#6546C3',
              borderRadius: 8
            })
          }}
        />
      </div>
      {isFetching ? (
        <div className="flex justify-center items-center h-[55vh] mx-auto">
          <Loading />
        </div>
      ) : groups.length ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2  max-h-[55vh] overflow-auto">
          {groups.map((group, index) => (
            <CardGroups group={group} key={index} />
          ))}
        </div>
      ) : (
        <EmptyData title={'Not Found'} />
      )}
    </div>
  );
};
export default GroupAssigneeList;
