import { useDispatch, useSelector } from 'react-redux';
import { setMoreModal } from '../../../../../../store/slices/form';
import { icons } from '../../../../../../constants';
import { downloadForm } from '../../../components/DownloadForm';
import { HiOutlineLocationMarker } from 'react-icons/hi';

const ActionModal = ({
  createIssueModal,
  id,
  title,
  approvalHistoryModal,
  enableEdit,
  isEditingMode,
  handleEditClicked,
  handleShareClicked,
  disableClickDuplicate,
  handleDuplicateClicked,
  enableCreateSubResponse,
  handleCreateSubResponseClicked,
  enableShowLocation,
  handleShowLocationClicked
}) => {
  const dispatch = useDispatch();
  const { moreModal } = useSelector(state => state.form);
  return (
    <div>
      <input
        type="checkbox"
        id="filterModal"
        checked={moreModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle  bg-[#232323]/[0.5]">
        <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px]  sm:h-fit">
          <div className="bg-white">
            <div className="flex items-center justify-between">
              <div className="font-semibold">Action</div>
              <img
                src={icons.icCloseModal}
                className="w-6 h-6 cursor-pointer"
                alt={'close-modal'}
                onClick={() => dispatch(setMoreModal(false))}
              />
            </div>
            <hr className="mt-2 mb-6" />
            <div className="flex flex-col justify-between gap-3">
              <div
                className="flex flex-row gap-2 items-center cursor-pointer"
                onClick={() => {
                  dispatch(setMoreModal(false));
                  createIssueModal(true);
                }}
              >
                <img src={icons.icChecklistSource} alt="issue" className="w-[16px] h-[16px]" />
                <div className="text-[12px]">Create Issue</div>
              </div>
              {enableCreateSubResponse && (
                <div
                  className="flex flex-row gap-2 items-center cursor-pointer"
                  onClick={() => {
                    dispatch(setMoreModal(false));
                    handleCreateSubResponseClicked();
                  }}
                >
                  <img
                    src={icons.icCreateSubResponse}
                    alt="create sub response"
                    className="w-[16px] h-[16px]"
                  />
                  <div className="text-[12px]">Create Sub Response</div>
                </div>
              )}
              <div
                className="flex flex-row gap-2 items-center cursor-pointer"
                onClick={() => {
                  dispatch(setMoreModal(false));
                  handleShareClicked();
                }}
              >
                <img src={icons.icShare} alt="share" className="w-[16px] h-[16px]" />
                <div className="text-[12px]">Share</div>
              </div>
              <div
                className="flex flex-row gap-2 items-center cursor-pointer"
                onClick={() => downloadForm(id, title)}
              >
                <img src={icons.icDownload} alt="download" className="w-[16px] h-[16px]" />
                <div className="text-[12px]">Download Form</div>
              </div>
              <div
                className={`flex flex-row gap-2 items-center cursor-pointer ${
                  disableClickDuplicate ? 'opacity-50' : 'opacity-100'
                }`}
                onClick={() => {
                  dispatch(setMoreModal(false));
                  handleDuplicateClicked();
                }}
              >
                <img src={icons.icDuplicateForm} alt="duplicate" className="w-[16px] h-[16px]" />
                <div className="text-[12px]">Duplicate Form</div>
              </div>
              {enableEdit && (
                <div
                  className="flex flex-row gap-2 items-center cursor-pointer"
                  onClick={() => {
                    dispatch(setMoreModal(false));
                    handleEditClicked();
                  }}
                >
                  <img src={icons.icEditBg} alt="edit" className="w-[16px] h-[16px]" />
                  <div className="text-[12px]">
                    {isEditingMode ? 'Cancel Edit Form' : 'Edit Form'}
                  </div>
                </div>
              )}
              <div
                className="flex flex-row gap-2 items-center cursor-pointer"
                onClick={() => {
                  dispatch(setMoreModal(false));
                  approvalHistoryModal(true);
                }}
              >
                <img
                  src={icons.icApprovalHistory}
                  alt="history approval"
                  className="w-[16px] h-[16px]"
                />
                <div className="text-[12px]">History Approval</div>
              </div>
              {enableShowLocation && (
                <div
                  className="flex flex-row gap-2 items-center cursor-pointer"
                  onClick={() => {
                    dispatch(setMoreModal(false));
                    handleShowLocationClicked();
                  }}
                >
                  <div className="w-[16px] h-[16px] bg-[#EBEDF8] rounded-md flex items-center justify-center">
                    <HiOutlineLocationMarker color="#6546C3" size={14} />
                  </div>
                  <div className="text-[12px]">Show Location</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ActionModal;
