import fileDownload from 'js-file-download';
import { get } from '../../../../../api/base';
import { apiUrls } from '../../../../../constants';

export const downloadForm = (id, title) => {
  get(`${apiUrls.DYNAMIC_FORM}/contents/${id}/export-excel`, { responseType: 'blob' }).then(res => {
    if (res.status === 200) {
      const file = new Blob([res.data]);

      const dateTime = new Date();
      const formatedDate =
        dateTime.getDate() + '-' + (dateTime.getMonth() + 1) + '-' + dateTime.getFullYear();
      const formatedTime =
        dateTime.getHours() + '-' + dateTime.getMinutes() + '-' + dateTime.getSeconds();

      let validName = title.replace(/[/\\?%*:|"<>]/g, '-');

      let fileName = validName + '_' + formatedDate + '_' + formatedTime + '.xlsx';
      fileDownload(file, fileName);
    }
  });
};
