import { Loading } from '../../../../components';
import { icons } from '../../../../constants';

const AppsInfoModal = ({ showModal, setShowModal, detailApps, isFetching }) => {
  return (
    <div>
      <input
        type="checkbox"
        id="menu-modal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />

      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div
          className="modal-box flex flex-col bg-white p-4 sm:p-6 w-screen sm:w-[512px] md:w-[780px] h-[75vh] sm:h-fit"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex flex-row items-center gap-4">
            <div className="w-6 h-6 bg-[#EBEDF8] rounded-md flex justify-center items-center">
              <img src={detailApps?.icon} className="w-4 h-4" alt={detailApps?.name} />
            </div>

            <div className="flex-grow font-semibold text-[18px] text-[#232323]">
              {detailApps?.name}
            </div>
            <img
              src={icons.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              alt={'close-modal'}
              onClick={() => setShowModal(false)}
            />
          </div>

          <hr className="my-4" />

          {isFetching ? (
            <div className="h-[200px] flex items-center justify-center">
              <Loading />
            </div>
          ) : (
            <>
              <div className="font-semibold text-[12px] text-[#232323] my-2">Contact Person</div>
              <div className="bg-[#EBEDF8] rounded-md flex items-center text-[12px] text-[#232323] p-2">
                <img src={icons.icContactPerson} className="w-6 h-6 mr-2" alt="Contact Person" />
                {detailApps?.contact_person}
              </div>
              <hr className="my-6" />
              <div className="flex flex-1 flex-col justify-between">
                <div className="text-[12px] text-[#232323] flex-grow max-h-[230px] md:max-h-[350px] overflow-y-auto">
                  <div dangerouslySetInnerHTML={{ __html: detailApps?.description }} />
                </div>
                <button
                  className="bg-[#6546C3] rounded-lg py-[10px] mt-4 w-full font-semibold text-white 
                text-[14px] flex justify-center items-center"
                  onClick={() => setShowModal(false)}
                >
                  Back
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppsInfoModal;
