import { useEffect, useRef, useState } from 'react';
import { get, post, put } from '../../../../api/base';
import { toast } from 'react-toastify';
import { Loading, SuccessPopUp, Toast } from '../../../../components';
import { useParams } from 'react-router-dom';
import { apiUrls, icons, images } from '../../../../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import SubmitEmailModal from './components/SubmitEmailModal';
import ActionModal from './components/ActionModal';
import CreateIssueModal from '../components/CreateIssueModal';
import { downloadForm } from '../components/DownloadForm';
import GenerateForm from './GenerateForm';
import { useDispatch, useSelector } from 'react-redux';
import { setShowLoading } from '../../../../store/slices/popup';
import moment from 'moment';
import { cacheHelper, offlineHelper } from '../../../../helpers';
import { v4 as uuidv4 } from 'uuid';
import ApprovalHistoryModal from '../components/ApprovalHistory';
import RequestorOptionModal from './components/RequestorOptionModal';
import RequestorInfoModal from './components/RequestorInfoModal';
import DuplicateFormModal from '../components/DuplicateFormModal';
import { setFinishStoreForm, setHideHeader } from '../../../../store/slices/form';
import ConfirmationModal from '../../../../components/Popup/ConfirmationModal';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import SubResponseModal from './components/SubResponseModal';
import ShareFormModal from '../components/ShareFormModal';
import LocationModal from './components/LocationModal';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { FaCircleInfo } from 'react-icons/fa6';

const DetailForm = () => {
  const [submission, setSubmission] = useState({});
  let mandatoryField;
  let calculatedMandatoryField;
  const [isFetching, setIsFetching] = useState(false);
  const [isFirstFetching, setIsFirstFetching] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [successApprovalModal, setSuccessApprovalModal] = useState(false);
  const [approveCondition, setApproveCondition] = useState(false);
  const [approvalHistoryModal, setApprovalHistoryModal] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [successEditModal, setSuccessEditModal] = useState(false);
  const [createIssueModal, setCreateIssueModal] = useState(false);
  const [showSubResponseModal, setShowSubResponseModal] = useState(false);
  const [showDuplicateFormModal, setShowDuplicateFormModal] = useState(false);
  const [showShareFormModal, setShowShareFormModal] = useState(false);
  const [accessRestricModal, setAccessRestricModal] = useState(false);
  const [payloadForm, setPayloadForm] = useState({});
  const [indexSection, setIndexSection] = useState(0);
  const [prevIndexSection, setPrevIndexSection] = useState(0);
  const user = useSelector(state => state.user.userData);
  const dispatch = useDispatch();
  const [collapseParentData, setCollapseParentData] = useState(true);
  const { id, responseId, parentId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const uuid = uuidv4();
  let submitted = true;
  if (pathname.toLowerCase().split('/').includes('formsubmission')) {
    submitted = false;
  }
  const [isStatusDraft, setIsStatusDraft] = useState(false);
  const [isTabletLayout, setIsTabletLayout] = useState(false);
  const [isWizardLayout, setIsWizardLayout] = useState(false);
  const [locationCoords, setLocationCoords] = useState(null);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [isWideMode, setIsWideMode] = useState(false);

  // data to and cc
  const [selectedTo, setSelectedTo] = useState(null);
  const [selectedCc, setSelectedCc] = useState([]);

  // requestor is not submitter
  const [differentRequestorActive, setDifferentRequestorActive] = useState(false);
  const [showRequestorOptionModal, setShowRequestorOptionModal] = useState(false);
  const [showRequestorInfoModal, setShowRequestorInfoModal] = useState(false);
  const [selectedRequestor, setSelectedRequestor] = useState(null);
  const [collapseRequestorInfo, setCollapseRequestorInfo] = useState(false);

  const handleDifferentRequestorActiveChange = checked => {
    if (checked) {
      setShowRequestorOptionModal(true);
    } else {
      setSelectedRequestor(null);
      setDifferentRequestorActive(false);
    }
  };

  useEffect(() => {
    fetchDetailSubmission();
    setIsFirstFetching(true);
  }, [id, responseId]);

  useEffect(() => {
    if (submission && isFirstFetching) {
      checkAccessRestriction();
    }
    if (!submitted && submission?.location) {
      requestLocationPermission();
    }
  }, [submission]);

  const checkAccessRestriction = () => {
    const screenWidth = window.screen.width;
    const formMaxWidth = submission?.form_size?.max_width;
    const formMinWidth = submission?.form_size?.min_width;

    if (formMinWidth > screenWidth || (formMaxWidth && screenWidth > formMaxWidth)) {
      setAccessRestricModal(true);
      setIsFirstFetching(false);
    }
  };

  const successCallback = position => {
    setLocationCoords({
      latitude: position?.coords?.latitude?.toString(),
      longitude: position?.coords?.longitude?.toString()
    });
  };

  const requestLocationPermission = () => {
    navigator.geolocation.getCurrentPosition(successCallback);
  };

  const handleShowLocation = () => {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${submission?.location.latitude},${submission?.location.longitude}`,
      '_blank'
    );
  };

  const fetchDetailSubmission = () => {
    setIsFetching(true);
    if (isEditingMode) setIsEditingMode(false);
    if (submitted) {
      if (navigator.onLine) {
        get(`${apiUrls.DYNAMIC_FORM}/contents/${responseId ? responseId : id}`)
          .then(response => {
            const { status, data } = response;
            setIsFetching(false);
            if (status === 200) {
              setSubmission(data);
              setDifferentRequestorActive(
                !!data?.requestor && data?.requestor?.id !== data?.user?.id
              );
              setSelectedRequestor(data?.requestor);
              setSelectedTo(data?.to);
              setSelectedCc(data?.cc);
              setIsWizardLayout(data?.form?.form_method === 'WIZARD');
              setIsStatusDraft(data?.form?.status === 'DRAFT');
            } else {
              toast.error(
                <Toast message={'Error'} detailedMessage={`${response.data.error.message}`} />
              );
            }
          })
          .catch(_ => {
            setIsFetching(false);
          });
      } else {
        cacheHelper
          .getCachedData(`${apiUrls.BASE_URL_USERS}/${user?.id}/dynamic-forms/contents`)
          .then(response => {
            const submissions = response.form_contents?.filter(
              form => form.id.toString() === id.toString()
            );
            setSubmission(submissions[0]);
          })
          .finally(() => {
            setIsFetching(false);
          });
      }
    } else {
      if (navigator.onLine) {
        if (parentId) {
          get(`${apiUrls.DYNAMIC_FORM}/${id}/parent/${parentId}`)
            .then(response => {
              const { status } = response;
              setIsFetching(false);
              if (status === 200) {
                if (response.data.error?.code === 40410) {
                  navigate(-1);
                  toast.error(
                    <Toast
                      message={'You are not allowed to access form'}
                      detailedMessage={
                        'Please contact Form Administrator or IT Helpdesk for further assistance'
                      }
                    />
                  );
                } else {
                  setSubmission(response.data);
                  setIsTabletLayout(!!response.data?.custom_tablet_layout);
                  if (!!response.data?.custom_tablet_layout) {
                    dispatch(setHideHeader(true));
                  }
                  setIsWizardLayout(response.data?.form_method === 'WIZARD');
                }
              } else {
                toast.error(
                  <Toast message={'Error'} detailedMessage={`${response.data.error.message}`} />
                );
              }
            })
            .catch(_ => {
              setIsFetching(false);
            });
        } else {
          get(`${apiUrls.DYNAMIC_FORM}/${id}`)
            .then(response => {
              const { status } = response;
              setIsFetching(false);
              if (status === 200) {
                if (response.data.error?.code === 40410) {
                  navigate(-1);
                  toast.error(
                    <Toast
                      message={'You are not allowed to access form'}
                      detailedMessage={
                        'Please contact Form Administrator or IT Helpdesk for further assistance'
                      }
                    />
                  );
                } else {
                  setSubmission(populateMasterStockForms(response.data));
                  setIsTabletLayout(!!response.data?.custom_tablet_layout);
                  if (!!response.data?.custom_tablet_layout) {
                    dispatch(setHideHeader(true));
                  }
                  setIsWizardLayout(response.data?.form_method === 'WIZARD');
                }
              } else {
                toast.error(
                  <Toast message={'Error'} detailedMessage={`${response.data.error.message}`} />
                );
              }
            })
            .catch(_ => {
              setIsFetching(false);
            });
        }
      } else {
        cacheHelper.getCachedData(`/api/users/${user.id}/dynamic-forms?offline=true`).then(res => {
          const form = res.forms.filter(form => form.id === parseInt(id));
          setSubmission(form[0]);
          setIsFetching(false);
        });
      }
    }
  };

  const populateMasterStockForms = data => {
    data?.sections?.forEach((section, sectionIndex) => {
      section?.fields?.forEach((field, fieldIndex) => {
        if (field?.field_type.value === 'M2P_FORM') {
          let masterStockFormsData = data?.master_stock_forms?.find(
            form => form.id === field?.form_system.id
          );
          section?.fields?.splice(fieldIndex, 0, ...masterStockFormsData.fields);
        }
      });
    });
    return data;
  };

  const checkFieldValue = fields => {
    mandatoryField = [];
    calculatedMandatoryField = [];
    for (const field of fields) {
      if (field?.additional_option?.length > 0) {
        for (const additional of field.additional_option) {
          if (
            (!additional?.value || (_.isArray(additional?.value) && !additional?.value?.length)) &&
            field?.mandatory
          ) {
            field['highlight'] = true;
            mandatoryField.push(additional.title);
          } else if ((additional?.value || additional?.value?.length) && field?.mandatory) {
            if (field.hasOwnProperty('highlight')) {
              mandatoryField = mandatoryField.filter(function (e) {
                return e !== additional?.title;
              });
              delete field.highlight;
            }
          }
          if (field.field_type?.value === 'RADIO_BUTTON_ATTACHMENT') {
            for (const option of additional.options) {
              if (additional.value === option.label && option.mandatory) {
                if (_.isArray(additional.attachment_value)) {
                  if (!additional.attachment_value.length) {
                    return false;
                  }
                } else {
                  if (!additional.attachment_value?._parts.length) {
                    return false;
                  }
                }
              }
            }
          } else if (field.field_type?.value === 'CHECKBOX_ATTACHMENT') {
            for (const option of additional.options) {
              if (additional.value && additional.value !== '') {
                for (const value of additional?.value) {
                  if (value === option.label && option.mandatory) {
                    if (_.isArray(additional.attachment_value)) {
                      if (!additional.attachment_value.length) {
                        return false;
                      }
                    } else {
                      if (!additional.attachment_value?._parts.length) {
                        return false;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        if (
          (field?.value === '' || (_.isArray(field?.value) && !field?.value.length)) &&
          field?.mandatory
        ) {
          field['highlight'] = true;
          setSubmission({ ...submission });
          mandatoryField.push(field?.field_title);
        } else if ((field?.value !== '' || field?.value.length) && field?.mandatory) {
          if (field.hasOwnProperty('highlight')) {
            mandatoryField = mandatoryField.filter(function (e) {
              return e !== field?.field_title;
            });
            delete field.highlight;
          }
          setSubmission({ ...submission });
        }
        if (field.field_type?.value === 'RADIO_BUTTON_ATTACHMENT') {
          for (const option of field.options) {
            if (field.value === option.label && option.mandatory) {
              if (_.isArray(field.attachment_value)) {
                if (!field.attachment_value.length) {
                  return false;
                }
              } else {
                if (!field.attachment_value?._parts.length) {
                  return false;
                }
              }
            }
          }
        }
        if (field.field_type?.value === 'CHECKBOX_ATTACHMENT') {
          for (const option of field.options) {
            if (field.value && field.value !== '') {
              for (const value of field.value) {
                if (value === option.label && option.mandatory) {
                  if (_.isArray(field.attachment_value)) {
                    if (!field.attachment_value.length) {
                      return false;
                    }
                  } else {
                    if (!field.attachment_value?._parts.length) {
                      return false;
                    }
                  }
                }
              }
            }
          }
        }
        if (field.field_type?.value === 'CALCULATED_FIELD') {
          let raw = field?.field_formula?.operand_fields?.map(field => field?.field_title);
          if (!field?.value) {
            calculatedMandatoryField.push(...raw);
            return false;
          } else {
            calculatedMandatoryField = _.difference(mandatoryField, raw);
          }
        }
      }
    }
    return true;
  };

  const checkValue = (sections, attachment) => {
    for (const element of sections) {
      if (!checkFieldValue(element.fields)) {
        return false;
      }
      if (element.inner_section) {
        for (const inner of element.inner_section) {
          let resultInnFieldValue = checkFieldValue(inner);
          let resultFieldValue = checkFieldValue(element.fields);
          if (!resultInnFieldValue || !resultFieldValue) {
            return false;
          }
        }
      } else if (element.sub_sections?.length) {
        for (const sub of element.sub_sections) {
          let resultSubValue = checkFieldValue(sub.fields);
          let resultFieldValue = checkFieldValue(element.fields);
          if (!resultSubValue || !resultFieldValue) {
            return false;
          }
        }
      }
    }
    if (submission.attachment && !isTabletLayout) {
      if (_.isArray(attachment)) {
        if (!attachment.length) {
          return false;
        }
      } else {
        if (!attachment?._parts?.length) {
          return false;
        }
      }
    }
    return true;
  };

  const checkMandatoryField = sections => {
    for (const section of sections) {
      const fields = section.fields;
      for (const field of fields) {
        if ('highlight' in field) {
          return false;
        }
      }
    }
    return true;
  };

  const handleSendApproval = ({ approvalType, remark }) => {
    dispatch(setShowLoading(true));
    post(
      `${apiUrls.DYNAMIC_FORM}/contents/${id}/action`,
      {},
      {
        query: {
          type: approvalType,
          comment: remark
        }
      }
    )
      .then(response => {
        dispatch(setShowLoading(false));
        if (response.status === 200) {
          setSuccessApprovalModal(true);
          setApproveCondition(approvalType === 'APPROVE');
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={response.data?.error?.message} />);
        }
      })
      .catch(() => {
        dispatch(setShowLoading(false));
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
      });
  };

  const storeForm = ({ sections, attachment, is_draft }) => {
    if (submission?.location && !locationCoords) {
      setShowLocationModal(true);
      return;
    }
    setSubmission({ ...submission, sections: sections });
    if (isWizardLayout || (checkValue(sections, attachment) && checkMandatoryField(sections))) {
      let payload = {
        ...(!isStatusDraft && !isEditingMode && { form_id: submission.id }),
        sections: sections,
        submitted_time: new Date(),
        attachments: attachment && attachment.length ? attachment : undefined,
        location: locationCoords,
        is_draft: is_draft,
        ...(selectedRequestor && { requestor: selectedRequestor?.id }),
        ...(parentId && { linkage_form_section_id: parentId })
      };
      setPayloadForm(payload);
      setIsDraft(is_draft);
      if (submission?.module_id || submission?.form?.module_id) {
        submitForm(null, null, payload);
      } else if (!is_draft) {
        setEmailModal(true);
      } else {
        submitDraftForm(
          payload,
          isStatusDraft ? submission?.to : null,
          isStatusDraft ? submission?.cc : null
        );
      }
    } else {
      if (mandatoryField?.length) {
        toast.error(
          <Toast message={'Error'} detailedMessage={`${mandatoryField[0]} cannot be empty`} />
        );
      } else if (calculatedMandatoryField?.length) {
        toast.error(
          <Toast
            message={'Error'}
            detailedMessage={`${calculatedMandatoryField.join(', ')} cannot be empty`}
          />
        );
      } else {
        toast.error(
          <Toast message={'Error'} detailedMessage={`Mandatory Field cannot be empty`} />
        );
      }
    }
  };

  const submitForm = (to, cc, payload) => {
    let finalPayload;
    if (payload) {
      finalPayload = { ...payload, to, cc };
    } else {
      finalPayload = { ...payloadForm, to, cc };
    }
    dispatch(setShowLoading(true));
    if (!isStatusDraft && !isEditingMode) {
      if (navigator.onLine) {
        post(`${apiUrls.DYNAMIC_FORM}/contents`, finalPayload, {})
          .then(res => {
            dispatch(setShowLoading(false));
            if (res.status === 201) {
              setSuccessModal(true);
            } else {
              toast.error(<Toast message={'Error'} detailedMessage={res.data?.error?.message} />);
            }
          })
          .catch(_ => {
            dispatch(setShowLoading(false));
            toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
          });
      } else {
        offlineHelper
          .saveRequestDataOffline({
            url: `${apiUrls.DYNAMIC_FORM}/contents`,
            body: finalPayload,
            cacheUrl: `/api/users/${user.id}/dynamic-forms/contents`,
            key: 'form_contents',
            data: {
              id: 'offline' + uuid,
              sent_at: null,
              submitted_time: moment().format('DD-MMM-YY HH:mm'),
              sections: finalPayload.sections,
              form: {
                title: submission.title,
                last_submission: submission.last_submission,
                company: submission.company
              },
              attachments: finalPayload.attachments ? finalPayload.attachments : null,
              location: locationCoords,
              status: 'SUBMITTED'
            }
          })
          .then(_ => {
            dispatch(setShowLoading(false));
            setSuccessModal(true);
          })
          .catch(_ => {
            dispatch(setShowLoading(false));
            toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
          });
      }
    } else {
      put(`${apiUrls.DYNAMIC_FORM}/contents/${submission.id}`, finalPayload, {})
        .then(res => {
          dispatch(setShowLoading(false));
          if (res.status === 201) {
            if (isEditingMode) {
              setSuccessEditModal(true);
            } else {
              setSuccessModal(true);
            }
          } else {
            toast.error(<Toast message={'Error'} detailedMessage={res.data?.error?.message} />);
          }
        })
        .catch(_ => {
          dispatch(setShowLoading(false));
          toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
        });
    }
  };

  const submitDraftForm = (payload, to, cc) => {
    const finalPayload = { ...payload, to, cc };
    dispatch(setShowLoading(true));
    if (!isStatusDraft && !isEditingMode) {
      if (navigator.onLine) {
        post(`${apiUrls.DYNAMIC_FORM}/contents`, finalPayload, {})
          .then(res => {
            dispatch(setShowLoading(false));
            if (res.status === 201) {
              setSuccessModal(true);
            } else {
              toast.error(<Toast message={'Error'} detailedMessage={res.data?.error?.message} />);
            }
          })
          .catch(_ => {
            dispatch(setShowLoading(false));
            toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
          });
      } else {
        offlineHelper
          .saveRequestDataOffline({
            url: `${apiUrls.DYNAMIC_FORM}/contents`,
            body: finalPayload,
            cacheUrl: `/api/users/${user.id}/dynamic-forms/contents`,
            key: 'form_contents',
            data: {
              id: 'offline' + uuid,
              sent_at: null,
              submitted_time: moment().format('DD-MMM-YY HH:mm'),
              sections: finalPayload.sections,
              form: {
                title: submission.title,
                last_submission: submission.last_submission,
                company: submission.company
              },
              attachments: finalPayload.attachments ? finalPayload.attachments : null,
              location: locationCoords,
              status: 'DRAFT'
            }
          })
          .then(_ => {
            dispatch(setShowLoading(false));
            setSuccessModal(true);
          })
          .catch(_ => {
            dispatch(setShowLoading(false));
            toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
          });
      }
    } else {
      put(`${apiUrls.DYNAMIC_FORM}/contents/${submission.id}`, finalPayload, {})
        .then(res => {
          dispatch(setShowLoading(false));
          if (res.status === 201) {
            if (isEditingMode) {
              setSuccessEditModal(true);
            } else {
              setSuccessModal(true);
            }
          } else {
            toast.error(<Toast message={'Error'} detailedMessage={res.data?.error?.message} />);
          }
        })
        .catch(_ => {
          dispatch(setShowLoading(false));
          toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
        });
    }
  };

  const submitFormTabletLayout = ({ sections, attachments, title, value }) => {
    if (submission?.location && !locationCoords) {
      setShowLocationModal(true);
      return;
    }
    dispatch(setFinishStoreForm('Failed'));
    dispatch(setShowLoading(true));
    let payload = {
      form_id: submission.id,
      sections: sections,
      submitted_time: new Date(),
      attachments: attachments && attachments.length ? attachments : undefined,
      location: locationCoords,
      is_draft: false
    };
    if (navigator.onLine) {
      post(`${apiUrls.DYNAMIC_FORM}/contents`, payload, {})
        .then(res => {
          dispatch(setShowLoading(false));
          if (res.status === 201) {
            dispatch(setFinishStoreForm('Success'));
            toast.success(
              <Toast
                message={'Success'}
                detailedMessage={`${moment().format('DD-MMM-YYYY')} ${title} ${
                  value ? `: ${value}` : ''
                }`}
              />,
              {
                position: 'bottom-right'
              }
            );
          } else {
            dispatch(setFinishStoreForm('Failed'));
            toast.error(<Toast message={'Error'} detailedMessage={res.data?.error?.message} />);
          }
        })
        .catch(_ => {
          dispatch(setFinishStoreForm('Failed'));
          dispatch(setShowLoading(false));
          toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
        });
    } else {
      offlineHelper
        .saveRequestDataOffline({
          url: `${apiUrls.DYNAMIC_FORM}/contents`,
          body: payload,
          cacheUrl: `/api/users/${user.id}/dynamic-forms/contents`,
          key: 'form_contents',
          data: {
            id: 'offline' + uuid,
            sent_at: null,
            submitted_time: moment().format('DD-MMM-YY HH:mm'),
            sections: payload.sections,
            form: {
              title: submission.title,
              last_submission: submission.last_submission,
              company: submission.company
            },
            attachments: payload.attachments ? payload.attachments : null,
            location: locationCoords,
            status: 'SUBMITTED'
          }
        })
        .then(_ => {
          dispatch(setShowLoading(false));
          dispatch(setFinishStoreForm('Success'));
          toast.success(
            <Toast
              message={'Success'}
              detailedMessage={`${moment().format('DD-MMM-YYYY')} ${title} ${
                value ? `: ${value}` : ''
              }`}
            />,
            {
              position: 'bottom-right'
            }
          );
        })
        .catch(_ => {
          dispatch(setShowLoading(false));
          dispatch(setFinishStoreForm('Failed'));
          toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
        });
    }
  };

  const dropdown = useRef();
  const handleClick = () => {
    dropdown.current.classList.toggle('dropdown-open');
    document.activeElement.blur();
  };

  const handleWideMode = () => {
    if (!isWideMode) {
      setIsWideMode(true);
      dispatch(setHideHeader(true));
    } else {
      setIsWideMode(false);
      dispatch(setHideHeader(false));
    }
  };

  return (
    <div className={`${isTabletLayout ? 'p-6 h-full' : 'sm:mx-[24px]'}`}>
      {isFetching ? (
        <div className="flex justify-center h-[50vh] my-auto">
          <Loading />
        </div>
      ) : (
        <>
          {!isTabletLayout && !isWideMode && (
            <div className="flex flex-col gap-6">
              {submitted && (
                <div className="flex flex-row bg-white rounded-2xl justify-between p-[16px] mx-[24px] sm:mx-0">
                  <div className="flex gap-4 font-poppins text-[16px]">
                    <button
                      onClick={() => {
                        if (location.pathname.split('/').includes('BrowseFormSubmission')) {
                          navigate(
                            `/Task/BrowseFormSubmission/NewestSubmittedDocument/${id}/DetailFormSubmission/${submission?.previous_id}`
                          );
                        } else {
                          navigate(`/Task/DetailForm/${submission?.previous_id}`);
                        }
                      }}
                      disabled={!submission?.previous_id}
                      className="w-8 h-8 py-[5px] font-poppins cursor-pointer text-center rounded-full 
                        enabled:text-white enabled:bg-gradient-to-r enabled:from-[#6546C3] enabled:to-[#9E7CE3] disabled:bg-[#F2F5FC]"
                    >
                      &lt;
                    </button>
                    <button
                      onClick={() => {
                        if (location.pathname.split('/').includes('BrowseFormSubmission')) {
                          navigate(
                            `/Task/BrowseFormSubmission/NewestSubmittedDocument/${id}/DetailFormSubmission/${submission?.next_id}`
                          );
                        } else {
                          navigate(`/Task/DetailForm/${submission?.next_id}`);
                        }
                      }}
                      disabled={!submission?.next_id}
                      className="w-8 h-8 py-[5px] font-poppins cursor-pointer text-center rounded-full 
                        enabled:text-white enabled:bg-gradient-to-r enabled:from-[#6546C3] enabled:to-[#9E7CE3] disabled:bg-[#F2F5FC]"
                    >
                      &gt;
                    </button>
                  </div>
                  <div className="flex gap-4 items-center">
                    <div className="font-normal text-[12px]">submitted position</div>
                    <div className="bg-[#C800A5] w-9 h-9 rounded-full flex items-center justify-center">
                      <div className="font-semibold text-[12px] text-white">
                        {submission?.submitted_position}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex flex-row bg-white rounded-2xl justify-between p-[16px] mb-[40px] mx-[24px] sm:mx-0">
                <div className={'flex-1'}>
                  <div className="font-normal text-[12px]">Last Submission</div>
                  <div
                    className={`font-semibold text-[12px] text-[#C800A5] ${
                      submitted ? 'mb-2' : ''
                    }`}
                  >
                    {submitted
                      ? submission?.form?.last_submission || '-'
                      : submission?.last_submission || '-'}
                  </div>
                  {submitted ? (
                    <>
                      <div className="font-normal text-[12px]">
                        {submission?.running_number?.title || 'Number'}
                      </div>
                      <div className="font-semibold text-[12px] text-[#C800A5]">
                        {submission?.running_number?.value || '-'}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className={'flex-1'}>
                  <div className="flex flex-row">
                    <div className="font-normal text-[12px] mr-auto">Company</div>
                    <div
                      className="dropdown dropdown-bottom dropdown-end justify-center items-center m-0"
                      ref={dropdown}
                    >
                      <FaCircleInfo
                        tabIndex={0}
                        color="#C800A5"
                        size="16px"
                        className="cursor-pointer"
                        onMouseEnter={() => handleClick()}
                        onMouseLeave={() => handleClick()}
                      />
                      <div
                        tabIndex={0}
                        className="dropdown-content menu p-4 shadow bg-base-100 rounded-box w-60 m-0"
                      >
                        {(submitted ? submission?.form?.company : submission?.company)?.map(
                          (company, companyIndex) => {
                            return (
                              <div
                                key={companyIndex}
                                className="font-semibold text-[12px] text-[#C800A5] break-words w-fit max-w-[75vw]"
                              >
                                {company}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="font-semibold text-[12px] text-[#C800A5] line-clamp-2">
                    {submitted
                      ? submission?.form?.company?.join(', ') || '-'
                      : submission?.company?.join(', ') || '-'}
                  </div>
                </div>
                {submitted && (
                  <div className="dropdown dropdown-bottom dropdown-end justify-center items-center m-0 hidden sm:flex">
                    <button
                      tabIndex={0}
                      className="flex justify-center items-center bg-[#F1F1FD] w-[24px] h-[24px] rounded-md"
                    >
                      <img src={icons.icMore} alt="more" width={20} height={20} />
                    </button>
                    <ul
                      tabIndex={0}
                      className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-60 -mt-5 m-0"
                    >
                      <li>
                        <div
                          className="flex flex-row items-center gap-4"
                          onClick={() => {
                            setCreateIssueModal(true);
                          }}
                        >
                          <img src={icons.icChecklistSource} alt="arrow" height={24} width={24} />
                          Create Issue
                        </div>
                      </li>
                      {submission?.children_form?.length > 0 && (
                        <li>
                          <div
                            className="flex flex-row items-center gap-4"
                            onClick={() => {
                              setShowSubResponseModal(true);
                            }}
                          >
                            <img
                              src={icons.icCreateSubResponse}
                              alt="arrow"
                              height={24}
                              width={24}
                            />
                            Create Sub Response
                          </div>
                        </li>
                      )}
                      <li>
                        <div
                          className="flex flex-row items-center gap-4"
                          onClick={() => setShowShareFormModal(true)}
                        >
                          <img src={icons.icShare} alt="arrow" height={24} width={24} />
                          Share
                        </div>
                      </li>
                      <li>
                        <div
                          className="flex flex-row items-center gap-4"
                          onClick={() => downloadForm(id, submission?.form?.title)}
                        >
                          <img src={icons.icDownload} alt="arrow" height={24} width={24} />
                          Download Form
                        </div>
                      </li>
                      <li>
                        <div
                          className={`flex flex-row items-center gap-4 ${
                            submission?.form?.module_id ? 'opacity-50' : 'opacity-100'
                          }`}
                          onClick={
                            submission?.form?.module_id
                              ? null
                              : () => setShowDuplicateFormModal(true)
                          }
                        >
                          <img src={icons.icDuplicateForm} alt="arrow" height={24} width={24} />
                          Duplicate Form
                        </div>
                      </li>
                      {(submission?.form?.form_type === 'LINKAGE' ||
                        submission?.form?.form_type === 'SYSTEM') &&
                        !isStatusDraft && (
                          <li>
                            <div
                              className="flex flex-row items-center gap-4"
                              onClick={() => {
                                setIsEditingMode(prev => !prev);
                                document.activeElement.blur();
                              }}
                            >
                              <img src={icons.icEditBg} alt="edit" height={24} width={24} />
                              {isEditingMode ? 'Cancel Edit Form' : 'Edit Form'}
                            </div>
                          </li>
                        )}
                      <li>
                        <div
                          className="flex flex-row items-center gap-4"
                          onClick={() => {
                            setApprovalHistoryModal(true);
                          }}
                        >
                          <img src={icons.icApprovalHistory} alt="arrow" height={24} width={24} />
                          History Approval
                        </div>
                      </li>
                      {submission?.location && (
                        <li>
                          <div
                            className="flex flex-row items-center gap-4"
                            onClick={() => handleShowLocation()}
                          >
                            <div className="w-6 h-6 bg-[#EBEDF8] rounded-lg flex items-center justify-center">
                              <HiOutlineLocationMarker color="#6546C3" size={22} />
                            </div>
                            Show Location
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className={`bg-[#6546C3] rounded-[32px] pt-2 gap-6 ${isWideMode ? 'mt-4' : ''}`}>
            <div className="flex justify-center">
              <div
                className="rounded-full h-2 w-[50px] bg-[#DEDEDE] cursor-pointer"
                onClick={() => handleWideMode()}
              ></div>
            </div>
            <div className="m-[16px] mb-0">
              <div className="font-semibold text-[14px]  text-white">Title</div>
              <div className="text-white font-normal text-[12px] mt-[4px]">
                {submitted ? submission?.form?.title : submission?.title}
              </div>
            </div>
            {(!submitted || isStatusDraft || isEditingMode) && isWizardLayout && (
              <>
                <div className="sm:hidden block w-[85vw] bg-white ml-[24px] flex flex-row p-2 align-center rounded-lg mt-[16px]">
                  <div className="w-[20vw]">
                    <CircularProgressbar
                      value={indexSection + 1}
                      maxValue={submission?.sections?.length}
                      styles={{
                        path: { stroke: '#6546C3' },
                        text: { fill: '#6546C3', fontWeight: 'bolder' }
                      }}
                      text={`${indexSection + 1} of ${submission?.sections?.length}`}
                    />
                  </div>
                  {!_.isEmpty(submission) && (
                    <div className="flex flex-col gap-2 ml-4 align-center justify-center">
                      <div className="text-[16px] truncate">
                        {submission.sections[indexSection]?.section_title}
                      </div>
                      {submission?.sections.length > indexSection + 1 && (
                        <div className="text-[12px] text-[#AAAAAA] truncate">
                          Next: {submission.sections[indexSection + 1]?.section_title}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="sm:block hidden bg-white mx-[24px] flex flex-col p-2 align-center rounded-lg mt-[16px] px-4">
                  {!_.isEmpty(submission) && (
                    <>
                      <div className="flex flex-row justify-between">
                        <div className="flex flex-col gap-1 align-center justify-center">
                          <div className="text-[16px] truncate">
                            {submission.sections[indexSection]?.section_title}
                          </div>
                          {submission?.sections.length > indexSection + 1 && (
                            <div className="text-[12px] text-[#AAAAAA] truncate">
                              Next: {submission.sections[indexSection + 1]?.section_title}
                            </div>
                          )}
                        </div>
                        <div>
                          {indexSection + 1} of {submission?.sections?.length}
                        </div>
                      </div>
                      <div>
                        <progress
                          className="progress progress-secondary color-[#6546C3]"
                          value={indexSection + 1}
                          max={submission?.sections?.length}
                        ></progress>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}

            <div className="flex flex-col bg-[#EBEDF8] rounded-[32px] mt-[32px] px-6 pt-6 gap-6">
              <div className="flex flex-col gap-6">
                {submitted &&
                !!submission?.requestor &&
                submission?.requestor?.id !== submission?.user?.id &&
                !isStatusDraft ? (
                  <div className="flex flex-col bg-white rounded-2xl p-6">
                    <div
                      className="flex justify-between items-center cursor-pointer"
                      onClick={() => setCollapseRequestorInfo(prev => !prev)}
                    >
                      <div className="font-semibold text-[14px] text-[#C800A5]">Requestor Info</div>
                      <img
                        src={
                          collapseRequestorInfo
                            ? icons.icArrowDownCollapse
                            : icons.icArrowUpCollapse
                        }
                        alt="collapse"
                        className="w-7"
                      />
                    </div>

                    {!collapseRequestorInfo && (
                      <>
                        <hr className="mt-4 mb-6" />
                        <div className="flex flex-col gap-4">
                          <div className="flex flex-1 flex-col gap-2">
                            <div className="font-semibold text-[12px]">Name</div>
                            <input
                              className={
                                'text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]'
                              }
                              placeholder={'Name'}
                              value={submission?.requestor?.name || '-'}
                              disabled
                            />
                          </div>
                          <div className="flex flex-1 flex-col gap-2">
                            <div className="font-semibold text-[12px]">Email</div>
                            <input
                              className={
                                'text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]'
                              }
                              placeholder={'Email'}
                              value={submission?.requestor?.email || '-'}
                              disabled
                            />
                          </div>
                          <div className="flex flex-1 flex-col gap-2">
                            <div className="font-semibold text-[12px]">Company</div>
                            <input
                              className={
                                'text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]'
                              }
                              value={submission?.requestor?.company || '-'}
                              disabled
                            />
                          </div>
                          <div className="flex flex-1 flex-col gap-2">
                            <div className="font-semibold text-[12px]">Work Location</div>
                            <input
                              className={
                                'text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]'
                              }
                              value={submission?.requestor?.work_location || '-'}
                              disabled
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ) : submission?.different_requestor || differentRequestorActive ? (
                  <div className="flex flex-col bg-white rounded-2xl p-6 gap-4">
                    <div className="flex flex-row justify-between items-center">
                      <div className="text-[14px] font-medium">
                        Please activate this button if you are not the requestor
                      </div>
                      <label
                        htmlFor={`different-requestor`}
                        className="flex relative items-center cursor-pointer"
                      >
                        <input
                          type="checkbox"
                          value=""
                          id={`different-requestor`}
                          className="sr-only peer"
                          checked={differentRequestorActive}
                          onChange={event =>
                            handleDifferentRequestorActiveChange(event.target.checked)
                          }
                        />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#6546C3] dark:peer-focus:ring-[#6546C3] rounded-full peer dark:bg-[#DEDEDE] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#DEDEDE] peer-checked:bg-[#6546C3]"></div>
                      </label>
                    </div>
                    {differentRequestorActive && (
                      <button
                        className="bg-transparent border-2 border-[#6546C3] rounded-lg py-[10px] w-[150px] font-semibold text-[#6546C3] 
                      text-[14px] flex justify-center items-center"
                        onClick={() => setShowRequestorInfoModal(true)}
                      >
                        Requestor Info
                      </button>
                    )}
                  </div>
                ) : (
                  <></>
                )}

                {parentId || !_.isEmpty(submission?.parent) ? (
                  <div className="flex flex-col bg-white rounded-2xl p-6">
                    <div
                      className="flex justify-between items-center cursor-pointer"
                      onClick={() => setCollapseParentData(prev => !prev)}
                    >
                      <div className="flex flex-col gap-3">
                        <div className="font-semibold text-[14px] text-[#C800A5]">
                          {submission?.parent?.title}
                        </div>
                        {submission?.parent?.id_value && (
                          <div className="text-[12px]">
                            {submission?.parent?.id_value?.field_title}:{' '}
                            {submission?.parent?.id_value?.value}
                          </div>
                        )}
                      </div>

                      <img
                        src={
                          collapseParentData ? icons.icArrowDownCollapse : icons.icArrowUpCollapse
                        }
                        alt="collapse"
                        className="w-7"
                      />
                    </div>

                    {!collapseParentData && (
                      <>
                        <div className="bg-[#EBEDF8] h-3 w-[calc(100% + 48px)] -ml-6 -mr-6 mt-4 mb-6"></div>
                        <div className="flex flex-col gap-4">
                          {submission?.parent?.data?.map(item => (
                            <div key={item?.field_id} className="flex flex-1 flex-col gap-2">
                              <div className="font-semibold text-[12px]">{item?.field_title}</div>
                              <input
                                className="text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full"
                                value={item?.value || '-'}
                                disabled
                              />
                            </div>
                          ))}
                          <button
                            onClick={() =>
                              window.open(`/Task/DetailForm/${submission?.parent?.id}`, '_blank')
                            }
                            className="w-fit mt-2 py-3 px-4 bg-[#6546C3] text-white text-[12px] sm:text-[14px] font-semibold rounded-lg shadow-[0_7px_15px_0_rgba(70,49,145,0.3)]"
                          >
                            Open Detail Form
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <></>
                )}

                <GenerateForm
                  submission={{ ...submission }}
                  submitted={submitted && !isStatusDraft && !isEditingMode}
                  allowApproval={
                    submission?.form?.module_id &&
                    submission?.form?.userPics.some(
                      pic => pic.name?.toLowerCase() === user?.username
                    ) &&
                    !submission?.form?.status.includes('REVISE')
                  }
                  handleSendApproval={handleSendApproval}
                  isEditingMode={isEditingMode}
                  setIsEditingMode={setIsEditingMode}
                  isTabletLayout={isTabletLayout}
                  storeForm={storeForm}
                  submitFormTabletLayout={submitFormTabletLayout}
                  isWizardLayout={isWizardLayout}
                  indexSection={indexSection}
                  setIndexSection={setIndexSection}
                  prevIndexSection={prevIndexSection}
                  setPrevIndexSection={setPrevIndexSection}
                  checkFieldValue={checkFieldValue}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <SubmitEmailModal
        showModal={emailModal}
        setShowModal={setEmailModal}
        submitForm={submitForm}
        setSuccessModal={setSuccessModal}
        to={selectedTo}
        cc={selectedCc}
      />
      <SuccessPopUp
        show={successModal}
        imageSuccess={images.imageSuccess}
        title={'Form Submission Successfully'}
        message={
          isDraft
            ? 'You have successfully save the form as a draft'
            : 'If you want to add another response using same form please click resubmit'
        }
        labelSuccessButton={'Back to List'}
        labelBackButton={isDraft ? '' : 'Resubmit'}
        handleSuccessButton={() => navigate('/Task', { state: { tabName: 'Form' } })}
        handleBackButton={() => {
          setEmailModal(false);
          fetchDetailSubmission();
          navigate(`/Task/FormSubmission/DetailFormSubmission/${id}`, { replace: true });
          setSuccessModal(false);
        }}
      />
      <ActionModal
        createIssueModal={setCreateIssueModal}
        id={id}
        title={submission?.form?.title}
        approvalHistoryModal={setApprovalHistoryModal}
        enableEdit={
          (submission?.form?.form_type === 'LINKAGE' || submission?.form?.form_type === 'SYSTEM') &&
          !isStatusDraft
        }
        isEditingMode={isEditingMode}
        handleEditClicked={() => setIsEditingMode(prev => !prev)}
        handleShareClicked={() => setShowShareFormModal(true)}
        disableClickDuplicate={!!submission?.form?.module_id}
        handleDuplicateClicked={
          submission?.form?.module_id ? null : () => setShowDuplicateFormModal(true)
        }
        enableCreateSubResponse={submission?.children_form?.length > 0}
        handleCreateSubResponseClicked={() => setShowSubResponseModal(true)}
        enableShowLocation={!!submission?.location}
        handleShowLocationClicked={() => handleShowLocation()}
      />
      <CreateIssueModal
        showModal={createIssueModal}
        setShowModal={setCreateIssueModal}
        formId={submission.id}
      />
      <ApprovalHistoryModal
        id={submission.id}
        setShowModal={setApprovalHistoryModal}
        showModal={approvalHistoryModal}
        title={submission?.form?.title}
      />
      <RequestorOptionModal
        showModal={showRequestorOptionModal}
        setShowModal={setShowRequestorOptionModal}
        setSelectedRequestor={setSelectedRequestor}
        setDifferentRequestorActive={setDifferentRequestorActive}
      />
      <RequestorInfoModal
        showModal={showRequestorInfoModal}
        setShowModal={setShowRequestorInfoModal}
        requestorInfo={selectedRequestor || submission?.requestor}
      />
      <DuplicateFormModal
        showModal={showDuplicateFormModal}
        setShowModal={setShowDuplicateFormModal}
        formId={submission.id}
        formTitle={submission?.form?.title}
      />
      <ConfirmationModal
        showModal={accessRestricModal}
        setShowModal={setAccessRestricModal}
        imageSuccess={images.imageEmpty}
        title={'Access Restriction'}
        message={'This form cannot be accessed on this screens'}
        labelSubmitButton={'Continue'}
        handleSubmit={() => setAccessRestricModal(false)}
        labelCancelButton={'Ok, Back'}
        handleCancel={() => navigate(-1)}
      />
      <SubResponseModal
        showModal={showSubResponseModal}
        setShowModal={setShowSubResponseModal}
        subResponseForm={submission?.children_form}
      />
      <ShareFormModal
        showModal={showShareFormModal}
        setShowModal={setShowShareFormModal}
        formId={submission.id}
      />
      <SuccessPopUp
        show={successEditModal}
        title={'Successfully Edit Form'}
        message={'Hooray! You have successfully edit form response'}
        labelSuccessButton={'Ok'}
        handleSuccessButton={() => {
          setEmailModal(false);
          setSuccessEditModal(false);
          setIsEditingMode(false);
          fetchDetailSubmission();
        }}
      />
      <LocationModal
        showModal={showLocationModal}
        setShowModal={setShowLocationModal}
        handleAllow={requestLocationPermission}
      />
      <SuccessPopUp
        show={successApprovalModal}
        title={'Successfully'}
        message={`Hooray! You have successfully ${
          approveCondition ? 'approve' : 'send back'
        } this form`}
        labelSuccessButton={'Ok, Back'}
        handleSuccessButton={() => {
          setSuccessApprovalModal(false);
          setApproveCondition(false);
          navigate(-1);
        }}
      />
    </div>
  );
};

export default DetailForm;
