import { useState } from 'react';
import { apiUrls, icons } from '../../../../constants';
import { getCountries } from 'react-phone-number-input';
import { getCountryCallingCode } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import { Toast } from '../../../../components';
import axios from 'axios';

const GuestRegistrationModal = ({ showModal, setShowModal, setSuccessPopup }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const config = {
    headers: {
      'Cache-Control': 'no-store'
    }
  };
  const axiosInstance = axios.create({ config, baseURL, timeout: 18000 });
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [countryCode, setCountryCode] = useState({
    label: `+${getCountryCallingCode('ID')}`,
    value: `+${getCountryCallingCode('ID')}`,
    country: 'ID'
  });
  const reset = () => {
    setEmailError(false);
    setPhoneNumberError(false);
    setEmail('');
    setPhoneNumber('');
    setShowModal(false);
  };
  const register = () => {
    var caseEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9-]+[.][A-Za-z]{2,}$/;
    var casePhoneNumber = /^\\d{1,20}$/;
    if (!caseEmail.test(email)) {
      setEmailError(true);
      toast.error(<Toast message={'Error'} detailedMessage={`Email is Invalid`} />);
    } else if (casePhoneNumber.test(phoneNumber)) {
      setPhoneNumberError(true);
      toast.error(<Toast message={'Error'} detailedMessage={`Phone Number is Invalid`} />);
    } else {
      setEmailError(false);
      setPhoneNumberError(false);
      axiosInstance
        .post(
          baseURL + apiUrls.ADD_GUEST_USER,
          {
            email: email,
            country_code: countryCode?.value,
            phone_number: phoneNumber
          },
          { headers: { 'Cache-Control': 'no-store' } }
        )
        .then(res => {
          if (res.status === 200) {
            reset();
            setSuccessPopup(true);
          } else {
            toast.error(<Toast message={'Error'} detailedMessage={res.data.error?.message} />);
          }
        })
        .catch(e => {
          toast.error(
            <Toast message={'Error'} detailedMessage={e?.response?.data?.error?.message} />
          );
        });
    }
    return;
  };
  const finalOption = getCountries().map(country => {
    return {
      label: `+${getCountryCallingCode(country)}`,
      value: country,
      country: country
    };
  });
  const Option = props => {
    return (
      <components.Option {...props}>
        <div className="flex flex-row">
          <img
            src={`https://catamphetamine.github.io/country-flag-icons/3x2/${props.data.country}.svg`}
            width={20}
            height={20}
            alt="flags"
          />
          <span className="ml-1">{props.data.label}</span>
        </div>
      </components.Option>
    );
  };

  const SingleValue = ({ ...props }) => {
    return (
      <components.SingleValue {...props}>
        <div className="flex flex-row">
          <img
            src={`https://catamphetamine.github.io/country-flag-icons/3x2/${props.data.country}.svg`}
            width={20}
            height={20}
            alt="flags"
          />
          <span className="ml-1">{props.data.label}</span>
        </div>
      </components.SingleValue>
    );
  };
  const CountrySelect = ({ value, onChange }) => (
    <Select
      value={value}
      options={finalOption}
      onChange={country => onChange(country)}
      components={{ Option, SingleValue }}
      className="w-[180px]"
      styles={{
        control: provided => ({
          ...provided,
          borderColor: 'none',
          ':focus': { borderColor: '#6546C3' },
          ':active': { borderColor: '#6546C3' },
          ':hover': { borderColor: '#6546C3' },
          paddingLeft: 4,
          boxShadow: 'none'
        }),
        dropdownIndicator: base => ({
          ...base,
          color: '#6546C3',
          paddingLeft: 0,
          ':hover': { color: '#6546C3' }
        }),
        indicatorSeparator: () => ({})
      }}
    />
  );
  return (
    <div>
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px] h-[60vh] sm:h-fit  p-0">
          <div className="m-8">
            <div className="bg-white">
              <div className="flex flex-row items-center justify-between">
                <div className="text-[18px] font-semibold">Guest Registration Form</div>
                <img
                  src={icons.icCloseModal}
                  className="w-6 h-6 cursor-pointer"
                  alt={'close-modal'}
                  onClick={() => setShowModal(false)}
                />
              </div>
              <hr className="mt-2 mb-6" />
              <div className="font-semibold text-[12px] mt-[28px] mb-[8px]">Email</div>
              <input
                type="text"
                className={`focus:outline-none ${
                  emailError ? 'border-[#C800A5]' : 'focus:border-[#6546C3]'
                } border text-[12px] w-full p-2 rounded-md`}
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="Enter your email"
              />
              <div className="font-semibold text-[12px] mt-[16px] mb-[8px]">Phone Number</div>
              <div className="flex flex-row">
                <CountrySelect value={countryCode} onChange={setCountryCode} />
                <input
                  type="number"
                  placeholder="ex: 8987404565"
                  value={phoneNumber}
                  onChange={value => setPhoneNumber(value.target.value)}
                  className={`focus:outline-none ${
                    phoneNumberError ? 'border-[#C800A5]' : 'focus:border-[#6546C3]'
                  } border text-[12px] w-full p-2 rounded-md ml-1`}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-center sm:justify-end bg-[#EBEDF8] w-full sm:w-[512px] md:w-[512px] mt-8 rounded-b-xl">
            <button
              className="rounded-lg border-2 border-[#6546C3] px-7 py-2 m-2 flex text-[#6546C3] font-semibold text-[14px]"
              onClick={reset}
            >
              Back
            </button>
            <button
              className="rounded-lg border-2 bg-[#6546C3] px-6 py-2 m-2 flex text-white font-semibold text-[14px]"
              onClick={register}
            >
              Register
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestRegistrationModal;
