const imageSplashHeader = require('../assets/images/img_splash_header.png');
const imageSplashFooter = require('../assets/images/img_splash_footer.png');
const imageSplashLogo = require('../assets/images/img_splash_logo.png');
const imageLoginSide = require('../assets/images/img_login_side.png');
const imageM2PLogo = require('../assets/images/img_m2p_logo.png');
const imageBackgroundNameDrawer = require('../assets/images/img_bg_name_drawer.png');
const imageHeader = require('../assets/images/img_header.png');
const imageHeaderMobile = require('../assets/images/img_header_mobile.png');
const imageDummyAvatar = require('../assets/images/img_avatar_dummy.png');
const imageBgOvalLeft = require('../assets/images/img_bg_oval_left.png');
const imageBgOvalRight = require('../assets/images/img_bg_oval_right.png');
const imageEmpty = require('../assets/images/img_empty.png');
const imageSuccess = require('../assets/images/img_success_modal.png');
const imageBgStatusTask = require('../assets/images/img_bg_status_task.png');
const imageConfirmation = require('../assets/images/img_confirmation.png');
const imageEmptyNotif = require('../assets/images/img_empty_notif.png');
const imageCreatePasswordSide = require('../assets/images/img_create_password_side.png');
const imageLocation = require('../assets/images/img_location.png');

export default {
  imageLoginSide,
  imageM2PLogo,
  imageSplashHeader,
  imageSplashFooter,
  imageSplashLogo,
  imageBackgroundNameDrawer,
  imageHeader,
  imageHeaderMobile,
  imageDummyAvatar,
  imageBgOvalLeft,
  imageBgOvalRight,
  imageEmpty,
  imageSuccess,
  imageBgStatusTask,
  imageConfirmation,
  imageEmptyNotif,
  imageCreatePasswordSide,
  imageLocation
};
