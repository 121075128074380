import { useDispatch, useSelector } from 'react-redux';
import { icons } from '../../constants';
import { setShowFormQRScan, setShowQRScan } from '../../store/slices/popup';

const QRCodeScanPage = () => {
  const showModal = useSelector(state => state.popup.showQrScan);
  const dispatch = useDispatch();
  return (
    <div>
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px] h-[35vh] sm:h-fit">
          <div className="flex flex-row items-center justify-between">
            <div className="text-[18px] font-semibold">Next Activity</div>
            <img
              src={icons.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              alt={'close-modal'}
              onClick={() => dispatch(setShowQRScan(false))}
            />
          </div>
          <hr className="mt-2 mb-4" />
          <div className="flex flex-row justify-center gap-16 ">
            <div
              onClick={() => {
                dispatch(setShowFormQRScan(true));
                dispatch(setShowQRScan(false));
              }}
              className="flex flex-col justify-center align-center gap-2"
            >
              <img src={icons.icFormScanner} alt="form" className="w-[80px] h-[80px] self-center" />
              <div className="text-[12px] text-[#232323] text-center w-full">Form Submission</div>
            </div>
            <div className="flex flex-col justify-center align-center gap-2">
              <img src={icons.icPointDrawer} alt="form" className="w-[80px] h-[80px] self-center" />
              <div className="text-[12px] text-[#232323] text-center w-full">Point Drawer</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QRCodeScanPage;
