import icons from './icons';

const guestMenus = [
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: false,
    name: 'Form',
    path: '/Form',
    crumbs: ['Form']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Detail Form',
    path: '/Task/DetailForm/:id',
    crumbs: ['Form', 'Detail Form']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Form Submission',
    path: '/Task/FormSubmission',
    crumbs: ['Form', 'Form Submission']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Detail Form Submission',
    path: '/Task/FormSubmission/DetailFormSubmission/:id',
    crumbs: ['Form', 'Form Submission', 'Detail Form Submission']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Detail Form Submission',
    path: '/Task/FormSubmission/DetailFormSubmission/:id/:parentId',
    crumbs: ['Form', 'Form Submission', 'Detail Form Submission']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Form Submission',
    path: '/Task/BrowseFormSubmission',
    crumbs: ['Form', 'Browse Form Submission']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Newest Submitted Document',
    path: '/Task/BrowseFormSubmission/NewestSubmittedDocument/:id',
    crumbs: ['Form', 'Browse Form Submission', 'Newest Submitted Document']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Detail Form Submission',
    path: '/Task/BrowseFormSubmission/NewestSubmittedDocument/:id/DetailFormSubmission/:responseId',
    crumbs: [
      'Form',
      'Browse Form Submission',
      'Newest Submitted Document',
      'Detail Form Submission'
    ]
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Guidance',
    path: '/Task/DetailForm/:id/Guidance',
    crumbs: ['Form', 'Detail Form', 'Guidance']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Guidance',
    path: '/Task/FormSubmission/DetailFormSubmission/:id/Guidance',
    crumbs: ['Form', 'Form Submission', 'Detail Form Submission', 'Guidance']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Guidance',
    path: '/Task/FormSubmission/DetailFormSubmission/:id/:parentId/Guidance',
    crumbs: ['Form', 'Form Submission', 'Detail Form Submission', 'Guidance']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Guidance',
    path: '/Task/BrowseFormSubmission/NewestSubmittedDocument/:id/DetailFormSubmission/:responseId/Guidance',
    crumbs: [
      'Form',
      'Browse Form Submission',
      'Newest Submitted Document',
      'Detail Form Submission',
      'Guidance'
    ]
  }
];

export default guestMenus;
