import { store } from '../../store';
import { handleParam, handleQuery } from './handler';
import httpInstance from './httpInstance';
import * as App from '../../index';
import { loginRequest } from '../../authConfig';
const _ = require('lodash');

const request = async config => {
  const { token } = store.getState().auth;
  let accessToken = token;
  const instance = App.msalInstance;
  const accounts = instance.getAllAccounts();

  const accessTokenResponse = await instance
    .acquireTokenSilent({
      ...loginRequest,
      forceRefresh: true,
      account: accounts[0]
    })
    .then(response => response)
    .catch(err => err);

  if (config.url.endsWith('/auth/login/azure')) {
    accessToken = accessTokenResponse?.accessToken;
  }
  config.headers = {
    ...config.headers,
    'Cache-Control': 'no-store',
    Authorization: `Bearer ${accessToken}`
  };
  // TODO:
  // - Check Network and Signal Strength
  // - Alert no internet connection

  return httpInstance.request(_.pick(config, ['url', 'method', 'data', 'headers', 'responseType']));
};

export const get = async (url, opts = {}) => {
  const options = {
    method: 'get',
    url: `${handleParam(url, opts.params)}${handleQuery(opts.query)}`,
    headers: opts.headers,
    responseType: opts.responseType
  };
  return request(options);
};

export const post = async (url, body, opts = {}) => {
  const options = {
    method: 'post',
    url: `${handleParam(url, opts.params)}${handleQuery(opts.query)}`,
    data: body,
    headers: opts.headers
  };

  return request(options);
};

export const put = async (url, body, opts = {}) => {
  const options = {
    method: 'put',
    url: `${handleParam(url, opts.params)}${handleQuery(opts.query)}`,
    data: body,
    headers: opts.headers
  };

  return request(options);
};

export const del = async (url, body, opts = {}) => {
  const options = {
    method: 'delete',
    url: `${handleParam(url, opts.params)}${handleQuery(opts.query)}`,
    data: body,
    headers: opts.headers
  };
  return request(options);
};
