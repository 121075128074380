const extensionImages = ['jpeg', 'png', 'jpg'];
const extensionVideos = ['mp4', 'mkv', 'avi', 'mov'];
const extensionSounds = ['wav', 'mp3', 'mpga', 'webm', 'audio/webm;codecs="opus"', 'weba'];
const fileTypes = [
  'audio/*',
  'video/*',
  'image/*',
  '.doc',
  '.docx',
  '.pdf',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  '.txt',
  '.csv',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

export default {
  extensionImages,
  fileTypes,
  extensionSounds,
  extensionVideos
};
