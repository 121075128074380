import { useDispatch, useSelector } from 'react-redux';
import { icons } from '../../constants';
import { BiDownArrow, BiUpArrow } from 'react-icons/bi';
import PaginationAccent from '../Pagination';
import { setPage, setPageSize, setSearch, setSortBy } from '../../store/slices/table';
import Loading from '../Loading';
import EmptyData from '../EmptyData';
const TableAccent = props => {
  const {
    isFetching,
    tableTitle,
    tableTitleOnTop,
    tableHeaders,
    data,
    className,
    buttonText,
    onButtonClick,
    children,
    iconFilter,
    filterLength,
    onFilterClick,
    emptyDataTitle,
    emptyDataDescription,
    offlineSearch
  } = props;
  const { limits, page, pageSize, search, sortBy } = useSelector(state => state.table);
  const dispatch = useDispatch();
  const onPageSizeChange = limit => {
    dispatch(setPage(1));
    dispatch(setPageSize(limit));
  };

  const calculateStep = () => {
    return `${page * pageSize - (pageSize - 1)} -${
      page * pageSize > data.total_data ? data.total_data : page * pageSize
    } `;
  };

  const onPrevPage = () => {
    if (page > 1) {
      dispatch(setPage(page - 1));

      return;
    }

    dispatch(setPage(1));
  };

  const onPageChange = pageNumber => dispatch(setPage(pageNumber));

  const onNextPage = () => {
    if (page === (data?.total_page || 1)) {
      dispatch(setPage(page));

      return;
    }

    dispatch(setPage(page + 1));
  };

  const searchData = ev => {
    switch (true) {
      case ev.key === 'Enter':
      case ev.target.value.length === 0:
        if (navigator.onLine) {
          dispatch(setSearch(ev.target.value));
        } else {
          offlineSearch(ev.target.value);
        }
        break;
      default:
        break;
    }
  };

  const transformSort = (sortString, sortKey) => {
    const resultSort = [];

    if (sortString) {
      const splittedSort = sortString.split(',');

      splittedSort
        .filter(item => item !== `-${sortKey}`)
        .forEach(item => {
          if (item === sortKey) {
            resultSort.push(`-${item}`);
          } else {
            resultSort.push(`${item}`);
          }
        });

      if (!splittedSort.some(item => item === sortKey || item === `-${sortKey}`)) {
        resultSort.push(`${sortKey}`);
      }

      return resultSort.toString();
    }

    resultSort.push(`${sortKey}`);

    return resultSort.toString();
  };

  const sortTable = (isSortable, key) => {
    if (isSortable) {
      const transformedSort = transformSort(sortBy, key);
      dispatch(setSortBy(transformedSort));
    }
  };

  const isHaveSync = () => {
    let rawHead = tableHeaders?.filter(head => head?.key === 'sync');
    return rawHead.length === 1;
  };

  return (
    <div className={`flex flex-col bg-white rounded-2xl m-[24px] ${className}`}>
      <div className="flex flex-col">
        <div className="p-6 flex flex-row justify-between items-center">
          <div className="w-1/3">
            {buttonText ? (
              <button
                className="btn normal-case bg-[#6546C3] text-white text-[14px] hover:bg-[#6546C3]"
                onClick={onButtonClick}
              >
                {buttonText}
              </button>
            ) : (
              tableTitleOnTop && (
                <div className="font-semibold text-[16px] text-[#232323]">{tableTitle}</div>
              )
            )}
          </div>
          <div className="flex flex-col justify-center">
            <div className="flex items-center gap-x-2 text-[14px]">
              Show
              <div className="flex relative bg-[#F2F5FC] rounded-lg p-1">
                <select
                  value={pageSize}
                  onChange={e => {
                    onPageSizeChange(Number(e.target.value));
                  }}
                  className="text-primary cursor-pointer appearance-none w-7 bg-[#F2F5FC] focus:outline-none"
                >
                  {limits.map(limit => (
                    <option key={limit} value={limit}>
                      {limit}
                    </option>
                  ))}
                </select>
                <div className="flex flex-col justify-center">
                  <BiUpArrow size="8px" color="#C800A5" />
                  <BiDownArrow size="8px" color="#C800A5" />
                </div>
              </div>
              Data
            </div>
          </div>
          <div className="pl-5 flex flex-row justify-end">
            <div className="flex flex-row-reverse sm:flex-row gap-4">
              {iconFilter && (
                <div
                  className="flex gap-1 border-[1px] border-[#463191] border-opacity-25 items-center justify-center px-1 min-w-[45px] w-fit h-[40px] rounded-lg cursor-pointer"
                  onClick={onFilterClick}
                >
                  {filterLength > 0 && (
                    <div className="py-0.5 px-2 rounded-md bg-[#6546C3] text-white font-semibold">
                      {filterLength}
                    </div>
                  )}
                  <img src={iconFilter} className="w-[26px] h-[26px]" alt="Filter" />
                </div>
              )}
              <div className="flex w-[150px] md:w-[300px] h-[40px] border-[1px] items-center p-[12px] rounded-lg">
                <img src={icons.icSearch} className="w-[14px] h-[14px]" alt="Search" />
                <input
                  className="ml-2 focus:outline-none text-[12px] w-full"
                  key={search}
                  defaultValue={search}
                  placeholder="Search here..."
                  onKeyDown={searchData}
                  onBlur={searchData}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="overflow-x-auto overflow-y-hidden mx-[24px]">
          {!tableTitleOnTop && (
            <div className="font-semibold text-[16px] text-[#232323]">{tableTitle}</div>
          )}
          <table className="flex table w-full border-spacing-y-[16px] border-separate">
            <thead className="justify-center">
              <tr>
                {tableHeaders &&
                  tableHeaders.length &&
                  tableHeaders.map(item => (
                    <th
                      key={item.key}
                      className={`cursor-pointer bg-white ${
                        item.name === 'Action'
                          ? `${isHaveSync() ? 'sticky left-10' : 'sticky left-0'}`
                          : ''
                      }`}
                      onClick={() => sortTable(item.isSortable, item.sortKey)}
                    >
                      <div
                        className={`flex flex-row ${
                          item.name === 'Action' ? 'justify-center' : ''
                        }`}
                      >
                        <span className="text-[#232323] normal-case text-[14px]">{item.name}</span>
                      </div>
                    </th>
                  ))}
              </tr>
            </thead>
            {isFetching ? (
              <tbody>
                <tr>
                  <td colSpan={tableHeaders.length}>
                    <div className="flex flex-col flex-grow items-center justify-center">
                      <Loading />
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : data?.data && data?.data.length ? (
              <tbody>
                {data?.data &&
                  data?.data.length &&
                  data?.data.map((item, index) => (
                    <tr
                      className="bg-white rounded-full"
                      key={item.id}
                      style={{ boxShadow: '0 5px 15px 0 rgba(0, 0, 0, 0.06)' }}
                    >
                      {tableHeaders &&
                        tableHeaders.length &&
                        tableHeaders.map(tHead => (
                          <td
                            className={`p-[16px] ${
                              tHead.key === 'action' && isHaveSync()
                                ? 'sticky left-10'
                                : tHead.key === 'action'
                                ? 'sticky left-0'
                                : tHead.key === 'sync'
                                ? 'sticky left-0'
                                : ''
                            }`}
                            key={tHead.key}
                          >
                            {children(item, tHead, index)}
                          </td>
                        ))}
                    </tr>
                  ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={tableHeaders.length}>
                    <EmptyData title={emptyDataTitle} message={emptyDataDescription} />
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
        {data?.total_data ? (
          <div className="p-5 flex flex-row justify-between">
            <span>{`Showing ${calculateStep()} dari ${data?.total_data || 0}  data`}</span>
            <PaginationAccent
              {...{
                page,
                totalPage: data?.total_page || 1,
                onPrev: onPrevPage,
                onChangePage: onPageChange,
                onNext: onNextPage
              }}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TableAccent;
