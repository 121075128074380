export default {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
    maxWidth: '90%'
  },

  '&multiLine': {
    control: {
      fontFamily: 'monospace',
      minHeight: 63
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent'
    },
    input: {
      padding: 9,
      border: '1px solid silver'
    }
  },

  '&singleLine': {
    display: 'inline-block',
    maxWidth: '100%',

    input: {
      outline: 'none',
      padding: 1,
      border: '2px inset transparent',
      maxWidth: '90%'
    },

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
      maxWidth: '90%'
    }
  },

  suggestions: {
    list: {
      borderRadius: 8,
      backgroundColor: '#232323',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
      color: 'white'
    },
    item: {
      padding: '5px 15px ',
      borderBottom: '1px solid rgba(0,0,0,0.15)'
    }
  }
};
