import Field from '../../../Field';
import { toast } from 'react-toastify';
import { Toast } from '../../../../../../../components';
import { useState } from 'react';
import _ from 'lodash';

const MandatoryFieldModal = ({
  showModal,
  setShowModal,
  sections,
  sectionId,
  subSectionIndex,
  onChangeValueField,
  onChangeCheckBox,
  onChangeRadio,
  onAddAttachment,
  removeFile,
  onChangeValueSubSection,
  onChangeSubCheckBox,
  onChangeSubRadio,
  onAddSubAttachment,
  removeSubSectionFile
}) => {
  const [fieldIndex, setIndex] = useState(0);

  const submit = () => {
    if (
      sections[fieldIndex].field?.value === '' ||
      (_.isArray(sections[fieldIndex].field?.value) && !sections[fieldIndex].field?.value.length)
    ) {
      toast.error(
        <Toast message={'Error'} detailedMessage={`All mandatory field cannot be empty`} />
      );
    } else {
      if (fieldIndex === sections.length) setShowModal(false);
      else setIndex(fieldIndex + 1);
    }
  };

  const isSubsection = () => subSectionIndex !== -1;

  return (
    sections[fieldIndex]?.field?.mandatory && (
      <>
        <input
          type="checkbox"
          id="filterModal"
          checked={showModal}
          onChange={e => e.preventDefault()}
          className="modal-toggle"
        />
        <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
          <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px]  sm:h-fit">
            <div className="bg-white">
              <div className="flex items-center justify-between flex-wrap">
                <div className="font-semibold text-[18px] text-[#C800A5]">Mandatory Field</div>
              </div>
              <hr className="mt-2 mb-6" />
              <div className="flex flex-col gap-4">
                <div>
                  <Field
                    key={sections[fieldIndex]?.field?.field_id}
                    field={sections[fieldIndex]?.field}
                    submitted={false}
                    sectionId={sectionId}
                    onChange={(event, additionalIndex) => {
                      isSubsection()
                        ? onChangeValueSubSection(
                            event,
                            additionalIndex,
                            sections[fieldIndex]?.fieldIndex,
                            subSectionIndex,
                            sections[fieldIndex].sectionIndex
                          )
                        : onChangeValueField(
                            event,
                            additionalIndex,
                            sections[fieldIndex]?.fieldIndex,
                            sections[fieldIndex].sectionIndex
                          );
                    }}
                    onChangeCheck={(e, label, index) => {
                      isSubsection()
                        ? onChangeSubCheckBox(
                            e,
                            label,
                            index,
                            sections[fieldIndex]?.fieldIndex,
                            subSectionIndex,
                            sections[fieldIndex].sectionIndex
                          )
                        : onChangeCheckBox(
                            e,
                            label,
                            index,
                            sections[fieldIndex]?.fieldIndex,
                            null,
                            sections[fieldIndex].sectionIndex
                          );
                    }}
                    onChangeRadioButton={(e, label, index) => {
                      isSubsection()
                        ? onChangeSubRadio(
                            e,
                            label,
                            index,
                            sections[fieldIndex]?.fieldIndex,
                            subSectionIndex,
                            sections[fieldIndex].sectionIndex
                          )
                        : onChangeRadio(
                            e,
                            label,
                            index,
                            sections[fieldIndex]?.fieldIndex,
                            null,
                            sections[fieldIndex].sectionIndex
                          );
                    }}
                    addAttachment={(file, additionalIndex) => {
                      isSubsection()
                        ? onAddSubAttachment(
                            file,
                            additionalIndex,
                            sections[fieldIndex]?.fieldIndex,
                            subSectionIndex,
                            sections[fieldIndex]?.sectionIndex
                          )
                        : onAddAttachment(
                            file,
                            additionalIndex,
                            sections[fieldIndex]?.fieldIndex,
                            null,
                            sections[fieldIndex].sectionIndex
                          );
                    }}
                    onRemoveFile={(indexFile, additionalIndex) => {
                      isSubsection()
                        ? removeSubSectionFile(
                            indexFile,
                            additionalIndex,
                            sections[fieldIndex]?.fieldIndex,
                            subSectionIndex,
                            sections[fieldIndex]?.sectionIndex
                          )
                        : removeFile(
                            indexFile,
                            additionalIndex,
                            sections[fieldIndex]?.fieldIndex,
                            null,
                            sections[fieldIndex]?.sectionIndex
                          );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-center sm:justify-end w-full flex-row mt-9 gap-6">
              <button
                className="bg-[#6546C3] border-2 border-[#6546C3] rounded-lg py-3 px-8 font-semibold text-white text-[14px]r"
                onClick={submit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default MandatoryFieldModal;
