import { useEffect, useRef, useState } from 'react';
import { apiUrls, icons } from '../../../../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Toast } from '../../../../../../../components';
import { setShowLoading } from '../../../../../../../store/slices/popup';
import { del, post } from '../../../../../../../api/base';
import _ from 'lodash';
import { useNavigate } from 'react-router';
import { cacheHelper, offlineHelper } from '../../../../../../../helpers';
import { v4 as uuidv4 } from 'uuid';
const ImageFromGalleryModal = ({ showModal, setShowModal, id, setTask, setComments }) => {
  const [attachments, setAttachments] = useState([]);
  const [rawAttachment, setRawAttachments] = useState([]);
  const [displayImage, setDisplayImage] = useState({});
  const [remark, setRemark] = useState('');
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const user = useSelector(state => state.user.userData);
  const dispatch = useDispatch();
  const uuid = uuidv4();
  async function handleAddAttachment({ target: { files } }) {
    if (!files.length) return;
    const fileSizeUser = user?.file_size * 1048576;
    const formData = new FormData();
    for (let index = 0; index < files.length; index++) {
      formData.append('files[]', files[index]);
    }
    let statusFile;
    if (attachments?.length <= 5) {
      if (files.length <= 5) {
        for (let i = 0; i < files.length; i++) {
          const isImage = files[i].type.match('image') ? true : false;
          if (files[i].size > fileSizeUser) {
            statusFile = false;
            toast.error(
              <Toast
                message={'Error'}
                detailedMessage={`Cannot upload ${files[i].name} because the size of file is more than ${fileSizeUser} mb`}
              />
            );
          } else {
            statusFile = true;
            if (isImage) {
              formData.append('files[]', {
                uri: files[i].fileCopyUri,
                type: files[i].type,
                name: files[i].name
              });
            } else {
              toast.error(
                <Toast
                  message={'Error'}
                  detailedMessage={`Cannot upload ${files[i].name} because the file is not an image`}
                />
              );
              return;
            }
          }
        }
        if (statusFile || formData._parts.length) {
          dispatch(setShowLoading(true));
          if (navigator.onLine) {
            post(`${apiUrls.TASK}/attachments`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(response => {
              if (response.status === 200) {
                let rawAttachment = attachments.concat(response.data);
                setAttachments(rawAttachment);
                setDisplayImage(rawAttachment[rawAttachment.length - 1]);
              } else if (response.status === 0) {
                toast.error(
                  <Toast
                    message={'Error'}
                    detailedMessage={'Cannot Reach server, please try again later'}
                  />
                );
              } else {
                toast.error(
                  <Toast message={'Error'} detailedMessage={`${response.data.error.message}`} />
                );
              }
              dispatch(setShowLoading(false));
            });
          } else {
            const tempAttachment = await Promise.all(
              formData
                .getAll('files[]')
                .filter(data => data instanceof File)
                .map(async file => {
                  return await offlineHelper.saveAttachmentOffline(file);
                })
            );
            const newAttachments = [];
            setRawAttachments(rawAttachment.concat(tempAttachment));
            if (!_.isEmpty(tempAttachment) && _.has(tempAttachment[0], 'cacheIdentifier')) {
              for (const value of tempAttachment) {
                await cacheHelper.getCachedAttachment(value.cacheIdentifier).then(res => {
                  newAttachments.push(res);
                });
              }
              setAttachments(attachments.concat(newAttachments));
              setDisplayImage([attachments.length, ...newAttachments]);
            } else {
              setAttachments(attachments.concat(attachments));
            }

            dispatch(setShowLoading(false));
          }
        }
      } else {
        toast.error(
          <Toast message={'Error'} detailedMessage={'Maximum of uploaded file is 5 files'} />
        );
      }
    } else {
      toast.error(
        <Toast
          message={'Error'}
          detailedMessage={'You have reached the maximum number of file uploads, maximum 5'}
        />
      );
    }
    fileInputRef.current.value = null;
  }

  const deleteAttachment = id => {
    dispatch(setShowLoading(true));
    del(`${apiUrls.TASK}/attachments/${id}`).then(response => {
      dispatch(setShowLoading(false));
      if (response.status === 200) {
        setAttachments(prevAttachments => prevAttachments.filter(attach => attach.id !== id));
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={`${response.data.error.message}`} />);
      }
    });
  };

  const handleCloseModal = () => {
    setAttachments([]);
    setDisplayImage({});
    setShowModal(false);
  };

  const handleDeleteAttachment = id => {
    if (navigator.onLine) {
      let temp = attachments.filter(attachment => attachment.id !== id);
      if (_.isArray(attachments)) {
        deleteAttachment(id);
      }
      if (temp.length) {
        setDisplayImage(temp[temp.length - 1]);
      } else {
        handleCloseModal();
      }
    } else {
      cacheHelper.deleteCachedAttachment(rawAttachment[id].cacheIdentifier);
      rawAttachment.splice(id, 1);
      attachments.splice(id, 1);
      setAttachments([...attachments]);
      dispatch(setShowLoading(false));
      if (attachments.length) {
        setDisplayImage([attachments.length - 1, attachments[attachments.length - 1]]);
      } else {
        handleCloseModal();
      }
    }
  };

  const handleSendButton = () => {
    let payload = {
      remark: {
        message: remark
      },
      attachments: navigator.onLine ? attachments : rawAttachment,
      description: 'Added Images'
    };
    dispatch(setShowLoading(true));
    if (navigator.onLine) {
      post(`${apiUrls.TASK}/${id}/comments`, payload)
        .then(response => {
          dispatch(setShowLoading(false));
          if (response.status === 201) {
            handleCloseModal();
            navigate(0);
          } else {
            toast.error(<Toast message={'Error'} detailedMessage={response.data.error.message} />);
          }
        })
        .catch(e => {
          dispatch(setShowLoading(false));
          toast.error(<Toast message={'Error'} detailedMessage={e} />);
        });
    } else {
      dispatch(setShowLoading(false));
      let tempTask;
      let tempComment = {
        id: 'offline' + uuid,
        remark: {
          message: [{ value: remark, type: 'TEXT' }]
        },
        description: 'Added Images',
        attachments: rawAttachment,
        recording: null,
        created_at: new Date().toISOString(),
        sender: user?.full_name
      };
      cacheHelper
        .getCachedData(apiUrls.TASK)
        .then(response => {
          let index = response.data?.findIndex(task => task.id.toString() === id.toString());
          tempTask = response.data;
          tempTask[index].comments.unshift(tempComment);
          offlineHelper
            .updateRequestDataOffline({
              url: `${apiUrls.TASK}/${id}/comments`,
              body: payload,
              cacheUrl: apiUrls.TASK,
              key: 'data',
              data: [...tempTask]
            })
            .then(_ => {
              setTask(tempTask[index]);
              setComments(tempTask[index].comments.sort((a, b) => b.id - a.id));
              handleCloseModal();
            })
            .catch(_ => {
              dispatch(setShowLoading(false));
              toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
            });
        })
        .finally(() => {
          dispatch(setShowLoading(false));
        });
    }
  };

  useEffect(() => {
    if (showModal) fileInputRef.current.click();
  }, [showModal]);

  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }}
        className="absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer"
        multiple={true}
        accept={'image/*'}
        onChange={handleAddAttachment}
      />
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-middle bg-[#232323]/[0.5]">
        <div className="flex flex-row justify-between items-center bg-[#6546C3] w-full top-0 fixed px-4 sm:px-16 py-4">
          <div className="flex flex-row gap-4 items-center" onClick={handleCloseModal}>
            <img
              src={icons.icCloseModal}
              alt="close"
              className="w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] cursor-pointer"
            />
            <div className="font-semibold text-[12px] text-white">Close</div>
          </div>
          <button
            className="bg-[#EBEDF8] w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] rounded-xl flex items-center justify-center"
            onClick={e => {
              e.stopPropagation();
              handleDeleteAttachment(navigator.onLine ? displayImage?.id : displayImage[0]);
            }}
          >
            <img
              src={icons.icTrash}
              className="w-[20px] h-[20px] sm:w-[30px] sm:h-[30px]"
              alt="clear"
            />
          </button>
        </div>
        <div className="flex flex-col items-center gap-6 w-full">
          {!_.isEmpty(displayImage) ? (
            <div className="modal-box flex flex-col bg-transparent sm:min-w-[400px] sm:w-fit max-h-[350px] sm:max-h-[400px] p-0">
              <img
                className="w-[400px] h-[400x] cursor-pointer"
                src={displayImage?.url || window.URL?.createObjectURL(displayImage[1])}
                alt="image_choosed"
              />
            </div>
          ) : (
            <div className="modal-box flex flex-col bg-white sm:min-w-[400px] sm:w-fit h-[350px] sm:h-[400px] p-0 items-center justify-center">
              No Image To Display
            </div>
          )}
          <div className="flex flex-row items-center justify-center gap-2 w-full mx-[18px] sm:mx-0">
            <div className="flex bg-white w-full h-[40px] border-[1px] items-center p-[12px] rounded-lg  rounded-lg">
              <img
                src={icons.icGallery}
                className="w-[24px] h-[24px] cursor-pointer"
                alt="Attachment"
                onClick={() => fileInputRef.current.click()}
              />
              <input
                className="ml-2 focus:outline-none text-[12px] w-full"
                value={remark}
                onChange={event => setRemark(event.target.value)}
                placeholder="Add remark..."
              />
            </div>
            <div
              className="bg-[#6546C3] p-[6px] rounded-lg cursor-pointer"
              onClick={handleSendButton}
            >
              <img src={icons.icSend} className="w-[24px] h-[24px] " alt="send" />
            </div>
          </div>
        </div>

        <div className="flex flex-row items-center bg-white w-full bottom-0 fixed px-4 sm:px-16 py-4 rounded-t-3xl gap-8">
          {attachments?.map((attachment, index) => {
            return (
              <img
                onClick={() =>
                  navigator.onLine
                    ? setDisplayImage(attachment)
                    : setDisplayImage([index, attachment])
                }
                key={index}
                src={attachment?.url || window.URL?.createObjectURL(attachment)}
                alt={attachment?.fileName || attachment?.name}
                className="w-[64px] h-[66px] rounded-lg"
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ImageFromGalleryModal;
