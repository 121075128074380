import { images } from '../../constants';

const Splash = () => {
  return (
    <div className="w-[400px] h-screen flex flex-col justify-between mx-auto bg-[#6546C3]">
      <img src={images.imageSplashHeader} alt="Splash Header" />
      <div className={'flex flex-col items-center gap-5 px-5'}>
        <img className={'w-[152px] h-[52px]'} src={images.imageM2PLogo} alt="M2P Logo" />
        <progress className="progress progress-white" />
      </div>
      <img src={images.imageSplashFooter} alt="Splash Footer" />
    </div>
  );
};

export default Splash;
