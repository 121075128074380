import { createSlice } from '@reduxjs/toolkit';

const authState = {
  token: null,
  refreshToken: null,
  username: null,
  password: null,
  uuid: null,
  fcmToken: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: authState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    logout: state => {
      state.token = null;
      state.refreshToken = null;
      state.username = null;
      state.password = null;
      state.uuid = null;
    },
    saveUuid: (state, action) => {
      state.uuid = action.payload;
    },
    savePassword: (state, action) => {
      state.password = action.payload;
    },
    saveUsername: (state, action) => {
      state.username = action.payload;
    },
    saveFcmToken: (state, action) => {
      state.fcmToken = action.payload;
    }
  }
});

export const {
  setToken,
  setRefreshToken,
  logout,
  saveUuid,
  savePassword,
  saveUsername,
  saveFcmToken
} = authSlice.actions;

export const selectToken = state => state.auth.token;

export default authSlice.reducer;
