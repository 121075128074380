import { useEffect, useState } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import { useDispatch, useSelector } from 'react-redux';
import { setShowFormQRScan } from '../../../store/slices/popup';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { Toast } from '../../../components';
const QRFormScanPage = () => {
  const showFormQRScan = useSelector(state => state.popup.showFormQRScan);
  const [formId, setFormId] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [camera, setCamera] = useState('');

  const init = () => {
    let html5QrCode = new Html5Qrcode('reader');
    setCamera(html5QrCode);
    const qrCodeSuccessCallback = decodedText => {
      let splittedUrl = decodedText.split('/');
      setFormId(splittedUrl[splittedUrl?.length - 1]);
    };
    const config = { fps: 10 };
    html5QrCode
      .start({ facingMode: 'environment' }, config, qrCodeSuccessCallback)
      .catch(() =>
        toast.error(<Toast message={'Error'} detailedMessage={'camera device not available'} />)
      );
  };

  useEffect(() => {
    if (showFormQRScan) init();
  }, [showFormQRScan]);

  return (
    <div>
      <div>
        <input
          type="checkbox"
          id="filterModal"
          checked={showFormQRScan}
          onChange={e => e.preventDefault()}
          className="modal-toggle"
        />
        <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
          <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px] h-[75vh] sm:h-fit p-0">
            <div className="p-8">
              <div className="text-[18px] font-semibold">Form Submission</div>
              <hr className=" mb-4" />
              {showFormQRScan && (
                <div id="reader" className="flex justify-center align-center relative"></div>
              )}
              <div className="text-[18px] mt-[20px] font-semibold">Trouble Scanning By QR Code</div>
              <div className="text-[12px] mt-[8px] text-[#a0a1b499]">
                You can enter form id manually here
              </div>
              <div className="font-semibold text-[12px] mt-[16px] mb-[12px]">Form Id</div>
              <input
                type="number"
                className="focus:outline-none focus:border-[#6546C3] border text-[12px] w-full p-2 rounded-md"
                placeholder="Form Id"
                value={formId}
                onChange={e => {
                  setFormId(e?.target?.value);
                }}
              />
              <div className="flex flex-row justify-center sm:justify-end w-full mt-[32px] rounded-b-xl">
                <button
                  className="rounded-lg border-2 border-[#6546C3] px-7 py-2 m-2 flex text-[#6546C3] font-semibold text-[14px]"
                  onClick={() => {
                    dispatch(setShowFormQRScan(false));
                    if (camera.getState() !== 1 && camera.getState() !== 0) {
                      camera.stop();
                    }
                  }}
                >
                  Back
                </button>
                <button
                  className="rounded-lg border-2 bg-[#6546C3] px-6 py-2 m-2 flex text-white font-semibold text-[14px]"
                  onClick={() => {
                    if (formId?.length) {
                      camera.stop().then(_ => {
                        navigate(`/Task/FormSubmission/DetailFormSubmission/${formId}`, {
                          replace: true
                        });
                        setFormId('');
                        dispatch(setShowFormQRScan(false));
                      });
                    }
                  }}
                >
                  Process
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QRFormScanPage;
