const apiBaseUrl = process.env.REACT_APP_BASE_URL;
/* eslint-disable no-console */
let cacheStorage;
caches
  .open('api-data')
  .then(cache => {
    cacheStorage = cache;
  })
  .catch(error => {
    console.error('Failed to open cache storage:', error);
  });

const putDatatoCache = ({ cacheData, dataObject, cacheUrl }) => {
  const responseHeader = new Headers();

  cacheData.headers.forEach((value, key) => {
    responseHeader.append(key, value);
  });

  const responseOptions = {
    status: cacheData.status,
    statusText: cacheData.statusText,
    headers: responseHeader
  };

  const newResponse = new Response(JSON.stringify(dataObject), responseOptions);
  cacheStorage.put(apiBaseUrl + cacheUrl, newResponse);
};

const addDataToCache = async (data, { cacheUrl, key = 'data' }) => {
  const cacheData = await cacheStorage.match(apiBaseUrl + cacheUrl);
  const dataObject = JSON.parse(await cacheData.text());
  dataObject[key].unshift(data);

  putDatatoCache({ cacheData, dataObject, cacheUrl });
};

const updateDataCache = async (data, { cacheUrl, key = 'data' }) => {
  const cacheData = await cacheStorage.match(apiBaseUrl + cacheUrl);
  const dataObject = JSON.parse(await cacheData.text());
  dataObject[key] = data;

  putDatatoCache({ cacheData, dataObject, cacheUrl });
};

const getCachedData = async url => {
  const cacheStorage = await caches.open('api-data');
  const cacheData = await cacheStorage.match(apiBaseUrl + url);

  const dataObject = JSON.parse(await cacheData.text());
  return dataObject;
};

const cacheAttachment = async attachment => {
  const cacheStorage = await caches.open('attachments');
  const response = new Response(attachment, {
    status: 200,
    statusText: 'OK',
    headers: { 'Content-Type': attachment.type }
  });

  const metaData = {
    name: attachment.name,
    type: attachment.type
  };

  response.headers.append('X-Attachment-Metadata', JSON.stringify(metaData));

  const identifier = `/${Date.now()}_${attachment.name}`;

  await cacheStorage.put(identifier, response);
  return identifier;
};

const getCachedAttachment = async identifier => {
  const cacheStorage = await caches.open('attachments');
  const cacheData = await cacheStorage.match(identifier);

  if (cacheData) {
    const metaData = JSON.parse(cacheData.headers.get('X-Attachment-Metadata'));
    return new File([await cacheData.blob()], metaData.name, { type: metaData.type });
  }

  return null;
};

const deleteCachedAttachment = async identifier => {
  const cacheStorage = await caches.open('attachments');
  await cacheStorage.delete(identifier);
};

export default {
  addDataToCache,
  getCachedData,
  cacheAttachment,
  getCachedAttachment,
  deleteCachedAttachment,
  updateDataCache
};
