import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setOrderBy } from '../../../../../store/slices/table';
import { icons, apiUrls } from '../../../../../constants';
import Select from 'react-select';
import { get } from '../../../../../api/base';
import { toast } from 'react-toastify';
import { Toast } from '../../../../../components';

const FilterModal = ({
  showModal,
  setShowModal,
  filterAttribute,
  setFilterAttribute,
  filterTasked,
  setPageRef,
  setOfflineFilterAttribute
}) => {
  const [locationList, setLocationList] = useState([]);
  const [typelist, setTypeList] = useState([]);
  const [type, setType] = useState([]);
  const [location, setLocation] = useState([]);
  const [tempFilterAttribute, setTempFilterAttribute] = useState({
    order_by: null,
    ...filterAttribute
  });
  const dispatch = useDispatch();

  const sortBy = value => {
    setTempFilterAttribute({ ...tempFilterAttribute, order_by: value });
  };
  const reset = () => {
    setType([]);
    setLocation([]);
    setTempFilterAttribute({ order_by: null, ...filterTasked });
  };

  const fetchType = () => {
    get(apiUrls.ISSUE_TYPES).then(res => {
      if (res.status === 200) {
        setTypeList(res.data);
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={res.data.error.message} />);
      }
    });
  };

  const fetchLocation = () => {
    get(apiUrls.ISSUE_WORK_LOCATION).then(res => {
      const { status } = res;
      if (status === 200) {
        setLocationList(res.data);
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={res.data.error.message} />);
      }
    });
  };

  useEffect(() => {
    if (showModal) {
      fetchType();
      fetchLocation();
    }
  }, [showModal]);

  return (
    <div>
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px] h-fit p-0">
          <div className="bg-white p-6">
            <div className="flex flex-row items-center justify-between">
              <div className="text-[18px] font-semibold">Filter</div>
              <img
                src={icons.icCloseModal}
                className="w-6 h-6 cursor-pointer"
                alt={'close-modal'}
                onClick={() => setShowModal(false)}
              />
            </div>
            <hr className="mt-2 mb-6" />

            <div className="font-semibold text-[12px] mt-[28px] mb-[16px]">Issue Type</div>
            <Select
              onChange={selectedOptions => {
                setType(selectedOptions);
                setTempFilterAttribute({
                  ...tempFilterAttribute,
                  issue_type: selectedOptions?.name
                });
              }}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.name}
              options={typelist}
              name="Type"
              className={
                'input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4'
              }
              placeholder="Select type"
              isClearable
              value={type}
              styles={{
                option: provided => ({
                  ...provided
                }),
                control: provided => ({
                  ...provided,
                  ':focus': { borderColor: '#6546C3' },
                  ':active': { borderColor: '#6546C3' },
                  ':hover': { borderColor: '#6546C3' },
                  paddingLeft: 4,
                  boxShadow: 'none'
                }),
                dropdownIndicator: provided => ({
                  ...provided,
                  color: '#6546C3'
                }),
                indicatorSeparator: () => ({}),
                valueContainer: provided => ({
                  ...provided
                })
              }}
            />

            <div className="font-semibold text-[12px] mt-[28px] mb-[16px]">Last Update</div>
            <div
              className={`flex flex-row cursor-pointer border border-2 rounded-md p-2 items-center  ${
                tempFilterAttribute?.order_by === 'desc'
                  ? 'border-[#6546C3] bg-[#6546C3] bg-opacity-[0.2]'
                  : ''
              }`}
              onClick={() =>
                tempFilterAttribute?.order_by === 'desc' ? sortBy(null) : sortBy('desc')
              }
            >
              <div
                className={`text-[12px] ${
                  tempFilterAttribute?.order_by === 'desc' ? 'text-[#6546C3] font-semibold' : ''
                }`}
              >
                Latest
              </div>
              <div
                className={`ml-auto border border-2 rounded-full w-[16px] h-[16px] border-[#6546C3] ${
                  tempFilterAttribute?.order_by === 'desc' ? 'bg-[#6546C3]' : 'bg-transparent'
                }`}
              />
            </div>

            <div
              className={`flex flex-row cursor-pointer border border-2 rounded-md p-2 items-center mt-[8px] ${
                tempFilterAttribute?.order_by === 'asc'
                  ? 'border-[#6546C3] bg-[#6546C3] bg-opacity-[0.2]'
                  : ''
              }`}
              onClick={() =>
                tempFilterAttribute?.order_by === 'asc' ? sortBy(null) : sortBy('asc')
              }
            >
              <div
                className={`text-[12px] ${
                  tempFilterAttribute?.order_by === 'asc' ? 'text-[#6546C3] font-semibold' : ''
                }`}
              >
                Earlier
              </div>
              <div
                className={`ml-auto border border-2 rounded-full w-[16px] h-[16px] border-[#6546C3] ${
                  tempFilterAttribute?.order_by === 'asc' ? 'bg-[#6546C3]' : 'bg-transparent'
                }`}
              />
            </div>
            <div className="font-semibold text-[12px] mt-[28px] mb-[16px]">Saverity</div>
            <div className="flex flex-row justify-between gap-6">
              <div
                className={`flex flex-row flex-grow cursor-pointer border border-2 rounded-md p-1 items-center   ${
                  tempFilterAttribute.saverity?.LOW
                    ? 'border-[#6546C3] bg-[#6546C3] bg-opacity-[0.2]'
                    : ''
                }`}
                onClick={() =>
                  setTempFilterAttribute({
                    ...tempFilterAttribute,
                    saverity: {
                      LOW: !tempFilterAttribute.saverity.LOW,
                      MEDIUM: false,
                      HIGH: false
                    }
                  })
                }
              >
                <div className="w-[6px] mr-2  h-[24px] rounded-md  border-2 border-[#01B59C] bg-[#01B59C]" />
                <div
                  className={`text-[12px] ${
                    tempFilterAttribute.saverity?.LOW
                      ? 'text-[#6546C3] font-semibold'
                      : 'text-[#AAAAAA]'
                  }`}
                >
                  Low
                </div>
              </div>
              <div
                className={`flex flex-row flex-grow cursor-pointer border border-2 rounded-md p-2 items-center   ${
                  tempFilterAttribute.saverity?.MEDIUM
                    ? 'border-[#6546C3] bg-[#6546C3] bg-opacity-[0.2]'
                    : ''
                }`}
                onClick={() =>
                  setTempFilterAttribute({
                    ...tempFilterAttribute,
                    saverity: {
                      LOW: false,
                      MEDIUM: !tempFilterAttribute.saverity.MEDIUM,
                      HIGH: false
                    }
                  })
                }
              >
                <div className="w-[6px] mr-2  h-[24px] rounded-md  border-2 border-[#FF7301] bg-[#FF7301]" />
                <div
                  className={`text-[12px] ${
                    tempFilterAttribute.saverity?.MEDIUM
                      ? 'text-[#6546C3] font-semibold'
                      : 'text-[#AAAAAA]'
                  }`}
                >
                  Medium
                </div>
              </div>
              <div
                className={`flex flex-row flex-grow cursor-pointer border border-2 rounded-md p-2 items-center justify-center  ${
                  tempFilterAttribute.saverity?.HIGH
                    ? 'border-[#6546C3] bg-[#6546C3] bg-opacity-[0.2]'
                    : ''
                }`}
                onClick={() =>
                  setTempFilterAttribute({
                    ...tempFilterAttribute,
                    saverity: {
                      LOW: false,
                      MEDIUM: false,
                      HIGH: !tempFilterAttribute.saverity.HIGH
                    }
                  })
                }
              >
                <div className="w-[6px] mr-2  h-[24px] rounded-md  border-2 border-[#C800A5] bg-[#C800A5]" />
                <div
                  className={`text-[12px] ${
                    tempFilterAttribute.saverity?.HIGH
                      ? 'text-[#6546C3] font-semibold'
                      : 'text-[#AAAAAA]'
                  }`}
                >
                  High
                </div>
              </div>
            </div>
            <div className="font-semibold text-[12px] mt-[28px] mb-[16px]">Status</div>
            <div className="flex flex-row justify-between gap-6">
              <div
                className={`flex flex-row flex-grow cursor-pointer border border-2 rounded-md p-2 items-center justify-center  ${
                  tempFilterAttribute.status?.DRAFT
                    ? 'border-[#6546C3] bg-[#6546C3] bg-opacity-[0.2]'
                    : ''
                }`}
                onClick={() =>
                  setTempFilterAttribute({
                    ...tempFilterAttribute,
                    status: {
                      OPEN: false,
                      DRAFT: !tempFilterAttribute.status.DRAFT,
                      CLOSED: false
                    }
                  })
                }
              >
                <div
                  className={`text-[12px] ${
                    tempFilterAttribute.status?.DRAFT
                      ? 'text-[#6546C3] font-semibold'
                      : 'text-[#AAAAAA]'
                  }`}
                >
                  Draft
                </div>
              </div>
              <div
                className={`flex flex-row flex-grow cursor-pointer border border-2 rounded-md p-2 items-center justify-center  ${
                  tempFilterAttribute.status?.OPEN
                    ? 'border-[#6546C3] bg-[#6546C3] bg-opacity-[0.2]'
                    : ''
                }`}
                onClick={() =>
                  setTempFilterAttribute({
                    ...tempFilterAttribute,
                    status: {
                      OPEN: !tempFilterAttribute.status.OPEN,
                      DRAFT: false,
                      CLOSED: false
                    }
                  })
                }
              >
                <div
                  className={`text-[12px] ${
                    tempFilterAttribute.status?.OPEN
                      ? 'text-[#6546C3] font-semibold'
                      : 'text-[#AAAAAA]'
                  }`}
                >
                  Open
                </div>
              </div>
              <div
                className={`flex flex-row flex-grow cursor-pointer border border-2 rounded-md p-2 items-center justify-center  ${
                  tempFilterAttribute.status?.CLOSED
                    ? 'border-[#6546C3] bg-[#6546C3] bg-opacity-[0.2]'
                    : ''
                }`}
                onClick={() =>
                  setTempFilterAttribute({
                    ...tempFilterAttribute,
                    status: {
                      OPEN: false,
                      DRAFT: false,
                      CLOSED: !tempFilterAttribute.status.CLOSED
                    }
                  })
                }
              >
                <div
                  className={`text-[12px] ${
                    tempFilterAttribute.status?.CLOSED
                      ? 'text-[#6546C3] font-semibold'
                      : 'text-[#AAAAAA]'
                  }`}
                >
                  Closed
                </div>
              </div>
            </div>
            <div className="font-semibold text-[12px] mt-[28px] mb-[16px]">Location</div>
            <Select
              onChange={selectedOptions => {
                setLocation(selectedOptions);
                setTempFilterAttribute({ ...tempFilterAttribute, location: selectedOptions?.name });
              }}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.name}
              options={locationList}
              name="Location"
              className={
                'input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4'
              }
              placeholder="Select location"
              isClearable
              value={location}
              styles={{
                option: provided => ({
                  ...provided
                }),
                control: provided => ({
                  ...provided,
                  ':focus': { borderColor: '#6546C3' },
                  ':active': { borderColor: '#6546C3' },
                  ':hover': { borderColor: '#6546C3' },
                  paddingLeft: 4,
                  boxShadow: 'none'
                }),
                dropdownIndicator: provided => ({
                  ...provided,
                  color: '#6546C3'
                }),
                indicatorSeparator: () => ({}),
                valueContainer: provided => ({
                  ...provided
                })
              }}
            />
          </div>
          <div className="flex flex-row justify-center sm:justify-end bg-[#EBEDF8] w-full">
            <button
              className="rounded-lg border-2 border-[#6546C3] px-7 py-2 m-2 flex text-[#6546C3] font-semibold text-[14px]"
              onClick={reset}
            >
              Reset
            </button>
            <button
              className="rounded-lg border-2 bg-[#6546C3] px-6 py-2 m-2 flex text-white font-semibold text-[14px]"
              onClick={() => {
                if (navigator.onLine) {
                  dispatch(setOrderBy(tempFilterAttribute.order_by || 'desc'));
                  !tempFilterAttribute.order_by && delete tempFilterAttribute.order_by;
                  !tempFilterAttribute.location && delete tempFilterAttribute.location;
                  setFilterAttribute({
                    ...tempFilterAttribute
                  });
                  setShowModal(false);
                  setPageRef(1);
                } else {
                  setShowModal(false);
                  setOfflineFilterAttribute({ ...tempFilterAttribute });
                }
              }}
            >
              Filter Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
