import { apiUrls, icons } from '../../../constants';
import { useEffect, useState } from 'react';
import { get, put } from '../../../api/base';
import { toast } from 'react-toastify';
import { Select, Toast } from '../..';
import { useDispatch, useSelector } from 'react-redux';
import { setGuestCompanyModal, setShowLoading, setSuccessModal } from '../../../store/slices/popup';
import _ from 'lodash';
import { setUserData } from '../../../store/slices/user';

const GuestCompanyModal = ({ showModal, setShowModal }) => {
  const userCompany = useSelector(state => state.user.userData?.company);
  const [selectedCompanyOption, setSelectedCompanyOption] = useState(null);
  const [companyOptions, setCompanyOptions] = useState([]);
  const user = useSelector(state => state.user.userData);
  const dispatch = useDispatch();
  const handleOnCompanyChange = selectedOption => {
    setSelectedCompanyOption(selectedOption);
  };
  const handleCreateOption = inputValue => {
    const newOption = {
      label: inputValue,
      value: inputValue
    };

    setSelectedCompanyOption(newOption);
  };
  useEffect(() => {
    if (showModal && userCompany)
      setSelectedCompanyOption({ label: userCompany, value: userCompany });
  }, [userCompany, showModal]);
  const fetchCompanies = () => {
    get(apiUrls.ORGANIZATION_MASTER_URL, {
      query: { organization_column: 'COMPANY' }
    }).then(res => {
      const { status, data } = res;
      if (status === 200) {
        let listCompany = data.map(company => ({ label: company.name, value: company.id }));
        setCompanyOptions(listCompany);
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={res?.data.error.message} />);
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch companies'} />);
      }
    });
  };

  useEffect(() => {
    if (showModal) {
      fetchCompanies();
    }
  }, [showModal]);

  const back = () => {
    dispatch(setGuestCompanyModal(false));
  };

  const save = () => {
    if (_.isEmpty(selectedCompanyOption)) {
      toast.error(<Toast message={`Error`} detailedMessage={'Company cannot be empty'} />);
    } else {
      dispatch(setShowLoading(true));
      put(`${apiUrls.UPDATE_USER}/${user?.id}`, { company: selectedCompanyOption?.label }).then(
        res => {
          if (res.status === 200) {
            get(apiUrls.USER_PROFILE_URL).then(res => {
              dispatch(setShowLoading(false));
              if (res.status === 200) {
                dispatch(setUserData(res.data));
                dispatch(setGuestCompanyModal(false));
                dispatch(setSuccessModal(true));
              } else {
                toast.error(
                  <Toast message={'Error'} detailedMessage={`${res.data.error?.message}`} />
                );
              }
            });
          } else {
            dispatch(setShowLoading(false));
            toast.error(<Toast message={`Error`} detailedMessage={res?.data.error.message} />);
          }
        }
      );
    }
  };
  return (
    <div>
      <input
        type="checkbox"
        id="guestCompanyModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px] h-[75vh] sm:h-fit p-0">
          <div className="bg-white p-8">
            <div className="flex flex-row items-center justify-between">
              <div className="text-[18px] font-semibold">Profile Info</div>
              {(user?.company?.length || user?.company) && (
                <img
                  src={icons.icCloseModal}
                  className="w-6 h-6 cursor-pointer"
                  alt={'close-modal'}
                  onClick={() => setShowModal(false)}
                />
              )}
            </div>
            <hr className="mt-2 mb-6" />
            <div className="flex-1">
              <div className="font-poppins font-semibold text-[12px] mb-2">Company</div>
              <Select
                onChange={selectedOptions => handleOnCompanyChange(selectedOptions)}
                onCreateOption={value => handleCreateOption(value)}
                options={companyOptions}
                name="Company"
                className={`input-select text-[12px] rounded-md w-full focus:outline-none focus:border-[#6546C3]`}
                placeholder="Select company"
                isMulti={false}
                isSearchable
                isClearable
                isCreatable
                value={selectedCompanyOption}
                styles={{
                  option: (provided, state) => ({
                    ...provided
                  }),
                  control: provided => ({
                    ...provided,
                    minHeight: '40px',
                    borderColor: '#DEDEDE',
                    borderRadius: '6px',
                    ':focus': { borderColor: '#6546C3' },
                    ':active': { borderColor: '#6546C3' },
                    ':hover': { borderColor: '#6546C3' },
                    paddingLeft: 4,
                    paddingTop: 2,
                    paddingBottom: 2,
                    boxShadow: 'none'
                  }),
                  dropdownIndicator: provided => ({
                    ...provided,
                    color: '#C800A5',
                    ':hover': { color: '#6546C3' }
                  }),
                  indicatorSeparator: () => ({})
                }}
              />
            </div>
          </div>
          <div className="flex flex-row justify-center sm:justify-end bg-[#EBEDF8] w-full sm:w-[512px] md:w-[512px] mt-8 rounded-b-xl">
            <button
              disabled={!user?.company?.length || !user?.company}
              className="rounded-lg border-2 border-[#6546C3] px-7 py-2 m-2 flex text-[#6546C3] font-semibold text-[14px]"
              onClick={back}
            >
              Back
            </button>
            <button
              className="rounded-lg border-2 bg-[#6546C3] px-6 py-2 m-2 flex text-white font-semibold text-[14px]"
              onClick={save}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestCompanyModal;
