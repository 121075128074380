import Loading from '../../../../../components/Loading';
import { EmptyData, Toast } from '../../../../../components';
import Field from '../Field';
import { Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import { apiUrls, icons } from '../../../../../constants';
import { post } from '../../../../../api/base';
import { useDispatch } from 'react-redux';
import { setShowLoading } from '../../../../../store/slices/popup';

const TableLayout = ({
  section,
  onChangeValueField,
  onChangeCheckBox,
  onChangeRadio,
  onAddAttachment,
  removeFile,
  isFetching,
  submitted,
  handleAddMoreField,
  handleDeleteMoreField,
  onChangeValueInnerSectionField,
  sectionIndex
}) => {
  const fields = section.fields;
  let tableHeaders = fields?.map(field => {
    return (
      field?.field_type?.value !== 'CALCULATED_SUBTOTAL' && {
        key: field?.field_type?.value,
        name: field?.field_title
      }
    );
  });
  tableHeaders = [...tableHeaders, { key: 'Action', name: 'Action' }];
  const innerSections = section.inner_section;
  const [fieldMessage, setFieldMessage] = useState([]);
  const dispatch = useDispatch();

  const handleBackgroundColor = status => {
    if (status === 'OUT_OF_STOCK') {
      return { background: 'rgba(200, 0, 165, 0.4)', text: '#C800A5' };
    } else if (status === 'STOCK_NOT_SUFFICIENT') {
      return { background: 'rgba(255, 115, 1, 0.2)', text: '#FF7301' };
    } else if (status === 'AVAILABLE') {
      return { background: '#FFFFFF', text: '#01B59C' };
    } else {
      return { background: '#F2F5FC', text: '#F2F5FC' };
    }
  };

  const handleCheckStock = () => {
    let payload = [];
    let itemSection = section.fields.find(field => field?.field_type.value === 'MASTER_STOCK');
    let amountSection = section.fields.find(
      field => field?.field_type.value === 'STOCK_IN_TRANSACTION'
    );
    let unitSection = section.fields.find(field => field?.field_type.value === 'STOCK_UNIT');
    let m2pFormId = section.fields.find(field => field?.field_type.value === 'M2P_FORM');
    if (itemSection?.value && amountSection?.value && unitSection?.value) {
      payload.push({
        item: itemSection.value?.value,
        amount: parseInt(amountSection.value),
        unit: unitSection.value?.value,
        index: sectionIndex
      });
    }
    if (section?.inner_section?.length) {
      section.inner_section.forEach((fields, fieldIndex) => {
        let itemInnerSection = fields.find(field => field?.field_type.value === 'MASTER_STOCK');
        let unitInnerSection = fields.find(field => field?.field_type.value === 'STOCK_UNIT');
        let amountInnerSection = fields.find(
          field => field?.field_type.value === 'STOCK_IN_TRANSACTION'
        );
        if (itemInnerSection.value && amountInnerSection.value && unitInnerSection.value) {
          payload.push({
            item: itemInnerSection.value?.value,
            amount: parseInt(amountInnerSection.value),
            unit: unitInnerSection.value?.value,
            index: fieldIndex + sectionIndex + 1
          });
        }
      });
    }
    if (payload.length !== 0) {
      dispatch(setShowLoading(true));
      post(
        `${apiUrls.DYNAMIC_FORM}/master-stocks/${m2pFormId?.form_system?.id}/check-stock`,
        payload
      ).then(res => {
        dispatch(setShowLoading(false));
        if (res.status === 200) {
          setFieldMessage(res.data);
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />);
        }
      });
    } else {
      toast.error(
        <Toast
          message={'Error'}
          detailedMessage={
            itemSection?.field_title +
            ', ' +
            amountSection?.field_title +
            ' and ' +
            unitSection?.field_title +
            ' cannot be empty'
          }
        />
      );
    }
  };

  const findFieldById = fieldId => {
    for (const field of section.fields) {
      if (field.field_id === fieldId) {
        return field;
      }
    }
    return null;
  };
  const findFieldByIdInInnerSections = (fieldId, innerSectionIndex) => {
    for (const field of innerSections[innerSectionIndex]) {
      if (field.field_id === fieldId) {
        return field;
      }
    }
    return null;
  };

  const mappingFormula = (calculatedField, isInnerSectin, innerSectionIndex) => {
    let rawFormula;
    rawFormula = calculatedField.field_formula.formula;
    let finalFormula;
    for (const operand of calculatedField.field_formula?.operand_fields) {
      let field = isInnerSectin
        ? findFieldByIdInInnerSections(operand?.field_id, innerSectionIndex)
        : findFieldById(operand?.field_id);
      if (field) {
        rawFormula = rawFormula.replace(new RegExp(`#${operand.field_title}#`, 'g'), field.value);
      }
    }
    finalFormula = rawFormula.replace(/(\d+)\s*-\s*(\d+)\s*%\s*/g, function (match, p1, p2) {
      var value1 = parseFloat(p1);
      var value2 = parseFloat(p2);
      var result = value1 - value1 * (value2 / 100);
      return result;
    });
    return finalFormula;
  };

  const onChangeCalculatedField = () => {
    let event;
    let tempCalculatedSubtotal = section?.fields?.find(
      field => field?.field_type.value === 'CALCULATED_SUBTOTAL'
    );
    let tempCalculatedSubtotalIndex = section?.fields?.indexOf(tempCalculatedSubtotal);
    let subtotal;
    subtotal = 0;
    section?.fields?.forEach((calculatedField, fieldIndex) => {
      if (calculatedField?.field_type?.value === 'CALCULATED_FIELD') {
        try {
          // eslint-disable-next-line no-eval
          event = { target: { value: eval(mappingFormula(calculatedField, false)) } };
        } catch {
          event = { target: { value: '' } };
        }
        onChangeValueField(event, null, fieldIndex);
      }
    });
    if (innerSections?.length !== 0) {
      innerSections?.forEach((innerSectionFields, innerSectionIndex) => {
        innerSectionFields?.forEach((field, fieldIndex) => {
          if (field?.field_type?.value === 'CALCULATED_FIELD') {
            try {
              // eslint-disable-next-line no-eval
              event = { target: { value: eval(mappingFormula(field, true, innerSectionIndex)) } };
            } catch {
              event = { target: { value: '' } };
            }
            onChangeValueInnerSectionField(event, null, fieldIndex, innerSectionIndex);
            let tempCalculated = innerSectionFields?.filter(
              innerField =>
                innerField?.field_id === tempCalculatedSubtotal?.field_subtotal?.field_id
            );
            tempCalculated.forEach(calculated => {
              if (calculated.value) {
                subtotal = subtotal + calculated?.value;
              }
            });
          }
        });
      });
    }
    if (tempCalculatedSubtotalIndex !== -1) {
      event = {
        target: {
          value: findFieldById(tempCalculatedSubtotal?.field_subtotal?.field_id)?.value + subtotal
        }
      };
      onChangeValueField(event, null, tempCalculatedSubtotalIndex);
    }
  };

  return (
    <div className="flex flex-col bg-white rounded-2xl p-4">
      <div className="flex items-center justify-between">
        <div className="font-semibold text-[14px] text-[#C800A5]">{section.section_title}</div>
      </div>
      <hr className="mt-2 mb-6" />
      <div className="flex flex-row ">
        {section?.multiple_input && (
          <button
            className="rounded-lg border-2 bg-[#6546C3] px-6 py-2 m-2 flex text-white font-semibold text-[14px]"
            onClick={handleAddMoreField}
          >
            Add Data
          </button>
        )}
        {section?.check_stock && (
          <button
            className="rounded-lg border-2 border-[#6546C3] px-7 py-2 m-2 flex text-[#6546C3] font-semibold text-[14px]"
            onClick={handleCheckStock}
          >
            Check Stock
          </button>
        )}
      </div>
      <div className="overflow-x-auto">
        <table className="flex table table-auto w-full border-spacing-y-[8px] border-separate">
          <thead className="justify-center bg-[#F2F5FC]">
            <tr>
              {tableHeaders &&
                tableHeaders.length &&
                tableHeaders.map(
                  (item, index) =>
                    item?.key !== 'M2P_FORM' && (
                      <th
                        style={{ position: 'relative' }}
                        key={index}
                        className={'cursor-pointer bg-[#F2F5FC] p-4'}
                        onClick={() => {}}
                      >
                        <div className={'flex flex-row justify-center'}>
                          <span className="text-[#232323] normal-case text-[14px]">
                            {item.name}
                          </span>
                        </div>
                      </th>
                    )
                )}
            </tr>
          </thead>
          {isFetching ? (
            <tbody>
              <tr>
                <td colSpan={tableHeaders?.length}>
                  <div className="flex flex-col flex-grow items-center justify-center">
                    <Loading />
                  </div>
                </td>
              </tr>
            </tbody>
          ) : fields && fields?.length ? (
            <tbody>
              <tr
                className="bg-white rounded-full"
                style={{
                  boxShadow: '0 5px 15px 0 rgba(0, 0, 0, 0.06)'
                }}
              >
                {tableHeaders &&
                  tableHeaders.length &&
                  tableHeaders?.map((tHead, index) => {
                    return (
                      tHead.key !== 'M2P_FORM' && (
                        <td
                          style={{
                            backgroundColor: handleBackgroundColor(
                              fieldMessage[sectionIndex]?.status
                            ).background
                          }}
                          key={index}
                        >
                          {fields?.map((item, fieldIndex) => {
                            if (
                              tHead.key === item?.field_type.value &&
                              tHead.name === item?.field_title
                            ) {
                              return item?.field_type.value !== 'M2P_FORM' ? (
                                <div key={fieldIndex}>
                                  {item?.field_type.value === 'SECTION_SEQ' ? (
                                    <div>{fieldIndex + 1}</div>
                                  ) : (
                                    <div key={fieldIndex} className="min-w-[200px]">
                                      <Field
                                        isTabular={true}
                                        key={`${item?.field_id}${fieldIndex}`}
                                        field={item}
                                        submitted={submitted}
                                        sectionId={section.section_id}
                                        onChange={(event, additionalIndex) => {
                                          onChangeValueField(event, additionalIndex, fieldIndex);
                                          if (
                                            item?.field_type?.value === 'TEXT_FIELD' &&
                                            item?.keyboard_type?.value === 'NUMERIC'
                                          ) {
                                            onChangeCalculatedField();
                                          }
                                        }}
                                        onChangeCheck={(e, label, index) =>
                                          onChangeCheckBox(e, label, index, fieldIndex, null)
                                        }
                                        onChangeRadioButton={(e, label, index) =>
                                          onChangeRadio(e, label, index, fieldIndex, null)
                                        }
                                        addAttachment={(file, additionalIndex) =>
                                          onAddAttachment(file, additionalIndex, fieldIndex, null)
                                        }
                                        onRemoveFile={(indexFile, additionalIndex) =>
                                          removeFile(indexFile, additionalIndex, fieldIndex, null)
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <></>
                              );
                            }
                            return null;
                          })}
                        </td>
                      )
                    );
                  })}
              </tr>
              {fieldMessage[sectionIndex]?.status && (
                <tr className="bg-white rounded-full">
                  <td
                    className="!rounded-lg m-8"
                    style={{
                      backgroundColor: handleBackgroundColor(fieldMessage[sectionIndex]?.status)
                        .background
                    }}
                    colSpan={tableHeaders?.length}
                  >
                    <div
                      className="rounded rounded-full"
                      style={{
                        color: handleBackgroundColor(fieldMessage[sectionIndex]?.status).text,
                        fontWeight: '600'
                      }}
                    >
                      {fieldMessage[sectionIndex]?.message}
                    </div>
                  </td>
                </tr>
              )}
              {innerSections?.length !== 0 &&
                innerSections?.map((innerSectionFields, innerIndex) => {
                  return (
                    <Fragment key={innerIndex}>
                      <tr key={innerIndex}>
                        {tableHeaders &&
                          tableHeaders.length &&
                          tableHeaders?.map((tHead, headerIndex) => {
                            return (
                              tHead.key !== 'M2P_FORM' && (
                                <td
                                  style={{
                                    backgroundColor: handleBackgroundColor(
                                      fieldMessage[innerIndex + 1]?.status
                                    ).background
                                  }}
                                  key={headerIndex}
                                >
                                  {innerSectionFields.map((item, index) => {
                                    if (
                                      tHead.key === item?.field_type.value &&
                                      tHead.name === item?.field_title
                                    ) {
                                      return (
                                        <div key={index}>
                                          {item?.field_type.value === 'SECTION_SEQ' ? (
                                            <div>{innerIndex + 2}</div>
                                          ) : (
                                            <div key={index} className="min-w-[200px]">
                                              <Field
                                                isTabular={true}
                                                key={`${item?.field_id}${index}`}
                                                field={item}
                                                submitted={submitted}
                                                sectionId={section.section_id}
                                                onChange={(event, additionalIndex) => {
                                                  onChangeValueInnerSectionField(
                                                    event,
                                                    additionalIndex,
                                                    index,
                                                    innerIndex
                                                  );
                                                  if (
                                                    item?.field_type?.value === 'TEXT_FIELD' &&
                                                    item?.keyboard_type?.value === 'NUMERIC'
                                                  ) {
                                                    onChangeCalculatedField();
                                                  }
                                                }}
                                                onChangeCheck={(e, label, index) =>
                                                  onChangeCheckBox(
                                                    e,
                                                    label,
                                                    index,
                                                    index,
                                                    innerIndex
                                                  )
                                                }
                                                onChangeRadioButton={(e, label, index) =>
                                                  onChangeRadio(e, label, index, index, innerIndex)
                                                }
                                                addAttachment={(file, additionalIndex) =>
                                                  onAddAttachment(
                                                    file,
                                                    additionalIndex,
                                                    index,
                                                    innerIndex
                                                  )
                                                }
                                                onRemoveFile={(indexFile, additionalIndex) =>
                                                  removeFile(
                                                    indexFile,
                                                    additionalIndex,
                                                    index,
                                                    innerIndex
                                                  )
                                                }
                                              />
                                            </div>
                                          )}
                                        </div>
                                      );
                                    }
                                    return null;
                                  })}
                                  {tHead.key === 'Action' && (
                                    <img
                                      onClick={() => {
                                        section.inner_section.splice(innerIndex, 1);
                                        fieldMessage?.splice(innerIndex + 1, 1);
                                        onChangeCalculatedField();
                                        handleDeleteMoreField(section);
                                      }}
                                      src={icons.icTrashSecondary}
                                      alt="trash"
                                      height={32}
                                      width={32}
                                      className="self-center items-center justify-center mx-auto"
                                    />
                                  )}
                                </td>
                              )
                            );
                          })}
                      </tr>
                      {fieldMessage[innerIndex + 1]?.status && (
                        <tr>
                          <td
                            className="!rounded-lg m-8"
                            style={{
                              backgroundColor: handleBackgroundColor(
                                fieldMessage[innerIndex + 1]?.status
                              ).background
                            }}
                            colSpan={tableHeaders?.length}
                          >
                            <div
                              style={{
                                color: handleBackgroundColor(fieldMessage[innerIndex + 1]?.status)
                                  .text,
                                fontWeight: '600'
                              }}
                            >
                              {fieldMessage[innerIndex + 1]?.message}
                            </div>
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  );
                })}
              {fields?.map((field, index) => {
                return (
                  field?.field_type?.value === 'CALCULATED_SUBTOTAL' && (
                    <tr
                      key={index + field?.field_id}
                      className="bg-white m-4"
                      style={{
                        boxShadow: '0 5px 15px 0 rgba(0, 0, 0, 0.06)'
                      }}
                    >
                      <td
                        className="bg-[#6546C3] text-white !rounded-lg"
                        colSpan={tableHeaders?.length}
                      >
                        <div
                          style={{
                            fontWeight: '600'
                          }}
                        >
                          {field?.field_title + ': ' + field?.value}
                        </div>
                      </td>
                    </tr>
                  )
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={tableHeaders?.length}>
                  <EmptyData
                    title={'You’ve not added any data yet'}
                    message={'Please click the "add data” button to add it now!'}
                  />
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default TableLayout;
