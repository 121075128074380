import { useEffect, useState } from 'react';
import { get } from '../../../../../../api/base';
import { apiUrls, icons } from '../../../../../../constants';
import { toast } from 'react-toastify';
import { Toast } from '../../../../../../components';
import AsyncSelect from 'react-select/async';

const RequestorOptionModal = ({
  showModal,
  setShowModal,
  setSelectedRequestor,
  setDifferentRequestorActive
}) => {
  const [selectedRequestorOptions, setSelectedRequestorOptions] = useState(null);

  const noOptionsMessage = ({ inputValue }) =>
    inputValue.length >= 0 && inputValue.length < 5
      ? 'Type at least 5 characters'
      : 'No results found';

  const getDataRequestor = name => {
    return new Promise((resolve, reject) => {
      if (name.length === 0 || name.length >= 5) {
        get(`${apiUrls.DYNAMIC_FORM}/users-hris`, {
          query: { search: name }
        })
          .then(res => {
            if (res.status === 200) {
              let data = [...res.data];
              let formattedData = data.map(user => ({
                ...user,
                label: `${user.full_name} (${user.email})`,
                value: user.id
              }));
              resolve(formattedData);
            } else {
              toast.error(
                <Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />
              );
              resolve([]);
            }
          })
          .catch(error => {
            reject(error);
          });
      } else {
        resolve([]);
      }
    });
  };

  const onSaveRequestor = () => {
    if (selectedRequestorOptions) {
      setSelectedRequestor(selectedRequestorOptions);
      setDifferentRequestorActive(true);
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (showModal) setSelectedRequestorOptions(null);
  }, [showModal]);

  return (
    <div>
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px]  sm:h-fit">
          <div className="bg-white">
            <div className="flex items-center justify-between">
              <div className="font-semibold text-[18px] text-[#C800A5]">Requestor</div>
              <img
                src={icons.icCloseModal}
                className="w-6 h-6 cursor-pointer"
                alt={'close-modal'}
                onClick={() => setShowModal(false)}
              />
            </div>
            <hr className="mt-2 mb-6" />
            <div>
              <div>
                <div className="font-semibold text-[14px] mt-[28px] mb-[16px]">
                  Please search requestor here
                </div>
                <AsyncSelect
                  className={
                    'input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4'
                  }
                  placeholder="Search requestor"
                  value={selectedRequestorOptions}
                  onChange={selectedOptions => setSelectedRequestorOptions(selectedOptions)}
                  styles={{
                    option: provided => ({
                      ...provided
                    }),
                    control: provided => ({
                      ...provided,
                      ':focus': { borderColor: '#6546C3' },
                      ':active': { borderColor: '#6546C3' },
                      ':hover': { borderColor: '#6546C3' },
                      paddingLeft: 4,
                      boxShadow: 'none'
                    }),
                    dropdownIndicator: provided => ({
                      ...provided,
                      color: '#6546C3'
                    }),
                    indicatorSeparator: () => ({}),
                    valueContainer: provided => ({
                      ...provided
                    })
                  }}
                  loadOptions={getDataRequestor}
                  noOptionsMessage={noOptionsMessage}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center sm:justify-end w-full mt-9">
            <button
              className="bg-[#6546C3] border-2 border-[#6546C3] rounded-lg py-3 px-8 font-semibold text-white text-[14px]r"
              onClick={onSaveRequestor}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RequestorOptionModal;
