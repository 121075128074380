import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { icons, images, menus, apiUrls, guestMenus } from '../../../../constants';
import { useSelector } from 'react-redux';
import { deleteUserData, selectUserData } from '../../../../store/slices/user';
import { post } from '../../../../api/base';
import { useDispatch } from 'react-redux';
import { store } from '../../../../store';
import { logout } from '../../../../store/slices/auth';
import { Toast } from '../../../../components';
import { toast } from 'react-toastify';
import {
  setGuestCompanyModal,
  setShowFormQRScan,
  setShowQRScan
} from '../../../../store/slices/popup';
import _ from 'lodash';
import { setIsFromLogout } from '../../../../store/slices/setting';
import { reset } from '../../../../store/slices/table';

const SideNav = () => {
  const fcmToken = useSelector(state => state?.auth?.fcmToken);
  const location = useLocation();
  const navigate = useNavigate();
  const isQRScan = useSelector(state => state.popup.showQrScan);
  const isFormQRScan = useSelector(state => state.popup.showFormQRScan);
  const dispatch = useDispatch();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const userData = useSelector(selectUserData);
  const { uuid } = store.getState().auth;
  const userGroups = useSelector(state => state.user.userData?.groups);
  const isGuestAccount = userGroups?.find(group => group?.name === 'GUEST USER');
  const menuItem = (_.isEmpty(isGuestAccount) ? menus : guestMenus).filter(item => !item.isSubMenu);
  const isActive = menu => {
    if (menu?.name === 'QR Scan' && (isQRScan || isFormQRScan)) {
      return true;
    } else if (
      location.pathname.toLowerCase().includes(menu?.path?.toLowerCase()) &&
      !isQRScan &&
      !isFormQRScan
    ) {
      return true;
    } else if (menu?.name === 'Form' && menuItem[0]?.crumbs?.includes(menu.name)) {
      return true;
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleMenuClick = data => {
    if (data?.name === 'QR Scan') {
      navigate('/Home');
      if (userData?.groups?.some(group => group.name === 'Point Drawer')) {
        dispatch(setShowQRScan(true));
      } else {
        dispatch(setShowFormQRScan(true));
      }
    } else {
      if (data?.name === 'My Activity') dispatch(reset());
      toggleSidebar();
      navigate(data.path, { state: { name: data.name, crumbs: data.crumbs } });
    }
  };

  const handleLogout = () => {
    post(apiUrls.LOGOUT_URL, { device_id: uuid, fcm_token: fcmToken || null })
      .then(_ => {
        dispatch(setIsFromLogout(true));
        dispatch(logout());
        dispatch(deleteUserData());
        localStorage.clear();
      })
      .catch(_ => {
        toast.error(<Toast message={'Error'} detailedMessage={'Failed to logout'} />);
      });
  };

  return (
    <div className={'drawer lg:drawer-open'}>
      <input
        id="my-drawer"
        type="checkbox"
        className="drawer-toggle"
        checked={isSidebarOpen}
        onChange={toggleSidebar}
      />
      <div className="drawer-side">
        <label htmlFor="my-drawer" className="drawer-overlay"></label>
        <ul className="list-none m-0 p-0 w-72 h-full bg-white overflow-y-auto">
          {/* Profile Info */}
          <div className="relative h-[135px]">
            <div
              className="absolute inset-0 flex flex-row gap-4 items-center p-4 cursor-pointer"
              onClick={() => {
                _.isEmpty(isGuestAccount)
                  ? navigate('/Profile')
                  : dispatch(setGuestCompanyModal(true));
                toggleSidebar();
              }}
            >
              <img
                src={userData?.picture ? userData.picture : images.imageDummyAvatar}
                alt="Profile"
                className="w-[42px] h-[42px] rounded-full border-4 border-white"
              />
              <div className="text-white">
                <div className="font-semibold text-[16px] mb-0.5">
                  {userData?.full_name || userData?.email}
                </div>
                <div className="text-[12px]">{userData?.division}</div>
              </div>
            </div>
            <img
              src={images.imageBackgroundNameDrawer}
              alt="Background Name Drawer"
              className="h-full w-full"
            />
          </div>
          {/* Menu List */}
          <div className="flex flex-col justify-between h-[calc(100%-135px)] px-4 py-6">
            <div>
              {menuItem.map((menu, index) => (
                <li
                  key={index}
                  className={`${
                    isActive(menu)
                      ? 'cursor-pointer bg-[#EBEDF8] h-10 rounded-lg p-1 flex gap-x-4 items-center mb-2'
                      : 'cursor-pointer hover:bg-[#EBEDF8] h-10 rounded-lg p-1 flex gap-x-4 items-center mb-2'
                  }`}
                  onClick={() => handleMenuClick(menu)}
                >
                  <img
                    alt={menu.name}
                    src={`${isActive(menu) ? menu.icon : menu.icon_inactive}`}
                    className="w-6 h-6"
                  />
                  <span
                    className={`${
                      isActive(menu) ? 'text-[#C800A5] font-semibold' : 'text-[#AFAFB9] font-normal'
                    } font-poppins text-[14px]`}
                  >
                    {menu.name}
                  </span>
                </li>
              ))}
            </div>
            <button
              className="bg-[#C800A5] rounded-lg px-[12px] py-[10px] w-full text-left text-white text-xs 
                font-semibold flex flex-row gap-3 items-center"
              onClick={handleLogout}
            >
              <img src={icons.icLogout} className="w-6 h-6" alt="Logout" />
              Logout
            </button>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default SideNav;
