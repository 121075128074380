import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userData: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    deleteUserData: () => initialState
  }
});

export const { setUserData, deleteUserData } = userSlice.actions;
export const selectUserData = state => state.user.userData;
export default userSlice.reducer;
