import { useState } from 'react';
import { images } from '../../../../../../constants';

const CompleteTaskConfirmationModal = ({ showModal, setShowModal, handleCompleteTask }) => {
  const [remark, setRemark] = useState('');
  return (
    <div>
      <input
        type="checkbox"
        className="modal-toggle"
        checked={showModal}
        onChange={e => e.preventDefault()}
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div
          className="modal-box px-0 relative sm:rounded-2xl rounded-t-[32px] w-screen sm:w-[350px] h-fit sm:h-fit py-[20px] "
          onClick={e => e.stopPropagation()}
        >
          <div className="flex flex-col items-center items-center mb-[20px]">
            <img
              src={images.imageConfirmation}
              className="w-[120px] h-[120px] mb-6"
              alt="confirmation"
            />
            <span className="font-semibold text-[14px] mb-1">Complete Task</span>
            <span className="w-full text-[12px] text-center text-gray-500">
              Are you sure you want to complete the task?
            </span>
          </div>
          <div className="flex flex-1 flex-col gap-2 bg-[#EBEDF8] w-full p-4">
            <div className="font-semibold text-[12px]">Remark</div>
            <input
              className={
                'input-text  text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]'
              }
              onChange={event => setRemark(event.target.value)}
              value={remark}
              placeholder="Enter remark here"
            />
          </div>
          <div className="flex justify-center mt-6 w-full flex-row sm:mt-10 gap-[14px]">
            <button
              className="bg-transparent border-2 border-[#6546C3] rounded-lg py-[10px] w-[130px] font-semibold text-[#6546C3] 
                    text-[14px] flex justify-center items-center"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
            <button
              className="bg-[#6546C3] border-2 border-[#6546C3] rounded-lg py-[10px] w-[130px] font-semibold text-white 
                    text-[14px] flex justify-center items-center shadow-lg"
              onClick={() => handleCompleteTask(remark)}
            >
              Complete Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompleteTaskConfirmationModal;
