import icons from './icons';

const menus = [
  {
    isSubMenu: true,
    name: 'Profile',
    path: '/Profile',
    crumbs: ['Profile']
  },
  {
    icon: icons.icHomeActive,
    icon_inactive: icons.icHomeInactive,
    isSubMenu: false,
    name: 'Home',
    path: '/Home',
    crumbs: ['Home']
  },
  {
    icon: icons.icMyActivityActive,
    icon_inactive: icons.icMyActivityInactive,
    isSubMenu: false,
    name: 'My Activity',
    path: '/MyActivity',
    crumbs: ['My Activity']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: false,
    name: 'Task, Issue & Form',
    path: '/Task',
    crumbs: ['Task, Issue & Form']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Detail Form',
    path: '/Task/DetailForm/:id',
    crumbs: ['Task, Issue & Form', 'Detail Form']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Form Submission',
    path: '/Task/FormSubmission',
    crumbs: ['Task, Issue & Form', 'Form Submission']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Detail Form Submission',
    path: '/Task/FormSubmission/DetailFormSubmission/:id',
    crumbs: ['Task, Issue & Form', 'Form Submission', 'Detail Form Submission']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Detail Form Submission',
    path: '/Task/FormSubmission/DetailFormSubmission/:id/:parentId',
    crumbs: ['Task, Issue & Form', 'Form Submission', 'Detail Form Submission']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Form Submission',
    path: '/Task/BrowseFormSubmission',
    crumbs: ['Task, Issue & Form', 'Browse Form Submission']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Newest Submitted Document',
    path: '/Task/BrowseFormSubmission/NewestSubmittedDocument/:id',
    crumbs: ['Task, Issue & Form', 'Browse Form Submission', 'Newest Submitted Document']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Detail Form Submission',
    path: '/Task/BrowseFormSubmission/NewestSubmittedDocument/:id/DetailFormSubmission/:responseId',
    crumbs: [
      'Task, Issue & Form',
      'Browse Form Submission',
      'Newest Submitted Document',
      'Detail Form Submission'
    ]
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Guidance',
    path: '/Task/DetailForm/:id/Guidance',
    crumbs: ['Task, Issue & Form', 'Detail Form', 'Guidance']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Guidance',
    path: '/Task/FormSubmission/DetailFormSubmission/:id/Guidance',
    crumbs: ['Task, Issue & Form', 'Form Submission', 'Detail Form Submission', 'Guidance']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Guidance',
    path: '/Task/FormSubmission/DetailFormSubmission/:id/:parentId/Guidance',
    crumbs: ['Task, Issue & Form', 'Form Submission', 'Detail Form Submission', 'Guidance']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Guidance',
    path: '/Task/BrowseFormSubmission/NewestSubmittedDocument/:id/DetailFormSubmission/:responseId/Guidance',
    crumbs: [
      'Task, Issue & Form',
      'Browse Form Submission',
      'Newest Submitted Document',
      'Detail Form Submission',
      'Guidance'
    ]
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Task Submission',
    path: '/Task/TaskSubmission',
    crumbs: ['Task, Issue & Form', 'Task Submission']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Task Submission',
    path: '/Task/TaskSubmission/:id',
    crumbs: ['Task, Issue & Form', 'Task Submission']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Send Task',
    path: '/Task/TaskSubmission/SendTask',
    crumbs: ['Task, Issue & Form', 'Task Submission', 'Send Task']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Send Task',
    path: '/Task/TaskSubmission/:id/SendTask',
    crumbs: ['Task, Issue & Form', 'Task Submission', 'Send Task']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Detail Task',
    path: '/Task/DetailTask/:id',
    crumbs: ['Task, Issue & Form', 'Detail Task']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Detail Issue',
    path: '/Task/DetailIssue/:id',
    crumbs: ['Task, Issue & Form', 'Detail Issue']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Issue Submission',
    path: '/Task/IssueSubmission',
    crumbs: ['Task, Issue & Form', 'Issue Submission']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Task Submission',
    path: '/Task/IssueSubmission/:id',
    crumbs: ['Task, Issue & Form', 'Issue Submission']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Send Issue',
    path: '/Task/IssueSubmission/SendIssue',
    crumbs: ['Task, Issue & Form', 'Issue Submission', 'Send Issue']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Send Issue',
    path: '/Task/IssueSubmission/:id/SendIssue',
    crumbs: ['Task, Issue & Form', 'Issue Submission', 'Send Issue']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Select Assignee',
    path: '/Task/DetailTask/:id/SelectAssignee',
    crumbs: ['Task, Issue & Form', 'Detail Task', 'Select Assignee']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Share Task',
    path: '/Task/DetailTask/:id/ShareTask',
    crumbs: ['Task, Issue & Form', 'Detail Task', 'Share Task']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Select Assignee',
    path: '/Task/DetailIssue/:id/SelectAssignee',
    crumbs: ['Task, Issue & Form', 'Detail Issue', 'Select Assignee']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Share Issue',
    path: '/Task/DetailIssue/:id/ShareIssue',
    crumbs: ['Task, Issue & Form', 'Detail Issue', 'Share Issue']
  },
  {
    icon: icons.icTaskActive,
    icon_inactive: icons.icTaskInactive,
    isSubMenu: true,
    name: 'Owner',
    path: '/Task/DetailIssue/:id/Owner',
    crumbs: ['Task, Issue & Form', 'Detail Issue', 'Owner']
  },
  {
    icon: icons.icActiveUserLog,
    icon_inactive: icons.icInactiveUserLog,
    isSubMenu: false,
    name: 'User Log',
    path: '/UserLog',
    crumbs: ['User Log']
  },
  {
    icon: icons.icQrScannerActive,
    icon_inactive: icons.icQrScannerInActive,
    isSubMenu: false,
    name: 'QR Scan',
    path: 'QRScan'
  },
  {
    icon: icons.icActiveFeedback,
    icon_inactive: icons.icInctiveFeedback,
    isSubMenu: false,
    name: 'Feedback',
    path: 'Feedback',
    crumbs: ['Feedback']
  }
];

export default menus;
