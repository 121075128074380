import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showPopupLoading: false,
  showQrScan: false,
  showFormQRScan: false,
  showPointDrawerQRScan: false,
  guestCompanyModal: false,
  successModal: false
};

export const PopupSlice = createSlice({
  name: 'popup',
  initialState: initialState,
  reducers: {
    setShowLoading: (state, action) => {
      state.showPopupLoading = action.payload;
    },
    setShowQRScan: (state, action) => {
      state.showQrScan = action.payload;
    },
    setShowFormQRScan: (state, action) => {
      state.showFormQRScan = action.payload;
    },
    setShowPointDrawerQRScan: (state, action) => {
      state.showPointDrawerQRScan = action.payload;
    },
    setGuestCompanyModal: (state, action) => {
      state.guestCompanyModal = action.payload;
    },
    setSuccessModal: (state, action) => {
      state.successModal = action.payload;
    }
  }
});

export const {
  setShowLoading,
  setShowQRScan,
  setShowFormQRScan,
  setShowPointDrawerQRScan,
  setGuestCompanyModal,
  setSuccessModal
} = PopupSlice.actions;

export default PopupSlice.reducer;
