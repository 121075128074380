import { useEffect, useState } from 'react';
import { get } from '../../../../api/base';
import { apiUrls, icons } from '../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { Loading, TableAccent, Toast } from '../../../../components';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMediaQuery } from 'react-responsive';
import { setPage, setPageSize } from '../../../../store/slices/table';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const CardNewestSubmittedDocument = ({ form, handleGoToDetail }) => {
  return (
    <div
      className="flex flex-row p-5 bg-white items-center mt-2 rounded-md"
      style={{ boxShadow: '0 5px 15px 0 rgba(0, 0, 0, 0.06)' }}
      onClick={() => handleGoToDetail(form.id)}
    >
      <div className="mr-2">
        <img src={icons.icDocument} alt="document" height={32} width={32} />
      </div>
      <div className="flex flex-col">
        <div className="max-w-[200px] text-[12px]">
          <div className="w-full truncate">{form?.company}</div>
        </div>
        {form?.submitted_time ? (
          <div className={'min-w-[6rem] flex flex-row text-[12px] text-[#AFAFB9]'}>
            Submitted:<div className="text-[#01B59C] ml-1">{form.submitted_time}</div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const NewestSubmittedDocument = () => {
  const { limits, page, pageSize, search } = useSelector(state => state.table);
  const [forms, setForms] = useState([]);
  const [pageRef, setPageRef] = useState(1);
  const [searchRef, setSearchRef] = useState('');
  const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });
  const [totalData, setTotalData] = useState(0);
  const [firstFetching, setFirstFetching] = useState(false);
  const user = useSelector(state => state.user.userData);
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const headers = [
    {
      key: 'action',
      name: 'Action',
      isSortable: false
    },
    {
      key: 'company',
      name: 'Company',
      isSortable: true
    },
    {
      key: 'submitted_time',
      name: 'Submitted',
      isSortable: true
    }
  ];

  const loadMoreData = reloadPage => {
    const filter = {
      limit: 50,
      page: reloadPage ? 1 : pageRef,
      search: searchRef,
      user_id: user?.id,
      order_by: 'desc'
    };
    if (!searchRef) {
      delete filter.search;
    }
    get(`${apiUrls.DYNAMIC_FORM}/${id}/contents`, {
      query: filter
    })
      .then(res => {
        if (res.status === 200) {
          let temp = res.data;
          temp.data = temp.form_contents;
          delete temp.form_contents;
          if (filter.page === 1) {
            setForms(temp);
          } else {
            const result = forms.data.concat(temp.data);
            setForms({ ...forms, data: result });
          }

          setTotalData(res.data.total_data);
          setPageRef(filter.page + 1);
          setFirstFetching(false);
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />);
        }
      })
      .catch(_ => {
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
      });
  };
  const searchDataRef = ev => {
    switch (true) {
      case ev.key === 'Enter':
      case ev.target.value.length === 0:
        setSearchRef(ev.target.value);
        break;
      default:
        break;
    }
  };
  const initiateFormData = () => {
    const filter = {
      limit: pageSize,
      page,
      search,
      user_id: user?.id,
      order_by: 'desc'
    };
    if (!search) {
      delete filter.search;
    }
    setIsFetching(true);
    get(`${apiUrls.DYNAMIC_FORM}/${id}/contents`, {
      query: filter
    })
      .then(res => {
        setIsFetching(false);
        if (res.status === 200) {
          if (page > res.data.total_page) {
            dispatch(setPage(1));
          }
          let temp = res.data;
          temp.data = temp.form_contents;
          delete temp.form_contents;
          setForms(temp);
          setFirstFetching(false);
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />);
        }
      })
      .catch(_ => {
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
        setIsFetching(false);
      });
  };

  const handleGoToDetail = responseId => {
    navigate(
      `/Task/BrowseFormSubmission/NewestSubmittedDocument/${id}/DetailFormSubmission/${responseId}`
    );
  };

  useEffect(() => {
    dispatch(setPageSize(50));
  }, []);

  useEffect(() => {
    if (!isSmallScreen) initiateFormData();
  }, [limits, page, pageSize, search]);

  useEffect(() => {
    if (!firstFetching) setFirstFetching(true);
    if (isSmallScreen) loadMoreData(true);
  }, [searchRef]);

  return (
    <>
      {isSmallScreen ? (
        <div className="p-5 pt-0 min-h-screen flex flex-col sm:hidden">
          <div className="max-h-[calc(100%-17rem)] flex flex-col gap-y-2 overflow-y-scroll">
            <div className="flex flex-row-reverse md:flex-row gap-4">
              <div className="flex w-full md:min-w-[150px] h-[40px] bg-white border-[1px] items-center p-[12px] rounded-lg">
                <img src={icons.icSearch} className="w-[14px] h-[14px]" alt="Search" />
                <input
                  className="ml-2 focus:outline-none text-[12px] w-full"
                  key={search}
                  defaultValue={search}
                  placeholder="Search here..."
                  onKeyDown={searchDataRef}
                  onBlur={searchDataRef}
                />
              </div>
            </div>
            {firstFetching ? (
              <div className="flex justify-center h-[50vh] my-auto">
                <Loading />
              </div>
            ) : (
              <InfiniteScroll
                dataLength={forms?.data?.length || 0}
                next={() => loadMoreData(false)}
                hasMore={forms?.data?.length < totalData}
                className="!overflow-hidden"
                loader={
                  <div className="flex justify-center h-[100px] my-auto">
                    <Loading />
                  </div>
                }
              >
                {forms?.data?.map((form, index) => (
                  <CardNewestSubmittedDocument
                    form={form}
                    key={index}
                    handleGoToDetail={handleGoToDetail}
                  />
                ))}
              </InfiniteScroll>
            )}
          </div>
        </div>
      ) : (
        <div className="hidden sm:flex sm:flex-col">
          <TableAccent
            {...{
              isFetching,
              tableTitle: location.state?.formTitle,
              tableTitleOnTop: true,
              tableHeaders: headers,
              data: forms,
              emptyDataTitle: 'There are no documents submitted to show',
              emptyDataDescription: 'You have not submitted any documents for now',
              isForm: true
            }}
          >
            {(item, tHead) => {
              switch (tHead.key) {
                case 'action':
                  return (
                    <div className="flex justify-center m-0 w-full">
                      <button
                        className="flex justify-center items-center bg-[#F1F1FD] w-[24px] h-[24px] rounded-md"
                        onClick={() => handleGoToDetail(item.id)}
                      >
                        <img src={icons.icArrowRight} alt="form submission" />
                      </button>
                    </div>
                  );
                default:
                  return <span>{item[tHead.key]}</span>;
              }
            }}
          </TableAccent>
        </div>
      )}
    </>
  );
};
export default NewestSubmittedDocument;
