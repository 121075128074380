import { useState } from 'react';
import ListFormSubmittedPage from './Form';
import ListTaskPage from './Task';
import ListIssuePage from './Issue';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { reset, setPageSize } from '../../store/slices/table';

const Task = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [tabName, setTabName] = useState(location.state?.tabName || 'Task');
  const tabResult = () => {
    switch (tabName) {
      case 'Task':
        dispatch(reset());
        return <ListTaskPage />;
      case 'Issue':
        dispatch(reset());
        return <ListIssuePage />;
      case 'Form':
        dispatch(reset());
        dispatch(setPageSize(50));
        return <ListFormSubmittedPage tabName={tabName} />;
      default:
        return <ListTaskPage />;
    }
  };

  return (
    <div>
      <div className="grid grid-flow-col rounded-[8px] grid-cols-3 auto-rows-col bg-white gap-2 justify-evenly mx-[24px] h-[36px]">
        <button
          className={`${tabName === 'Task' ? 'border-b-4 border-[#C800A5]' : ''}  text-[14px]`}
          onClick={() => setTabName('Task')}
        >
          <div
            className={`${tabName === 'Task' ? 'font-semibold text-[#C800A5]' : 'text-[#AFAFB9]'}`}
          >
            Task
          </div>
        </button>
        <button
          className={`${
            tabName === 'Issue' ? 'border-b-4 border-[#C800A5]' : 'text-[#AFAFB9]'
          }  text-[14px]`}
          onClick={() => setTabName('Issue')}
        >
          <div className={`${tabName === 'Issue' ? 'font-semibold text-[#C800A5]' : ''}`}>
            Issue
          </div>
        </button>
        <button
          className={`${
            tabName === 'Form' ? 'border-b-4 border-[#C800A5]' : 'text-[#AFAFB9]'
          }  text-[14px]`}
          onClick={() => setTabName('Form')}
        >
          <div className={`${tabName === 'Form' ? 'font-semibold text-[#C800A5]' : ''}`}>Form</div>
        </button>
      </div>
      {tabResult()}
    </div>
  );
};

export default Task;
