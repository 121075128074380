import { useEffect, useState } from 'react';
import { apiUrls, icons } from '../../../constants';
import { get, post } from '../../../api/base';
import { toast } from 'react-toastify';
import Toast from '../../Toast';
import Loading from '../../Loading';
import EmptyData from '../../EmptyData';
import { useDispatch } from 'react-redux';
import { setShowLoading } from '../../../store/slices/popup';

const AddOwnerModal = ({ showModal, id, setShowModal, setSuccessModal }) => {
  const [search, setSearch] = useState('');
  const [notOwners, setNotOwners] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const dispatch = useDispatch();

  const searchData = event => {
    switch (true) {
      case event.key === 'Enter':
      case event.target.value.length === 0:
        setSearch(event.target.value);
        break;
      default:
        break;
    }
  };

  const getNotOwner = () => {
    get(`${apiUrls.ISSUE}/${id}/not-owners`).then(res => {
      const { status } = res;
      if (status === 200) {
        setNotOwners(res.data.data);
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={res.data.error.message} />);
      }
      setIsFetching(false);
    });
  };

  const onClickUserCard = userId => {
    let newSelectedUsers = [];
    if (!selectedUsers.includes(userId)) {
      newSelectedUsers = [...selectedUsers, userId];
    } else {
      newSelectedUsers = [...selectedUsers.filter(id => id !== userId)];
    }
    setSelectedUsers(newSelectedUsers);
  };

  const handleAddOwner = () => {
    dispatch(setShowLoading(true));
    post(`${apiUrls.ISSUE}/${id}/owners`, { owners: selectedUsers }).then(res => {
      dispatch(setShowLoading(false));
      if (res.status === 200) {
        setSuccessModal(true);
        setSearch('');
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={res.data.error.message} />);
      }
    });
  };

  useEffect(() => {
    if (showModal) {
      setIsFetching(true);
      getNotOwner();
    }
  }, [showModal]);

  const CardUser = ({ user }) => {
    return (
      <div
        className={`drop-shadow-md flex flex-row justify-between items-center rounded-md p-[16px] cursor-pointer ${
          selectedUsers.includes(user.id) ? 'bg-[#EBEDF8]' : 'bg-white'
        }`}
        onClick={() => onClickUserCard(user.id)}
      >
        <div className="flex items-center gap-[12px]">
          <img
            src={user.picture ? user.picture : icons.icActiveUserOnline}
            alt="search"
            className="rounded-full h-[36px] w-[36px]"
          />
          <div className="flex flex-col justify-center">
            <div className="font-semibold text-[12px]">{user.full_name}</div>
            <div className="text-[#AFAFB9] text-[12px]">
              {user.department ? user.department : ''}
            </div>
          </div>
        </div>

        {selectedUsers.includes(user.id) && (
          <img src={icons.icPrimaryChecklist} alt="checked" className="h-[16px]" />
        )}
      </div>
    );
  };

  return (
    <div>
      <input
        type="checkbox"
        className="modal-toggle"
        checked={showModal}
        onChange={e => e.preventDefault()}
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div
          className="modal-box flex flex-col bg-white p-4 sm:p-6 w-screen sm:w-[512px] md:w-[780px] h-[75vh] sm:h-fit"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex flex-row items-center justify-between">
            <div className="font-semibold text-[18px] text-[#232323]">Add Owner</div>

            <img
              src={icons.icCloseModal}
              alt="close-modal"
              className="w-6 h-6 cursor-pointer"
              onClick={() => setShowModal(false)}
            />
          </div>

          <hr className="my-4" />

          <div className="flex h-[40px] bg-white border-[1px] items-center p-[12px] rounded-lg">
            <img src={icons.icSearch} className="w-[14px] h-[14px]" alt="Search" />
            <input
              className="ml-2 focus:outline-none text-[12px] w-full"
              defaultValue={search}
              placeholder="Search here..."
              onKeyDown={searchData}
              onBlur={searchData}
              key={search}
            />
          </div>

          <div className="my-4 max-h-[50vh] overflow-auto">
            {isFetching ? (
              <div className="items-center justify-center flex">
                <Loading />
              </div>
            ) : notOwners.length ? (
              <div className="grid grid-cols-1 gap-4 py-2">
                {search
                  ? notOwners
                      .filter(n => n.full_name?.toLowerCase().includes(search.toLowerCase()))
                      .map((user, index) => <CardUser user={user} key={index} />)
                  : notOwners.map((user, index) => <CardUser user={user} key={index} />)}
              </div>
            ) : (
              <EmptyData title="Not Found" />
            )}
          </div>

          <button
            className="bg-[#6546C3] rounded-lg py-[10px] mt-4 w-full font-semibold text-white 
                text-[14px] flex justify-center items-center"
            onClick={handleAddOwner}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddOwnerModal;
