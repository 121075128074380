import React, { useState } from 'react';
import Field from '../Field';
import SubSection from '../SubSection';
import { apiUrls, icons } from '../../../../../constants';
import { useDispatch } from 'react-redux';
import { setShowLoading } from '../../../../../store/slices/popup';
import { post } from '../../../../../api/base';
import { toast } from 'react-toastify';
import { Toast } from '../../../../../components';

const Section = ({
  formId,
  section,
  submitted,
  handleAddMoreField,
  handleDeleteMoreField,
  onChangeValueField,
  onChangeValueInnerSectionField,
  onChangeCheckBox,
  onChangeRadio,
  onAddAttachment,
  removeFile,
  onChangeValueSubSection,
  onChangeSubCheckBox,
  onChangeSubRadio,
  onAddSubAttachment,
  removeSubSectionFile,
  formType,
  sectionIndex
}) => {
  const fields = section.fields;
  const innerSections = section.inner_section || [];
  const subSections = section.sub_sections;
  const isMultiple = section.multiple_input;
  const [fieldMessage, setFieldMessage] = useState([]);
  const dispatch = useDispatch();

  const handleBackgroundColor = status => {
    if (status === 'OUT_OF_STOCK') {
      return { background: 'rgba(200, 0, 165, 0.4)', text: '#C800A5' };
    } else if (status === 'STOCK_NOT_SUFFICIENT') {
      return { background: 'rgba(255, 115, 1, 0.2)', text: '#FF7301' };
    } else if (status === 'AVAILABLE') {
      return { background: '#FFFFFF', text: '#01B59C' };
    } else {
      return { background: '#F2F5FC', text: '#F2F5FC' };
    }
  };

  const handleCheckStock = () => {
    let payload = [];
    let itemSection = section.fields.find(field => field?.field_type.value === 'MASTER_STOCK');
    let amountSection = section.fields.find(
      field => field?.field_type.value === 'STOCK_IN_TRANSACTION'
    );
    let unitSection = section.fields.find(field => field?.field_type.value === 'STOCK_UNIT');
    let m2pFormId = section.fields.find(field => field?.field_type.value === 'M2P_FORM');
    if (itemSection?.value && amountSection?.value && unitSection?.value) {
      payload.push({
        item: itemSection.value?.value,
        amount: parseInt(amountSection.value),
        unit: unitSection.value?.value,
        index: sectionIndex
      });
    }
    if (section?.inner_section?.length) {
      section.inner_section.forEach((fields, fieldIndex) => {
        let itemInnerSection = fields.find(field => field?.field_type.value === 'MASTER_STOCK');
        let unitInnerSection = fields.find(field => field?.field_type.value === 'STOCK_UNIT');
        let amountInnerSection = fields.find(
          field => field?.field_type.value === 'STOCK_IN_TRANSACTION'
        );
        if (itemInnerSection.value && amountInnerSection.value && unitInnerSection.value) {
          payload.push({
            item: itemInnerSection.value?.value,
            amount: parseInt(amountInnerSection.value),
            unit: unitInnerSection.value?.value,
            index: fieldIndex + sectionIndex + 1
          });
        }
      });
    }
    if (payload.length !== 0) {
      dispatch(setShowLoading(true));
      post(
        `${apiUrls.DYNAMIC_FORM}/master-stocks/${m2pFormId?.form_system?.id}/check-stock`,
        payload
      ).then(res => {
        dispatch(setShowLoading(false));
        if (res.status === 200) {
          setFieldMessage(res.data);
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />);
        }
      });
    } else {
      toast.error(
        <Toast
          message={'Error'}
          detailedMessage={
            itemSection?.field_title +
            ', ' +
            amountSection?.field_title +
            ' and ' +
            unitSection?.field_title +
            ' cannot be empty'
          }
        />
      );
    }
  };

  const findFieldById = fieldId => {
    for (const field of section.fields) {
      if (field.field_id === fieldId) {
        return field;
      }
    }
    return null;
  };
  const findFieldByIdInInnerSections = (fieldId, innerSectionIndex) => {
    for (const field of innerSections[innerSectionIndex]) {
      if (field.field_id === fieldId) {
        return field;
      }
    }
    return null;
  };

  const mappingFormula = (calculatedField, isInnerSectin, innerSectionIndex) => {
    let rawFormula;
    rawFormula = calculatedField.field_formula.formula;
    let finalFormula;
    for (const operand of calculatedField.field_formula?.operand_fields) {
      let field = isInnerSectin
        ? findFieldByIdInInnerSections(operand?.field_id, innerSectionIndex)
        : findFieldById(operand?.field_id);
      if (field) {
        rawFormula = rawFormula.replace(new RegExp(`#${operand.field_title}#`, 'g'), field.value);
      }
    }
    finalFormula = rawFormula.replace(/(\d+)\s*-\s*(\d+)\s*%\s*/g, function (match, p1, p2) {
      var value1 = parseFloat(p1);
      var value2 = parseFloat(p2);
      var result = value1 - value1 * (value2 / 100);
      return result;
    });
    return finalFormula;
  };

  const onChangeCalculatedField = () => {
    let event;
    let tempCalculatedSubtotal = section?.fields?.find(
      field => field?.field_type.value === 'CALCULATED_SUBTOTAL'
    );
    let tempCalculatedSubtotalIndex = section?.fields?.indexOf(tempCalculatedSubtotal);
    let subtotal;
    subtotal = 0;
    section?.fields?.forEach((calculatedField, fieldIndex) => {
      if (calculatedField?.field_type?.value === 'CALCULATED_FIELD') {
        try {
          // eslint-disable-next-line no-eval
          event = { target: { value: eval(mappingFormula(calculatedField, false)) } };
        } catch {
          event = { target: { value: '' } };
        }
        onChangeValueField(event, null, fieldIndex);
      }
    });
    if (innerSections?.length !== 0) {
      innerSections?.forEach((innerSectionFields, innerSectionIndex) => {
        innerSectionFields?.forEach((field, fieldIndex) => {
          if (field?.field_type?.value === 'CALCULATED_FIELD') {
            try {
              // eslint-disable-next-line no-eval
              event = { target: { value: eval(mappingFormula(field, true, innerSectionIndex)) } };
            } catch {
              event = { target: { value: '' } };
            }
            onChangeValueInnerSectionField(event, null, fieldIndex, innerSectionIndex);
            let tempCalculated = innerSectionFields?.filter(
              innerField =>
                innerField?.field_id === tempCalculatedSubtotal?.field_subtotal?.field_id
            );
            tempCalculated.forEach(calculated => {
              if (calculated.value) {
                subtotal = subtotal + calculated?.value;
              }
            });
          }
        });
      });
    }
    if (tempCalculatedSubtotalIndex !== -1) {
      event = {
        target: {
          value: findFieldById(tempCalculatedSubtotal?.field_subtotal?.field_id)?.value + subtotal
        }
      };
      onChangeValueField(event, null, tempCalculatedSubtotalIndex);
    }
  };

  return (
    <div className="flex flex-col bg-white rounded-2xl p-6">
      <div className="flex items-center justify-between">
        <div className="font-semibold text-[14px] text-[#C800A5]">{section.section_title}</div>
        {isMultiple && !submitted ? (
          <img
            src={icons.icAdd}
            className="w-8 h-8 rounded-full cursor-pointer"
            alt="add"
            onClick={() => handleAddMoreField()}
          />
        ) : (
          <></>
        )}
      </div>
      {section?.check_stock && !submitted && (
        <button
          className="w-[150px] rounded-lg border-2 border-[#6546C3] px-7 py-2 m-2 flex text-[#6546C3] font-semibold text-[14px]"
          onClick={handleCheckStock}
        >
          Check Stock
        </button>
      )}
      <hr className="mt-2 mb-6" />
      <div
        style={{
          borderColor: handleBackgroundColor(fieldMessage[sectionIndex]?.status).text,
          borderWidth: fieldMessage[sectionIndex]?.status ? 2 : 0
        }}
        className={`flex flex-col gap-4 ${isMultiple ? 'bg-[#F2F5FC] rounded-2xl p-6 mb-4' : ''}`}
      >
        {fields?.map((field, fieldIndex) => (
          <div key={fieldIndex}>
            {field?.field_type.value === 'SECTION_SEQ' ? (
              <div>{field?.field_title + ' ' + (fieldIndex + 1)}</div>
            ) : (
              field?.field_type.value !== 'CALCULATED_SUBTOTAL' && (
                <Field
                  formId={formId}
                  formType={formType}
                  key={`${field.field_id}${fieldIndex}`}
                  field={field}
                  submitted={submitted}
                  sectionId={section.section_id}
                  onChange={(event, additionalIndex) => {
                    onChangeValueField(event, additionalIndex, fieldIndex);
                    if (field?.field_type?.value === 'MASTER_STOCK_AMOUNT') {
                      onChangeValueField(event, additionalIndex, fieldIndex + 1);
                    }
                    if (
                      field?.field_type?.value === 'TEXT_FIELD' &&
                      field?.keyboard_type?.value === 'NUMERIC'
                    ) {
                      onChangeCalculatedField();
                    }
                  }}
                  onChangeCheck={(e, label, index) =>
                    onChangeCheckBox(e, label, index, fieldIndex, null)
                  }
                  onChangeRadioButton={(e, label, index) =>
                    onChangeRadio(e, label, index, fieldIndex, null)
                  }
                  addAttachment={(file, additionalIndex) =>
                    onAddAttachment(file, additionalIndex, fieldIndex, null)
                  }
                  onRemoveFile={(indexFile, additionalIndex) =>
                    removeFile(indexFile, additionalIndex, fieldIndex, null)
                  }
                />
              )
            )}
          </div>
        ))}
        {fieldMessage.length !== 0 && (
          <div
            className="rounded-lg px-8 py-4"
            style={{
              color: handleBackgroundColor(fieldMessage[sectionIndex]?.status).text,
              backgroundColor: handleBackgroundColor(fieldMessage[sectionIndex]?.status).background
            }}
          >
            {fieldMessage[sectionIndex]?.message}
          </div>
        )}
      </div>
      {subSections?.map((section, subSectionIndex) => {
        return (
          <div key={subSectionIndex}>
            <hr className="my-6" />
            <div className="flex flex-col gap-4">
              <SubSection
                key={`${section.section_id}${subSectionIndex}`}
                section={section}
                submitted={submitted}
                onChangeValueSubSectionField={(event, additionalIndex, fieldIndex) =>
                  onChangeValueSubSection(event, additionalIndex, fieldIndex, subSectionIndex)
                }
                onChangeSubSectionCheckBox={(e, label, additionalIndex, fieldIndex) =>
                  onChangeSubCheckBox(e, label, additionalIndex, fieldIndex, subSectionIndex)
                }
                onChangeSubSectionRadio={(e, label, index, fieldIndex) =>
                  onChangeSubRadio(e, label, index, fieldIndex, subSectionIndex)
                }
                onAddSubSectionAttachment={(file, additionalIndex, fieldIndex) =>
                  onAddSubAttachment(file, additionalIndex, fieldIndex, subSectionIndex)
                }
                removeSubSectionFile={(indexFile, additionalIndex, fieldIndex) =>
                  removeSubSectionFile(indexFile, additionalIndex, fieldIndex, subSectionIndex)
                }
              />
            </div>
          </div>
        );
      })}
      {innerSections?.map((innerSectionFields, innerSectionIndex) => {
        return (
          <div key={innerSectionIndex}>
            <hr className="my-6" />
            <div
              style={{
                borderColor: handleBackgroundColor(fieldMessage[innerSectionIndex + 1]?.status)
                  .text,
                borderWidth: fieldMessage[innerSectionIndex + 1]?.status ? 2 : 0
              }}
              className="flex flex-col gap-4 bg-[#F2F5FC] rounded-2xl p-6 mb-4"
            >
              {innerSectionFields.map((field, fieldIndex) => (
                <div key={fieldIndex}>
                  {field?.field_type?.value === 'SECTION_SEQ' ? (
                    <div>{field?.field_title + ' ' + (innerSectionIndex + 2)}</div>
                  ) : (
                    <Field
                      formId={formId}
                      formType={formType}
                      key={`${field?.field_id}${innerSectionIndex}`}
                      field={field}
                      submitted={submitted}
                      sectionId={section.section_id}
                      onChange={(event, additionalIndex) => {
                        onChangeValueInnerSectionField(
                          event,
                          additionalIndex,
                          fieldIndex,
                          innerSectionIndex
                        );
                        if (
                          field?.field_type?.value === 'TEXT_FIELD' &&
                          field?.keyboard_type?.value === 'NUMERIC'
                        ) {
                          onChangeCalculatedField();
                        }
                      }}
                      onChangeCheck={(e, label, index) =>
                        onChangeCheckBox(e, label, index, fieldIndex, innerSectionIndex)
                      }
                      onChangeRadioButton={(e, label, index) =>
                        onChangeRadio(e, label, index, fieldIndex, innerSectionIndex)
                      }
                      addAttachment={(file, additionalIndex) =>
                        onAddAttachment(file, additionalIndex, fieldIndex, innerSectionIndex)
                      }
                      onRemoveFile={(indexFile, additionalIndex) =>
                        removeFile(indexFile, additionalIndex, fieldIndex, innerSectionIndex)
                      }
                    />
                  )}
                </div>
              ))}
              {fieldMessage.length !== 0 && (
                <div
                  className="rounded-lg px-8 py-4"
                  style={{
                    color: handleBackgroundColor(fieldMessage[innerSectionIndex + 1]?.status).text,
                    backgroundColor: handleBackgroundColor(
                      fieldMessage[innerSectionIndex + 1]?.status
                    ).background
                  }}
                >
                  {fieldMessage[innerSectionIndex + 1]?.message}
                </div>
              )}
              <hr className="my-2" />
              {!submitted && (
                <button
                  className="flex flex-1 gap-2 justify-center items-center text-[#C800A5] text-[14px]"
                  onClick={() => {
                    section.inner_section.splice(innerSectionIndex, 1);
                    onChangeCalculatedField();
                    handleDeleteMoreField(section);
                  }}
                >
                  <img src={icons.icTrash} alt="delete" className="w-6 h-6" />
                  Delete
                </button>
              )}
            </div>
          </div>
        );
      })}
      {fields?.map((field, fieldIndex) => (
        <div key={fieldIndex}>
          {field.field_type.value === 'CALCULATED_SUBTOTAL' && (
            <Field
              formId={formId}
              formType={formType}
              key={`${field.field_id}${fieldIndex}`}
              field={field}
              submitted={submitted}
              sectionId={section.section_id}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default Section;
