import React, { useEffect, useRef, useState } from 'react';
import { get } from '../../../../../api/base';

import Select, { components } from 'react-select';
import { apiUrls, icons } from '../../../../../constants';
import parse from 'html-react-parser';
import SignatureCanvas from 'react-signature-canvas';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import FieldAttachment from '../components/FieldAttachment';
import Rating from 'react-rating';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Toast } from '../../../../../components';

const Field = ({
  field,
  formId,
  submitted,
  sectionId,
  onChange,
  onChangeCheck,
  onChangeRadioButton,
  addAttachment,
  onRemoveFile,
  tabletLayout,
  additionalTitle,
  formType,
  isTabular
}) => {
  const fieldTitle = field?.field_title;
  const fieldType = field?.field_type.value;
  const fieldValue = field?.value;
  const fieldOptions = field?.options;
  const fieldId = field?.field_id;
  const fieldAttachments = field?.attachment_value;
  const fieldKeyboardType = field?.keyboard_type;
  const fieldFormat = field?.format;
  const fieldHighlight = field?.highlight;
  const fieldMandatory = field?.mandatory;
  const fieldMasterData = field?.master_data;
  const inputRef = useRef(null);
  const [signatureClicked, setSignatureClicked] = useState(false);
  const [masterDataSource, setMasterDataSource] = useState([]);
  const onChangeData = (event, index) => {
    onChange(event, index);
  };

  const onChangeCheckBox = (event, label, index) => {
    onChangeCheck(event, label, index);
  };

  const onChangeRadio = (event, label, index) => {
    onChangeRadioButton(event, label, index);
  };

  const onDeleteFile = (indexFile, additionalIndex) => {
    onRemoveFile(indexFile, additionalIndex);
  };

  const fetchMasterDataSource = sourceId => {
    get(`${apiUrls.MASTER_DATA}/${sourceId}/details`, { query: { form_id: formId } }).then(res => {
      if (res.status === 200) {
        const dropdownList = res.data.map(v => {
          return { label: v.name, value: v.name };
        });
        setMasterDataSource(dropdownList);
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />);
      }
    });
  };

  useEffect(() => {
    if (!_.isEmpty(fieldMasterData) && fieldType === 'DROPDOWN_MASTER_DATA' && navigator.onLine) {
      if (fieldMasterData?.sourceMasterDataName !== 'Other Master Data') {
        fetchMasterDataSource(fieldMasterData?.sourceMasterDataId);
      }
    }
  }, []);

  const renderField = ({
    fieldId,
    sectionId,
    fieldType,
    fieldTitle,
    fieldValue,
    fieldOptions,
    fieldAttachments,
    fieldKeyboardType,
    fieldFormat,
    submitted,
    index
  }) => {
    switch (fieldType) {
      case 'MASTER_STOCK_AMOUNT':
      case 'STOCK_IN_TRANSACTION':
      case 'TEXT_FIELD':
        return (
          <TextField
            isTabular={isTabular}
            formType={formType}
            fieldType={fieldType}
            tabletLayout={tabletLayout}
            fieldHighlight={fieldHighlight}
            fieldMandatory={fieldMandatory}
            title={fieldTitle}
            value={fieldValue}
            type={fieldKeyboardType}
            submitted={submitted}
            onChangeValue={event => onChangeData(event, index)}
          />
        );

      case 'TEXT_AREA':
        return (
          <TextArea
            isTabular={isTabular}
            tabletLayout={tabletLayout}
            fieldHighlight={fieldHighlight}
            fieldMandatory={fieldMandatory}
            title={fieldTitle}
            value={fieldValue}
            submitted={submitted}
            onChangeValue={event => onChangeData(event, index)}
          />
        );

      case 'CHECKBOX':
        return (
          <Checkbox
            isTabular={isTabular}
            tabletLayout={tabletLayout}
            fieldHighlight={fieldHighlight}
            fieldMandatory={fieldMandatory}
            title={fieldTitle}
            value={fieldValue}
            options={fieldOptions}
            submitted={submitted}
            onChange={(event, label) => onChangeCheckBox(event, label, index)}
          />
        );

      case 'RADIO_BUTTON':
        return (
          <RadioButton
            isTabular={isTabular}
            tabletLayout={tabletLayout}
            fieldHighlight={fieldHighlight}
            fieldMandatory={fieldMandatory}
            id={fieldId}
            sectionId={sectionId}
            title={fieldTitle}
            value={fieldValue}
            options={fieldOptions}
            submitted={submitted}
            onChange={(event, label) => onChangeRadio(event, label, index)}
          />
        );
      case 'DROPDOWN_MASTER_DATA':
      case 'DROPDOWN':
        return (
          <Dropdown
            fieldType={fieldType}
            masterData={fieldMasterData}
            isTabular={isTabular}
            tabletLayout={tabletLayout}
            fieldHighlight={fieldHighlight}
            fieldMandatory={fieldMandatory}
            id={fieldId}
            title={fieldTitle}
            value={fieldValue}
            options={fieldOptions?.length ? fieldOptions : masterDataSource}
            submitted={submitted}
            onChange={event => onChangeData(event, index)}
          />
        );

      case 'DATE_PICKER':
        return (
          <DatePicker
            isTabular={isTabular}
            tabletLayout={tabletLayout}
            fieldHighlight={fieldHighlight}
            fieldMandatory={fieldMandatory}
            title={fieldTitle}
            value={fieldValue}
            format={fieldFormat || undefined}
            submitted={submitted}
            onChange={event => onChangeData(event, index)}
          />
        );

      case 'SIGNATURE':
        return (
          <Signature
            tabletLayout={tabletLayout}
            fieldHighlight={fieldHighlight}
            fieldMandatory={fieldMandatory}
            title={fieldTitle}
            value={fieldValue}
            submitted={submitted}
            inputRef={inputRef}
            signatureClicked={signatureClicked}
            setSignatureClicked={setSignatureClicked}
            onChange={event => onChangeData(event)}
          />
        );

      case 'CHECKBOX_ATTACHMENT':
        return (
          <CheckboxWithAttachment
            isTabular={isTabular}
            tabletLayout={tabletLayout}
            title={fieldTitle}
            fieldHighlight={fieldHighlight}
            fieldMandatory={fieldMandatory}
            value={fieldValue}
            options={fieldOptions}
            attachments={fieldAttachments}
            submitted={submitted}
            inputRef={inputRef}
            onChange={(event, label) => onChangeCheckBox(event, label, index)}
            handleChangeAttachment={e => addAttachment(e, index)}
            handleRemoveFile={indexFile => onDeleteFile(indexFile, index)}
          />
        );

      case 'RADIO_BUTTON_ATTACHMENT':
        return (
          <RadioButtonWithAttachment
            isTabular={isTabular}
            tabletLayout={tabletLayout}
            id={fieldId}
            fieldHighlight={fieldHighlight}
            fieldMandatory={fieldMandatory}
            sectionId={sectionId}
            title={fieldTitle}
            value={fieldValue}
            options={fieldOptions}
            attachments={fieldAttachments}
            submitted={submitted}
            inputRef={inputRef}
            onChange={(event, label) => onChangeRadio(event, label, index)}
            handleChangeAttachment={e => addAttachment(e, index)}
            handleRemoveFile={indexFile => onDeleteFile(indexFile, index)}
          />
        );

      case 'STATIC_FIELD':
        return (
          <StaticField
            tabletLayout={tabletLayout}
            title={fieldTitle}
            value={field?.description}
            height={field?.height}
            fieldId={field.field_id}
            submitted={submitted}
          />
        );

      case 'RATE_BY_ICON':
        return (
          <RateByIconField
            isTabular={isTabular}
            tabletLayout={tabletLayout}
            title={fieldTitle}
            fieldHighlight={fieldHighlight}
            fieldMandatory={fieldMandatory}
            onChange={value => onChangeData(value, index)}
            options={fieldOptions}
            value={fieldValue}
            submitted={submitted}
            sectionId={sectionId}
            maximumOption={field?.maximum_option}
          />
        );

      case 'STOCK_UNIT':
      case 'MASTER_STOCK':
        return (
          <MasterStockDropdown
            isTabular={isTabular}
            tabletLayout={tabletLayout}
            fieldHighlight={fieldHighlight}
            fieldMandatory={fieldMandatory}
            id={fieldId}
            title={fieldTitle}
            value={fieldValue}
            options={fieldOptions}
            submitted={submitted}
            onChange={event => onChangeData(event, index)}
          />
        );
      case 'CALCULATED_FIELD':
      case 'CALCULATED_SUBTOTAL':
        return (
          <CalculatedField
            isTabular={isTabular}
            tabletLayout={tabletLayout}
            title={fieldTitle}
            value={fieldValue}
            type={fieldKeyboardType}
            submitted={submitted}
            onChangeValue={event => onChangeData(event, index)}
          />
        );

      default:
        return;
    }
  };

  if (field?.additional_option.length > 0) {
    if (tabletLayout && additionalTitle) {
      let additionalIndex = field?.additional_option.findIndex(e => e.title === additionalTitle);
      let additional = field?.additional_option.find(e => e.title === additionalTitle);
      return (
        <div className="flex flex-col gap-2">
          {renderField({
            fieldId: additional.field_id,
            sectionId,
            fieldType,
            fieldTitle: additional.title,
            fieldValue: additional.value,
            fieldOptions: additional.options,
            fieldAttachments: additional.attachment_value,
            fieldKeyboardType,
            fieldFormat,
            index: additionalIndex,
            submitted
          })}
        </div>
      );
    } else {
      return (
        <div>
          <span className="font-semibold text-[14px]">{fieldTitle}</span>
          {field?.additional_option.map((field, index) => (
            <div key={index}>
              {renderField({
                fieldId: field.field_id,
                sectionId,
                fieldType,
                fieldTitle: field.title,
                fieldValue: field.value,
                fieldOptions: field.options,
                fieldAttachments: field.attachment_value,
                fieldKeyboardType,
                fieldFormat,
                index,
                submitted
              })}
            </div>
          ))}
        </div>
      );
    }
  } else {
    return renderField({
      fieldId,
      sectionId,
      fieldType,
      fieldTitle,
      fieldValue,
      fieldOptions,
      fieldAttachments,
      fieldKeyboardType,
      fieldFormat,
      index: null,
      submitted
    });
  }
};

export default Field;

const TextField = ({
  title,
  value,
  type,
  submitted,
  onChangeValue,
  fieldHighlight,
  fieldMandatory,
  tabletLayout,
  fieldType,
  formType,
  isTabular
}) => {
  const isStockInTransaction = fieldType === 'STOCK_IN_TRANSACTION';
  const isFormSystem = formType === 'SYSTEM';
  return (
    <div className="flex flex-1 flex-col gap-2">
      {!tabletLayout && !isTabular && (
        <div className="flex flex-rows font-semibold text-[12px]">
          {title}
          {fieldMandatory && <div className="text-[12px] text-rose-500">*</div>}
        </div>
      )}
      {fieldHighlight && !submitted && (
        <span className="text-rose-500 text-[12px] font-semibold"> This field cannot be empty</span>
      )}
      <input
        type={type?.value === 'NUMERIC' ? 'number' : 'text'}
        className={`text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none ${
          fieldHighlight && !submitted ? 'border-2 border-rose-500' : 'focus:border-[#6546C3]'
        }`}
        placeholder={submitted ? '-' : `Enter ${title}`}
        value={value}
        disabled={submitted || (isStockInTransaction && isFormSystem)}
        onChange={event => onChangeValue(event)}
      />
    </div>
  );
};

const StaticField = ({ title, value, height, fieldId, submitted, tabletLayout, isTabular }) => {
  const navigate = useNavigate();
  const { id, responseId, parentId } = useParams();
  const handleFullscreenClick = () => {
    if (submitted) {
      if (responseId) {
        navigate(
          `/Task/BrowseFormSubmission/NewestSubmittedDocument/${id}/DetailFormSubmission/${responseId}/Guidance`,
          {
            state: {
              content: value
            }
          }
        );
      } else {
        navigate(`/Task/DetailForm/${id}/Guidance`, {
          state: {
            content: value
          }
        });
      }
    } else {
      if (parentId) {
        navigate(`/Task/FormSubmission/DetailFormSubmission/${id}/${parentId}/Guidance`, {
          state: {
            content: value
          }
        });
      } else {
        navigate(`/Task/FormSubmission/DetailFormSubmission/${id}/Guidance`, {
          state: {
            content: value
          }
        });
      }
    }
  };
  return (
    <div className="flex flex-1 flex-col ">
      {!tabletLayout && !isTabular && (
        <>
          <div className="flex flex-row items-center" onClick={handleFullscreenClick}>
            <div className="font-semibold text-[12px] text-[#C800A5]">{title}</div>
            <div className="text-[#463191] flex flex-row items-center ml-auto">
              <div className="font-semibold text-[12px]">See Detail</div>
              <div className="text-[18px] ml-[20px]">{'>'}</div>
            </div>
          </div>
          <hr className="mt-2 mb-6" />
        </>
      )}

      <div
        id={`static-field-${fieldId}`}
        className="overflow-hidden overflow-y-auto bg-white"
        style={{ height: parseInt(height) || 300 }}
      >
        {parse(value)}
      </div>
    </div>
  );
};

const TextArea = ({
  title,
  value,
  submitted,
  onChangeValue,
  fieldHighlight,
  fieldMandatory,
  tabletLayout,
  isTabular
}) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      {!tabletLayout && !isTabular && (
        <div className="flex flex-rows font-semibold text-[12px]">
          {title}
          {fieldMandatory && <div className="text-[12px] text-rose-500">*</div>}
        </div>
      )}
      {fieldHighlight && !submitted && (
        <span className="text-rose-500 text-[12px] font-semibold"> This field cannot be empty</span>
      )}
      <textarea
        placeholder={submitted ? '-' : `Enter ${title}`}
        className={
          'text-[12px] px-[16px] py-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]'
        }
        value={value}
        disabled={submitted}
        onChange={event => onChangeValue(event)}
      />
    </div>
  );
};

const Checkbox = ({
  title,
  value,
  options,
  submitted,
  onChange,
  fieldHighlight,
  fieldMandatory,
  tabletLayout,
  isTabular
}) => {
  let tempOptions = options.filter(option => option.value === true);
  let tabletLayoutOptions = options.map(option => {
    return { label: option.label, value: option.label };
  });

  const onChangeTabletLayout = selectedOption => {
    let event = { target: { checked: true } };
    for (const selected of selectedOption) {
      onChange(event, selected.label);
    }
  };

  const onRemoveValueTabletLayout = removedValue => {
    let event = { target: { checked: false } };
    onChange(event, removedValue.label);
  };

  const onClearTabletLayout = removedValues => {
    let event = { target: { checked: false } };
    for (const value of removedValues) {
      onChange(event, value.label);
    }
  };

  return (
    <div className="flex flex-1 flex-col gap-2">
      {!tabletLayout && !isTabular && (
        <div className="flex flex-rows font-semibold text-[12px]">
          {title}
          {fieldMandatory && <div className="text-[12px] text-rose-500">*</div>}
        </div>
      )}
      {fieldHighlight && !submitted && (
        <span className="text-rose-500 text-[12px] font-semibold"> This field cannot be empty</span>
      )}
      <div className={!tabletLayout ? 'grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4' : ''}>
        {submitted ? (
          tempOptions.length ? (
            tempOptions.map((opt, index) => (
              <div key={index} className="text-[12px]">
                {opt.label}
              </div>
            ))
          ) : (
            <div className="text-[12px]">-</div>
          )
        ) : tabletLayout ? (
          <Select
            isSearchable={false}
            options={tabletLayoutOptions}
            name={title}
            isDisabled={submitted}
            isMulti={true}
            value={tabletLayoutOptions.filter(option => value?.includes(option.label))}
            onChange={(selectedOption, triggeredAction) => {
              switch (triggeredAction.action) {
                case 'select-option':
                  onChangeTabletLayout(selectedOption);
                  break;
                case 'clear':
                  onClearTabletLayout(triggeredAction.removedValues);
                  break;
                case 'remove-value':
                  onRemoveValueTabletLayout(triggeredAction.removedValue);
                  break;
                default:
                  return;
              }
            }}
            className={
              'flex-1 w-full text-[12px] border rounded-lg focus:outline-none focus:border-[#6546C3]'
            }
            placeholder={submitted ? '-' : `Select ${title}`}
            styles={{
              control: provided => ({
                ...provided,
                borderColor: 'none',
                ':focus': { borderColor: '#6546C3' },
                ':active': { borderColor: '#6546C3' },
                ':hover': { borderColor: '#6546C3' },
                paddingLeft: 4,
                boxShadow: 'none'
              }),
              dropdownIndicator: base => ({
                ...base,
                color: '#6546C3',
                marginRight: '4px',
                ':hover': { color: '#6546C3' }
              }),
              indicatorSeparator: () => ({}),
              valueContainer: provided => ({
                ...provided
              }),
              multiValueLabel: provided => ({
                ...provided,
                color: '#fff',
                fontFamily: 'poppins'
              }),
              multiValueRemove: provided => ({
                ...provided,
                color: '#fff'
              }),
              multiValue: provided => ({
                ...provided,
                backgroundColor: '#6546C3',
                borderRadius: 8
              })
            }}
          />
        ) : (
          options.map((option, index) => (
            <div key={index} className="flex items-center gap-2">
              <>
                <input
                  type="checkbox"
                  className="w-5 h-5 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                  checked={option.value}
                  disabled={submitted}
                  onChange={event => onChange(event, option.label)}
                />
                <div className="text-[12px]">
                  {option.label}
                  {option.mandatory && <span className="text-[#C800A5]">*</span>}
                </div>
              </>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

const RadioButton = ({
  id,
  sectionId,
  title,
  options,
  value,
  submitted,
  onChange,
  fieldHighlight,
  fieldMandatory,
  tabletLayout,
  isTabular
}) => {
  let tempOptions = options.filter(option => option.value === value);
  let tabletLayoutOptions = options.map(option => {
    return { label: option.label, value: option.label };
  });

  const onChangeTabletLayout = selectedOption => {
    let event = { target: { checked: true } };
    onChange(event, selectedOption.label);
  };

  return (
    <div className="flex flex-1 flex-col gap-2">
      {!tabletLayout && !isTabular && (
        <div className="flex flex-rows font-semibold text-[12px]">
          {title}
          {fieldMandatory && <div className="text-[12px] text-rose-500">*</div>}
        </div>
      )}
      {fieldHighlight && !submitted && (
        <span className="text-rose-500 text-[12px] font-semibold"> This field cannot be empty</span>
      )}
      <div className={!tabletLayout ? 'grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4' : ''}>
        {submitted ? (
          tempOptions.length ? (
            tempOptions.map((opt, index) => (
              <div key={index} className="text-[12px]">
                {opt.label}
              </div>
            ))
          ) : (
            <div className="text-[12px]">-</div>
          )
        ) : tabletLayout ? (
          <Select
            isSearchable={false}
            options={tabletLayoutOptions}
            name={title}
            isDisabled={submitted}
            value={tempOptions}
            onChange={selectedOption => {
              onChangeTabletLayout(selectedOption);
            }}
            className={
              'flex-1 w-full text-[12px] border rounded-lg focus:outline-none focus:border-[#6546C3]'
            }
            placeholder={submitted ? '-' : `Select ${title}`}
            styles={{
              control: provided => ({
                ...provided,
                borderColor: 'none',
                ':focus': { borderColor: '#6546C3' },
                ':active': { borderColor: '#6546C3' },
                ':hover': { borderColor: '#6546C3' },
                paddingLeft: 4,
                boxShadow: 'none'
              }),
              dropdownIndicator: base => ({
                ...base,
                color: '#6546C3',
                marginRight: '4px',
                ':hover': { color: '#6546C3' }
              }),
              indicatorSeparator: () => ({})
            }}
          />
        ) : (
          options.map((option, index) => (
            <div key={index} className="flex items-center gap-2">
              <input
                type="radio"
                name={`${sectionId}-${id}`}
                disabled={submitted}
                checked={option.value === value}
                className="w-5 h-5 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                onChange={event => onChange(event, option.value)}
              />
              <div className="text-[12px]">{option.label}</div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

const Dropdown = ({
  title,
  options,
  value,
  submitted,
  onChange,
  fieldHighlight,
  fieldMandatory,
  tabletLayout,
  isTabular,
  masterData,
  fieldType
}) => {
  const formatOptions = options.map(option => ({ label: option.label, value: option.label }));
  const isMulti =
    (fieldType === 'DROPDOWN_MASTER_DATA' &&
      masterData?.sourceMasterDataName === 'Other Master Data') ||
    fieldType !== 'DROPDOWN_MASTER_DATA';
  const formattedValue = isMulti
    ? value?.length
      ? value.map(item =>
          typeof item === 'object' && item.hasOwnProperty('label') && item.hasOwnProperty('value')
            ? item
            : { label: item, value: item }
        )
      : null
    : { label: value, value: value };
  return (
    <div className="flex flex-1 flex-col gap-2">
      {!tabletLayout && !isTabular && (
        <div className="flex flex-rows font-semibold text-[12px]">
          {title}
          {fieldMandatory && <div className="text-[12px] text-rose-500">*</div>}
        </div>
      )}
      {fieldHighlight && !submitted && (
        <span className="text-rose-500 text-[12px] font-semibold"> This field cannot be empty</span>
      )}
      <Select
        isSearchable={false}
        options={formatOptions}
        name={title}
        isDisabled={submitted}
        isMulti={isMulti}
        value={formattedValue}
        onChange={selectedOption =>
          onChange(isMulti ? selectedOption.map(option => option.label) : selectedOption?.label)
        }
        className={'flex-1 text-[12px] border rounded-lg focus:outline-none focus:border-[#6546C3]'}
        placeholder={submitted ? '-' : `Select ${title}`}
        styles={{
          control: provided => ({
            ...provided,
            borderColor: 'none',
            ':focus': { borderColor: '#6546C3' },
            ':active': { borderColor: '#6546C3' },
            ':hover': { borderColor: '#6546C3' },
            paddingLeft: 4,
            boxShadow: 'none'
          }),
          dropdownIndicator: base => ({
            ...base,
            color: '#6546C3',
            marginRight: '4px',
            ':hover': { color: '#6546C3' }
          }),
          indicatorSeparator: () => ({}),
          valueContainer: provided => ({
            ...provided
          }),
          multiValueLabel: provided => ({
            ...provided,
            color: '#fff',
            fontFamily: 'poppins'
          }),
          multiValueRemove: provided => ({
            ...provided,
            color: '#fff'
          }),
          multiValue: provided => ({
            ...provided,
            backgroundColor: '#6546C3',
            borderRadius: 8
          })
        }}
      />
    </div>
  );
};

const DatePicker = ({
  title,
  value = null,
  format = 'dd-MMM-yyyy HH:mm',
  submitted,
  onChange,
  fieldHighlight,
  fieldMandatory,
  tabletLayout,
  isTabular
}) => {
  let valueFormat = format.replace(/d/g, 'D').replace(/y/g, 'Y');
  let adjustedFormat = format.replace(/D/g, 'd').replace(/Y/g, 'y');

  return (
    <div className="flex flex-1 flex-col gap-2">
      {!tabletLayout && !isTabular && (
        <div className="flex flex-rows font-semibold text-[12px]">
          {title}
          {fieldMandatory && <div className="text-[12px] text-rose-500">*</div>}
        </div>
      )}
      {fieldHighlight && !submitted && (
        <span className="text-rose-500 text-[12px] font-semibold"> This field cannot be empty</span>
      )}
      <div className="flex relative">
        {submitted ? (
          <input
            type="text"
            value={value || '-'}
            disabled
            className={
              'input-text text-[12px] pl-[40px] pr-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]'
            }
          />
        ) : (
          <ReactDatePicker
            selected={!value ? null : moment(value, valueFormat).toDate()}
            onChange={date => onChange(moment(date).format(valueFormat))}
            timeInputLabel="Time:"
            dateFormat={adjustedFormat}
            showYearPicker={['yy', 'yyyy'].includes(format.toLowerCase())}
            name={title}
            className={
              'input-text text-[12px] pl-[40px] pr-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]'
            }
            disabled={submitted}
            wrapperClassName="date-picker"
            placeholderText={`Select ${title}`}
            onKeyDown={e => {
              e.preventDefault();
            }}
            showTimeInput={adjustedFormat.includes('H') || adjustedFormat.includes('h')}
            showTimeSelectOnly={
              (adjustedFormat.includes('H') || adjustedFormat.includes('h')) &&
              (!adjustedFormat.includes('y') || !adjustedFormat.includes('d'))
            }
            popperModifiers={[
              {
                name: 'arrow',
                options: {
                  padding: ({ popper, reference }) => ({
                    right: Math.min(popper.width, reference.width) - 50
                  })
                }
              }
            ]}
          />
        )}

        <img
          src={icons.icCalendar}
          className="w-6 absolute left-[12px] top-[8px] pointer-events-none"
          alt="calendar"
        />
      </div>
    </div>
  );
};
const Signature = ({
  title,
  value,
  submitted,
  inputRef,
  signatureClicked,
  setSignatureClicked,
  onChange,
  fieldHighlight,
  fieldMandatory,
  tabletLayout,
  isTabular
}) => {
  const handleEnd = () => {
    const canvas = inputRef.current.getTrimmedCanvas();
    const dataURL = canvas.toDataURL('image/png');
    onChange(dataURL);
  };
  return (
    <div className="flex flex-1 flex-col gap-2">
      {!tabletLayout && !isTabular && (
        <div className="flex flex-rows font-semibold text-[12px]">
          {title}
          {fieldMandatory && <div className="text-[12px] text-rose-500">*</div>}
        </div>
      )}
      {fieldHighlight && !submitted && (
        <span className="text-rose-500 text-[12px] font-semibold"> This field cannot be empty</span>
      )}
      <div className="bg-[#F2F5FC] rounded-2xl h-[212px]">
        {signatureClicked ? (
          <div className="relative h-full">
            <div className="absolute right-0 top-0">
              <div
                className="bg-white w-7 h-7 rounded-full m-2 flex items-center justify-center"
                onClick={() => {
                  inputRef.current.clear();
                  onChange('');
                }}
              >
                <img src={icons.icTrash} className="w-6 h-6" alt="clear" />
              </div>
            </div>
            <SignatureCanvas
              canvasProps={{ className: 'sigCanvas h-full w-full' }}
              ref={inputRef}
              onEnd={handleEnd}
            />
          </div>
        ) : (
          <div
            className="flex items-center justify-center gap-3 h-full"
            onClick={submitted ? () => {} : () => setSignatureClicked(!signatureClicked)}
          >
            {submitted && value ? (
              <img src={value} className="w-full h-full" alt="signature" />
            ) : submitted && !value ? (
              <span className="font-semibold text-[12px]">-</span>
            ) : (
              <>
                <img src={icons.icPen} className="w-6 h-6" alt="signature" />
                <span className="font-semibold text-[12px]">Sign Here</span>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const CheckboxWithAttachment = ({
  title,
  value,
  options,
  attachments,
  submitted,
  onChange,
  inputRef,
  handleChangeAttachment,
  handleRemoveFile,
  fieldHighlight,
  fieldMandatory,
  tabletLayout,
  isTabular
}) => {
  let tempOptions = options.filter(option => option.value === true);
  let tabletLayoutOptions = options.map(option => {
    return { label: option.label, value: option.label, mandatory: option.mandatory };
  });

  const onChangeTabletLayout = selectedOption => {
    let event = { target: { checked: true } };
    for (const selected of selectedOption) {
      onChange(event, selected.label);
    }
  };

  const onRemoveValueTabletLayout = removedValue => {
    let event = { target: { checked: false } };
    onChange(event, removedValue.label);
  };

  const onClearTabletLayout = removedValues => {
    let event = { target: { checked: false } };
    for (const value of removedValues) {
      onChange(event, value.label);
    }
  };

  const Option = props => {
    return (
      <components.Option {...props}>
        <div>
          {props.data.label}
          {props.data.mandatory && <span className="text-[#C800A5]">*</span>}
        </div>
      </components.Option>
    );
  };

  const MultiValueLabel = ({ ...props }) => {
    return (
      <components.MultiValueLabel {...props}>
        <div>
          {props.data.label}
          {props.data.mandatory && <span className="text-[#FF5500]">*</span>}
        </div>
      </components.MultiValueLabel>
    );
  };

  return (
    <div className="flex flex-1 flex-col gap-2">
      {!tabletLayout && !isTabular && (
        <div className="flex flex-rows font-semibold text-[12px]">
          {title}
          {fieldMandatory && <div className="text-[12px] text-rose-500">*</div>}
        </div>
      )}
      {fieldHighlight && !submitted && (
        <span className="text-rose-500 text-[12px] font-semibold"> This field cannot be empty</span>
      )}
      <div className={!tabletLayout ? 'grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4' : ''}>
        {submitted ? (
          tempOptions.length ? (
            tempOptions.map((opt, index) => (
              <div key={index} className="text-[12px]">
                {opt.label}
              </div>
            ))
          ) : (
            <div className="text-[12px]">-</div>
          )
        ) : tabletLayout ? (
          <Select
            components={{ Option, MultiValueLabel }}
            isSearchable={false}
            options={tabletLayoutOptions}
            name={title}
            isDisabled={submitted}
            isMulti={true}
            value={tabletLayoutOptions.filter(option => value?.includes(option.label))}
            onChange={(selectedOption, triggeredAction) => {
              switch (triggeredAction.action) {
                case 'select-option':
                  onChangeTabletLayout(selectedOption);
                  break;
                case 'clear':
                  onClearTabletLayout(triggeredAction.removedValues);
                  break;
                case 'remove-value':
                  onRemoveValueTabletLayout(triggeredAction.removedValue);
                  break;
                default:
                  return;
              }
            }}
            className={
              'flex-1 w-full text-[12px] border rounded-lg focus:outline-none focus:border-[#6546C3]'
            }
            placeholder={submitted ? '-' : `Select ${title}`}
            styles={{
              control: provided => ({
                ...provided,
                borderColor: 'none',
                ':focus': { borderColor: '#6546C3' },
                ':active': { borderColor: '#6546C3' },
                ':hover': { borderColor: '#6546C3' },
                paddingLeft: 4,
                boxShadow: 'none'
              }),
              dropdownIndicator: base => ({
                ...base,
                color: '#6546C3',
                marginRight: '4px',
                ':hover': { color: '#6546C3' }
              }),
              indicatorSeparator: () => ({}),
              valueContainer: provided => ({
                ...provided
              }),
              multiValueLabel: provided => ({
                ...provided,
                color: '#fff',
                fontFamily: 'poppins'
              }),
              multiValueRemove: provided => ({
                ...provided,
                color: '#fff'
              }),
              multiValue: provided => ({
                ...provided,
                backgroundColor: '#6546C3',
                borderRadius: 8
              })
            }}
          />
        ) : (
          options.map((option, index) => (
            <div key={index} className="flex items-center gap-2">
              <>
                <input
                  type="checkbox"
                  className="w-5 h-5 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                  checked={option.value}
                  disabled={submitted}
                  onChange={event => onChange(event, option.label)}
                />
                <div className="text-[12px]">
                  {option.label}
                  {option.mandatory && <span className="text-[#C800A5]">*</span>}
                </div>
              </>
            </div>
          ))
        )}
      </div>
      <div className="flex flex-col gap-2 mt-2">
        {(submitted && attachments) || !submitted ? (
          <div className="font-semibold text-[12px] text-[#C800A5]">Attachment</div>
        ) : (
          <></>
        )}
        <FieldAttachment
          attachments={attachments ? [...attachments] : attachments}
          fileInputRef={inputRef}
          submitted={submitted}
          handleAddAttachment={handleChangeAttachment}
          handleDeleteAttachment={handleRemoveFile}
        />
      </div>
    </div>
  );
};

const RadioButtonWithAttachment = ({
  id,
  sectionId,
  title,
  options,
  value,
  attachments,
  submitted,
  onChange,
  inputRef,
  handleChangeAttachment,
  handleRemoveFile,
  fieldHighlight,
  fieldMandatory,
  tabletLayout,
  isTabular
}) => {
  let tempOptions = options.filter(option => option.value === value);
  let tabletLayoutOptions = options.map(option => {
    return { label: option.label, value: option.label, mandatory: option.mandatory };
  });

  const onChangeTabletLayout = selectedOption => {
    let event = { target: { checked: true } };
    onChange(event, selectedOption.label);
  };

  const Option = props => {
    return (
      <components.Option {...props}>
        <div>
          {props.data.label}
          {props.data.mandatory && <span className="text-[#C800A5]">*</span>}
        </div>
      </components.Option>
    );
  };

  const SingleValue = ({ ...props }) => {
    return (
      <components.SingleValue {...props}>
        <div>
          {props.data.label}
          {props.data.mandatory && <span className="text-[#C800A5]">*</span>}
        </div>
      </components.SingleValue>
    );
  };

  return (
    <div className="flex flex-1 flex-col gap-2">
      {!tabletLayout && !isTabular && (
        <div className="flex flex-rows font-semibold text-[12px]">
          {title}
          {fieldMandatory && <div className="text-[12px] text-rose-500">*</div>}
        </div>
      )}
      {fieldHighlight && !submitted && (
        <span className="text-rose-500 text-[12px] font-semibold"> This field cannot be empty</span>
      )}
      <div className={!tabletLayout ? 'grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4' : ''}>
        {submitted ? (
          tempOptions.length ? (
            tempOptions.map((opt, index) => (
              <div key={index} className="text-[12px]">
                {opt.label}
              </div>
            ))
          ) : (
            <div className="text-[12px]">-</div>
          )
        ) : tabletLayout ? (
          <Select
            components={{ Option, SingleValue }}
            isSearchable={false}
            options={tabletLayoutOptions}
            name={title}
            isDisabled={submitted}
            value={tempOptions}
            onChange={selectedOption => {
              onChangeTabletLayout(selectedOption);
            }}
            className={
              'flex-1 w-full text-[12px] border rounded-lg focus:outline-none focus:border-[#6546C3]'
            }
            placeholder={submitted ? '-' : `Select ${title}`}
            styles={{
              control: provided => ({
                ...provided,
                borderColor: 'none',
                ':focus': { borderColor: '#6546C3' },
                ':active': { borderColor: '#6546C3' },
                ':hover': { borderColor: '#6546C3' },
                paddingLeft: 4,
                boxShadow: 'none'
              }),
              dropdownIndicator: base => ({
                ...base,
                color: '#6546C3',
                marginRight: '4px',
                ':hover': { color: '#6546C3' }
              }),
              indicatorSeparator: () => ({})
            }}
          />
        ) : (
          options.map((option, index) => (
            <div key={index} className="flex items-center gap-2">
              <input
                type="radio"
                name={`${sectionId}-${id}`}
                disabled={submitted}
                checked={option.value === value}
                className="w-5 h-5 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                onChange={event => onChange(event, option.value)}
              />
              <div className="text-[12px]">
                {option.label}
                {option.mandatory && <span className="text-[#C800A5]">*</span>}
              </div>
            </div>
          ))
        )}
      </div>
      <div className="flex flex-col gap-2 mt-2">
        {(submitted && attachments) || !submitted ? (
          <div className="font-semibold text-[12px] text-[#C800A5]">Attachment</div>
        ) : (
          <></>
        )}
        <FieldAttachment
          attachments={attachments ? [...attachments] : attachments}
          fileInputRef={inputRef}
          submitted={submitted}
          handleAddAttachment={handleChangeAttachment}
          handleDeleteAttachment={handleRemoveFile}
        />
      </div>
    </div>
  );
};

const RateByIconField = ({
  title,
  options,
  value,
  submitted,
  onChange,
  maximumOption,
  fieldHighlight,
  fieldMandatory,
  tabletLayout,
  isTabular
}) => {
  let rawOptions = _.cloneDeep([...options].reverse());
  const emptyIcons = rawOptions.map(unicode => {
    const codePoint = parseInt(unicode.replace('U+', ''), 16);
    return (
      <div className="opacity-50 mx-[2px]">
        <span className="text-[16px] sm:text-[32px]" style={{ color: 'red' }}>
          {String.fromCodePoint(`0x${codePoint.toString(16).toUpperCase()}`)}
        </span>
      </div>
    );
  });
  const fullIcons = rawOptions.map(unicode => {
    const codePoint = parseInt(unicode.replace('U+', ''), 16);
    return (
      <div className="opacity-100 mx-[2px]">
        <span className="text-[16px] sm:text-[32px]" style={{ color: 'red' }}>
          {String.fromCodePoint(`0x${codePoint.toString(16).toUpperCase()}`)}
        </span>
      </div>
    );
  });
  let isDifferentOptions = options.length > 1 && value;

  return (
    <div className="flex flex-1 flex-col gap-2">
      {!tabletLayout && !isTabular && (
        <div className="flex flex-rows font-semibold text-[12px]">
          {title}
          {fieldMandatory && <div className="text-[12px] text-rose-500">*</div>}
        </div>
      )}
      {fieldHighlight && !submitted && (
        <span className="text-rose-500 text-[12px] font-semibold"> This field cannot be empty</span>
      )}
      <div className="flex flex-row">
        <Rating
          readonly={submitted}
          initialRating={value}
          stop={isDifferentOptions ? 1 : maximumOption}
          emptySymbol={emptyIcons}
          fullSymbol={isDifferentOptions ? fullIcons[value - 1] : fullIcons}
          onChange={value => {
            onChange(value);
          }}
        />
        {!submitted && isDifferentOptions && (
          <div onClick={() => onChange('')} className="cursor-pointer text-red-500">
            X
          </div>
        )}
      </div>
    </div>
  );
};

const MasterStockDropdown = ({
  title,
  options,
  value,
  submitted,
  onChange,
  fieldHighlight,
  fieldMandatory,
  tabletLayout,
  isTabular
}) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      {!tabletLayout && !isTabular && (
        <div className="flex flex-rows font-semibold text-[12px]">
          {title}
          {fieldMandatory && <div className="text-[12px] text-rose-500">*</div>}
        </div>
      )}
      {fieldHighlight && !submitted && (
        <span className="text-rose-500 text-[12px] font-semibold"> This field cannot be empty</span>
      )}
      <Select
        isSearchable={false}
        options={options}
        name={title}
        isDisabled={submitted}
        isClearable
        value={value}
        onChange={selectedOption => onChange(selectedOption)}
        className={
          'flex-1 w-full text-[12px] border rounded-lg focus:outline-none focus:border-[#6546C3]'
        }
        placeholder={submitted ? '-' : `Select ${title}`}
        styles={{
          control: provided => ({
            ...provided,
            borderColor: 'none',
            ':focus': { borderColor: '#6546C3' },
            ':active': { borderColor: '#6546C3' },
            ':hover': { borderColor: '#6546C3' },
            paddingLeft: 4,
            boxShadow: 'none'
          }),
          dropdownIndicator: base => ({
            ...base,
            color: '#6546C3',
            marginRight: '4px',
            ':hover': { color: '#6546C3' }
          }),
          indicatorSeparator: () => ({})
        }}
      />
    </div>
  );
};

const CalculatedField = ({ title, value, type, submitted, tabletLayout, isTabular }) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      {!tabletLayout && !isTabular && (
        <div className="flex flex-rows font-semibold text-[12px]">{title}</div>
      )}
      <input
        type={type?.value === 'NUMERIC' ? 'number' : 'text'}
        className={`text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none`}
        placeholder={''}
        value={value}
        disabled={true}
      />
    </div>
  );
};
