import { useEffect, useRef, useState } from 'react';
import { apiUrls, icons, mockData } from '../../../../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Toast } from '../../../../../../../components';
import { setShowLoading } from '../../../../../../../store/slices/popup';
import { del, post } from '../../../../../../../api/base';
import _ from 'lodash';
import { useNavigate } from 'react-router';
import { cacheHelper, offlineHelper } from '../../../../../../../helpers';
import { v4 as uuidv4 } from 'uuid';

const DocumentModal = ({ showModal, setShowModal, id, setTask, setComments }) => {
  const [attachments, setAttachments] = useState([]);
  const [rawAttachment, setRawAttachment] = useState([]);
  const [remark, setRemark] = useState('');
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const user = useSelector(state => state.user.userData);
  const dispatch = useDispatch();
  const uuid = uuidv4();
  async function handleAddAttachment({ target: { files } }) {
    if (!files.length) return;
    const fileSizeUser = user?.file_size * 1048576;
    const formData = new FormData();
    for (let index = 0; index < files.length; index++) {
      formData.append('files[]', files[index]);
    }
    let statusFile;
    if (attachments?.length <= 5) {
      if (files.length <= 5) {
        for (let i = 0; i < files.length; i++) {
          const isImage =
            files[i].type.match('image') || files[i].type.match('video') ? true : false;
          if (files[i].size > fileSizeUser) {
            statusFile = false;
            toast.error(
              <Toast
                message={'Error'}
                detailedMessage={`Cannot upload ${files[i].name} because the size of file is more than ${fileSizeUser} mb`}
              />
            );
          } else {
            statusFile = true;
            if (!isImage) {
              formData.append('files[]', {
                uri: files[i].fileCopyUri,
                type: files[i].type,
                name: files[i].name
              });
            } else {
              toast.error(
                <Toast
                  message={'Error'}
                  detailedMessage={`Cannot upload ${files[i].name} because the file is not an document`}
                />
              );
              return;
            }
          }
        }
        if (statusFile || formData._parts.length) {
          dispatch(setShowLoading(true));
          if (navigator.onLine) {
            post('/api/dynamic-forms/attachments', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(response => {
              if (response.status === 200) {
                let rawAttachment = attachments.concat(response.data);
                setAttachments(rawAttachment);
              } else if (response.status === 0) {
                toast.error(
                  <Toast
                    message={'Error'}
                    detailedMessage={'Cannot Reach server, please try again later'}
                  />
                );
              } else {
                toast.error(
                  <Toast message={'Error'} detailedMessage={`${response.data.error.message}`} />
                );
              }
              dispatch(setShowLoading(false));
            });
          } else {
            const tempAttachment = await Promise.all(
              formData
                .getAll('files[]')
                .filter(data => data instanceof File)
                .map(async file => {
                  return await offlineHelper.saveAttachmentOffline(file);
                })
            );
            const newAttachments = [];
            setRawAttachment(rawAttachment.concat(tempAttachment));
            if (!_.isEmpty(tempAttachment) && _.has(tempAttachment[0], 'cacheIdentifier')) {
              for (const value of tempAttachment) {
                await cacheHelper.getCachedAttachment(value.cacheIdentifier).then(res => {
                  newAttachments.push(res);
                });
              }
              setAttachments(attachments.concat(newAttachments));
            } else {
              setAttachments(attachments.concat(newAttachments));
            }

            dispatch(setShowLoading(false));
          }
        }
      } else {
        toast.error(
          <Toast message={'Error'} detailedMessage={'Maximum of uploaded file is 5 files'} />
        );
      }
    } else {
      toast.error(
        <Toast
          message={'Error'}
          detailedMessage={'You have reached the maximum number of file uploads, maximum 5'}
        />
      );
    }
    fileInputRef.current.value = null;
  }

  const handleCloseModal = () => {
    setAttachments([]);
    setShowModal(false);
  };

  const deleteAttachment = id => {
    del(`${apiUrls.TASK}/attachments/${id}`).then(response => {
      dispatch(setShowLoading(false));
      if (response.status === 200) {
        setAttachments(prevAttachments => prevAttachments.filter(attach => attach.id !== id));
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={`${response.data.error.message}`} />);
      }
    });
  };

  const handleDeleteAttachment = index => {
    dispatch(setShowLoading(true));
    if (navigator.onLine) {
      deleteAttachment(attachments[index].id);
    } else {
      cacheHelper.deleteCachedAttachment(rawAttachment[index].cacheIdentifier);
      rawAttachment.splice(index, 1);
      attachments.splice(index, 1);
      setAttachments([...attachments]);
      dispatch(setShowLoading(false));
    }
  };

  const handleSendButton = () => {
    let payload = {
      remark: {
        message: remark
      },
      attachments: navigator.onLine ? attachments : rawAttachment,
      description: 'Added Documents'
    };
    dispatch(setShowLoading(true));
    if (navigator.onLine) {
      post(`${apiUrls.TASK}/${id}/comments`, payload)
        .then(response => {
          dispatch(setShowLoading(false));
          if (response.status === 201) {
            handleCloseModal();
            navigate(0);
          } else {
            toast.error(<Toast message={'Error'} detailedMessage={response.data.error.message} />);
          }
        })
        .catch(e => {
          dispatch(setShowLoading(false));
          toast.error(<Toast message={'Error'} detailedMessage={e} />);
        });
    } else {
      dispatch(setShowLoading(false));
      let tempTask;
      let tempComment = {
        id: 'offline' + uuid,
        remark: {
          message: [{ value: remark, type: 'TEXT' }]
        },
        description: 'Added Documents',
        attachments: rawAttachment,
        recording: null,
        created_at: new Date().toISOString(),
        sender: user?.full_name
      };
      cacheHelper
        .getCachedData(apiUrls.TASK)
        .then(response => {
          let index = response.data?.findIndex(task => task.id.toString() === id.toString());
          tempTask = response.data;
          tempTask[index].comments.unshift(tempComment);
          offlineHelper
            .updateRequestDataOffline({
              url: `${apiUrls.TASK}/${id}/comments`,
              body: payload,
              cacheUrl: apiUrls.TASK,
              key: 'data',
              data: [...tempTask]
            })
            .then(_ => {
              setTask(tempTask[index]);
              setComments(tempTask[index].comments.sort((a, b) => b.id - a.id));
              handleCloseModal();
            })
            .catch(_ => {
              dispatch(setShowLoading(false));
              toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
            });
        })
        .finally(() => {
          dispatch(setShowLoading(false));
        });
    }
  };

  const getAttachmentType = fileName => {
    if (fileName) {
      const fileNameSplit = fileName.split('.');
      return fileNameSplit[fileNameSplit.length - 1];
    } else {
      return 'jpg';
    }
  };

  const getAttachmentImage = fileType => {
    switch (fileType) {
      case 'pptx':
        return icons.icPpt;
      case 'ppt':
        return icons.icPpt;
      case 'docx':
        return icons.icDoc;
      case 'doc':
        return icons.icDoc;
      case 'xls':
        return icons.icXls;
      case 'xlsx':
        return icons.icXls;
      default:
        return icons.icDoc;
    }
  };

  useEffect(() => {
    if (showModal) fileInputRef.current.click();
  }, [showModal]);

  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }}
        className="absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer"
        multiple={true}
        accept={mockData.fileTypes.slice(3, 13).join(',')}
        onChange={handleAddAttachment}
      />
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px] h-fit p-0">
          <div className="bg-white p-6">
            <div className="flex flex-row items-center justify-between">
              <div className="text-[18px] font-semibold">Document</div>
              <img
                src={icons.icCloseModal}
                className="w-6 h-6 cursor-pointer"
                alt={'close-modal'}
                onClick={() => setShowModal(false)}
              />
            </div>
            <hr className="mt-2 mb-6" />
            <div className="flex flex-col gap-2 sm:gap-0">
              <div className="flex flex-row flex-wrap gap-4">
                {attachments?.map((attachment, index) => {
                  return (
                    <div
                      className="relative w-[120px] h-[120px] bg-[#EBEDF8] rounded-lg flex flex-col gap-1 items-center justify-center"
                      key={index}
                      onClick={() => window.open(attachment.url)}
                    >
                      <img
                        src={getAttachmentImage(
                          getAttachmentType(attachment.fileName || attachment.name)
                        )}
                        className="sm:w-20 sm:h-20 w-10 h-10"
                        alt="Attachments"
                      />
                      <div className="w-20 truncate text-[12px] text-[#232323] text-center">
                        {attachment.fileName
                          ? attachment.fileName.substring(attachment.fileName.indexOf('_') + 1)
                          : attachment.name.substring(attachment.name.indexOf('_') + 1)}
                      </div>
                      <button
                        className="bg-white w-7 h-7 rounded-full flex items-center justify-center absolute bottom-[100px] left-[100px]"
                        onClick={e => {
                          e.stopPropagation();
                          handleDeleteAttachment(index);
                        }}
                      >
                        <img src={icons.icTrash} className="w-6 h-6" alt="clear" />
                      </button>
                    </div>
                  );
                })}
              </div>
              <div className=" flex flex-row items-center justify-center gap-2 mx-[18px] sm:mx-0 mt-2">
                <div className="flex bg-white w-full h-[40px] border-[1px] items-center p-[12px] rounded-lg  rounded-lg">
                  <img
                    src={icons.icAttachment}
                    className="w-[24px] h-[24px] cursor-pointer"
                    alt="Attachment"
                    onClick={() => fileInputRef.current.click()}
                  />
                  <input
                    className="ml-2 focus:outline-none text-[12px] w-full"
                    value={remark}
                    onChange={event => setRemark(event.target.value)}
                    placeholder="Add remark..."
                  />
                </div>
                <div
                  className="bg-[#6546C3] p-[6px] rounded-lg cursor-pointer"
                  onClick={handleSendButton}
                >
                  <img src={icons.icSend} className="w-[24px]  h-[24px] " alt="send" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentModal;
