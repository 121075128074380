import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import GroupAssigneeList from './GroupAssigneeList';
import { get, post, put } from '../../api/base';
import { apiUrls, images } from '../../constants';
import { toast } from 'react-toastify';
import Toast from '../Toast';
import UserAssigneeList from './UserAssigneeList';
import { useNavigate, useParams } from 'react-router-dom';
import SuccessPopUp from '../Popup/SuccessPopup';
import { useDispatch, useSelector } from 'react-redux';
import { setShowLoading } from '../../store/slices/popup';
import ConfirmationModal from '../Popup/ConfirmationModal';
import { offlineHelper } from '../../helpers';
import { v4 as uuidv4 } from 'uuid';

const AssigneeList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  let { type, payload } = location.state;
  const apiUrl = type === 'ShareTask' || type === 'SelectAssignee' ? apiUrls.TASK : apiUrls.ISSUE;
  const [groups, setGroups] = useState([]);
  const user = useSelector(state => state.user.userData);
  const uuid = uuidv4();
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [fullSelectedUsers, setFullSelectedUsers] = useState([]);
  const [fullSelectedGroups, setFullSelectedGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [successModal, setSuccessModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [tabName, setTabName] = useState('Contacts');
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [labelSuccessButton, setLabelSuccessButton] = useState('');
  const [path, setPath] = useState('');
  const [workingLocationOptions, setWorkingLocationOptions] = useState([]);
  const [workingLocation, setWorkingLocation] = useState('');
  const [isFetchingUsers, setIsFetchingUsers] = useState(true);
  const [isFetchingGroups, setIsFetchingGroups] = useState(true);

  const taskGroups = (search, workLocationValue) => {
    let query = {
      search,
      work_location: workLocationValue
    };
    if (!search) delete query.search;
    setIsFetchingGroups(true);
    get(apiUrls.TASK_GROUPS, { query }).then(res => {
      const { status } = res;
      if (status === 200) {
        setGroups(res.data.groups);
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={res.data.error.message} />);
      }
      setIsFetchingGroups(false);
    });
  };

  const taskUsers = search => {
    let query = { search };
    if (!search) delete query.search;
    setIsFetchingUsers(true);
    get(apiUrls.TASK_USERS, { query }).then(res => {
      const { status } = res;
      if (status === 200) {
        setUsers(res.data.users);
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={res.data.error.message} />);
      }
      setIsFetchingUsers(false);
    });
  };

  const usersAssignee = search => {
    setIsFetchingUsers(true);
    get(`${apiUrl}/${id}/not-assignee`, {
      query: { type: 'user', search }
    }).then(res => {
      const { status } = res;
      if (status === 200) {
        setUsers(res.data.data);
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={res.data.error.message} />);
      }
      setIsFetchingUsers(false);
    });
  };

  const groupsAssignee = (search, workLocationValue) => {
    setIsFetchingGroups(true);
    get(`${apiUrl}/${id}/not-assignee`, {
      query: { type: 'group', search, work_location: workLocationValue }
    }).then(res => {
      const { status } = res;
      if (status === 200) {
        setGroups(res.data.data);
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={res.data.error.message} />);
      }
      setIsFetchingGroups(false);
    });
  };

  const usersShare = search => {
    setIsFetchingUsers(true);
    get(`${apiUrl}/${id}/not-share`, {
      query: { type: 'user', search }
    }).then(res => {
      const { status } = res;
      if (status === 200) {
        setUsers(res.data.data);
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={res.data.error.message} />);
      }
      setIsFetchingUsers(false);
    });
  };

  const groupsShare = (search, workLocationValue) => {
    setIsFetchingGroups(true);
    get(`${apiUrl}/${id}/not-share`, {
      query: { type: 'group', search, work_location: workLocationValue }
    }).then(res => {
      const { status } = res;
      if (status === 200) {
        setGroups(res.data.data);
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={res.data.error.message} />);
      }
      setIsFetchingGroups(false);
    });
  };

  const issueUsers = search => {
    setIsFetchingUsers(true);
    get(apiUrls.ISSUE_USERS, {
      query: { search }
    }).then(res => {
      const { status } = res;
      if (status === 200) {
        setUsers(res.data.users);
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={res.data.error.message} />);
      }
      setIsFetchingUsers(false);
    });
  };

  const issueGroups = (search, workLocationValue) => {
    setIsFetchingGroups(true);
    get(apiUrls.ISSUE_GROUPS, {
      query: { search, work_location: workLocationValue }
    }).then(res => {
      const { status } = res;
      if (status === 200) {
        setGroups(res.data.groups);
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={res.data.error.message} />);
      }
      setIsFetchingGroups(false);
    });
  };

  const submitTask = () => {
    if (assignUserGroupValidation()) {
      dispatch(setShowLoading(true));
      let finalPayload = {
        ...payload,
        assign_to_users: selectedUsers,
        assign_to_groups: selectedGroups
      };
      if (id) {
        put(`${apiUrls.TASK}/${id}`, finalPayload).then(res => {
          dispatch(setShowLoading(false));
          if (res.status === 201) {
            setSuccessModal(true);
          } else {
            toast.error(<Toast message={'Error'} detailedMessage={res.data.error.message} />);
          }
        });
      } else {
        if (navigator.onLine) {
          post(apiUrls.TASK, finalPayload).then(res => {
            dispatch(setShowLoading(false));
            if (res.status === 201) {
              setSuccessModal(true);
            } else {
              toast.error(<Toast message={'Error'} detailedMessage={res.data.error.message} />);
            }
          });
        } else {
          offlineHelper
            .saveRequestDataOffline({
              url: apiUrls.TASK,
              body: finalPayload,
              cacheUrl: apiUrls.TASK,
              key: 'data',
              data: {
                id: 'offline' + uuid,
                title: payload?.title,
                description: payload?.description,
                planned_start: payload?.planned_start,
                due_date: payload?.due_date,
                visibility: payload?.visibility,
                attachments: payload?.attachments,
                created_by: user?.full_name,
                created_at: new Date().toISOString(),
                status: 'OPEN_TASK',
                status_progress_task: 'NOT_STARTED',
                users: fullSelectedUsers,
                groups: fullSelectedGroups,
                comments: [
                  {
                    id: null,
                    remark: {
                      message: []
                    },
                    description: 'Assign this task to',
                    attachments: null,
                    recording: null,
                    created_at: new Date().toISOString(),
                    sender: user?.full_name,
                    tags: [],
                    asign_to: [...fullSelectedUsers, ...fullSelectedGroups]
                  }
                ]
              }
            })
            .then(_ => {
              dispatch(setShowLoading(false));
              setSuccessModal(true);
            })
            .catch(_ => {
              dispatch(setShowLoading(false));
              toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
            });
          dispatch(setShowLoading(false));
        }
      }
    }
  };

  const submitIssue = () => {
    if (assignUserGroupValidation()) {
      dispatch(setShowLoading(true));
      let finalPayload = {
        ...payload,
        assign_to_users: selectedUsers,
        assign_to_groups: selectedGroups
      };
      if (id) {
        put(`${apiUrls.ISSUE}/${id}`, finalPayload).then(res => {
          dispatch(setShowLoading(false));
          if (res.status === 201) {
            setSuccessModal(true);
          } else {
            toast.error(<Toast message={'Error'} detailedMessage={res.data.error.message} />);
          }
        });
      } else {
        if (navigator.onLine) {
          post(apiUrls.ISSUE, finalPayload).then(res => {
            dispatch(setShowLoading(false));
            if (res.status === 201) {
              setSuccessModal(true);
            } else {
              toast.error(<Toast message={'Error'} detailedMessage={res.data.error.message} />);
            }
          });
        } else {
          offlineHelper
            .saveRequestDataOffline({
              url: apiUrls.ISSUE,
              body: finalPayload,
              cacheUrl: apiUrls.ISSUE,
              key: 'data',
              data: {
                id: 'offline' + uuid,
                title: payload?.title,
                description: payload?.description,
                form_section_id: payload?.form_section_id || null,
                issue_type: payload?.issue_type,
                location: payload?.location,
                visibility: payload?.visibility,
                attachments: payload?.attachments,
                saverity: payload?.saverity,
                created_by: user?.full_name,
                created_at: new Date().toISOString(),
                status: 'OPEN',
                due_date: payload?.due_date,
                users: fullSelectedUsers,
                groups: fullSelectedGroups,
                comments: [
                  {
                    id: 1,
                    remark: {
                      message: []
                    },
                    description: 'Assign this issue to',
                    attachments: null,
                    recording: null,
                    created_at: new Date().toISOString(),
                    sender: user?.full_name,
                    tags: [],
                    asign_to: [...fullSelectedUsers, ...fullSelectedGroups]
                  }
                ]
              }
            })
            .then(_ => {
              dispatch(setShowLoading(false));
              setSuccessModal(true);
            })
            .catch(_ => {
              dispatch(setShowLoading(false));
              toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
            });
          dispatch(setShowLoading(false));
        }
      }
    }
  };

  const selectAssignee = assignType => {
    if (assignType === 'Add') {
      handleAddAsignee();
    } else if (assignType === 'Replace') {
      if (assignUserGroupValidation()) {
        setShowConfirmationModal(true);
      }
    }
  };

  const handleAddAsignee = () => {
    if (assignUserGroupValidation()) {
      dispatch(setShowLoading(true));
      post(`${apiUrl}/${id}/assignee`, {
        users: selectedUsers,
        groups: selectedGroups
      })
        .then(response => {
          dispatch(setShowLoading(false));
          const { status } = response;
          if (status === 200) {
            setSuccessModal(true);
            setMessage(
              `Hooray! You have successfully add assignee ${
                type === 'ShareTask' ? 'task' : 'issue'
              }`
            );
          } else {
            toast.error(
              <Toast message={'Error'} detailedMessage={response?.data?.error?.message} />
            );
          }
        })
        .catch(e => {
          dispatch(setShowLoading(false));
          toast.error(<Toast message={'Error'} detailedMessage="Failed to add assignee" />);
        });
    }
  };

  const handleReplaceAssignee = () => {
    setShowConfirmationModal(false);
    dispatch(setShowLoading(true));
    put(`${apiUrl}/${id}/assignee`, {
      users: selectedUsers,
      groups: selectedGroups
    })
      .then(response => {
        dispatch(setShowLoading(false));
        const { status } = response;
        if (status === 200) {
          setSuccessModal(true);
          setMessage('Hooray! You have successfully replace assignee');
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={response?.data?.error?.message} />);
        }
      })
      .catch(e => {
        dispatch(setShowLoading(false));
        toast.error(<Toast message={'Error'} detailedMessage="Failed to replace assignee" />);
      });
  };

  const shareTaskOrIssue = () => {
    if (assignUserGroupValidation()) {
      dispatch(setShowLoading(true));
      post(`${apiUrl}/${id}/share`, {
        users: selectedUsers,
        groups: selectedGroups
      })
        .then(response => {
          dispatch(setShowLoading(false));
          const { status } = response;
          if (status === 200) {
            setSuccessModal(true);
            setMessage(
              `Hooray! You have successfully share ${type === 'ShareTask' ? 'task' : 'issue'}`
            );
          } else {
            toast.error(
              <Toast message={'Error'} detailedMessage={response?.data?.error?.message} />
            );
          }
        })
        .catch(e => {
          dispatch(setShowLoading(false));
          toast.error(
            <Toast
              message={'Error'}
              detailedMessage={`Failed to share ${type === 'ShareTask' ? 'task' : 'issue'}`}
            />
          );
        });
    }
  };

  const fetchTaskWorkLocation = () => {
    get(apiUrls.TASK_WORK_LOCATION).then(response => {
      const { status } = response;
      if (status === 200) {
        setWorkingLocationOptions(response.data);
      }
    });
  };
  const fetchIssueWorkLocation = () => {
    get(apiUrls.ISSUE_WORK_LOCATION).then(response => {
      const { status } = response;
      if (status === 200) {
        setWorkingLocationOptions(response.data);
      }
    });
  };

  const assignUserGroupValidation = () => {
    if (selectedUsers.length === 0 && selectedGroups.length === 0) {
      toast.error(
        <Toast
          message={'Error'}
          detailedMessage="You need to atleast select one user or one group"
        />
      );
      return false;
    }
    return true;
  };

  const submit = assignType => {
    switch (type) {
      case 'SubmitTask':
        submitTask();
        break;
      case 'SubmitIssue':
        submitIssue();
        break;
      case 'SelectAssignee':
      case 'SelectAssigneeIssue':
        selectAssignee(assignType);
        break;
      case 'ShareTask':
      case 'ShareIssue':
        shareTaskOrIssue();
        break;
      default:
        return;
    }
  };

  const getData = () => {
    switch (type) {
      case 'SubmitTask':
        fetchTaskWorkLocation();
        setTitle('Successfully Send Task');
        setMessage('Hooray! You have send a new task');
        setPath('Task');
        break;
      case 'SubmitIssue':
        fetchIssueWorkLocation();
        setTitle('Successfully Send Issue');
        setMessage('Hooray! You have send a new issue');
        setPath('Issue');
        break;
      case 'SelectAssignee':
      case 'ShareTask':
        fetchTaskWorkLocation();
        setTitle('Successfully');
        setLabelSuccessButton('Ok, Back to detail');
        setPath('Task');
        break;
      case 'SelectAssigneeIssue':
      case 'ShareIssue':
        fetchIssueWorkLocation();
        setTitle('Successfully');
        setLabelSuccessButton('Ok, Back to detail');
        setPath('Issue');
        break;
      default:
        return;
    }
  };

  const getUsersByType = {
    SubmitTask: taskUsers,
    SubmitIssue: issueUsers,
    SelectAssignee: usersAssignee,
    ShareTask: usersShare,
    SelectAssigneeIssue: usersAssignee,
    ShareIssue: usersShare
  };

  const getGroupsByType = {
    SubmitTask: taskGroups,
    SubmitIssue: issueGroups,
    SelectAssignee: groupsAssignee,
    ShareTask: groupsShare,
    SelectAssigneeIssue: groupsAssignee,
    ShareIssue: groupsShare
  };

  const handleSuccessButton = () => {
    if (type === 'SelectAssignee' || type === 'ShareTask') {
      navigate(`/Task/DetailTask/${id}`);
    } else if (type === 'SelectAssigneeIssue' || type === 'ShareIssue') {
      navigate(`/Task/DetailIssue/${id}`);
    } else {
      navigate('/Task', {
        state: {
          tabName: path
        }
      });
    }
  };

  const tabResult = () => {
    switch (tabName) {
      case 'Contacts':
        return (
          <UserAssigneeList
            users={users}
            getUsers={getUsersByType[type]}
            setSelectedUsers={setSelectedUsers}
            selectedUsers={selectedUsers}
            isFetching={isFetchingUsers}
            fullSelectedUsers={fullSelectedUsers}
            setFullSelectedUsers={setFullSelectedUsers}
          />
        );
      case 'Groups':
        return (
          <GroupAssigneeList
            groups={groups}
            getGroups={getGroupsByType[type]}
            selectedGroups={selectedGroups}
            setSelectedGroups={setSelectedGroups}
            workingLocation={workingLocation}
            setWorkingLocation={setWorkingLocation}
            workingLocationOptions={workingLocationOptions}
            isFetching={isFetchingGroups}
            fullSelectedGroups={fullSelectedGroups}
            setFullSelectedGroups={setFullSelectedGroups}
          />
        );
      default:
        return;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    switch (type) {
      case 'SubmitTask':
        taskGroups('', workingLocation?.name);
        break;
      case 'SubmitIssue':
        issueGroups('', workingLocation?.name);
        break;
      case 'SelectAssignee':
      case 'SelectAssigneeIssue':
        groupsAssignee('', workingLocation?.name);
        break;
      case 'ShareTask':
      case 'ShareIssue':
        groupsShare('', workingLocation?.name);
        break;
      default:
        return;
    }
  }, [workingLocation]);

  return (
    <div>
      <div className="grid grid-flow-col rounded-[8px] grid-cols-2 auto-rows-col bg-white gap-2 justify-evenly mx-[24px] h-[36px]">
        <button
          className={`${tabName === 'Contacts' ? 'border-b-4 border-[#C800A5]' : ''}  text-[14px]`}
          onClick={() => setTabName('Contacts')}
        >
          <div
            className={`${
              tabName === 'Contacts' ? 'font-semibold text-[#C800A5]' : 'text-[#AFAFB9]'
            }`}
          >
            Contacts
          </div>
        </button>
        <button
          className={`${
            tabName === 'Groups' ? 'border-b-4 border-[#C800A5]' : 'text-[#AFAFB9]'
          }  text-[14px]`}
          onClick={() => setTabName('Groups')}
        >
          <div className={`${tabName === 'Groups' ? 'font-semibold text-[#C800A5]' : ''}`}>
            Groups
          </div>
        </button>
      </div>
      <div className="bg-white rounded-2xl mx-0 sm:mx-4 ">
        {tabResult()}
        <div
          className={`flex flex-row gap-2 mt-[20px] sm:mt-[20px] m-[12px] mb-[16px] ${
            type === 'SelectAssignee' ? 'justify-between' : 'justify-end'
          }`}
        >
          <button
            className=" bg-transparent border-2 border-[#6546C3] rounded-lg py-[10px] w-[150px] font-semibold text-[#6546C3] 
                    text-[14px] flex justify-center items-center"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          {type === 'SelectAssignee' || type === 'SelectAssigneeIssue' ? (
            <div className="flex flex-row gap-2">
              <button
                className=" bg-transparent border-2 border-[#6546C3] rounded-lg py-[10px] w-[150px] font-semibold text-[#6546C3] 
                    text-[14px] flex justify-center items-center"
                onClick={() => submit('Add')}
              >
                Add
              </button>
              <button
                className="bg-[#6546C3] border-2 border-[#6546C3] rounded-lg py-[10px] w-[150px] font-semibold text-white 
                    text-[14px] flex justify-center items-center"
                onClick={() => submit('Replace')}
              >
                Replace
              </button>
            </div>
          ) : (
            <button
              className="bg-[#6546C3] border-2 border-[#6546C3] rounded-lg py-[10px] w-[150px] font-semibold text-white 
                    text-[14px] flex justify-center items-center"
              onClick={() => submit()}
            >
              {type === 'ShareTask' || type === 'ShareIssue' ? 'Share' : 'Submit'}
            </button>
          )}
        </div>
      </div>
      <SuccessPopUp
        show={successModal}
        imageSuccess={images.imageSuccess}
        title={title}
        message={message}
        labelSuccessButton={labelSuccessButton || 'Ok, Back to List'}
        labelBackButton={''}
        handleSuccessButton={handleSuccessButton}
      />
      <ConfirmationModal
        showModal={showConfirmationModal}
        setShowModal={setShowConfirmationModal}
        handleSubmit={handleReplaceAssignee}
        title={'Replace Assignee'}
        message={'Are you sure you want to replace assignee?'}
        labelSubmitButton={'Yes, Replace'}
      />
    </div>
  );
};

export default AssigneeList;
