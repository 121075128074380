import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  limits: [25, 50, 75, 100],
  page: 1,
  pageSize: 25,
  search: '',
  sortBy: '',
  order_by: 'desc'
};

const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setOrderBy: (state, action) => {
      state.order_by = action.payload;
    },
    reset: state => {
      state.page = 1;
      state.pageSize = 25;
      state.search = '';
      state.sortBy = '';
      state.order_by = 'desc';
    }
  }
});

export const { setPage, setPageSize, setSearch, setSortBy, reset, setOrderBy } = tableSlice.actions;
export default tableSlice.reducer;
