import { images } from '../../constants';

const EmptyData = props => {
  const { title, message, contact, label } = props;
  return (
    <div className="flex flex-col flex-grow items-center justify-center">
      <img src={images.imageEmpty} className="w-[180px] h-[180px] mb-4" alt="Empty Data" />
      <div className="font-semibold text-[18px] text-[#232323] mb-2">{title}</div>
      <div className="font-normal text-center text-[12px] text-[#AFAFB9] mb-10">
        {message} <span className="text-[#463191]">{contact}</span>
      </div>
      {label && (
        <button className="py-[10px] w-[140px] bg-[#6546C3] shadow-lg shadow-[#46319180] text-white text-[14px] font-semibold rounded-lg">
          {label}
        </button>
      )}
    </div>
  );
};
export default EmptyData;
