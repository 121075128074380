import { useDispatch, useSelector } from 'react-redux';
import { put } from '../../../../api/base';
import { apiUrls, icons } from '../../../../constants';
import { useRef } from 'react';
import { setShowLoading } from '../../../../store/slices/popup';
import { toast } from 'react-toastify';
import { Toast } from '../../../../components';
import { setUserData } from '../../../../store/slices/user';

const ChangePictureModal = ({ showModal, setShowModal, setCamera }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const user = useSelector(state => state.user.userData);

  const updateUser = picture => {
    dispatch(setShowLoading(true));
    put(`${apiUrls.UPDATE_USER}/${user.id}`, {
      picture: picture
    })
      .then(response => {
        dispatch(setShowLoading(false));
        if (response.status === 200) {
          toast.success(<Toast message={`Success`} detailedMessage={`Update photo successful`} />);
          dispatch(setUserData({ ...user, picture }));
          setShowModal(false);
        } else {
          toast.error(
            <Toast message={`Error`} detailedMessage={`${response?.data?.error?.message}`} />
          );
        }
      })
      .catch(e => {
        dispatch(setShowLoading(false));
        toast.error(<Toast message={`Error`} detailedMessage={e} />);
      });
  };

  const handleChangePicture = async ({ target: { files } }) => {
    const maxSize = 5 * 1048576;
    if (files[0].size > maxSize) {
      toast.error(
        <Toast
          message={'Error'}
          detailedMessage={`Cannot update profile picture because file size is more than 5 mb`}
        />
      );
      return;
    }
    let payload = await toBase64(files[0]);
    updateUser(payload);
    fileInputRef.current.value = null;
  };

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        className="absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer"
        multiple={false}
        style={{ display: 'none' }}
        accept={'image/*'}
        onChange={handleChangePicture}
      />
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px] h-[35vh] sm:h-fit">
          <div className="flex flex-row items-center justify-between">
            <div className="text-[18px] font-semibold">Photo Profile</div>
            <img
              src={icons.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              alt={'close-modal'}
              onClick={() => setShowModal(false)}
            />
          </div>
          <hr className="mt-2 mb-4" />
          <div className="flex flex-row justify-center gap-16 ">
            <div
              onClick={() => setCamera(true)}
              className="flex flex-col justify-center align-center gap-2"
            >
              <img src={icons.icCamera} alt="form" className="w-[80px] h-[80px] self-center" />
              <div className="text-[12px] text-[#232323] text-center w-full">Camera</div>
            </div>
            <div
              onClick={() => fileInputRef.current.click()}
              className="flex flex-col justify-center align-center gap-2"
            >
              <img src={icons.icGallery} alt="form" className="w-[80px] h-[80px] self-center" />
              <div className="text-[12px] text-[#232323] text-center w-full">Gallery</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePictureModal;
