const LoadingComponent = () => {
  return (
    <div className="bg-white w-screen h-screen flex flex-col gap-2 items-center justify-center">
      <p className="text-[20px] font-semibold mb-6">Authentication in progress</p>
      <span className="loading loading-dots loading-md lg:loading-lg text-[#6546C3]"></span>
    </div>
  );
};

export default LoadingComponent;
