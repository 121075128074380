import { useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { apiUrls, draftLogic, icons } from '../../../../constants';
import Select from 'react-select';
import FieldAttachment from '../../Form/DetailForm/components/FieldAttachment';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { SuccessPopUp, Toast } from '../../../../components';
import { setShowLoading } from '../../../../store/slices/popup';
import { del, get, post, put } from '../../../../api/base';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useNavigate, useParams } from 'react-router';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import { cacheHelper, offlineHelper } from '../../../../helpers';
import { v4 as uuidv4 } from 'uuid';

const CreateTaskPage = () => {
  const uuid = uuidv4();
  const [title, setTitle] = useState('');
  const [plannedStart, setPlannedStart] = useState(new Date());
  const [dueDate, setDuedate] = useState(new Date());
  const [visibility, setVisibility] = useState('');
  const [visibilityDescription, setVisibilityDescription] = useState('');
  const [visibilityOptions, setVisibilityOptions] = useState([]);
  const [description, setDescription] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [successModal, setSuccessModal] = useState(false);
  const user = useSelector(state => state.user?.userData);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    // hide some icon from editor
    document.querySelector('[title="Monospace"]').style.display = 'none';
    document.querySelector('[title="Superscript"]').style.display = 'none';
    document.querySelector('[title="Subscript"]').style.display = 'none';
    document.querySelector('[title="Indent"]').style.display = 'none';
    document.querySelector('[title="Outdent"]').style.display = 'none';
  }, []);

  useEffect(() => {
    if (id) fetchDetailTask();
  }, [id]);

  const fetchDetailTask = () => {
    dispatch(setShowLoading(true));
    if (navigator.onLine) {
      get(`${apiUrls.TASK}/${id}`)
        .then(response => {
          const { status, data } = response;
          dispatch(setShowLoading(false));
          if (status === 200) {
            setTitle(data.title);
            setPlannedStart(new Date(data.planned_start));
            setDuedate(new Date(data.due_date));
            setVisibility({ name: data.visibility });
            setAttachments(data.attachments);
            const contentState = convertFromHTML(draftLogic.DRAFT_TO_HTML)(data.description);
            setEditorState(EditorState.createWithContent(contentState));
          } else {
            toast.error(
              <Toast message={'Error'} detailedMessage={`${response?.data.error.message}`} />
            );
          }
        })
        .catch(_ => {
          dispatch(setShowLoading(false));
        });
    } else {
      cacheHelper
        .getCachedData(apiUrls.TASK)
        .then(response => {
          const cacheTask = response.data?.filter(form => form.id.toString() === id.toString());
          setTitle(cacheTask[0].title);
          setPlannedStart(new Date(cacheTask[0].planned_start));
          setDuedate(new Date(cacheTask[0].due_date));
          setVisibility({ name: cacheTask[0].visibility });
          setAttachments(cacheTask[0].attachments);
          const contentState = convertFromHTML(draftLogic.DRAFT_TO_HTML)(cacheTask[0].description);
          setEditorState(EditorState.createWithContent(contentState));
        })
        .finally(() => {
          dispatch(setShowLoading(false));
        });
    }
  };

  async function handleAddAttachment({ target: { files } }) {
    if (!files.length) return;

    const fileSizeUser = user?.file_size * 1048576;

    const formData = new FormData();
    for (let index = 0; index < files.length; index++) {
      formData.append('files[]', files[index]);
    }
    let statusFile;
    if (attachments?.length <= 5) {
      if (files.length <= 5) {
        for (let i = 0; i < files.length; i++) {
          if (files[i].size > fileSizeUser) {
            statusFile = false;
            toast.error(
              <Toast
                message={'Error'}
                detailedMessage={`Cannot upload ${files[i].name} because the size of file is more than ${fileSizeUser} mb`}
              />
            );
          } else {
            statusFile = true;
            formData.append('files[]', {
              uri: files[i].fileCopyUri,
              type: files[i].type,
              name: files[i].name
            });
          }
        }
        if (statusFile || formData._parts.length) {
          dispatch(setShowLoading(true));
          if (navigator.onLine) {
            post('/api/dynamic-forms/attachments', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(response => {
              if (response.status === 200) {
                let rawAttachment = attachments.concat(response.data);
                setAttachments(rawAttachment);
              } else if (response.status === 0) {
                toast.error(
                  <Toast
                    message={'Error'}
                    detailedMessage={'Cannot Reach server, please try again later'}
                  />
                );
              } else {
                toast.error(
                  <Toast message={'Error'} detailedMessage={`${response.data.error.message}`} />
                );
              }
              dispatch(setShowLoading(false));
            });
          } else {
            const rawAttachment = await Promise.all(
              formData
                .getAll('files[]')
                .filter(data => data instanceof File)
                .map(async file => {
                  return await offlineHelper.saveAttachmentOffline(file);
                })
            );
            setAttachments(attachments.concat(rawAttachment));
            dispatch(setShowLoading(false));
          }
        }
      } else {
        toast.error(
          <Toast message={'Error'} detailedMessage={'Maximum of uploaded file is 5 files'} />
        );
      }
    } else {
      toast.error(
        <Toast
          message={'Error'}
          detailedMessage={'You have reached the maximum number of file uploads, maximum 5'}
        />
      );
    }
    inputRef.current.value = null;
  }

  const deleteAttachment = id => {
    del(`/api/dynamic-forms/attachments/${id}`).then(response => {
      dispatch(setShowLoading(false));
      if (response.status === 200) {
        setAttachments(prevAttachments => prevAttachments.filter(attach => attach.id !== id));
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={`${response.data.error.message}`} />);
      }
    });
  };

  const handleDeleteAttachment = index => {
    dispatch(setShowLoading(true));
    if (navigator.onLine) {
      deleteAttachment(attachments[index].id);
    } else {
      cacheHelper.deleteCachedAttachment(attachments[index].cacheIdentifier);
      attachments.splice(index, 1);
      setAttachments([...attachments]);
      dispatch(setShowLoading(false));
    }
  };

  const getVisibility = () => {
    get(apiUrls.TASK_VISIBILITY)
      .then(res => {
        if (res.status === 200) {
          setVisibilityOptions(res.data);
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />);
        }
      })
      .catch(_ =>
        toast.error(<Toast message={'Error'} detailedMessage={'Something Went Wrong'} />)
      );
  };

  const getStringHtml = html => {
    let tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  const validationTask = isMessage => {
    if (!title) {
      return isMessage ? 'Title cannot be empty' : false;
    }
    if (!visibility) {
      return isMessage ? 'Visibility cannot be empty' : false;
    }
    if (!getStringHtml(description)) {
      return isMessage ? 'Description cannot be empty' : false;
    }
    return true;
  };

  const handleSubmit = ({ isDraft }) => {
    if (validationTask() || isDraft) {
      let payload = {
        title: title,
        description: description,
        planned_start: plannedStart,
        due_date: dueDate,
        visibility: visibility?.name,
        attachments: attachments,
        status: isDraft ? 'DRAFT' : 'OPEN_TASK'
      };
      if (isDraft) {
        dispatch(setShowLoading(true));
        if (id) {
          put(`${apiUrls.TASK}/${id}`, payload).then(res => {
            dispatch(setShowLoading(false));
            if (res.status === 201) {
              setSuccessModal(true);
            } else {
              if (res.data?.error?.errors[0][0].message) {
                toast.error(
                  <Toast
                    message={'Error'}
                    detailedMessage={res.data?.error?.errors[0][0].message}
                  />
                );
              } else {
                toast.error(<Toast message={'Error'} detailedMessage={res.data.error.message} />);
              }
            }
          });
        } else {
          if (navigator.onLine) {
            post(apiUrls.TASK, payload).then(res => {
              dispatch(setShowLoading(false));
              if (res.status === 201) {
                setSuccessModal(true);
              } else {
                if (res.data?.error?.errors[0][0].message) {
                  toast.error(
                    <Toast
                      message={'Error'}
                      detailedMessage={res.data?.error?.errors[0][0].message}
                    />
                  );
                } else {
                  toast.error(<Toast message={'Error'} detailedMessage={res.data.error.message} />);
                }
              }
            });
          } else {
            offlineHelper
              .saveRequestDataOffline({
                url: apiUrls.TASK,
                body: payload,
                cacheUrl: apiUrls.TASK,
                key: 'data',
                data: {
                  id: 'offline' + uuid,
                  ...payload,
                  created_by: user?.full_name,
                  created_at: new Date().toISOString()
                }
              })
              .then(_ => {
                dispatch(setShowLoading(false));
                setSuccessModal(true);
              })
              .catch(_ => {
                dispatch(setShowLoading(false));
                toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
              });
            dispatch(setShowLoading(false));
          }
        }
      } else {
        if (id) {
          navigate(`/Task/TaskSubmission/${id}/SendTask`, {
            state: {
              type: 'SubmitTask',
              payload
            }
          });
        } else {
          navigate('/Task/TaskSubmission/SendTask', {
            state: {
              type: 'SubmitTask',
              payload
            }
          });
        }
      }
    } else {
      toast.error(<Toast message={'Error'} detailedMessage={validationTask(true)} />);
    }
  };

  useEffect(() => {
    getVisibility();
  }, []);

  useEffect(() => {
    setDescription(convertToHTML(draftLogic.HTML_TO_DRAFT)(editorState.getCurrentContent()));
  }, [editorState]);

  useEffect(() => {
    // populate description when view task draft
    if (id && visibility && visibilityOptions.length) {
      setVisibilityDescription(
        visibilityOptions.find(opt => opt.name === visibility?.name)?.description
      );
    }
  }, [visibility, visibilityOptions]);

  return (
    <div className="flex flex-col bg-white rounded-2xl justify-between p-[16px] mb-[40px] mx-0 sm:mx-[16px] gap-4">
      <div className=" flex flex-col sm:flex-row gap-4 ">
        <div className="flex flex-1 flex-col gap-2">
          <div className="font-semibold text-[12px]">Title</div>
          <input
            className={
              'input-text lg:w-[32vw]  text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]'
            }
            onChange={event => setTitle(event.target.value)}
            value={title}
            placeholder="Enter title"
          />
        </div>
        <div className="flex flex-1 flex-col gap-2">
          <div className="font-semibold text-[12px]">Planned Start</div>
          <div className="flex relative">
            <ReactDatePicker
              selected={plannedStart}
              minDate={new Date()}
              onChange={date => {
                if (date > dueDate) setDuedate(date);
                setPlannedStart(date);
              }}
              dateFormat={'dd-MMM-yyyy'}
              className={
                'input-text lg:w-[32vw] text-[12px] pl-[40px] pr-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]'
              }
              wrapperClassName="date-picker"
              onKeyDown={e => {
                e.preventDefault();
              }}
              popperModifiers={[
                {
                  name: 'arrow',
                  options: {
                    padding: ({ popper, reference }) => ({
                      right: Math.min(popper.width, reference.width) - 50
                    })
                  }
                }
              ]}
            />

            <img
              src={icons.icCalendar}
              className="w-6 absolute left-[12px] top-[8px] pointer-events-none"
              alt="calendar"
            />
          </div>
        </div>
      </div>
      <div className=" flex flex-col sm:flex-row gap-4 ">
        <div className="flex flex-1 flex-col gap-2">
          <div className="font-semibold text-[12px]">Due Date</div>
          <div className="flex relative">
            <ReactDatePicker
              selected={dueDate}
              minDate={plannedStart}
              onChange={date => setDuedate(date)}
              dateFormat={'dd-MMM-yyyy'}
              className={
                'input-text lg:w-[32vw] text-[12px] pl-[40px] pr-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]'
              }
              wrapperClassName="date-picker"
              onKeyDown={e => {
                e.preventDefault();
              }}
              popperModifiers={[
                {
                  name: 'arrow',
                  options: {
                    padding: ({ popper, reference }) => ({
                      right: Math.min(popper.width, reference.width) - 50
                    })
                  }
                }
              ]}
            />

            <img
              src={icons.icCalendar}
              className="w-6 absolute left-[12px] top-[8px] pointer-events-none"
              alt="calendar"
            />
          </div>
        </div>
        <div className="flex flex-1 flex-col gap-2">
          <div className="font-semibold text-[12px]">Visibility</div>
          <Select
            getOptionLabel={option => option.name}
            getOptionValue={option => option.name}
            isSearchable={false}
            options={visibilityOptions}
            value={visibility}
            onChange={selectedOption => {
              setVisibility(selectedOption);
              setVisibilityDescription(selectedOption.description);
            }}
            className={
              'flex-1 text-[12px] border rounded-lg focus:outline-none focus:border-[#6546C3]'
            }
            placeholder={'Select Visibility'}
            styles={{
              control: provided => ({
                ...provided,
                borderColor: 'none',
                ':focus': { borderColor: '#6546C3' },
                ':active': { borderColor: '#6546C3' },
                ':hover': { borderColor: '#6546C3' },
                paddingLeft: 4,
                boxShadow: 'none'
              }),
              dropdownIndicator: base => ({
                ...base,
                color: '#6546C3',
                marginRight: '4px',
                ':hover': { color: '#6546C3' }
              }),
              indicatorSeparator: () => ({}),
              valueContainer: provided => ({
                ...provided
              }),
              multiValueLabel: provided => ({
                ...provided,
                color: '#fff',
                fontFamily: 'poppins'
              }),
              multiValueRemove: provided => ({
                ...provided,
                color: '#fff'
              }),
              multiValue: provided => ({
                ...provided,
                backgroundColor: '#6546C3',
                borderRadius: 8
              })
            }}
          />
          <div>
            {visibilityDescription && (
              <span className="p-1 rounded-lg w-auto font-poppins font-semibold bg-opacity-[0.16] text-[10px] mb-2 bg-[#C800A5] text-[#C800A5]">
                {visibilityDescription}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-1 flex-col gap-2">
        <div className="font-semibold text-[12px]">Description</div>
        <Editor
          toolbar={{
            options: ['inline', 'list', 'history']
          }}
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={setEditorState}
        />
      </div>
      <div className="flex flex-1 flex-col gap-2">
        <div className="font-semibold text-[12px]">Attachment</div>
        <FieldAttachment
          attachments={attachments}
          fileInputRef={inputRef}
          submitted={false}
          handleAddAttachment={handleAddAttachment}
          handleDeleteAttachment={handleDeleteAttachment}
        />
      </div>
      <div className="flex flex-row gap-6 mt-[60px] sm:mt-[160px]  justify-center sm:justify-between mx-[12px] sm:mx-0">
        <button
          className="bg-transparent border-2 border-[#6546C3] rounded-lg py-[10px] w-[150px] font-semibold text-[#6546C3] 
                  text-[14px] flex justify-center items-center"
          onClick={() => handleSubmit({ isDraft: true })}
        >
          Save Draft
        </button>
        <div className="flex flex-row gap-6">
          <button
            className="hidden sm:block bg-transparent border-2 border-[#6546C3] rounded-lg py-[10px] w-[150px] font-semibold text-[#6546C3] 
                    text-[14px] flex justify-center items-center"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
          <button
            className="bg-[#6546C3] border-2 border-[#6546C3] rounded-lg py-[10px] w-[150px] font-semibold text-white 
                    text-[14px] flex justify-center items-center"
            onClick={() => handleSubmit({ isDraft: false })}
          >
            Submit
          </button>
        </div>
      </div>
      <SuccessPopUp
        show={successModal}
        title={'Successfully Save Draft'}
        message={'Hooray! You have successfully save a task to draft'}
        labelSuccessButton={'Ok, Back to List'}
        handleSuccessButton={() => {
          setSuccessModal(false);
          navigate(-1);
        }}
        labelBackButton={''}
      />
    </div>
  );
};
export default CreateTaskPage;
