import { useEffect, useRef, useState, useCallback } from 'react';
import { apiUrls, icons } from '../../../../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Toast } from '../../../../../../../components';
import { setShowLoading } from '../../../../../../../store/slices/popup';
import { del, post } from '../../../../../../../api/base';
import _ from 'lodash';
import { useNavigate } from 'react-router';
import Webcam from 'react-webcam';
import { IoCameraReverseOutline } from 'react-icons/io5';
import ReactPlayer from 'react-player';
import { cacheHelper, offlineHelper } from '../../../../../../../helpers';
import { v4 as uuidv4 } from 'uuid';
import VideoThumbnail from 'react-video-thumbnail';
const VideoFromCameraModal = ({ showModal, setShowModal, id, setTask, setComments }) => {
  const [attachments, setAttachments] = useState([]);
  const [rawAttachment, setRawAttachments] = useState([]);
  const [displayVideo, setDisplayVideo] = useState({});
  const [remark, setRemark] = useState('');
  const navigate = useNavigate();
  const uuid = uuidv4();
  const user = useSelector(state => state.user.userData);
  const dispatch = useDispatch();
  const [captureMode, setCaptureMode] = useState(false);
  const [facingMode, setFacingMode] = useState('user');

  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);

  const videoConstraints = {
    width: 420,
    height: 420,
    facingMode: facingMode
  };

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks(prev => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: 'video/webm'
    });
    mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, setCapturing]);

  const switchCamera = useCallback(() => {
    setFacingMode(prevState => (prevState === 'user' ? 'environment' : 'user'));
  }, []);

  useEffect(() => {
    if (recordedChunks.length) handleAddAttachment(recordedChunks);
  }, [recordedChunks]);

  async function handleAddAttachment(videoFile) {
    const blob = new Blob(videoFile, {
      type: 'video/mp4'
    });

    const fileName = 'video.mp4';
    const fileType = 'video/mp4';
    const file = new File([blob], fileName, { type: fileType });

    const fileSizeUser = user?.file_size * 1048576;
    const formData = new FormData();
    formData.append('files[]', file, file.name);

    let statusFile;
    if (attachments?.length <= 5) {
      if (file.size > fileSizeUser) {
        statusFile = false;
        toast.error(
          <Toast
            message={'Error'}
            detailedMessage={`Cannot upload ${file.name} because the size of file is more than ${fileSizeUser} mb`}
          />
        );
      } else {
        statusFile = true;
      }

      if (statusFile || formData._parts.length) {
        dispatch(setShowLoading(true));
        if (navigator.onLine) {
          post(`${apiUrls.TASK}/attachments`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(response => {
            if (response.status === 200) {
              let rawAttachment = attachments.concat(response.data);
              setAttachments(rawAttachment);
              setDisplayVideo(rawAttachment[rawAttachment.length - 1]);
              setCaptureMode(false);
              setRecordedChunks([]);
            } else if (response.status === 0) {
              toast.error(
                <Toast
                  message={'Error'}
                  detailedMessage={'Cannot Reach server, please try again later'}
                />
              );
            } else {
              toast.error(
                <Toast message={'Error'} detailedMessage={`${response.data.error.message}`} />
              );
            }
            dispatch(setShowLoading(false));
          });
        } else {
          const tempAttachment = await Promise.all(
            formData
              .getAll('files[]')
              .filter(data => data instanceof File)
              .map(async file => {
                return await offlineHelper.saveAttachmentOffline(file);
              })
          );
          const newAttachments = [];
          setRawAttachments(rawAttachment.concat(tempAttachment));
          if (!_.isEmpty(tempAttachment) && _.has(tempAttachment[0], 'cacheIdentifier')) {
            for (const value of tempAttachment) {
              await cacheHelper.getCachedAttachment(value.cacheIdentifier).then(res => {
                newAttachments.push(res);
              });
            }
            setAttachments(attachments.concat(newAttachments));
            setDisplayVideo([attachments.length, ...newAttachments]);
          } else {
            setAttachments(attachments.concat(attachments));
          }
          setCaptureMode(false);
          setRecordedChunks([]);

          dispatch(setShowLoading(false));
        }
      }
    } else {
      toast.error(
        <Toast
          message={'Error'}
          detailedMessage={'You have reached the maximum number of file uploads, maximum 5'}
        />
      );
    }
    webcamRef.current.value = null;
  }

  const deleteAttachment = id => {
    dispatch(setShowLoading(true));
    del(`${apiUrls.TASK}/attachments/${id}`).then(response => {
      dispatch(setShowLoading(false));
      if (response.status === 200) {
        setAttachments(prevAttachments => prevAttachments.filter(attach => attach.id !== id));
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={`${response.data.error.message}`} />);
      }
    });
  };

  const handleCloseModal = () => {
    setCaptureMode(false);
    setAttachments([]);
    setDisplayVideo({});
    setShowModal(false);
  };

  const handleDeleteAttachment = id => {
    dispatch(setShowLoading(true));
    if (navigator.onLine) {
      let rawAttachment = attachments.filter(attachment => attachment.id !== id);
      if (_.isArray(attachments)) {
        deleteAttachment(id);
      } else {
        attachments._parts?.filter(attachment => attachment.id !== id);
        setAttachments({ ...attachments });
        dispatch(setShowLoading(false));
      }
      if (rawAttachment.length) {
        setDisplayVideo(rawAttachment[rawAttachment.length - 1]);
      } else {
        handleCloseModal();
      }
    } else {
      cacheHelper.deleteCachedAttachment(rawAttachment[id].cacheIdentifier);
      rawAttachment.splice(id, 1);
      attachments.splice(id, 1);
      setAttachments([...attachments]);
      dispatch(setShowLoading(false));
      if (attachments.length) {
        setDisplayVideo([attachments.length - 1, attachments[attachments.length - 1]]);
      } else {
        handleCloseModal();
      }
    }
  };

  const handleSendButton = () => {
    let payload = {
      remark: {
        message: remark
      },
      attachments: navigator.onLine ? attachments : rawAttachment,
      description: 'Added Videos'
    };
    dispatch(setShowLoading(true));
    if (navigator.onLine) {
      post(`${apiUrls.TASK}/${id}/comments`, payload)
        .then(response => {
          dispatch(setShowLoading(false));
          if (response.status === 201) {
            handleCloseModal();
            navigate(0);
          } else {
            toast.error(<Toast message={'Error'} detailedMessage={response.data.error.message} />);
          }
        })
        .catch(e => {
          dispatch(setShowLoading(false));
          toast.error(<Toast message={'Error'} detailedMessage={e} />);
        });
    } else {
      dispatch(setShowLoading(false));
      let tempTask;
      let tempComment = {
        id: 'offline' + uuid,
        remark: {
          message: [{ value: remark, type: 'TEXT' }]
        },
        description: 'Added Videos',
        attachments: rawAttachment,
        recording: null,
        created_at: new Date().toISOString(),
        sender: user?.full_name
      };
      cacheHelper
        .getCachedData(apiUrls.TASK)
        .then(response => {
          let index = response.data?.findIndex(task => task.id.toString() === id.toString());
          tempTask = response.data;
          tempTask[index].comments.unshift(tempComment);
          offlineHelper
            .updateRequestDataOffline({
              url: `${apiUrls.TASK}/${id}/comments`,
              body: payload,
              cacheUrl: apiUrls.TASK,
              key: 'data',
              data: [...tempTask]
            })
            .then(_ => {
              setTask(tempTask[index]);
              setComments(tempTask[index].comments.sort((a, b) => b.id - a.id));
              handleCloseModal();
            })
            .catch(_ => {
              dispatch(setShowLoading(false));
              toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
            });
        })
        .finally(() => {
          dispatch(setShowLoading(false));
        });
    }
  };

  useEffect(() => {
    if (showModal) setCaptureMode(true);
  }, [showModal]);

  return (
    <div>
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-middle bg-[#232323]/[0.5]">
        <div className="flex flex-row justify-between items-center bg-[#6546C3] w-full top-0 fixed px-4 sm:px-16 py-4">
          <div className="flex flex-row gap-4 items-center" onClick={handleCloseModal}>
            <img
              src={icons.icCloseModal}
              alt="close"
              className="w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] cursor-pointer"
            />
            <div className="font-semibold text-[12px] text-white">Close</div>
          </div>
          {!_.isEmpty(displayVideo) && !captureMode ? (
            <button
              className="bg-[#EBEDF8] w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] rounded-xl flex items-center justify-center"
              onClick={e => {
                e.stopPropagation();
                handleDeleteAttachment(navigator.onLine ? displayVideo?.id : displayVideo[0]);
              }}
            >
              <img
                src={icons.icTrash}
                className="w-[20px] h-[20px] sm:w-[30px] sm:h-[30px]"
                alt="clear"
              />
            </button>
          ) : captureMode ? (
            <div className="flex items-center gap-4">
              <IoCameraReverseOutline
                className="w-full h-[30px] sm:h-[40px]"
                color="white"
                onClick={switchCamera}
              />
              <div
                className="font-semibold text-[12px] text-white"
                onClick={() => {
                  setCaptureMode(false);
                  setCapturing(false);
                }}
              >
                Cancel
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="flex flex-col items-center gap-6 w-full relative">
          {!captureMode ? (
            <>
              {!_.isEmpty(displayVideo) ? (
                <div className="modal-box flex flex-col bg-transparent sm:min-w-[400px] sm:w-fit p-0">
                  <ReactPlayer
                    url={displayVideo?.url || window.URL.createObjectURL(displayVideo[1])}
                    width={'100%'}
                    controls
                  />
                </div>
              ) : (
                <div className="modal-box flex flex-col bg-white sm:min-w-[400px] sm:w-fit h-[350px] sm:h-[400px] p-0 items-center justify-center">
                  No Video To Display
                </div>
              )}
              <div className="flex flex-row items-center justify-center gap-2 w-full mx-[18px] sm:mx-0">
                <div className="flex bg-white w-full h-[40px] border-[1px] items-center p-[12px] rounded-lg  rounded-lg">
                  <img
                    src={icons.icGallery}
                    className="w-[24px] h-[24px] cursor-pointer"
                    alt="Attachment"
                    onClick={() => setCaptureMode(true)}
                  />
                  <input
                    className="ml-2 focus:outline-none text-[12px] w-full"
                    value={remark}
                    onChange={event => setRemark(event.target.value)}
                    placeholder="Add remark..."
                  />
                </div>
                <div
                  className="bg-[#6546C3] p-[6px] rounded-lg cursor-pointer"
                  onClick={handleSendButton}
                >
                  <img src={icons.icSend} className="w-[24px] h-[24px] " alt="send" />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="modal-box flex flex-col bg-white sm:min-w-[400px] sm:w-fit h-[400px] p-0 items-center justify-center">
                <Webcam
                  audio={false}
                  mirrored={facingMode === 'user'}
                  height={400}
                  width={400}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                />
              </div>
              <div className="w-[68px] h-[68px] absolute bottom-[-30px] rounded-full bg-[#232323]/[0.5] flex items-center justify-center cursor-pointer">
                {capturing ? (
                  <img
                    src={icons.icCaptureVideoStop}
                    className="w-[66px] h-[66px]"
                    onClick={handleStopCaptureClick}
                    alt="capture stop"
                  />
                ) : (
                  <img
                    src={icons.icCaptureVideo}
                    className="w-[66px] h-[66px]"
                    onClick={handleStartCaptureClick}
                    alt="capture start"
                  />
                )}
              </div>
            </>
          )}
        </div>

        <div className="flex flex-row items-center bg-white w-full bottom-0 fixed px-4 sm:px-16 py-4 rounded-t-3xl gap-8">
          {attachments?.map((attachment, index) => {
            if (navigator.onLine) {
              return (
                <video
                  preload="metadata"
                  className="w-[64px] h-[66px] rounded-lg"
                  onClick={() => setDisplayVideo(attachment)}
                  key={index}
                  src={attachment?.url}
                />
              );
            } else {
              return (
                <div
                  className="w-[64px] h-[66px] rounded-lg"
                  onClick={() => setDisplayVideo([index, attachment])}
                >
                  <VideoThumbnail
                    videoUrl={window.URL.createObjectURL(displayVideo[1])}
                    thumbnailHandler={thumbnail => (
                      <img
                        key={index}
                        src={thumbnail}
                        alt={attachment?.name}
                        className="w-[64px] h-[66px] rounded-lg"
                      />
                    )}
                    renderThumbnail
                  />
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default VideoFromCameraModal;
