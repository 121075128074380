import React, { useState } from 'react';
import ModalField from './components/ModalField';
import AttachmentModal from './components/AttachmentModal';
import MandatoryFieldModal from './components/MandatoryFieldModal';

const CustomTabletLayout = ({
  customTabletLayout,
  sections,
  submitFormTabletLayout,
  isHaveAttachment,
  attachments,
  fileInputRef,
  handleAddAttachment,
  handleDeleteAttachment,
  onChangeValueField,
  onChangeCheckBox,
  onChangeRadio,
  onAddAttachment,
  removeFile,
  onChangeValueSubSection,
  onChangeSubCheckBox,
  onChangeSubRadio,
  onAddSubAttachment,
  removeSubSectionFile,
  setNewAttachments
}) => {
  const [showFieldModal, setShowFieldModal] = useState(false);
  const [sectionIndex, setSectionIndex] = useState(-1);
  const [fieldIndex, setFieldIndex] = useState(-1);
  const [subSectionIndex, setSubSectionIndex] = useState(-1);
  const [field, setField] = useState(null);
  const [sectionId, setSectionId] = useState(null);
  const [additionalTitle, setAdditionalTitle] = useState(null);
  const [mandatoryFieldModal, setMandatoryFieldModal] = useState(true);
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [fieldValue, setFieldValue] = useState(null);
  const mandatoryFields = sections.map((section, sectionIndex) =>
    section.fields.map((field, fieldIndex) => {
      return (
        field?.mandatory && {
          fieldIndex: fieldIndex,
          field: field,
          sectionIndex: sectionIndex
        }
      );
    })
  )[0];
  function findField(fieldId) {
    let sectionIndex = -1;
    let fieldIndex = -1;
    let subSectionIndex = -1;
    let currentField = null;

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];

      for (let j = 0; j < section.fields.length; j++) {
        const field = section.fields[j];

        if (field.field_id === fieldId) {
          setSectionIndex(i);
          setFieldIndex(j);
          setField(field);
          setSectionId(section.section_id);
          setSubSectionIndex(-1);
          sectionIndex = i;
          fieldIndex = j;
          currentField = field;
          return { field: currentField, sectionIndex, fieldIndex, subSectionIndex };
        }
      }

      for (let k = 0; k < section.sub_sections.length; k++) {
        const subSection = section.sub_sections[k];

        for (let l = 0; l < subSection.fields.length; l++) {
          const field = subSection.fields[l];

          if (field.field_id === fieldId) {
            setSectionIndex(i);
            setFieldIndex(l);
            setField(field);
            setSectionId(section.section_id);
            setSubSectionIndex(k);
            sectionIndex = i;
            subSectionIndex = k;
            fieldIndex = l;
            currentField = field;
            return { field: currentField, sectionIndex, fieldIndex, subSectionIndex };
          }
        }
      }
    }

    return { field: currentField, sectionIndex, fieldIndex, subSectionIndex };
  }

  const handleClickField = item => {
    const { field, sectionIndex, fieldIndex, subSectionIndex } = findField(item.fieldId);
    let additionalIndex;

    if (item?.additionalOption) {
      setAdditionalTitle(item?.additionalOption);
      additionalIndex = field?.additional_option.findIndex(e => e.title === item?.additionalOption);
    } else {
      setAdditionalTitle(null);
    }

    if (field?.field_type?.value === 'CHECKBOX' && field?.options?.length === 2) {
      const selectedOptionLabel = field?.options[0].label;

      subSectionIndex !== -1
        ? onChangeSubCheckBox(
            { target: { checked: true } },
            selectedOptionLabel,
            additionalIndex,
            fieldIndex,
            subSectionIndex,
            sectionIndex
          )
        : onChangeCheckBox(
            { target: { checked: true } },
            selectedOptionLabel,
            additionalIndex,
            fieldIndex,
            null,
            sectionIndex
          );

      if (isHaveAttachment) {
        setShowAttachmentModal(true);
        setFieldValue(selectedOptionLabel);
      } else {
        if (!field.mandatory) setFieldValue(null);
        submitFormTabletLayout({
          sections,
          title: item?.additionalOption ? item.additionalOption : field?.field_title,
          value: selectedOptionLabel
        });
      }
    } else if (field?.field_type?.value === 'RADIO_BUTTON' && field?.options?.length === 2) {
      const selectedOptionLabel = field?.options[0].label;

      subSectionIndex !== -1
        ? onChangeSubRadio(
            { target: { checked: true } },
            selectedOptionLabel,
            additionalIndex,
            fieldIndex,
            subSectionIndex,
            sectionIndex
          )
        : onChangeRadio(
            { target: { checked: true } },
            selectedOptionLabel,
            additionalIndex,
            fieldIndex,
            null,
            sectionIndex
          );

      if (isHaveAttachment) {
        setShowAttachmentModal(true);
        setFieldValue(selectedOptionLabel);
      } else {
        if (!field.mandatory) setFieldValue(null);
        submitFormTabletLayout({
          sections,
          title: item?.additionalOption ? item.additionalOption : field?.field_title,
          value: selectedOptionLabel
        });
      }
    } else {
      setShowFieldModal(true);
    }
  };

  return (
    <>
      <div
        className="grid w-full"
        style={{
          gridTemplateColumns: `repeat(${customTabletLayout?.size?.col}, minmax(0, 1fr))`,
          gridTemplateRows: `repeat(${customTabletLayout?.size?.row}, minmax(0, 1fr))`
        }}
      >
        {customTabletLayout?.items.map(item => (
          <div
            key={item.minIndex}
            className={`min-h-[48px] cursor-pointer flex items-center p-2`}
            style={{
              ...(item?.position?.row[0] && {
                gridRowStart: item?.position?.row[0]
              }),
              ...(item?.position?.row[1] && {
                gridRowEnd: item?.position?.row[1] + 1
              }),
              ...(item?.position?.col[0] && {
                gridColumnStart: item?.position?.col[0]
              }),
              ...(item?.position?.col[1] && {
                gridColumnEnd: item?.position?.col[1] + 1
              })
            }}
          >
            {item?.text ? (
              <div className="text-[14px] font-semibold">{item?.text}</div>
            ) : item?.fieldTitle ? (
              <button
                className="bg-[#6546C3] rounded-lg py-[10px] px-4 font-semibold text-white 
          text-[14px] flex justify-center items-center w-full h-full"
                onClick={() => handleClickField(item)}
              >
                <div className="truncate">
                  {item?.additionalOption ? item.additionalOption : item.fieldTitle}
                </div>
              </button>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
      <MandatoryFieldModal
        showModal={mandatoryFields.length && mandatoryFieldModal}
        setShowModal={setMandatoryFieldModal}
        sections={mandatoryFields}
        subSectionIndex={subSectionIndex}
        onChangeValueField={onChangeValueField}
        onChangeCheckBox={onChangeCheckBox}
        onChangeRadio={onChangeRadio}
        onChangeValueSubSection={onChangeValueSubSection}
        onChangeSubCheckBox={onChangeSubCheckBox}
        onChangeSubRadio={onChangeSubRadio}
        onAddSubAttachment={onAddSubAttachment}
        removeSubSectionFile={removeSubSectionFile}
        onAddAttachment={onAddAttachment}
        removeFile={removeFile}
      />
      <ModalField
        setNewAttachments={setNewAttachments}
        additionalTitle={additionalTitle}
        showModal={showFieldModal}
        setShowModal={setShowFieldModal}
        submitFormTabletLayout={submitFormTabletLayout}
        sections={sections}
        sectionIndex={sectionIndex}
        sectionId={sectionId}
        fieldIndex={fieldIndex}
        subSectionIndex={subSectionIndex}
        field={field}
        isHaveAttachment={isHaveAttachment}
        attachments={attachments}
        fileInputRef={fileInputRef}
        handleAddAttachment={handleAddAttachment}
        handleDeleteAttachment={handleDeleteAttachment}
        onChangeValueField={onChangeValueField}
        onChangeCheckBox={onChangeCheckBox}
        onChangeRadio={onChangeRadio}
        onAddAttachment={onAddAttachment}
        removeFile={removeFile}
        onChangeValueSubSection={onChangeValueSubSection}
        onChangeSubCheckBox={onChangeSubCheckBox}
        onChangeSubRadio={onChangeSubRadio}
        onAddSubAttachment={onAddSubAttachment}
        removeSubSectionFile={removeSubSectionFile}
        setShowAttachmentModal={setShowAttachmentModal}
      />
      <AttachmentModal
        setFieldModal={setShowFieldModal}
        showModal={showAttachmentModal}
        setNewAttachments={setNewAttachments}
        setShowModal={setShowAttachmentModal}
        sections={sections}
        submitFormTabletLayout={submitFormTabletLayout}
        field={field}
        attachments={attachments}
        fileInputRef={fileInputRef}
        handleAddAttachment={handleAddAttachment}
        handleDeleteAttachment={handleDeleteAttachment}
        additionalTitle={additionalTitle}
        fieldValue={fieldValue}
      />
    </>
  );
};

export default CustomTabletLayout;
