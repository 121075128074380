const LOGIN_URL = '/api/auth/login';
const REFRESH_TOKEN_URL = '/api/auth/refresh-token';
const LOGOUT_URL = '/api/auth/logout';
const APPLICATION_PROFILE = '/api/applications';
const MODULE = '/api/modules';
const ORGANIZATION_MASTER_URL = '/api/users/organization-master';
const BASE_URL_USERS = '/api/users';
const USER_PROFILE_URL = `${BASE_URL_USERS}/me`;
const DYNAMIC_FORM = '/api/dynamic-forms';
const TASK = '/api/tasks';
const TASK_VISIBILITY = '/api/tasks/visibility';
const TASK_USERS = '/api/tasks/users';
const TASK_WORK_LOCATION = '/api/tasks/work-locations';
const TASK_GROUPS = '/api/tasks/groups';
const TASK_TAGS = '/api/tasks/tags';
const NOTIFICATION = '/api/users/notification';
const ISSUE = '/api/issues';
const ISSUE_USERS = '/api/issues/users';
const ISSUE_WORK_LOCATION = '/api/issues/work-locations';
const ISSUE_GROUPS = '/api/issues/groups';
const ISSUE_VISIBILITY = '/api/issues/visibility';
const ISSUE_TYPES = '/api/issues/issue-type';
const ISSUE_TAGS = '/api/issues/tags';
const POINT_HISTORY = '/api/points/history';
const REDEEM_POINT = '/api/points/redeem';
const LIST_NOTIFICATION = '/api/notifications';
const MY_ACTIVITY = '/api/my-activities';
const UPDATE_USER = '/api/users/manage-user';
const LOGIN_AZURE_URL = '/api/auth/login/azure';
const TIMEZONE_LIST = '/api/timezones';
const AREA = '/api/areas';
const USER_LOG = '/api/logs';
const MASTER_DATA_FEEDBACK_FORM = '/api/master-data/form-feedback';
const ADD_GUEST_USER = '/api/guest-users';
const MASTER_DATA = '/api/master-data';
export default {
  LOGIN_URL,
  REFRESH_TOKEN_URL,
  LOGOUT_URL,
  APPLICATION_PROFILE,
  MODULE,
  ORGANIZATION_MASTER_URL,
  USER_PROFILE_URL,
  BASE_URL_USERS,
  DYNAMIC_FORM,
  TASK_VISIBILITY,
  TASK_USERS,
  TASK_WORK_LOCATION,
  TASK_GROUPS,
  TASK,
  TASK_TAGS,
  NOTIFICATION,
  ISSUE,
  ISSUE_USERS,
  ISSUE_GROUPS,
  ISSUE_WORK_LOCATION,
  ISSUE_VISIBILITY,
  ISSUE_TYPES,
  ISSUE_TAGS,
  POINT_HISTORY,
  REDEEM_POINT,
  LIST_NOTIFICATION,
  MY_ACTIVITY,
  UPDATE_USER,
  LOGIN_AZURE_URL,
  TIMEZONE_LIST,
  AREA,
  USER_LOG,
  MASTER_DATA_FEEDBACK_FORM,
  ADD_GUEST_USER,
  MASTER_DATA
};
