import { useEffect, useState } from 'react';
import { icons } from '../../../constants';
import Loading from '../../Loading';
import EmptyData from '../../EmptyData';

const UserAssigneeList = ({
  users,
  getUsers,
  selectedUsers,
  setSelectedUsers,
  isFetching,
  fullSelectedUsers,
  setFullSelectedUsers
}) => {
  const [search, setSearch] = useState('');

  const handleOnUserPress = item => {
    const userId = item.id;
    let newSelectedUsers = [];
    let newFullUsers = [];
    if (selectedUsers.includes(userId)) {
      newSelectedUsers = selectedUsers.filter(oldSelectedId => oldSelectedId !== userId);
      newFullUsers = fullSelectedUsers.filter(user => user.id !== item.id);
    } else {
      newFullUsers = fullSelectedUsers.concat(users.filter(user => user.id === item.id));
      newSelectedUsers = [...selectedUsers, userId];
    }
    setFullSelectedUsers(newFullUsers);
    setSelectedUsers(newSelectedUsers);
  };

  const searchData = event => {
    switch (true) {
      case event.key === 'Enter':
      case event.target.value.length === 0:
        setSearch(event.target.value);
        break;
      default:
        break;
    }
  };

  const CardUsers = ({ user }) => {
    return (
      <div
        className={`drop-shadow-md flex items-center lg:w-[32vw] rounded-md p-[16px] on-pointer ${
          selectedUsers.includes(user.id) ? 'bg-[#EBEDF8]' : 'bg-white'
        }`}
        onClick={() => {
          handleOnUserPress(user);
        }}
      >
        <img
          src={user.picture ? user.picture : icons.icActiveUserOnline}
          alt="photoProfile"
          className=" bg-white rounded-full h-[36px] w-[36px] mr-[12px]"
        />
        <div className="flex flex-col px-[12px]">
          <div className="font-semibold text-[12px]">{user.full_name}</div>
          <div className="text-[#AFAFB9] text-[12px]">{user.department ? user.department : ''}</div>
        </div>
        {selectedUsers.includes(user.id) ? (
          <img src={icons.icPrimaryChecklist} className="h-[16px] ml-auto" alt="choose" />
        ) : (
          <div className="h-[14px]" />
        )}
      </div>
    );
  };

  useEffect(() => {
    getUsers(search);
  }, [search]);

  return (
    <div className="max-h-[80vh] mt-[20px] flex flex-col  justify-between p-[16px] mb-[40px] gap-4 max-h-[60vh]">
      <div className="flex flex-row items-center justify-between">
        <div className="font-semibold text-[16px]">Users List</div>
        <div className="flex h-[40px] bg-white border-[1px] items-center p-[12px] rounded-lg sm:w-[25vw] w-[45vw]">
          <img src={icons.icSearch} className="w-[14px] h-[14px]" alt="Search" />
          <input
            className="ml-2 focus:outline-none text-[12px] w-full"
            key={search}
            defaultValue={search}
            placeholder="Search here..."
            onKeyDown={searchData}
            onBlur={searchData}
          />
        </div>
      </div>
      <div>
        {isFetching ? (
          <div className="flex justify-center items-center h-[55vh] mx-auto">
            <Loading />
          </div>
        ) : users.length ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2  max-h-[55vh] overflow-auto">
            {users.map((user, index) => (
              <CardUsers user={user} key={index} />
            ))}
          </div>
        ) : (
          <EmptyData title={'Not Found'} />
        )}
      </div>
    </div>
  );
};
export default UserAssigneeList;
