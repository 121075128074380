import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { icons, images, apiUrls } from '../../constants';
import TimeZoneModal from './components/TimeZoneModal';
import { get } from '../../api/base';
import { Toast } from '../../components';
import { toast } from 'react-toastify';
import ChangePictureModal from './components/ChangePictureModal';
import ImageFromCamera from './components/ChangePictureModal/ImageFromCamera';
import { setUserData } from '../../store/slices/user';

const Profile = () => {
  const [tabName, setTabName] = useState('Profile Info');
  const user = useSelector(state => state.user.userData);
  const [showTimezoneModal, setShowModalTimezone] = useState(false);
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const [currentTimezone, setCurrentTimezone] = useState('');
  const [changePictureModal, setChangePictureModal] = useState(false);
  const [imageFromcamera, setImageFromcamera] = useState(false);
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();
  const tabResult = () => {
    switch (tabName) {
      case 'Profile Info':
        return (
          <>
            <div className="p-6">
              <div className="grid grid-rows-4 sm:grid-rows-2 grid-flow-col gap-x-8 gap-y-4">
                <div className="bg-white rounded-2xl w-full h-full p-4 shadow-md flex flex-row gap-4 items-center">
                  <img src={icons.icCompany} alt="company" className="w-9 h-9" />
                  <div className="flex flex-col gap-0.5">
                    <div className="text-[#AFAFB9] text-[12px] font-normal">Company</div>
                    <div className="text-[#232323] text-[12px] font-semibold">{user?.company}</div>
                  </div>
                </div>
                <div className="bg-white rounded-2xl w-full h-full p-4 shadow-md flex flex-row gap-4 items-center">
                  <img src={icons.icEmail} alt="email" className="w-9 h-9" />
                  <div className="flex flex-col gap-0.5">
                    <div className="text-[#AFAFB9] text-[12px] font-normal">Email</div>
                    <div className="text-[#232323] text-[12px] font-semibold">{user?.email}</div>
                  </div>
                </div>
                <div className="bg-white rounded-2xl w-full h-full p-4 shadow-md flex flex-row gap-4 items-center cursor-pointer">
                  <img src={icons.icLandingPage} alt="landing page" className="w-9 h-9" />
                  <div className="flex flex-col flex-grow gap-0.5">
                    <div className="text-[#232323] text-[12px] font-semibold">
                      Default Landing Page
                    </div>
                    <div className="text-[#AFAFB9] text-[12px] font-normal">
                      Current Setting:{' '}
                      <span className="text-[#6546C3] font-medium">{user?.landing_page}</span>
                    </div>
                  </div>
                  <img src={icons.icArrowRight} alt="timezone" className="w-6 h-6" />
                </div>
                <div
                  className="bg-white rounded-2xl w-full h-full p-4 shadow-md flex flex-row gap-4 items-center cursor-pointer"
                  onClick={() => setShowModalTimezone(true)}
                >
                  <img src={icons.icTimezone} alt="timezone" className="w-9 h-9" />
                  <div className="flex flex-col flex-grow gap-0.5">
                    <div className="text-[#AFAFB9] text-[12px] font-normal">Time Zone</div>
                    <div className="text-[#232323] text-[12px] font-semibold">
                      {user?.timezone?.name}
                    </div>
                  </div>
                  <img src={icons.icArrowRight} alt="timezone" className="w-6 h-6" />
                </div>
              </div>
            </div>
            <div className="w-full h-[8px] bg-[#EBEDF8]" />
            <div className="p-6 flex flex-col gap-6">
              <div className="text-[#232323] text-[16px] font-semibold">Connected Chat</div>
              <div className="grid grid-rows-2 sm:grid-rows-1 grid-flow-col gap-x-8 gap-y-4">
                <div className="bg-white rounded-2xl w-full h-full p-4 shadow-md flex flex-row gap-4 items-center">
                  <img src={icons.icTeamsBg} alt="company" className="w-9 h-9" />
                  <div className="text-[#232323] text-[12px] font-normal flex-1">
                    microsoft teams
                  </div>
                  <label
                    htmlFor={`microsoft-teams`}
                    className="flex relative items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      value=""
                      id={`microsoft-teams`}
                      className="sr-only peer"
                      defaultChecked={false}
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#6546C3] dark:peer-focus:ring-[#6546C3] rounded-full peer dark:bg-[#DEDEDE] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#DEDEDE] peer-checked:bg-[#6546C3]"></div>
                  </label>
                </div>
                <div className="bg-white rounded-2xl w-full h-full p-4 shadow-md flex flex-row gap-4 items-center">
                  <img src={icons.icWaBg} alt="email" className="w-9 h-9" />
                  <div className="text-[#232323] text-[12px] font-normal flex-1">WhatsApp</div>
                  <label htmlFor={`whatsapp`} className="flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      value=""
                      id={`whatsapp`}
                      className="sr-only peer"
                      defaultChecked={false}
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#6546C3] dark:peer-focus:ring-[#6546C3] rounded-full peer dark:bg-[#DEDEDE] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#DEDEDE] peer-checked:bg-[#6546C3]"></div>
                  </label>
                </div>
              </div>
            </div>
          </>
        );
      case 'QR Code':
        return (
          <div className="p-6">
            <div className="w-full h-full bg-[#EBEDF8] rounded-xl"></div>
          </div>
        );
      default:
        return;
    }
  };

  const fetchTimeZones = () => {
    get(apiUrls.TIMEZONE_LIST, { query: { search } }).then(response => {
      const { status, data } = response;
      if (status === 200) {
        setTimezoneOptions(data);
      } else if (status === 408) {
        toast.error(
          <Toast message={`Error`} detailedMessage={`${response?.data?.error?.message}`} />
        );
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch time zone list'} />);
      }
    });
  };

  const getProfile = () => {
    get(apiUrls.USER_PROFILE_URL).then(res => {
      if (res.status === 200) {
        dispatch(setUserData(res.data));
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error?.message}`} />);
      }
    });
  };

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    fetchTimeZones();
  }, [search]);

  useEffect(() => {
    if (timezoneOptions.length) {
      let timezone = timezoneOptions?.find(
        item => item.name === user?.timezone?.name && item.timezone === user?.timezone?.offset
      );
      if (timezone) setCurrentTimezone(timezone);
    }
  }, [timezoneOptions, user?.timezone]);

  return (
    <div className="mt-0 sm:m-6 min-h-[calc(100vh-150px)] flex flex-col relative">
      <div className="mt-[60px] pt-[142px] bg-white rounded-2xl flex flex-col flex-1">
        <div className="bg-[#F2F5FC] h-[68px] py-4 px-6">
          <div className="grid grid-flow-col rounded-[8px] grid-cols-2 auto-rows-col bg-white gap-2 justify-evenly mx-[24px] h-[36px]">
            <button
              className={`${
                tabName === 'Profile Info' ? 'border-b-4 border-[#C800A5]' : ''
              }  text-[14px]`}
              onClick={() => setTabName('Profile Info')}
            >
              <div
                className={`${
                  tabName === 'Profile Info' ? 'font-semibold text-[#C800A5]' : 'text-[#AFAFB9]'
                }`}
              >
                Profile Info
              </div>
            </button>
            <button
              className={`${
                tabName === 'QR Code' ? 'border-b-4 border-[#C800A5]' : 'text-[#AFAFB9]'
              }  text-[14px]`}
              onClick={() => setTabName('QR Code')}
            >
              <div className={`${tabName === 'QR Code' ? 'font-semibold text-[#C800A5]' : ''}`}>
                QR Code
              </div>
            </button>
          </div>
        </div>
        {tabResult()}
      </div>
      <div className="absolute bg-white rounded-2xl w-[calc(100%-48px)] h-[178px] mx-6 p-4 shadow-md flex flex-col gap-4">
        <div className="flex flex-row items-center gap-6">
          <div className="relative">
            <img
              src={user?.picture ? user.picture : images.imageDummyAvatar}
              alt={user?.full_name}
              className="w-20 h-20 rounded-2xl"
            />
            <img
              onClick={() => setChangePictureModal(true)}
              src={icons.icChangePicture}
              alt={user?.full_name}
              className="flex w-5 h-5 rounded-2xl absolute -bottom-1 -right-1 cursor-pointer"
            />
          </div>
          <div className="flex flex-1 flex-col sm:flex-row sm:items-center gap-2">
            <div className="flex flex-col gap-0.5 sm:gap-2 flex-grow">
              <div className="text-[#232323] text-[14px] font-semibold">{user?.full_name}</div>
              <div className="text-[#C800A5] text-[12px] font-normal">{user?.division}</div>
            </div>
            <div className="flex flex-row gap-1 border border-[#AAAAAA] rounded-full items-center px-2 py-1 w-fit h-fit">
              <img src={icons.icPoin} alt={user?.full_name} className="w-[18px] h-[18px]" />
              <div className="text-[#FFAF00] text-[12px] font-semibold">{user?.point}</div>
              <div className="text-[#AAAAAA] text-[12px] font-normal">points</div>
            </div>
          </div>
        </div>
        <div className="w-full h-full rounded-xl bg-[#F2F5FC] grid grid-flow-col grid-cols-3 justify-between">
          <div className="flex flex-col items-center justify-center">
            <div className="text-[#6546C3] text-[12px] font-semibold">5</div>
            <div className="text-[#AAAAAA] text-[12px] font-normal">Activity</div>
          </div>
          <div className="flex flex-col items-center justify-center border-x border-[#DEDEDE]">
            <div className="text-[#6546C3] text-[12px] font-semibold">20</div>
            <div className="text-[#AAAAAA] text-[12px] font-normal">Task</div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="text-[#6546C3] text-[12px] font-semibold">12</div>
            <div className="text-[#AAAAAA] text-[12px] font-normal">Issue</div>
          </div>
        </div>
      </div>
      <TimeZoneModal
        showModal={showTimezoneModal}
        setShowModal={setShowModalTimezone}
        timezoneOptions={timezoneOptions}
        currentTimezone={currentTimezone}
        search={search}
        setSearch={setSearch}
        userId={user?.id}
      />
      <ChangePictureModal
        showModal={changePictureModal}
        setShowModal={setChangePictureModal}
        setCamera={setImageFromcamera}
      />
      <ImageFromCamera showModal={imageFromcamera} setShowModal={setImageFromcamera} />
    </div>
  );
};

export default Profile;
