import { useDispatch } from 'react-redux';
import { apiUrls, icons, images, menus } from '../../../../constants';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setMoreModal } from '../../../../store/slices/form';
import { useEffect, useState } from 'react';
import { get, put } from '../../../../api/base';
import { Toast, Portal } from '../../../../components';
import { toast } from 'react-toastify';
import { usePopper } from 'react-popper';
import moment from 'moment';
import { FaChevronRight } from 'react-icons/fa6';

const Header = () => {
  const isBigScreen = useMediaQuery({ query: '(min-width: 1280px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 450px)' });
  const location = useLocation();
  const navigate = useNavigate();
  const { id, responseId, parentId } = useParams();
  const [notif, setNotif] = useState(false);
  const [notifOpen, setNotifOpen] = useState(false);
  const [notifList, setNotifList] = useState([]);
  const [totalUnread, setTotalUnread] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [actionPopperElement, setActionPopperElement] = useState(null);
  const [actionReferenceElement, setActionReferenceElement] = useState(null);
  const { styles, attributes } = usePopper(actionReferenceElement, actionPopperElement, {
    placement: 'bottom-end'
  });

  const dispatch = useDispatch();
  let name, crumbs;
  const { pathname } = location;
  let currentMenu = menus.find(obj => {
    let pathSegments = obj.path.toLowerCase().split('/');
    let stringSegments = pathname.toLowerCase().split('/');

    if (pathSegments.length !== stringSegments.length) {
      return false;
    }

    for (let i = 0; i < pathSegments.length; i++) {
      if (pathSegments[i] !== stringSegments[i] && !pathSegments[i].startsWith(':')) {
        return false;
      }
      if (
        pathSegments[i] !== stringSegments[i] &&
        pathSegments[i].startsWith(':') &&
        isNaN(parseInt(stringSegments[i]))
      ) {
        return false;
      }
    }

    return true;
  });

  if (location.state && location.state.name && location.state.crumbs) {
    name = location.state.name;
    crumbs = location.state.crumbs;
  } else {
    name = currentMenu?.name;
    crumbs = currentMenu?.crumbs;
  }

  const handleCrumbsClick = item => {
    let menuItem = menus.filter(menu => {
      let lastIndex = menu.crumbs?.length - 1;
      return menu.crumbs && menu.crumbs[lastIndex] === item;
    });

    if (menuItem.length === 1) {
      let path = menuItem[0].path.replace(':id', id);
      if (responseId) {
        path = path.replace(':responseId', responseId);
      }
      if (parentId) {
        path = path.replace(':parentId', parentId);
      }

      navigate(path, { state: menuItem[0] });
    } else {
      let currentMenuItem;
      if (responseId) {
        currentMenuItem = menuItem.find(menu => {
          return menu.path.includes(':responseId');
        });
      }
      if (parentId) {
        currentMenuItem = menuItem.find(menu => {
          return menu.path.includes(':parentId');
        });
      }
      if (!parentId && !responseId) {
        currentMenuItem = menuItem.find(menu => {
          return id ? menu.path.includes(':id') : !menu.path.includes(':id');
        });
      }

      let path = currentMenuItem.path.replace(':id', id);
      if (responseId) {
        path = path.replace(':responseId', responseId);
      }
      if (parentId) {
        path = path.replace(':parentId', parentId);
      }

      navigate(path, { state: currentMenuItem });
    }
  };

  useEffect(() => {
    if (
      !(
        pathname.includes('DetailForm') &&
        !pathname.includes('DetailFormSubmission') &&
        isSmallScreen
      )
    ) {
      checkNotification();
    }
  }, [pathname]);

  const checkNotification = () => {
    get(apiUrls.LIST_NOTIFICATION, { query: { limit: 1 } }).then(response => {
      if (response.status === 200) {
        if (response.data.total_unread_notification) {
          setNotif(true);
        } else {
          setNotif(false);
        }
      } else {
        setNotif(false);
      }
    });
  };

  const fetchNotifications = () => {
    setIsFetching(true);
    get(apiUrls.LIST_NOTIFICATION, { query: { limit: 5 } }).then(response => {
      if (response.status === 200) {
        setNotifList(response.data.data);
        setTotalUnread(response.data.total_unread_notification);
      } else {
        toast.error(
          <Toast message={'Error'} detailedMessage={`${response.data.error?.message}`} />
        );
      }
      setIsFetching(false);
    });
  };

  const getFormatDate = date => {
    if (!date) {
      return '-';
    }

    if (moment(date).format('DD MMM YYYY') === moment().format('DD MMM YYYY')) {
      return moment(date).format('[Today, ] h:mm a');
    }

    return moment(date).format('DD MMM YYYY');
  };

  const handleMarkAllAsRead = () => {
    if (!totalUnread) {
      toast.info(
        <Toast
          message={'Notification Clear'}
          detailedMessage={'All notifications have been read'}
        />
      );
    } else {
      setIsFetching(true);
      put(apiUrls.LIST_NOTIFICATION)
        .then(response => {
          setIsFetching(false);
          if (response.status === 200) {
            fetchNotifications();
            checkNotification();
          } else {
            toast.error(
              <Toast message={'Error'} detailedMessage={`${response.data?.error?.message}`} />
            );
          }
        })
        .catch(() => {
          setIsFetching(false);
          toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
        });
    }
  };

  const handleOnNotifItemClick = ({ notifId, id, className, url }) => {
    setIsFetching(true);
    put(apiUrls.LIST_NOTIFICATION, {}, { query: { id: notifId } })
      .then(response => {
        setIsFetching(false);
        if (response.status === 200) {
          fetchNotifications();
          setNotifOpen(false);
          checkNotification();
          switch (className) {
            case 'Task':
              navigate(`/Task/DetailTask/${id}`);
              break;
            case 'Issue':
              navigate(`/Task/DetailIssue/${id}`);
              break;
            case 'FORM':
              navigate(`/Task/FormSubmission/DetailFormSubmission/${id}`);
              break;
            case 'Form Submission':
              navigate(`/Task/DetailForm/${id}`);
              break;
            case 'Version':
              window.open(url);
              break;
            default:
              return;
          }
        } else {
          toast.error(
            <Toast message={'Error'} detailedMessage={`${response.data?.error?.message}`} />
          );
        }
      })
      .catch(e => {
        setIsFetching(false);
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
      });
  };

  return (
    <div
      className={`${
        location.pathname === '/Home' ? 'bg-white' : ''
      } min-h-[135px] flex flex-col w-screen lg:w-full`}
    >
      <div className="flex flex-row justify-between lg:justify-end absolute z-[2] w-full lg:w-[calc(100%-288px)]">
        <label htmlFor="my-drawer" className="drawer-button cursor-pointer lg:hidden">
          <img src={icons.icHamburger} alt="Menu" className="w-[48px] h-[48px]" />
        </label>
        {pathname.includes('DetailForm') &&
        !pathname.includes('DetailFormSubmission') &&
        isSmallScreen ? (
          <img
            src={icons.icMore}
            alt="more"
            className="w-[24px] h-[24px] m-2"
            onClick={() => dispatch(setMoreModal(true))}
          />
        ) : (
          <div className="dropdown dropdown-bottom dropdown-end">
            <img
              tabIndex={0}
              src={notif ? icons.icNotif : icons.icNoNotif}
              alt="Menu"
              className="w-[48px] h-[48px] cursor-pointer"
              onClick={() => {
                setNotifOpen(prev => !prev);
                fetchNotifications();
                document.activeElement.blur();
              }}
              ref={setActionReferenceElement}
            />
            {notifOpen && (
              <Portal>
                <div
                  tabIndex={0}
                  className="dropdown-content z-[1] menu shadow bg-white rounded-2xl w-[calc(100vw-24px)] sm:w-[400px] m-0 mx-3 p-0"
                  ref={setActionPopperElement}
                  style={styles.popper}
                  {...attributes.popper}
                >
                  {isFetching ? (
                    <div className="flex justify-center items-center py-[150px]">
                      <span className="loading loading-dots loading-sm lg:loading-md text-[#6546C3]"></span>
                    </div>
                  ) : notifList.length ? (
                    <div>
                      <div className="flex flex-row items-center justify-between p-4">
                        <div className="flex gap-2 items-center">
                          <div className="text-[16px] text-[#232323] font-semibold font-poppins">
                            Notification List
                          </div>
                          <div className="bg-[#EBEDF8] w-8 h-8 rounded-xl text-[#C800A5] font-semibold font-poppins flex items-center justify-center">
                            {totalUnread}
                          </div>
                        </div>
                        <div
                          className="flex items-center cursor-pointer"
                          onClick={handleMarkAllAsRead}
                        >
                          <img src={icons.icMarkAllRead} alt="mark as read" className="w-7" />
                          <div className="text-[12px] text-[#6546C3] font-poppins">
                            Mark as read
                          </div>
                        </div>
                      </div>
                      <div className="mb-[28px] flex flex-col gap-3">
                        {notifList.map(item => (
                          <div
                            key={item?.id}
                            className={`mr-4 flex gap-1 ${item?.read ? 'ml-4' : 'ml-1'}`}
                          >
                            {!item?.read && <div className="w-1 bg-[#6546C3] rounded"></div>}
                            <div
                              className={`p-4 flex flex-1 gap-4 rounded-xl ${
                                item?.read ? 'bg-white shadow-md' : 'bg-[#EBEDF8]'
                              } cursor-pointer`}
                              onClick={() =>
                                handleOnNotifItemClick({
                                  notifId: item.id,
                                  id: item.message.data.id,
                                  className: item.class_name,
                                  message: item.message,
                                  url: item.message.data.url
                                })
                              }
                            >
                              {!item?.sender ? (
                                <div className="w-8 h-8 rounded-full border border-[#DEDEDE] flex items-center justify-center">
                                  <img src={icons.icInfo} alt="info" className="w-4 h-4" />
                                </div>
                              ) : item?.sender?.picture ? (
                                <img
                                  src={item?.sender?.picture}
                                  alt="sender"
                                  className="w-8 h-8 rounded-full"
                                />
                              ) : (
                                <img
                                  source={images.imageDummyAvatar}
                                  alt="sender"
                                  className="w-8 h-8 rounded-full"
                                />
                              )}

                              <div className="flex-col flex-1 gap-1">
                                <div className="flex justify-between">
                                  <div className="text-[12px] text-[#232323] font-semibold font-poppins">
                                    {item?.sender?.full_name || 'M2P'}
                                  </div>
                                  <div className="text-[10px] text-[#232323] font-poppins">
                                    {getFormatDate(item?.created_at)}
                                  </div>
                                </div>
                                <div className="text-[12px] text-[#AFAFB9] font-poppins">
                                  {item?.message?.notification?.body}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div
                        className="h-[40px] bg-[#F9F9F9] text-[12px] text-[#6546C3] font-semibold rounded-b-2xl flex items-center 
                          justify-between px-6 cursor-pointer font-poppins"
                        onClick={() => navigate('/Notification')}
                      >
                        <div>See All</div>
                        <FaChevronRight color="#6546C3" size={16} />
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col items-center p-[50px]">
                      <img
                        src={images.imageEmptyNotif}
                        alt="no notif"
                        className="w-[180px] mb-[20px]"
                      />
                      <div className="text-[16px] text-[#232323] font-semibold mb-2">
                        No Notification Right Now!
                      </div>
                      <div className="text-[12px] text-[#AAAAAA]">
                        You’re up-to-date! would work well
                      </div>
                    </div>
                  )}
                </div>
              </Portal>
            )}
          </div>
        )}
      </div>

      {location.pathname === '/Home' ? (
        <div className="flex flex-1 flex-row justify-between">
          <img
            src={isBigScreen ? images.imageHeader : images.imageHeaderMobile}
            alt="Header"
            className={`${
              isSmallScreen ? 'max-w-[280px] min-h-[135px]' : 'h-[135px]'
            } absolute right-0`}
          />
          <div className="flex flex-col justify-center pl-6 absolute top-10">
            <div className="text-[#525151] text-xl sm:text-3xl font-bold mb-1">M2P</div>
            <div className="text-[#232323] text-[10px] sm:text-sm font-normal bg-white bg-opacity-[0.8]">
              ONE CLICK for any purpose from Mine to Port
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-1 flex-col items-center md:items-start justify-center p-6 z-[1]">
          <div className="text-[#232323] text-xl sm:text-2xl font-semibold mb-1">{name}</div>
          <div className="hidden md:flex md:flex-row gap-2">
            {crumbs?.map((item, index) => (
              <div key={index} className="flex flex-row gap-2">
                <div
                  className={`${
                    index + 1 === crumbs.length && crumbs.length > 1
                      ? 'text-[#232323]'
                      : 'text-[#C800A5]'
                  } text-[10px] sm:text-sm font-normal ${
                    index + 1 < crumbs.length ? 'cursor-pointer' : ''
                  }`}
                  onClick={index + 1 < crumbs.length ? () => handleCrumbsClick(item) : () => {}}
                >
                  {item}
                </div>
                {index + 1 < crumbs.length && (
                  <div
                    className={`${
                      index + 2 === crumbs.length ? 'text-[#232323]' : 'text-[#C800A5]'
                    } text-[10px] sm:text-sm font-normal`}
                  >
                    {'>'}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
