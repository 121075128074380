import { images } from '../../../constants';

const SuccessPopUp = ({
  show,
  imageSuccess,
  message,
  labelSuccessButton,
  labelBackButton,
  handleSuccessButton,
  handleBackButton,
  title,
  highlightMessage,
  highlightColor
}) => {
  return (
    <div>
      <input
        type="checkbox"
        className="modal-toggle"
        checked={show}
        onChange={e => e.preventDefault()}
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div
          className="modal-box bg-white relative sm:rounded-2xl rounded-t-[32px] w-screen sm:min-w-[350px] sm:w-fit h-fit sm:h-fit"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex flex-col items-center items-center mb-[42px]">
            <img
              src={imageSuccess ? imageSuccess : images.imageSuccess}
              className="w-[120px] h-[120px] mb-9"
              alt="success icon"
            />
            <span className="font-semibold text-[14px] mb-1 mx-[25px]">{title}</span>
            <span className="mx-[24px] text-[12px] text-center text-gray-500">
              {message}
              <span style={{ color: highlightColor }} className="font-bold">
                {highlightMessage}
              </span>
            </span>
          </div>
          <div className="flex justify-center w-full flex-row sm:mt-9 sm:gap-8">
            {labelBackButton && (
              <button
                onClick={handleBackButton}
                className="py-3 px-8  text-[#6546C3] text-[12px] sm:text-[14px] font-semibold rounded-md"
              >
                {labelBackButton}
              </button>
            )}
            {labelSuccessButton && (
              <button
                onClick={handleSuccessButton}
                className="py-3 px-8 rounded-lg border-2 bg-[#6546C3] text-white text-[12px] sm:text-[14px] font-semibold whitespace-nowrap"
              >
                {labelSuccessButton}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPopUp;
