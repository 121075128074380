import { useEffect, useState } from 'react';
import { get } from '../../../../../../api/base';
import { apiUrls, icons } from '../../../../../../constants';
import { toast } from 'react-toastify';
import { Toast, Select } from '../../../../../../components';
import { useSelector } from 'react-redux';

const SubmitEmailModal = ({ showModal, setShowModal, submitForm, to, cc }) => {
  const [selectedToOptions, setSelectedToOptions] = useState(null);
  const [selectedCcOptions, setSelectedCcOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);
  const [ccOptions, setCcOptions] = useState([]);
  const [selectedTo, setSelectedTo] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const userGroups = useSelector(state => state.user.userData?.groups);
  const isGuestAccount = userGroups?.find(group => group?.name === 'GUEST USER');
  const handleOnToChange = selectedOptions => {
    const id = selectedOptions?.id || null;
    setSelectedToOptions(selectedOptions);
    setSelectedTo(id);
  };
  const handleOnCcChange = selectedOptions => {
    setSelectedCcOptions(selectedOptions);
  };

  const handleCreateOption = inputValue => {
    const newOption = {
      label: inputValue,
      value: inputValue
    };

    setSelectedCcOptions([...selectedCcOptions, newOption]);
  };
  const getDataUsersTo = () => {
    get(`${apiUrls.DYNAMIC_FORM}/users-notification`, { query: { type: 'TO' } }).then(res => {
      if (res.status === 200) {
        setToOptions(res.data.data);
        if (to) {
          let dataTo = res.data.data.find(item => item.id === to);
          setSelectedToOptions(dataTo);
          setSelectedTo(to);
        }
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />);
      }
    });
  };
  const getDataUsersCc = () => {
    get(`${apiUrls.DYNAMIC_FORM}/users-notification`, { query: { type: 'CC' } }).then(res => {
      if (res.status === 200) {
        setCcOptions(res.data.data);
        if (cc) {
          let newCc = cc.map(item => ({
            value: item.id !== undefined && item.full_name !== undefined ? item.id : item.email,
            label: item.full_name !== undefined ? item.full_name : item.email
          }));
          setSelectedCcOptions(newCc);
        }
        setIsFetching(false);
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />);
      }
    });
  };

  const transformedCcOptions = ccOptions.map(option => ({
    value: option.id,
    label: option.full_name
  }));

  const back = () => setShowModal(false);

  const submit = () => {
    let newSelectedCc = [];
    selectedCcOptions?.forEach(item => {
      let foundItem = ccOptions.find(option => option.id === item.value);

      if (foundItem) {
        newSelectedCc.push(foundItem);
      } else {
        newSelectedCc.push({ email: item.label });
      }
    });

    submitForm(selectedTo, newSelectedCc.length ? newSelectedCc : null);
  };

  useEffect(() => {
    if (showModal) {
      if (!isGuestAccount) {
        setIsFetching(true);
        getDataUsersCc();
        getDataUsersTo();
      }
    } else {
      setSelectedToOptions(null);
      setSelectedCcOptions([]);
      setSelectedTo(null);
    }
  }, [showModal]);
  return (
    <div>
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px]  sm:h-fit">
          <div className="bg-white">
            <div className="flex items-center justify-between">
              <div className="font-semibold text-[18px] text-[#232323]">
                Leave it blank if no need to send notification
              </div>
              <img
                src={icons.icCloseModal}
                className="w-6 h-6 cursor-pointer"
                alt={'close-modal'}
                onClick={() => setShowModal(false)}
              />
            </div>
            <hr className="mt-2 mb-6" />
            <div>
              {isFetching ? (
                <div className="flex justify-center py-6">
                  <div className="loading loading-dots loading-sm lg:loading-lg text-[#6546C3]"></div>
                </div>
              ) : (
                <>
                  <div>
                    {!isGuestAccount && (
                      <>
                        <div className="font-semibold text-[14px] mt-[28px] mb-[16px]">To</div>
                        <Select
                          getOptionLabel={option => option.full_name}
                          getOptionValue={option => option.email}
                          onChange={selectedOptions => handleOnToChange(selectedOptions)}
                          options={toOptions}
                          isSearchable
                          isClearable
                          name="Type"
                          className={
                            'input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4'
                          }
                          placeholder="Select to user"
                          isMulti={false}
                          value={selectedToOptions}
                          styles={{
                            option: provided => ({
                              ...provided
                            }),
                            control: provided => ({
                              ...provided,
                              ':focus': { borderColor: '#6546C3' },
                              ':active': { borderColor: '#6546C3' },
                              ':hover': { borderColor: '#6546C3' },
                              paddingLeft: 4,
                              boxShadow: 'none'
                            }),
                            dropdownIndicator: provided => ({
                              ...provided,
                              color: '#6546C3'
                            }),
                            indicatorSeparator: () => ({}),
                            valueContainer: provided => ({
                              ...provided
                            })
                          }}
                        />
                      </>
                    )}
                  </div>
                  <div>
                    <div className="font-semibold text-[14px] mt-[28px] mb-[16px]">Cc</div>
                    <Select
                      onChange={selectedOptions => handleOnCcChange(selectedOptions)}
                      onCreateOption={value => handleCreateOption(value)}
                      options={!isGuestAccount ? transformedCcOptions : []}
                      name="Type"
                      className={
                        'input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4'
                      }
                      placeholder="Select to cc"
                      isMulti={true}
                      isSearchable
                      isCreatable
                      value={selectedCcOptions}
                      styles={{
                        option: provided => ({
                          ...provided
                        }),
                        control: provided => ({
                          ...provided,
                          ':focus': { borderColor: '#6546C3' },
                          ':active': { borderColor: '#6546C3' },
                          ':hover': { borderColor: '#6546C3' },
                          paddingLeft: 4,
                          boxShadow: 'none'
                        }),
                        dropdownIndicator: provided => ({
                          ...provided,
                          color: '#6546C3'
                        }),
                        indicatorSeparator: () => ({}),
                        valueContainer: provided => ({
                          ...provided
                        }),
                        multiValueLabel: provided => ({
                          ...provided,
                          color: '#fff',
                          fontFamily: 'poppins'
                        }),
                        multiValueRemove: provided => ({
                          ...provided,
                          color: '#fff'
                        }),
                        multiValue: provided => ({
                          ...provided,
                          backgroundColor: '#6546C3',
                          borderRadius: 8
                        })
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          {!isFetching && (
            <div className="flex justify-center sm:justify-end w-full flex-row mt-9 gap-6">
              <button
                className="bg-transparent border-2 border-[#6546C3] rounded-lg py-3 px-8 font-semibold text-[#6546C3] text-[14px]"
                onClick={back}
              >
                Back
              </button>
              <button
                className="bg-[#6546C3] border-2 border-[#6546C3] rounded-lg py-3 px-8 font-semibold text-white text-[14px]r"
                onClick={submit}
              >
                Submit
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default SubmitEmailModal;
