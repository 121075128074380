import { guestMenus, icons } from '../../../constants';
import { useLocation, useNavigate, useParams } from 'react-router';
const GuestHeader = () => {
  const { id, responseId, parentId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  let currentMenu = guestMenus.find(obj => {
    let pathSegments = obj.path.toLowerCase().split('/');
    let stringSegments = pathname.toLowerCase().split('/');

    if (pathSegments.length !== stringSegments.length) {
      return false;
    }

    for (let i = 0; i < pathSegments.length; i++) {
      if (pathSegments[i] !== stringSegments[i] && !pathSegments[i].startsWith(':')) {
        return false;
      }
    }

    return true;
  });
  let name = currentMenu?.name;
  let crumbs = currentMenu?.crumbs;

  const handleCrumbsClick = item => {
    let menuItem = guestMenus.filter(menu => {
      let lastIndex = menu.crumbs?.length - 1;
      return menu.crumbs && menu.crumbs[lastIndex] === item;
    });

    if (menuItem.length === 1) {
      let path = menuItem[0].path.replace(':id', id);
      if (responseId) {
        path = path.replace(':responseId', responseId);
      }
      if (parentId) {
        path = path.replace(':parentId', parentId);
      }

      navigate(path, { state: menuItem[0] });
    } else {
      let currentMenuItem;
      if (responseId) {
        currentMenuItem = menuItem.find(menu => {
          return responseId
            ? menu.path.includes(':responseId')
            : !menu.path.includes(':responseId');
        });
      }
      if (parentId) {
        currentMenuItem = menuItem.find(menu => {
          return parentId ? menu.path.includes(':parentId') : !menu.path.includes(':parentId');
        });
      }

      let path = currentMenuItem.path.replace(':id', id);
      if (responseId) {
        path = path.replace(':responseId', responseId);
      }
      if (parentId) {
        path = path.replace(':parentId', parentId);
      }

      navigate(path, { state: currentMenuItem });
    }
  };

  return (
    <div className="min-h-[135px] flex flex-col w-screen lg:w-full">
      <div className="flex flex-row justify-between lg:justify-end absolute z-[2] w-full lg:w-[calc(100%-288px)]">
        <label htmlFor="my-drawer" className="drawer-button cursor-pointer lg:hidden">
          <img src={icons.icHamburger} alt="Menu" className="w-[48px] h-[48px]" />
        </label>
      </div>
      <div className="flex flex-1 flex-col items-center md:items-start justify-center p-6 z-[1] mt-10">
        <div className="text-[#232323] text-xl sm:text-2xl font-semibold mb-1">{name}</div>
        <div className="hidden md:flex md:flex-row gap-2">
          {crumbs?.map((item, index) => (
            <div key={index} className="flex flex-row gap-2">
              <div
                className={`${
                  index + 1 === crumbs.length && crumbs.length > 1
                    ? 'text-[#232323]'
                    : 'text-[#C800A5]'
                } text-[10px] sm:text-sm font-normal ${
                  index + 1 < crumbs.length ? 'cursor-pointer' : ''
                }`}
                onClick={index + 1 < crumbs.length ? () => handleCrumbsClick(item) : () => {}}
              >
                {item}
              </div>
              {index + 1 < crumbs.length && (
                <div
                  className={`${
                    index + 2 === crumbs.length ? 'text-[#232323]' : 'text-[#C800A5]'
                  } text-[10px] sm:text-sm font-normal`}
                >
                  {'>'}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GuestHeader;
