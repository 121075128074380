const PaginationAccent = props => {
  const { totalPage, page, onPrev, onChangePage, onNext } = props;

  function showRenderPageNumbers(pageOptions) {
    if (pageOptions) {
      let result = pageOptions.map((data, index) => {
        if (data === 0)
          return (
            <div key={index} className="p-1 px-2 font-bold mr-1 ">
              ...
            </div>
          );
        return (
          <div
            key={index}
            className={`w-8 h-8 py-[8px] font-poppins cursor-pointer text-center rounded-full ${
              page === data ? 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white' : ''
            }`}
            onClick={() => onChangePage(data)}
          >
            {data}
          </div>
        );
      });
      return result;
    }
  }

  function showPaginationNumbers(pageNumbers) {
    let paginationNumbers = [];
    if (pageNumbers) {
      paginationNumbers.push(1);
      if (page - 2 > 1) paginationNumbers.push(0);
      if (page - 1 > 1) paginationNumbers.push(page - 1);
      if (page !== 1 && page !== pageNumbers) paginationNumbers.push(page);
      if (page + 1 < pageNumbers) paginationNumbers.push(page + 1);
      if (page + 2 < pageNumbers) paginationNumbers.push(0);
      if (pageNumbers > 1) paginationNumbers.push(pageNumbers);

      return showRenderPageNumbers(paginationNumbers);
    }
  }
  return (
    <div className="flex font-poppins text-[12px]">
      <button
        onClick={() => onPrev()}
        className={
          'w-8 h-8 mr-2 py-[5px] font-poppins cursor-pointer text-center rounded-full bg-[#F2F5FC]'
        }
      >
        &lt;
      </button>
      <div className="bg-[#F2F5FC] rounded-full flex">{showPaginationNumbers(totalPage)}</div>
      <button
        onClick={() => onNext()}
        className={
          'w-8 h-8 ml-2 py-[5px] font-poppins cursor-pointer text-center rounded-full bg-[#F2F5FC]'
        }
      >
        &gt;
      </button>
    </div>
  );
};

export default PaginationAccent;
