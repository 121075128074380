import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/auth';
import tableSlice from './slices/table';
import popupSlice from './slices/popup';
import userSlice from './slices/user';
import formSlice from './slices/form';
import settingsSlice from './slices/setting';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const reducer = combineReducers({
  auth: authSlice,
  table: tableSlice,
  popup: popupSlice,
  user: userSlice,
  form: formSlice,
  setting: settingsSlice
});

const config = {
  key: 'root',
  storage,
  blacklist: ['table', 'popup', 'form', 'setting']
};

export const store = configureStore({
  reducer: persistReducer(config, reducer),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
