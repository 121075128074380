import { useEffect, useRef, useState, useCallback } from 'react';
import { apiUrls, icons } from '../../../../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Toast } from '../../../../../../../components';
import { setShowLoading } from '../../../../../../../store/slices/popup';
import { del, post } from '../../../../../../../api/base';
import _ from 'lodash';
import { useNavigate } from 'react-router';
import Webcam from 'react-webcam';
import { IoCameraReverseOutline } from 'react-icons/io5';
import { cacheHelper, offlineHelper } from '../../../../../../../helpers';
import { v4 as uuidv4 } from 'uuid';
const ImageFromCameraModal = ({ showModal, setShowModal, id, setIssue, setComments }) => {
  const [attachments, setAttachments] = useState([]);
  const [rawAttachment, setRawAttachments] = useState([]);
  const [displayImage, setDisplayImage] = useState({});
  const [remark, setRemark] = useState('');
  const navigate = useNavigate();
  const user = useSelector(state => state.user.userData);
  const dispatch = useDispatch();
  const uuid = uuidv4();
  const [captureMode, setCaptureMode] = useState(false);
  const [img, setImg] = useState(null);
  const [facingMode, setFacingMode] = useState('user');

  const webcamRef = useRef(null);

  const videoConstraints = {
    width: 420,
    height: 420,
    facingMode: facingMode
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImg(imageSrc);
  }, [webcamRef]);

  const switchCamera = useCallback(() => {
    setFacingMode(prevState => (prevState === 'user' ? 'environment' : 'user'));
  }, []);

  useEffect(() => {
    if (img) handleAddAttachment(img);
  }, [img]);

  function base64ToBlob(base64) {
    const parts = base64.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uint8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uint8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uint8Array], { type: contentType });
  }

  async function handleAddAttachment(imageFile) {
    const blob = base64ToBlob(imageFile);

    const fileName = 'picture.jpg';
    const fileType = 'image/jpeg';
    const file = new File([blob], fileName, { type: fileType });

    const fileSizeUser = user?.file_size * 1048576;
    const formData = new FormData();
    formData.append('files[]', file, file.name);

    let statusFile;
    if (attachments?.length <= 5) {
      if (file.size > fileSizeUser) {
        statusFile = false;
        toast.error(
          <Toast
            message={'Error'}
            detailedMessage={`Cannot upload ${file.name} because the size of file is more than ${fileSizeUser} mb`}
          />
        );
      } else {
        statusFile = true;
        formData.append('files[]', {
          uri: URL.createObjectURL(file),
          type: file.type,
          name: file.name
        });
      }

      if (statusFile || formData._parts.length) {
        dispatch(setShowLoading(true));
        if (navigator.onLine) {
          post(`${apiUrls.ISSUE}/attachments`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(response => {
            if (response.status === 200) {
              let rawAttachment = attachments.concat(response.data);
              setAttachments(rawAttachment);
              setDisplayImage(rawAttachment[rawAttachment.length - 1]);
              setCaptureMode(false);
            } else if (response.status === 0) {
              toast.error(
                <Toast
                  message={'Error'}
                  detailedMessage={'Cannot Reach server, please try again later'}
                />
              );
            } else {
              toast.error(
                <Toast message={'Error'} detailedMessage={`${response.data.error.message}`} />
              );
            }
            dispatch(setShowLoading(false));
          });
        } else {
          const tempAttachment = await Promise.all(
            formData
              .getAll('files[]')
              .filter(data => data instanceof File)
              .map(async file => {
                return await offlineHelper.saveAttachmentOffline(file);
              })
          );
          const newAttachments = [];
          setRawAttachments(rawAttachment.concat(tempAttachment));
          if (!_.isEmpty(tempAttachment) && _.has(tempAttachment[0], 'cacheIdentifier')) {
            for (const value of tempAttachment) {
              await cacheHelper.getCachedAttachment(value.cacheIdentifier).then(res => {
                newAttachments.push(res);
              });
            }
            setAttachments(attachments.concat(newAttachments));
            setDisplayImage([attachments.length, ...newAttachments]);
          } else {
            setAttachments(attachments.concat(attachments));
          }
          setCaptureMode(false);
          dispatch(setShowLoading(false));
        }
      }
    } else {
      toast.error(
        <Toast
          message={'Error'}
          detailedMessage={'You have reached the maximum number of file uploads, maximum 5'}
        />
      );
    }
    webcamRef.current.value = null;
  }

  const deleteAttachment = id => {
    dispatch(setShowLoading(true));
    del(`${apiUrls.ISSUE}/attachments/${id}`).then(response => {
      dispatch(setShowLoading(false));
      if (response.status === 200) {
        setAttachments(prevAttachments => prevAttachments.filter(attach => attach.id !== id));
      } else {
        toast.error(<Toast message={'Error'} detailedMessage={`${response.data.error.message}`} />);
      }
    });
  };

  const handleCloseModal = () => {
    setCaptureMode(false);
    setAttachments([]);
    setDisplayImage({});
    setShowModal(false);
  };

  const handleDeleteAttachment = id => {
    if (navigator.onLine) {
      let temp = attachments.filter(attachment => attachment.id !== id);
      if (_.isArray(attachments)) {
        deleteAttachment(id);
      }
      if (temp.length) {
        setDisplayImage(temp[temp.length - 1]);
      } else {
        handleCloseModal();
      }
    } else {
      cacheHelper.deleteCachedAttachment(rawAttachment[id].cacheIdentifier);
      rawAttachment.splice(id, 1);
      attachments.splice(id, 1);
      setAttachments([...attachments]);
      dispatch(setShowLoading(false));
      if (attachments.length) {
        setDisplayImage([attachments.length - 1, attachments[attachments.length - 1]]);
      } else {
        handleCloseModal();
      }
    }
  };

  const handleSendButton = () => {
    let payload = {
      remark: {
        message: remark
      },
      attachments: navigator.onLine ? attachments : rawAttachment,
      description: 'Added Images'
    };
    dispatch(setShowLoading(true));
    if (navigator.onLine) {
      post(`${apiUrls.ISSUE}/${id}/comments`, payload)
        .then(response => {
          dispatch(setShowLoading(false));
          if (response.status === 201) {
            handleCloseModal();
            navigate(0);
          } else {
            toast.error(<Toast message={'Error'} detailedMessage={response.data.error.message} />);
          }
        })
        .catch(e => {
          dispatch(setShowLoading(false));
          toast.error(<Toast message={'Error'} detailedMessage={e} />);
        });
    } else {
      dispatch(setShowLoading(false));
      let tempIssue;
      let tempComment = {
        id: 'offline' + uuid,
        remark: {
          message: [{ value: remark, type: 'TEXT' }]
        },
        description: 'Added Images',
        attachments: rawAttachment,
        recording: null,
        created_at: new Date().toISOString(),
        sender: user?.full_name
      };
      cacheHelper
        .getCachedData(apiUrls.ISSUE)
        .then(response => {
          let index = response.data?.findIndex(issue => issue.id.toString() === id.toString());
          tempIssue = response.data;
          tempIssue[index].comments.unshift(tempComment);
          offlineHelper
            .updateRequestDataOffline({
              url: `${apiUrls.ISSUE}/${id}/comments`,
              body: payload,
              cacheUrl: apiUrls.ISSUE,
              key: 'data',
              data: [...tempIssue]
            })
            .then(_ => {
              setIssue(tempIssue[index]);
              setComments(tempIssue[index].comments.sort((a, b) => b.id - a.id));
              handleCloseModal();
            })
            .catch(_ => {
              dispatch(setShowLoading(false));
              toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
            });
        })
        .finally(() => {
          dispatch(setShowLoading(false));
        });
    }
  };

  useEffect(() => {
    if (showModal) setCaptureMode(true);
  }, [showModal]);

  return (
    <div>
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-middle bg-[#232323]/[0.5]">
        <div className="flex flex-row justify-between items-center bg-[#6546C3] w-full top-0 fixed px-4 sm:px-16 py-4">
          <div className="flex flex-row gap-4 items-center" onClick={handleCloseModal}>
            <img
              src={icons.icCloseModal}
              alt="close"
              className="w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] cursor-pointer"
            />
            <div className="font-semibold text-[12px] text-white">Close</div>
          </div>
          {!_.isEmpty(displayImage) && !captureMode ? (
            <button
              className="bg-[#EBEDF8] w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] rounded-xl flex items-center justify-center"
              onClick={e => {
                e.stopPropagation();
                handleDeleteAttachment(navigator.onLine ? displayImage?.id : displayImage[0]);
              }}
            >
              <img
                src={icons.icTrash}
                className="w-[20px] h-[20px] sm:w-[30px] sm:h-[30px]"
                alt="clear"
              />
            </button>
          ) : captureMode ? (
            <div className="flex items-center gap-4">
              <IoCameraReverseOutline
                className="w-full h-[30px] sm:h-[40px]"
                color="white"
                onClick={switchCamera}
              />
              <div
                className="font-semibold text-[12px] text-white"
                onClick={() => setCaptureMode(false)}
              >
                Cancel
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="flex flex-col items-center gap-6 w-full relative">
          {!captureMode ? (
            <>
              {!_.isEmpty(displayImage) ? (
                <div className="modal-box flex flex-col bg-transparent sm:min-w-[400px] sm:w-fit p-0">
                  <img
                    src={displayImage?.url || window.URL?.createObjectURL(displayImage[1])}
                    alt="image_choosed"
                  />
                </div>
              ) : (
                <div className="modal-box flex flex-col bg-white sm:min-w-[400px] sm:w-fit h-[350px] sm:h-[400px] p-0 items-center justify-center">
                  No Image To Display
                </div>
              )}
              <div className="flex flex-row items-center justify-center gap-2 w-full mx-[18px] sm:mx-0">
                <div className="flex bg-white w-full h-[40px] border-[1px] items-center p-[12px] rounded-lg  rounded-lg">
                  <img
                    src={icons.icGallery}
                    className="w-[24px] h-[24px] cursor-pointer"
                    alt="Attachment"
                    onClick={() => setCaptureMode(true)}
                  />
                  <input
                    className="ml-2 focus:outline-none text-[12px] w-full"
                    value={remark}
                    onChange={event => setRemark(event.target.value)}
                    placeholder="Add remark..."
                  />
                </div>
                <div
                  className="bg-[#6546C3] p-[6px] rounded-lg cursor-pointer"
                  onClick={handleSendButton}
                >
                  <img src={icons.icSend} className="w-[24px] h-[24px] " alt="send" />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="modal-box flex flex-col bg-white sm:min-w-[400px] sm:w-fit h-[400px] p-0 items-center justify-center">
                <Webcam
                  audio={false}
                  mirrored={facingMode === 'user'}
                  height={400}
                  width={400}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                />
              </div>
              <div className="w-[68px] h-[68px] absolute bottom-[-30px] rounded-full bg-[#232323]/[0.5] flex items-center justify-center cursor-pointer">
                <img
                  src={icons.icCapture}
                  className="w-[66px] h-[66px]"
                  onClick={capture}
                  alt="capture"
                />
              </div>
            </>
          )}
        </div>

        <div className="flex flex-row items-center bg-white w-full bottom-0 fixed px-4 sm:px-16 py-4 rounded-t-3xl gap-8">
          {attachments?.map((attachment, index) => {
            return (
              <img
                onClick={() =>
                  navigator.onLine
                    ? setDisplayImage(attachment)
                    : setDisplayImage([index, attachment])
                }
                key={index}
                src={attachment?.url || window.URL?.createObjectURL(attachment)}
                alt={attachment?.fileName}
                className="w-[64px] h-[66px] rounded-lg"
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ImageFromCameraModal;
