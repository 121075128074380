import cacheHelper from './cache.helper';
import indexedDBHelper from './indexedDB.helper';

const saveAttachmentOffline = async attachment => {
  const cache = await cacheHelper.cacheAttachment(attachment);

  return {
    file: attachment,
    cacheIdentifier: cache
  };
};

const updateRequestDataOffline = ({ url, body, data, cacheUrl, key = 'data' }) => {
  return new Promise((resolve, reject) => {
    indexedDBHelper
      .storeRequestData({ url, body })
      .then(async res => {
        await cacheHelper.updateDataCache(data, { cacheUrl, key });
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const saveRequestDataOffline = ({ url, body, data, cacheUrl, key = 'data' }) => {
  return new Promise((resolve, reject) => {
    indexedDBHelper
      .storeRequestData({ url, body })
      .then(res => {
        cacheHelper.addDataToCache(data, { cacheUrl, key });
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export default { saveAttachmentOffline, saveRequestDataOffline, updateRequestDataOffline };
