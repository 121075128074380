import { images } from '../../../constants';

const ConfirmationModal = ({
  showModal,
  setShowModal,
  title,
  message,
  labelCancelButton,
  labelSubmitButton,
  handleSubmit,
  handleCancel
}) => {
  return (
    <div>
      <input
        type="checkbox"
        className="modal-toggle"
        checked={showModal}
        onChange={e => e.preventDefault()}
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div
          className="modal-box bg-white relative sm:rounded-2xl rounded-t-[32px] w-screen sm:w-[350px] h-fit sm:h-fit py-[40px] px-[35px]"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex flex-col items-center items-center mb-[42px]">
            <img
              src={images.imageConfirmation}
              className="w-[120px] h-[120px] mb-9"
              alt="confirmation"
            />
            <span className="font-semibold text-[14px] mb-1">{title}</span>
            <span className="w-[232px] text-[12px] text-center text-gray-500">{message}</span>
          </div>
          <div className="flex justify-center w-full flex-row sm:mt-9 gap-[14px]">
            <button
              className="bg-transparent border-2 border-[#6546C3] rounded-lg py-[10px] w-[130px] font-semibold text-[#6546C3] 
                    text-[14px] flex justify-center items-center"
              onClick={handleCancel ? handleCancel : () => setShowModal(false)}
            >
              {labelCancelButton || 'Cancel'}
            </button>
            <button
              className="bg-[#6546C3] border-2 border-[#6546C3] rounded-lg py-[10px] w-[130px] font-semibold text-white 
                    text-[14px] flex justify-center items-center shadow-lg"
              onClick={handleSubmit}
            >
              {labelSubmitButton}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
