import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { apiUrls, icons } from '../../../../../../../constants';
import { useState } from 'react';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Toast } from '../../../../../../../components';
import { post } from '../../../../../../../api/base';
import { setShowLoading } from '../../../../../../../store/slices/popup';
import { useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { cacheHelper, offlineHelper } from '../../../../../../../helpers';

const VoiceRecorderModal = ({ showModal, setShowModal, id, setTask, setComments }) => {
  const recorderControls = useAudioRecorder();
  const [audio, setAudio] = useState('');
  const [rawAudio, setRawAudio] = useState([]);
  const [duration, setDuration] = useState(0);
  const uuid = uuidv4();
  const navigate = useNavigate();
  const user = useSelector(state => state.user.userData);
  let attachment = new FormData();
  let dispatch = useDispatch();

  const handleStopRecording = async blob => {
    if (blob.size > user?.file_size * 1048576) {
      toast.error(
        <Toast
          message={'Error'}
          detailedMessage={`Cannot upload  because the size of file is more than ${user?.file_size} mb`}
        />
      );
    } else {
      const file = new File([blob], 'recording.webm', {
        type: 'audio/webm;codecs="opus"'
      });
      attachment.append('files[]', file, file.name);
      attachment.append('files[]', {
        uri: window.URL.createObjectURL(file),
        type: file.type,
        name: file.name
      });

      if (!navigator.onLine) {
        const tempAttachment = await Promise.all(
          attachment
            .getAll('files[]')
            .filter(data => data instanceof File)
            .map(async file => {
              return await offlineHelper.saveAttachmentOffline(file);
            })
        );
        setRawAudio(tempAttachment);
      }
      setAudio(attachment);
    }
  };

  const handleSendAudio = () => {
    dispatch(setShowLoading(true));
    if (navigator.onLine) {
      post('/api/dynamic-forms/attachments', audio, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        dispatch(setShowLoading(false));
        if (res.status === 200) {
          let reqBodyApi = {
            recording: { ...res.data[0], duration },
            description: 'Added Record Audio'
          };
          post(`${apiUrls.TASK}/${id}/comments`, reqBodyApi).then(response => {
            dispatch(setShowLoading(false));
            if (response.status === 201) {
              navigate(0);
            } else {
              toast.error(
                <Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />
              );
            }
          });
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />);
        }
      });
    } else {
      dispatch(setShowLoading(false));
      let tempTask;
      let tempComment = {
        id: 'offline' + uuid,
        remark: {
          message: []
        },
        description: 'Added Record Audio',
        attachments: [],
        recording: rawAudio,
        created_at: new Date().toISOString(),
        sender: user?.full_name
      };
      cacheHelper
        .getCachedData(apiUrls.TASK)
        .then(response => {
          let index = response.data?.findIndex(task => task.id.toString() === id.toString());
          tempTask = response.data;
          tempTask[index].comments.unshift(tempComment);
          offlineHelper
            .updateRequestDataOffline({
              url: `${apiUrls.TASK}/${id}/comments`,
              body: { recording: rawAudio, description: 'Added Record Audio' },
              cacheUrl: apiUrls.TASK,
              key: 'data',
              data: [...tempTask]
            })
            .then(_ => {
              setTask(tempTask[index]);
              setComments(tempTask[index].comments.sort((a, b) => b.id - a.id));
              setShowModal(false);
            })
            .catch(_ => {
              dispatch(setShowLoading(false));
              toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
            });
        })
        .finally(() => {
          dispatch(setShowLoading(false));
        });
    }
  };

  return (
    <div>
      <input
        type="checkbox"
        id="filterModal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle bg-[#232323]/[0.5]">
        <div className="modal-box flex flex-col bg-white w-screen sm:w-[512px] md:w-[780px] h-fit p-0">
          <div className="bg-white p-6">
            <div className="flex flex-row items-center justify-between">
              <div className="text-[18px] font-semibold">Recording Audio</div>
              <img
                src={icons.icCloseModal}
                className="w-6 h-6 cursor-pointer"
                alt={'close-modal'}
                onClick={() => setShowModal(false)}
              />
            </div>
            <hr className="mt-2 mb-6" />
            <div className="flex flex-col gap-2 items-center justify-center">
              {audio ? (
                <>
                  <AudioPlayer
                    src={window.URL.createObjectURL(recorderControls.recordingBlob)}
                    autoPlay={false}
                    showSkipControls={false}
                    showJumpControls={false}
                    customProgressBarSection={[
                      RHAP_UI.MAIN_CONTROLS,
                      RHAP_UI.PROGRESS_BAR,
                      <button
                        className="bg-[#EBEDF8] w-10 h-10 rounded-full flex items-center justify-center"
                        onClick={e => {
                          e.stopPropagation();
                          setAudio('');
                          setRawAudio({});
                        }}
                      >
                        <img src={icons.icTrash} className="w-6 h-6" alt="clear" />
                      </button>
                    ]}
                    customControlsSection={[<div></div>, RHAP_UI.DURATION]}
                  />
                  <img
                    onClick={handleSendAudio}
                    src={icons.icChecklistWithBackground}
                    className="w-[40pt] h-[40pt] cursor-pointer"
                    alt="clear"
                  />
                </>
              ) : (
                <>
                  <div>
                    {new Date(recorderControls.recordingTime * 1000).toISOString().substr(11, 8)}
                  </div>
                  {recorderControls.isRecording ? (
                    <div
                      className="w-59 h-59 rounded-full bg-[#6546C3] items-center justify-center p-2 cursor-pointer"
                      onClick={() => {
                        setDuration(recorderControls.recordingTime);
                        recorderControls.stopRecording();
                      }}
                    >
                      <canvas height={20} width={20} className=" bg-white rounded-sm" />
                    </div>
                  ) : (
                    <div
                      onClick={() => recorderControls.startRecording()}
                      className="w-59 h-59 rounded-full bg-[#6546C3] items-center justify-center cursor-pointer"
                    >
                      <img src={icons.icMic} alt="mic" height={40} width={40} />
                    </div>
                  )}
                </>
              )}
              <div className="display-none">
                <AudioRecorder
                  onRecordingComplete={blob => handleStopRecording(blob)}
                  recorderControls={recorderControls}
                  downloadFileExtension="wav"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoiceRecorderModal;
