import { useEffect, useState } from 'react';
import { get } from '../../api/base';
import { apiUrls, icons, images } from '../../constants';
import { EmptyData, Loading, TableAccent, Toast } from '../../components';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';
import FilterModal from './component/Filter';
import { useNavigate } from 'react-router-dom';

const calculateType = type => {
  switch (type) {
    case 'Issue':
      return '#C800A5';
    case 'Form':
      return '#FF7301';
    default:
      return '#6546C3';
  }
};

const CardMyActivity = ({ activity, handleActionClicked }) => {
  return (
    <div
      className="flex flex-row p-5 bg-white rounded-box items-center mt-2"
      onClick={() => handleActionClicked(activity)}
    >
      <div className="mr-2">
        <img
          src={activity?.creator?.picture || images.imageDummyAvatar}
          alt="document"
          height={36}
          width={36}
        />
      </div>
      <div className="flex flex-col w-[200px]">
        <div className={'min-w-[6rem] font-bold text-[12px] font-semibold truncate'}>
          {activity?.creator?.full_name}
        </div>
        <div className={'w-[40vw] text-[12px] rounded-lg truncate'}>{activity?.title}</div>
        {activity?.due_date ? (
          <div className="rounded-full text-[#C800A5] bg-[#C800A5] bg-opacity-[0.1] p-[2px]  items-center text-[12px] flex flex-row w-[110px]">
            <img
              src={icons.icLastUpdate}
              alt="lastUpdate"
              height={15}
              width={15}
              className="mr-2"
            />
            {moment(activity?.due_date).format('DD-MMM-YYYY')}
          </div>
        ) : (
          <div className="text-[#C800A5] text-[12px]">No due date</div>
        )}
      </div>
      <div className="flex flex-col ml-auto items-center">
        <div
          className={'text-[12px] font-semibold truncate mb-5'}
          style={{ color: calculateType(activity?.type) }}
        >
          {activity?.type}
        </div>
        <img
          src={icons.icArrowRight}
          alt="status"
          className={'text-[#01B59C]'}
          width={26}
          height={10}
        />
      </div>
    </div>
  );
};

const MyActivity = () => {
  const [activities, setActivities] = useState([]);
  const [cloneActivities, setCloneActivities] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const { limits, page, pageSize, search, order_by } = useSelector(state => state.table);
  const [pageRef, setPageRef] = useState(1);
  const [types, setTypes] = useState(null);
  const [searchRef, setSearchRef] = useState('');
  const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });
  const [totalData, setTotalData] = useState(0);
  const [firstFetching, setFirstFetching] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [sync, setSync] = useState(false);
  const [notSync, setNotSync] = useState(false);
  const navigate = useNavigate();
  const tableHeaders = [
    {
      key: 'sync',
      name: '',
      isSortable: false,
      sortKey: null
    },
    {
      key: 'action',
      name: 'Action',
      isSortable: false,
      sortKey: null
    },
    {
      key: 'creator',
      name: 'Name',
      isSortable: false,
      sortKey: null
    },
    {
      key: 'title',
      name: 'Title',
      isSortable: false
    },
    {
      key: 'type',
      name: 'Type',
      isSortable: false
    },
    {
      key: 'due_date',
      name: 'Due Date',
      isSortable: false
    }
  ];

  const searchDataRef = ev => {
    switch (true) {
      case ev.key === 'Enter':
      case ev.target.value.length === 0:
        setSearchRef(ev.target.value);
        break;
      default:
        break;
    }
  };

  const getActivitiesMobile = reloadPage => {
    const filter = {
      order_by,
      sort_by: 'created_at',
      limit: 25,
      page: reloadPage ? 1 : pageRef,
      search: searchRef,
      type: types
    };
    if (!searchRef) {
      delete filter.search;
    }
    if (!types) {
      delete filter.type;
    }
    get(`${apiUrls.MY_ACTIVITY}`, {
      query: filter
    })
      .then(res => {
        setIsFetching(false);
        if (res.status === 200) {
          let temp = res.data;
          if (filter?.page === 1) {
            setActivities(temp);
            setCloneActivities(temp);
          } else {
            const result = activities.data.concat(temp.data);
            setActivities({ ...activities, data: result });
            setCloneActivities({ ...activities, data: result });
          }
          setTotalData(res.data.total_data);
          setFirstFetching(false);
          setPageRef(filter.page + 1);
        } else {
          toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error.message}`} />);
        }
      })
      .catch(_ => {
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
        setFirstFetching(false);
      });
  };

  const getActivities = () => {
    const filter = {
      sort_by: 'created_at',
      order_by,
      limit: pageSize,
      page,
      search,
      type: types
    };
    if (!search) {
      delete filter.search;
    }
    if (!types) {
      delete filter.type;
    }
    setIsFetching(true);
    get(`${apiUrls.MY_ACTIVITY}`, {
      query: filter
    })
      .then(res => {
        setIsFetching(false);
        if (res.status === 200) {
          setActivities(res.data);
          setCloneActivities(res.data);
          if (pageRef > res.data.total_page) {
            setPageRef(1);
          }
        }
      })
      .catch(_ => {
        toast.error(<Toast message={'Error'} detailedMessage={'Something went wrong'} />);
      });
  };

  const handleSync = () => {
    let rawFilter;
    if (notSync) {
      rawFilter = [...cloneActivities?.data.filter(elForm => elForm.id === null)];
    }
    if (sync) {
      rawFilter = [...cloneActivities?.data.filter(elForm => elForm.id !== null)];
    }
    if ((sync && notSync) || (!sync && !notSync)) {
      rawFilter = cloneActivities?.data;
    }
    setActivities({
      total_data: rawFilter?.length,
      total_page: rawFilter?.length / pageSize,
      data: rawFilter
    });
    setPageRef(1);
    setTotalData(rawFilter?.length);

    setFirstFetching(false);
  };

  useEffect(() => {
    handleSync();
  }, [sync, notSync]);

  useEffect(() => {
    if (!isSmallScreen) getActivities();
  }, [limits, page, pageSize, search, order_by, types, sync, notSync]);

  useEffect(() => {
    if (!firstFetching) setFirstFetching(true);
    if (isSmallScreen) getActivitiesMobile(true);
  }, [searchRef, types, order_by, sync, notSync]);

  const handleActionClicked = item => {
    switch (item.type) {
      case 'Issue':
        navigate(`/Task/DetailIssue/${item.id}`);
        break;
      case 'Task':
        navigate(`/Task/DetailTask/${item.id}`);
        break;
      case 'Form':
        navigate(`/Task/DetailForm/${item.id}`);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {isSmallScreen ? (
        <div className="pb-5 px-5 min-h-screen flex flex-col sm:hidden  mb-[100px]">
          <div className="max-h-[calc(100%-17rem)] flex flex-col gap-y-2 overflow-y-scroll">
            <div className="flex flex-row-reverse md:flex-row gap-4">
              <div className="flex border-[1px] border-[#463191] border-opacity-25 items-center justify-center w-[45px] h-[40px] rounded-lg">
                <img
                  src={icons.icFilter}
                  className="w-[26px] h-[26px]"
                  alt="Filter"
                  onClick={() => setShowModal(true)}
                />
              </div>
              <div className="flex w-full md:min-w-[150px] h-[40px] bg-white border-[1px] items-center p-[12px] rounded-lg">
                <img src={icons.icSearch} className="w-[14px] h-[14px]" alt="Search" />
                <input
                  className="ml-2 focus:outline-none text-[12px] w-full"
                  key={search}
                  defaultValue={search}
                  placeholder="Search here..."
                  onKeyDown={searchDataRef}
                  onBlur={searchDataRef}
                />
              </div>
            </div>
            {firstFetching ? (
              <div className="flex justify-center h-[50vh] my-auto">
                <Loading />
              </div>
            ) : activities?.data?.length ? (
              <InfiniteScroll
                dataLength={activities?.data?.length || 0}
                next={getActivitiesMobile}
                hasMore={activities?.data?.length < totalData}
                className="!overflow-hidden"
                loader={
                  <div className="flex justify-center h-[100px] my-auto">
                    <Loading />
                  </div>
                }
              >
                {activities?.data?.map((activity, index) => (
                  <CardMyActivity
                    activity={activity}
                    key={index}
                    handleActionClicked={handleActionClicked}
                  />
                ))}
              </InfiniteScroll>
            ) : (
              <EmptyData
                title={'There is no document to show'}
                message={'You do not have any activity'}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="mb-[80px] lg:mb-0">
          <TableAccent
            {...{
              isFetching,
              tableHeaders,
              data: activities,
              tableTitle: 'Activity List',
              tableTitleOnTop: true,
              iconFilter: icons.icFilter,
              onFilterClick: () => setShowModal(true),
              emptyDataTitle: 'There is no document to show',
              emptyDataDescription: 'You do not have any activity'
            }}
          >
            {(item, tHead) => {
              switch (tHead.key) {
                case 'sync':
                  return (
                    <div className="items-center w-[20px]">
                      <img
                        src={item.id ? icons.icChecklist : icons.icNotSync}
                        alt="status"
                        className={'text-[#01B59C] w-[20px] h-[20px]'}
                        width={20}
                        height={10}
                      />
                    </div>
                  );
                case 'action':
                  return (
                    <div className="flex justify-center m-0 w-full">
                      <button tabIndex={0} onClick={() => handleActionClicked(item)}>
                        <img src={icons.icArrowRight} alt="more" width={28} height={28} />
                      </button>
                    </div>
                  );
                case 'creator':
                  return (
                    <div className="flex flex-row items-center">
                      <img
                        src={item[tHead.key]?.picture || images.imageDummyAvatar}
                        alt="creator"
                        className="h-[24px] w-[24px] rounded-full"
                      />
                      <div className="ml-2 truncate">{item[tHead.key]?.full_name}</div>
                    </div>
                  );
                case 'due_date':
                  return (
                    <>
                      {item[tHead.key] ? (
                        <div className="rounded-full text-[#C800A5] bg-[#C800A5] bg-opacity-[0.1] p-1 items-center flex flex-row w-[130px]">
                          <img
                            src={icons.icLastUpdate}
                            alt="lastUpdate"
                            height={20}
                            width={20}
                            className="mr-2"
                          />
                          {moment(item[tHead.key]).format('DD-MMM-YYYY')}
                        </div>
                      ) : (
                        <div className="text-[#C800A5]">No due date</div>
                      )}
                    </>
                  );
                case 'type':
                  return (
                    <div className={`text-[${calculateType(item[tHead.key])}] font-semibold`}>
                      {item[tHead.key]}
                    </div>
                  );
                default:
                  return (
                    <div className=" flex flex-row text-[#232323] text-[12px] truncate w-full">
                      {item[tHead.key]}
                    </div>
                  );
              }
            }}
          </TableAccent>
        </div>
      )}
      <FilterModal
        showModal={showModal}
        setShowModal={setShowModal}
        setTypes={setTypes}
        setFirstFetching={setFirstFetching}
        setPageRef={setPageRef}
        setActivities={setActivities}
        setSync={setSync}
        setNotSync={setNotSync}
      />
    </>
  );
};

export default MyActivity;
