import { useState, useEffect } from 'react';
import FormInput from '../../components/FormInput';
import { apiUrls, icons, images } from '../../constants';
import { get, post } from '../../api/base';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import {
  savePassword,
  saveUsername,
  saveUuid,
  setRefreshToken,
  setToken
} from '../../store/slices/auth';
import { SuccessPopUp, Toast } from '../../components';
import { toast } from 'react-toastify';
import CryptoJS from 'crypto-js';
import { setUserData } from '../../store/slices/user';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import * as App from '../../index';
import LoadingComponent from './components/LoadingComponent';

import indexedDBHelper from '../../helpers/indexedDB.helper';
import { setGuestCompanyModal, setShowLoading } from '../../store/slices/popup';
import GuestRegistrationModal from './components/GuestRegistrationModal';
import GuestCompanyModal from '../../components/Popup/GuestCompanyModal';
import _ from 'lodash';
const LoginPage = () => {
  const [passIcon, setPassIcon] = useState(icons.icEyeClose);
  const [username, setUsername] = useState('');
  const location = useLocation();
  const fcmToken = useSelector(state => state?.auth?.fcmToken);
  const previousUrl = location.state?.from?.pathname;
  const [password, setPassword] = useState('');
  const [passType, setPassType] = useState('password');
  const [guestModal, setGuestModal] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [isFormError, setIsFormError] = useState(false);
  const handleUsernameChange = event => setUsername(event.target.value);
  const handlePasswordChange = event => setPassword(event.target.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uuid = uuidv4();
  const { msalInstance } = App;
  const accounts = msalInstance.getAllAccounts();
  const isAuthenticated = useIsAuthenticated();
  const [isLoading, setIsLoading] = useState(true);
  const LOGIN_WITH_AZURE_KEY = 'user_login_with_azure';
  const guestCompanyModal = useSelector(state => state.popup?.guestCompanyModal);
  const setShowGuestCompanyModal = condition => {
    dispatch(setGuestCompanyModal(condition));
  };
  const handlePassVisibility = () => {
    if (passType === 'password') {
      setPassType('text');
      setPassIcon(icons.icEyeOpen);
    } else {
      setPassType('password');
      setPassIcon(icons.icEyeClose);
    }
  };
  const encrypt = text => {
    return CryptoJS.AES.encrypt(JSON.stringify(text), process.env.REACT_APP_SECRET_PASS).toString();
  };
  const loginValidation = () => {
    if (username === '' || password === '') {
      return false;
    }
    return true;
  };
  const handleLogin = () => {
    dispatch(setShowLoading(true));
    if (loginValidation()) {
      post(apiUrls.LOGIN_URL, {
        username: username,
        password: password,
        uuid: uuid,
        token: fcmToken || null
      }).then(res => {
        dispatch(setShowLoading(false));
        if (res?.status === 200) {
          dispatch(setToken(res.data.token));
          dispatch(setRefreshToken(res.data.refresh_token));
          dispatch(saveUuid(uuid));
          dispatch(savePassword(encrypt(password)));
          dispatch(saveUsername(username));
          indexedDBHelper.storeRefreshToken(res.data.refresh_token);
          get(apiUrls.USER_PROFILE_URL).then(res => {
            if (res.status === 200) {
              const isGuestAccount = res.data?.groups?.find(group => group?.name === 'GUEST USER');
              dispatch(setUserData(res.data));
              if (!res.data?.company?.length || !res.data?.company) {
                dispatch(setGuestCompanyModal(true));
              } else if (_.isEmpty(isGuestAccount)) {
                if (previousUrl) {
                  navigate(previousUrl);
                } else {
                  navigate('/' + res.data?.landing_page);
                }
              }
            } else {
              toast.error(
                <Toast message={'Error'} detailedMessage={`${res.data.error?.message}`} />
              );
            }
          });
          setIsFormError(false);
        } else {
          setIsFormError(true);
          toast.error(<Toast message={'Error'} detailedMessage={`${res.data.error?.message}`} />);
        }
      });
    } else {
      dispatch(setShowLoading(false));
      setIsFormError(true);
      toast.error(
        <Toast message={'Error'} detailedMessage={'Username or Passwrod cannot be empty'} />
      );
    }
  };

  const { instance } = useMsal();

  const handleLoginAzure = () => {
    localStorage.clear();
    localStorage.setItem(LOGIN_WITH_AZURE_KEY, true);
    instance.loginRedirect(loginRequest).catch(e => {
      // eslint-disable-next-line no-console
      console.log(e);
    });
  };

  const callLoginAzureAPI = async () => {
    post(apiUrls.LOGIN_AZURE_URL).then(res => {
      if (res?.status === 200) {
        dispatch(setToken(res.data.token));
        dispatch(setRefreshToken(res.data.refresh_token));
        dispatch(saveUuid(uuid));
        dispatch(savePassword(encrypt(password)));
        dispatch(saveUsername(username));
        get(apiUrls.USER_PROFILE_URL).then(res => {
          if (res.status === 200) {
            dispatch(setUserData(res.data));
            navigate('/' + res.data?.landing_page);
          } else {
            toast.error(
              <Toast message={'Error'} detailedMessage={`${res.data?.error?.message}`} />
            );
          }
        });
        setIsFormError(false);
      } else {
        setIsFormError(true);
        toast.error(<Toast message={'Error'} detailedMessage={`${res?.data?.error?.message}`} />);
      }
    });
  };

  useEffect(() => {
    if (accounts.length > 0 && !isAuthenticated) {
      callLoginAzureAPI();
    }
  }, [accounts]);

  useEffect(() => {
    if (localStorage.getItem(LOGIN_WITH_AZURE_KEY)) {
      setTimeout(() => {
        setIsLoading(false);
        localStorage.removeItem(LOGIN_WITH_AZURE_KEY);
      }, 3000);
    }
  }, []);

  return (
    <>
      {localStorage.getItem(LOGIN_WITH_AZURE_KEY) && isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <div className="hidden lg:w-screen h-screen bg-gradient-to-b from-[#6546C3] to-[#9E7CE3] p-[16px] md:flex justify-center items-center">
            <div className="w-full h-full  bg-white rounded-[36px] p-[40px] flex flex-col md:flex-row">
              <div className="w-auto self-center h-auto overflow-hidden md:m-0">
                <img
                  src={images.imageLoginSide}
                  alt="ilustration"
                  className=" max-h-[85vh] self-center"
                />
              </div>
              <div className="flex-auto p-[8px] md:p-[40px] overflow-y-auto bg-[#EBEDF8] rounded-[36px] md:min-w-[400px]">
                <img src={images.imageM2PLogo} alt="ITM LOGO" className="w-[140px] mb-[16px]" />
                <div className="mb-1">
                  <div className="font-poppins font-normal text-[12px] text-[#232323]">
                    ONE click for any application
                  </div>
                </div>
                <div className="flex flex-col mt-[40px]">
                  <div className="flex-1">
                    <div>
                      <div className="mb-1">
                        <div className="font-poppins font-semibold text-[14px] text-[#232323]">
                          Username
                        </div>
                      </div>
                      <FormInput
                        type="text"
                        placeholder="Enter username"
                        value={username}
                        onChange={handleUsernameChange}
                        isError={isFormError}
                      />
                    </div>
                  </div>
                  <div className="flex-1 mt-[24px]">
                    <div className="mb-[45px]">
                      <div className="mb-1">
                        <div className="font-poppins font-semibold text-[14px] text-[#232323]">
                          Password
                        </div>
                      </div>
                      <FormInput
                        type={passType}
                        placeholder="Enter password"
                        value={password}
                        onChange={handlePasswordChange}
                        rightIcon={passIcon}
                        onRightIconClick={handlePassVisibility}
                        isError={isFormError}
                      />
                    </div>
                  </div>
                </div>
                <button
                  className="bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] font-poppins font-semibold text-[14px] text-white p-3 w-full rounded-md"
                  onClick={handleLogin}
                >
                  Login
                </button>
                <div className="flex justify-center my-[16px]">
                  <div className="text-[#AAAAAA]">or</div>
                </div>
                <button
                  className="bg-white flex flex-row font-poppins font-semibold text-[14px] text-black p-3 w-full rounded-md mt-[10px] justify-center"
                  onClick={handleLoginAzure}
                >
                  <img
                    src={icons.icAzure}
                    alt="azure"
                    width={24}
                    height={24}
                    className="mr-[8px]"
                  />
                  Login With Azure
                </button>
                <hr className="mt-4 mb-6 bg-[#DEDEDE]" />
                <div
                  onClick={() => setGuestModal(true)}
                  className="flex text-[#6546C3] text-[12px] justify-center w-full cursor-pointer"
                >
                  <div>Guest Registration</div>
                </div>
              </div>
            </div>
          </div>

          {/* Mobile View */}
          <div className="md:hidden w-screen h-screen bg-gradient-to-b from-[#6546C3] to-[#9E7CE3] pt-[16px] flex justify-center items-center">
            <div className="w-full h-full  bg-white rounded-[36px] flex flex-col md:flex-row">
              <img src={images.imageM2PLogo} alt="ITM LOGO" className="w-[140px] m-[16px]" />
              <div className="m-[16px] mt-0">
                <div className="font-poppins font-normal text-[12px] text-[#232323]">
                  ONE click for any application
                </div>
              </div>
              <div className="w-auto m-auto h-auto md:m-0">
                <img src={images.imageLoginSide} alt="ilustration" className="w-full self-center" />
              </div>
              <div className="flex-auto p-[8px] md:p-[10px] lg:p-[48px]  bg-[#EBEDF8]">
                <div className="flex flex-col mt-[40px]">
                  <div className="flex-1">
                    <div>
                      <div className="mb-1">
                        <div className="font-poppins font-semibold text-[14px] text-[#232323]">
                          Username
                        </div>
                      </div>
                      <FormInput
                        type="text"
                        placeholder="Enter username"
                        value={username}
                        onChange={handleUsernameChange}
                        isError={isFormError}
                        setIsFormError={setIsFormError}
                      />
                    </div>
                  </div>
                  <div className="flex-1 mt-[24px]">
                    <div className="mb-[48px]">
                      <div className="mb-1">
                        <div className="font-poppins font-semibold text-[14px] text-[#232323]">
                          Password
                        </div>
                      </div>
                      <FormInput
                        type={passType}
                        placeholder="Enter password"
                        value={password}
                        onChange={handlePasswordChange}
                        rightIcon={passIcon}
                        onRightIconClick={handlePassVisibility}
                        isError={isFormError}
                        setIsFormError={setIsFormError}
                      />
                    </div>
                  </div>
                </div>
                <button
                  className="bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] font-poppins font-semibold text-[14px] text-white p-3 w-full rounded-md"
                  onClick={handleLogin}
                >
                  Login
                </button>
                <div className="flex justify-center my-[16px]">
                  <div className="text-[#AAAAAA]">or</div>
                </div>
                <button
                  className="bg-white flex flex-row font-poppins font-semibold text-[14px] text-black p-3 w-full rounded-md mt-[10px] justify-center"
                  onClick={handleLoginAzure}
                >
                  <img
                    src={icons.icAzure}
                    alt="azure"
                    width={24}
                    height={24}
                    className="mr-[8px]"
                  />
                  Login With Azure
                </button>
                <hr className="mt-4 mb-6 bg-[#DEDEDE]" />
                <div
                  onClick={() => setGuestModal(true)}
                  className="flex text-[#6546C3] text-[12px] justify-center w-full mb-4"
                >
                  <div>Guest Registration</div>
                </div>
              </div>
            </div>
          </div>
          <GuestRegistrationModal
            showModal={guestModal}
            setShowModal={setGuestModal}
            setSuccessPopup={setSuccessPopup}
          />
          <SuccessPopUp
            show={successPopup}
            imageSuccess={images.imageSuccess}
            title={'Successfully'}
            message={'Your account has been successfully registered.'}
            highlightMessage={' Please check your email for account verification'}
            highlightColor={'#C800A5'}
            labelSuccessButton={'Ok'}
            handleSuccessButton={() => setSuccessPopup(false)}
          />
          <GuestCompanyModal
            showModal={guestCompanyModal}
            setShowModal={setShowGuestCompanyModal}
          />
        </>
      )}
    </>
  );
};

export default LoginPage;
