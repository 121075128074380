import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  moreModal: false,
  hideHeader: false,
  finishStoreForm: 'Failed'
};

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setMoreModal: (state, action) => {
      state.moreModal = action.payload;
    },
    setHideHeader: (state, action) => {
      state.hideHeader = action.payload;
    },
    setFinishStoreForm: (state, action) => {
      state.finishStoreForm = action.payload;
    }
  }
});

export const { setMoreModal, setHideHeader, setFinishStoreForm } = formSlice.actions;
export default formSlice.reducer;
