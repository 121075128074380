const icEyeOpen = require('../assets/icons/ic_eye_open.png');
const icEyeClose = require('../assets/icons/ic_eye_close.png');
const icCloseRound = require('../assets/icons/ic_close_round.png');
const icAzure = require('../assets/icons/ic_azure.png');
const icLogout = require('../assets/icons/ic_logout.png');
const icHamburger = require('../assets/icons/ic_hamburger.png');
const icNotif = require('../assets/icons/ic_notif.png');
const icNoNotif = require('../assets/icons/ic_no_notif.png');
const icHomeActive = require('../assets/icons/ic_home_active.png');
const icHomeInactive = require('../assets/icons/ic_home_inactive.png');
const icMyActivityActive = require('../assets/icons/ic_my_activity_active.png');
const icMyActivityInactive = require('../assets/icons/ic_my_activity_inactive.png');
const icTaskActive = require('../assets/icons/ic_task_active.png');
const icTaskInactive = require('../assets/icons/ic_task_inactive.png');
const icMore = require('../assets/icons/ic_more.png');
const icNotSync = require('../assets/icons/ic_not_sync.png');
const icChecklist = require('../assets/icons/ic_green_checklist.png');
const icSearch = require('../assets/icons/ic_search.png');
const icFilter = require('../assets/icons/ic_filter.png');
const icByAppName = require('../assets/icons/ic_by_appname.png');
const icByAppNameActive = require('../assets/icons/ic_by_appname_active.png');
const icByAreaName = require('../assets/icons/ic_by_areaname.png');
const icByAreaNameActive = require('../assets/icons/ic_by_areaname_active.png');
const icStar = require('../assets/icons/ic_star.png');
const icStarFullColor = require('../assets/icons/ic_star_full_color.png');
const icStarActive = require('../assets/icons/ic_star_active.png');
const icStarFilled = require('../assets/icons/ic_star_filled.png');
const icStarNotFilled = require('../assets/icons/ic_star_notfilled.png');
const icInfo = require('../assets/icons/ic_info.png');
const icExternalLink = require('../assets/icons/ic_external_link.png');
const icCloseModal = require('../assets/icons/ic_close_modal.png');
const icContactPerson = require('../assets/icons/ic_contact_person.png');
const icDocument = require('../assets/icons/ic_document.png');
const icLastUpdate = require('../assets/icons/ic_last_update.png');
const icArrowRight = require('../assets/icons/ic_arrow_right.png');
const icChecklistSource = require('../assets/icons/ic_checklist_source.png');
const icDownload = require('../assets/icons/ic_download.png');
const icShare = require('../assets/icons/ic_share_bg.png');
const icPen = require('../assets/icons/ic_pen.png');
const icTrash = require('../assets/icons/ic_trash.png');
const icCalendar = require('../assets/icons/ic_calendar.png');
const icAdd = require('../assets/icons/ic_add.png');
const icAddAttachment = require('../assets/icons/ic_add_attachment.png');
const icDoc = require('../assets/icons/ic_doc.png');
const icPpt = require('../assets/icons/ic_ppt.png');
const icXls = require('../assets/icons/ic_xls.png');
const icDuplicateForm = require('../assets/icons/ic_duplicate_form.png');
const icDraft = require('../assets/icons/ic_draft.png');
const icPrimaryChecklist = require('../assets/icons/ic_primary_checklist.png');
const icActiveUserOnline = require('../assets/icons/ic_active_user_online.png');
const icGroup = require('../assets/icons/ic_group.png');
const icCalendarWithBg = require('../assets/icons/ic_calendar_with_bg.png');
const icVisibility = require('../assets/icons/ic_visibility_private.png');
const icTrashSecondary = require('../assets/icons/ic_trash_secondary.png');
const icEditBg = require('../assets/icons/ic_edit_bg.png');
const icAssignBg = require('../assets/icons/ic_assign_bg.png');
const icOwnerBg = require('../assets/icons/ic_owner_bg.png');
const icTagBg = require('../assets/icons/ic_tag_bg.png');
const icAttachment = require('../assets/icons/ic_attachment.png');
const icSend = require('../assets/icons/ic_send.png');
const icCamera = require('../assets/icons/ic_camera.png');
const icVideo = require('../assets/icons/ic_video.png');
const icDocumentAttachment = require('../assets/icons/ic_document_attach.png');
const icGallery = require('../assets/icons/ic_gallery.png');
const icRecord = require('../assets/icons/ic_record.png');
const icPlus = require('../assets/icons/ic_plus.png');
const icPlayVideo = require('../assets/icons/ic_play_video.png');
const icCapture = require('../assets/icons/ic_capture.png');
const icMic = require('../assets/icons/ic_mic.png');
const icChecklistWithBackground = require('../assets/icons/ic_checklist_background.png');
const icCaptureVideo = require('../assets/icons/ic_capture_video.png');
const icCaptureVideoStop = require('../assets/icons/ic_capture_video_stop.png');
const icPoin = require('../assets/icons/ic_poin.png');
const icCompany = require('../assets/icons/ic_company.png');
const icEmail = require('../assets/icons/ic_email.png');
const icLandingPage = require('../assets/icons/ic_landing_page.png');
const icTimezone = require('../assets/icons/ic_timezone.png');
const icTeamsBg = require('../assets/icons/ic_teams_background.png');
const icWaBg = require('../assets/icons/ic_wa_background.png');
const icApprovalHistory = require('../assets/icons/ic_approval_history.png');
const icArrowDownCollapse = require('../assets/icons/ic_arrow_down_collapse.png');
const icArrowUpCollapse = require('../assets/icons/ic_arrow_up_collapse.png');
const icMarkAllRead = require('../assets/icons/ic_mark_all_read.png');
const icBack = require('../assets/icons/ic_back.png');
const icQrScannerInActive = require(`../assets/icons/Ic_QrScanner_inactive.png`);
const icQrScannerActive = require(`../assets/icons/Ic_QrScanner_active.png`);
const icPointDrawer = require(`../assets/icons/ic_point_drawer.png`);
const icFormScanner = require(`../assets/icons/ic_form_scanner.png`);
const icChangePicture = require(`../assets/icons/ic_change_icon.png`);
const icWA = require('../assets/icons/ic_wa.png');
const icTeams = require('../assets/icons/ic_teams.png');
const icInactiveUserLog = require('../assets/icons/ic_inactive_user_log.png');
const icActiveUserLog = require('../assets/icons/ic_active_user_log.png');
const icCanOffline = require('../assets/icons/ic_can_offline.png');
const icCantOffline = require("../assets/icons/ic_can't_offline.png");
const icActiveFeedback = require('../assets/icons/ic_active_feedback.png');
const icInctiveFeedback = require('../assets/icons/ic_inactive_feedback.png');
const icDefaultModule = require('../assets/icons/ic_default-module.png');
const icCreateSubResponse = require('../assets/icons/ic_create_sub_response.png');
const icAddSquare = require('../assets/icons/ic_add_square.png');
export default {
  icEyeOpen,
  icEyeClose,
  icCloseRound,
  icAzure,
  icLogout,
  icHamburger,
  icNotif,
  icNoNotif,
  icHomeActive,
  icHomeInactive,
  icMyActivityActive,
  icMyActivityInactive,
  icTaskActive,
  icTaskInactive,
  icMore,
  icNotSync,
  icChecklist,
  icSearch,
  icFilter,
  icByAppName,
  icByAppNameActive,
  icByAreaName,
  icByAreaNameActive,
  icStar,
  icStarFullColor,
  icStarActive,
  icStarFilled,
  icStarNotFilled,
  icInfo,
  icExternalLink,
  icCloseModal,
  icContactPerson,
  icDocument,
  icLastUpdate,
  icArrowRight,
  icChecklistSource,
  icDownload,
  icShare,
  icPen,
  icTrash,
  icCalendar,
  icAdd,
  icAddAttachment,
  icDoc,
  icXls,
  icPpt,
  icDuplicateForm,
  icDraft,
  icPrimaryChecklist,
  icActiveUserOnline,
  icGroup,
  icCalendarWithBg,
  icVisibility,
  icTrashSecondary,
  icEditBg,
  icAssignBg,
  icOwnerBg,
  icTagBg,
  icAttachment,
  icSend,
  icCamera,
  icRecord,
  icDocumentAttachment,
  icGallery,
  icVideo,
  icPlus,
  icPlayVideo,
  icCapture,
  icMic,
  icChecklistWithBackground,
  icCaptureVideo,
  icCaptureVideoStop,
  icPoin,
  icCompany,
  icEmail,
  icLandingPage,
  icTimezone,
  icTeamsBg,
  icWaBg,
  icApprovalHistory,
  icArrowDownCollapse,
  icArrowUpCollapse,
  icMarkAllRead,
  icBack,
  icQrScannerInActive,
  icQrScannerActive,
  icFormScanner,
  icPointDrawer,
  icChangePicture,
  icWA,
  icTeams,
  icInactiveUserLog,
  icActiveUserLog,
  icCanOffline,
  icCantOffline,
  icActiveFeedback,
  icInctiveFeedback,
  icDefaultModule,
  icCreateSubResponse,
  icAddSquare
};
