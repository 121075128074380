import { useState, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import SideNav from './components/SideNav';
import 'react-toastify/dist/ReactToastify.css';
import { images } from '../../constants';
import LoadingPopup from '../../components/Popup/LoadingPopup';
import ConfirmationModal from '../../components/Popup/ConfirmationModal';
import { useDispatch, useSelector } from 'react-redux';
import BottomNavigation from '../../components/BottomNavigation';
import QRCodeScanPage from '../QRScanPage';
import QRFormScanPage from '../QRScanPage/QRFormScanPage';
import { setHideHeader } from '../../store/slices/form';
import GuestHeader from '../GuestPage/GuestHeader';
import _ from 'lodash';
import GuestCompanyModal from '../../components/Popup/GuestCompanyModal';
import { setGuestCompanyModal, setSuccessModal } from '../../store/slices/popup';
import { SuccessPopUp } from '../../components';

const MainPage = () => {
  const location = useLocation();
  const [showTimezoneModal, setShowModalTimezone] = useState(false);
  const user = useSelector(state => state.user.userData);
  const guestCompanyModal = useSelector(state => state.popup?.guestCompanyModal);
  const successUpdateCompanyModal = useSelector(state => state.popup?.successModal);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hideHeader = useSelector(state => state.form.hideHeader);
  const userGroups = user?.groups;
  const isGuestAccount = userGroups?.find(group => group?.name === 'GUEST USER');

  useEffect(() => {
    if (!location.pathname.includes('FormSubmission/DetailFormSubmission') && hideHeader) {
      dispatch(setHideHeader(false));
    }
  }, [location]);

  useEffect(() => {
    if (!_.isEmpty(user) && (!user?.company?.length || !user?.company)) {
      dispatch(setGuestCompanyModal(true));
    }
  }, [user]);

  function timeZoneToOffset(sign, hours, minutes) {
    const totalMinutes = parseInt(hours, 10) * 60 + parseInt(minutes, 10);

    const offset = sign === '+' ? -totalMinutes : totalMinutes;

    return offset;
  }

  useEffect(() => {
    // check user timezone
    if (user?.timezone && !isGuestAccount) {
      const matches = user.timezone?.offset?.match(/^([+-])(\d{2}):?(\d{2})$/);

      if (!matches) {
        setShowModalTimezone(true);
        return;
      }

      const [, sign, hours, minutes] = matches;
      const timezoneOffset = timeZoneToOffset(sign, hours, minutes);
      const currentOffset = new Date().getTimezoneOffset();

      if (currentOffset !== timezoneOffset) {
        setShowModalTimezone(true);
      }
    }
  }, [user]);

  const setShowGuestCompanyModal = condition => {
    dispatch(setGuestCompanyModal(condition));
  };

  return (
    <>
      <div className="flex w-full h-full bg-[#F2F5FC] font-poppins">
        <div className="duration-300 fixed top-0 bottom-0 z-20">
          <SideNav />
        </div>
        <div className="flex-1 w-full overflow-auto lg:ml-72">
          {location.pathname !== '/Home' && (
            <div className="flex flex-row justify-between absolute w-full lg:w-[calc(100%-288px)]">
              <img
                src={images.imageBgOvalLeft}
                alt="Background Left"
                className="w-[100px] sm:w-[168px] h-[80px] sm:h-[148px]"
              />
              <img
                src={images.imageBgOvalRight}
                alt="Background"
                className="w-[180px] sm:w-[279px]"
              />
            </div>
          )}

          {location.pathname !== '/Notification' ? (
            !hideHeader ? (
              <div>{_.isEmpty(isGuestAccount) ? <Header /> : <GuestHeader />}</div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
          <div className="flex-auto min-h-[calc(100vh-135px)] overflow-hidden relative">
            <Outlet />
          </div>
          <BottomNavigation />
        </div>
      </div>
      <LoadingPopup />
      <ConfirmationModal
        showModal={showTimezoneModal}
        setShowModal={setShowModalTimezone}
        handleSubmit={() => {
          setShowModalTimezone(false);
          navigate('/Profile');
        }}
        title={"You're in different timezone"}
        message={'Would you like to update your local timezone profile?'}
        labelSubmitButton={'Yes'}
        labelCancelButton={'No'}
      />
      <QRCodeScanPage />
      <QRFormScanPage />
      <GuestCompanyModal showModal={guestCompanyModal} setShowModal={setShowGuestCompanyModal} />
      <SuccessPopUp
        show={successUpdateCompanyModal}
        imageSuccess={images.imageSuccess}
        title={'Successfully'}
        message={'Hooray! you have successfully set profile info'}
        labelSuccessButton={'Ok'}
        handleSuccessButton={() => {
          dispatch(setSuccessModal(false));
        }}
      />
    </>
  );
};

export default MainPage;
